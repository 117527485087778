import React from 'react';
import { BaseColor, Images } from "../config";

import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Image from 'react-bootstrap/Image';
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';

import PropTypes from "prop-types";
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Comments from '../Component/Comments';
import { httpPost } from '../Services/HttpServices';
import { toast } from 'react-toastify';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Link } from "react-router-dom";
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
    WhatsappIcon
} from "react-share";
import Editor from '../Component/QuillEditor';
import  RequestAnswer  from '../Component/RequestAnswer';
import  VoteList  from '../Component/VoteList';
function Question(props) {
    let dispatch = useDispatch()
    var auth = useSelector(({ auth }) => auth);
    let { question, answers, commentID } = props;
    question._id = question.id;
    let profilePicture = auth.ProfilePicture ? auth.ProfilePicture : Images.defaultProfile;
    const [userId, setUserId] = useState(question.user_id);
    const [userDetail, setUserDetail] = useState([]);
    const [answerpostdisplay, setAnswerPostDisplay] = useState(false)
    const [answer, setAnswer] = useState("");
    const [sortText, setSortText] = useState("Recommended");
    // Report List
    const [reportDisplay, setReportDisplay] = useState(false);
    const [reportTypes, setReportTypes] = useState([]);
    const [report, setReport] = useState({
        type: null,
        text: ""
    });

    const [seeMoreCount, setSeeMoreCount] = useState(0);
    const [isFollow, setIsFollow] = useState(null);
    var [currentPage, setCurrentPage] = useState(props.currentPage)
    const [updating, setUpdating] = useState(null);
    const [deleteQn, setDeleteQn] = useState(null);
    // Merge Question
    const [mergeQn, setMergeQn] = useState(false);
    const [searchQn, setSearchQn] = useState([]);
    const [mergeQnId, setMergeQnId] = useState(null);
    // Request Ans
    const [reqAns, setReqAns] = useState(false);
    const [searchUser, setSearchUser] = useState([]);
    const [requestQnId, setRequestQnId] = useState(null);
    const [loading, setLoading] = useState(false);

    var answersArr = []
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
            href=""
            ref={ref}
            onClick={e => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {/* Render custom icon here */}
          &#x25bc;
            {children}
        </a>
    ));
    // useEffect(() => {
    //     if (reportDisplay) {
    //         getReportTypes();
    //     }
    // }, [reportDisplay])
    useEffect(() => {
        if (question) {
            setUserId(question.user_id)
            getFollowDetails();
        }
    }, [props.question])
    useEffect(() => {
        if (userId) {
            getProfileDetail();
        }
    }, [userId])
    useEffect(() => {
        setAnswer("");
    }, [answerpostdisplay])
    useEffect(() => {
        // console.log('search user', searchUser);
    }, [searchUser])

    function handleUpvote(e) {
        if(auth && auth.user && auth.user._id){
            var upVoteObj = {
                AnswerID: e,
                QuestionID: props.questionID,
                upVote: 1
            }
            setUpdating(e);
            httpPost('answer_votes_controller/create', upVoteObj)
                .then(res => {
                    setUpdating(null);
                    if (res.status) {
                        props.questionArr(props.questionID)
                        toast.success(res.message);
                    } else {
                        toast.error(res.message)
                    }
                })
                .catch(err => {
                    setUpdating(null);
                    console.log('errr', err)
                })
        }else{
            window.location.href = "/signin?ref="+props.location.pathname;
        }
    }

    function handleDownvote(e) {
        if(auth && auth.user && auth.user._id){
            var downVoteObj = {
                AnswerID: e,
                QuestionID: props.questionID,
                downVote: 1
            }
            setUpdating(e);
            httpPost('answer_votes_controller/create', downVoteObj)
                .then(res => {
                    setUpdating(null);
                    if (res.status) {
                        props.questionArr(props.questionID)
                        toast.success(res.message);
                    } else {
                        toast.error(res.message)
                    }
                })
                .catch(err => {
                    setUpdating(null);
                    console.log('errr', err)
                })
        }else{
            window.location.href = "/signin?ref="+props.location.pathname;
        }
    }

    function handleQuestionvote(e, type) {
        if(auth && auth.user && auth.user._id){
            if(type === "upvote"){
                var voteObj = {
                    QuestionID: e,
                    upVote: 1
                }
            }else{
                var voteObj = {
                    QuestionID: e,
                    downVote: 1
                }
            }
            setUpdating(e);
            httpPost('question_votes_controller/create', voteObj)
                .then(res => {
                    setUpdating(null);
                    if (res.status) {
                        props.questionArr(props.questionID)
                        toast.success(res.message);
                    } else {
                        toast.error(res.message)
                    }
                })
                .catch(err => {
                    setUpdating(null);
                    console.log('errr', err)
                })
        }else{
            window.location.href = "/signin?ref="+props.location.pathname;
        }
    }

    function handleAnswer() {
        if(auth && auth.user && auth.user._id){
            setAnswerPostDisplay(!answerpostdisplay)
        }else{
            window.location.href = "/signin?ref="+props.location.pathname;
        }
    }
    function handleChangeInCreateAnswer(e) {
        setAnswer(e.target.value)
    }
    function handleCreateAnswer() {
        if(auth && auth.user && auth.user._id){
            var answerObj = {
                Answer: answer,
                QuestionID: props.questionID
            }
            httpPost("answer_controller/create", answerObj)
                .then(res => {
                    if (res.status) {
                        props.questionArr(props.questionID)
                        toast.success(res.message);
                    }
                })
            setAnswerPostDisplay(false)
        }else{
            window.location.href = "/signin?ref="+props.location.pathname;
        }
    }
    const getFollowDetails = () => {
        httpPost('follow_question_controller/retrieve', {
            QuestionID: question.id,
            UserID: auth && auth.user && auth.user._id || null,
        })
            .then(res => {
                setIsFollow(res && res.data || null);
            })
    }
    const unFollowQuestion = () => {
        if(auth && auth.user && auth.user._id){
            if (userId != (auth && auth.user && auth.user._id)) {
                httpPost('follow_question_controller/delete', {
                    FollowQuestionId: isFollow && isFollow._id
                }).then(res => {
                    console.log("follow_question_controller/delete => res => ", res);
                    if (res && res.status) {
                        toast.success(res.message);
                        setIsFollow(null);
                    } else {
                        toast.error(res.message);
                    }
                })
            }
        }else{
            window.location.href = "/signin?ref="+props.location.pathname;
        }
    }
    const followQuestion = () => {
        if(auth && auth.user && auth.user._id){
            var obj = {
                UserID: auth && auth.user && auth.user._id,
                QuestionID: question.id,
            }
            httpPost('follow_question_controller/create', obj).then(res => {
                if (res && res.status) {
                    toast.success(res.message);
                    getFollowDetails();
                } else {
                    toast.error(res.message);
                }
            })
        }else{
            window.location.href = "/signin?ref="+props.location.pathname;
        }
    }
    const handleReport = () => {
        if(auth && auth.user && auth.user._id){
            setLoading(true);
            httpPost('question_report_controlller/create', {
                QuestionID: question.id,
                ReportType: report.type,
                ReportText: report.text
            }).then(res => {
                setLoading(false);
                if (res) {
                    if (res.status) {
                        setReport({
                            type: null,
                            text: ""
                        });
                        setReportDisplay(false);
                        toast.success(res.message)
                    } else {
                        toast.error(res.message);
                    }
                } else {
                    toast.error('Network Error')
                }
            }).catch(err => {
                setLoading(false);
                toast.error('Network Error')
            })
        }else{
            window.location.href = "/signin?ref="+props.location.pathname;
        }
    }

    const onConfirmDelete = () => {
        if(auth && auth.user && auth.user._id){
            setDeleteQn(null);
            httpPost('question_controller/delete', {
                QuestionID: question.id
            }).then(res => {
                if (res) {
                    if (res.status) {
                        toast.success(res.message);
                        props.history.push('/feed');
                    } else {
                        toast.error(res.message);
                    }
                }
            })
        }else{
            window.location.href = "/signin?ref="+props.location.pathname;
        }
    }

    const searchQuestion = (e) => {
        httpPost('question_controller/list', {
            filter: {
                "Subject": e && e || ""
            },
        }).then(res => {
            let qData = [];
            res.data.map(ele => {
                if(question.id !== ele._id){
                    qData.push(ele);
                }
            })
            setSearchQn(qData || []);
        })
    }
    const mergeQuestion = (e) => {
        if(auth && auth.user && auth.user._id){
            if (mergeQnId && mergeQnId.length > 0) {
                setLoading(true)
                httpPost('merge_question_controller/create', {
                    QuestionFromID: question.id,
                    QuestionToID: mergeQnId && mergeQnId.length > 0 && mergeQnId[0]._id
                }).then(res => {
                    setLoading(false);
                    if (res && res.status) {
                        toast.success(res.message);
                        setMergeQn(false);
                        setMergeQnId(null);
                        setSearchQn([]);
                    }
                    if (res && !res.status) {
                        toast.error(res.message);
                    }
                }).catch(err => {
                    setLoading(false)
                })
            } else {
                toast.error('Please select question');
            }
        }else{
            window.location.href = "/signin?ref="+props.location.pathname;
        }
    }
    const searchuser = (e) => {
        httpPost('follow_user_controller/list', {
            FollowerID: auth && auth.user && auth.user._id,
            page: 1,
            filter: {
                'FirstName': e && e || ""
            }
        })
            .then(res => {
                var arr = [];
                if (res && res.data) {
                    res.data.map(ele => {
                        arr.push(ele.UserID || []);
                    })
                    setSearchUser(arr);
                }
            })
    }
    const requestAnswer = (e) => {
        if (requestQnId && requestQnId.length > 0) {
            setLoading(true)
            httpPost('answer_request_controller/request', {
                QuestionID: question.id,
                ToUserID: requestQnId && requestQnId.length > 0 && requestQnId[0]._id
            }).then(res => {
                setLoading(false);
                if (res && res.status) {
                    toast.success(res.message);
                    setReqAns(false);
                    setSearchUser([]);
                    setRequestQnId(null);
                }
                if (res && !res.status) {
                    toast.error(res.message);
                }
            }).catch(err => {
                setLoading(false)
            })
        } else {
            toast.error('Please select user');
        }
    }

    const answerLater = () => {
        if(auth && auth.user && auth.user._id){
            httpPost('answer_request_controller/answer_later', {
                Answer: answer || "",
                QuestionID: question.id,
            }).then(res => {
                if (res) {
                    if (res.status) {
                        setAnswerPostDisplay(false)
                        toast.success(res.message);
                    } else {
                        toast.error(res.message);
                    }
                }
            }).catch(err => {
                console.log('err', err);
            })
        }else{
            window.location.href = "/signin?ref="+props.location.pathname;
        }
    }

    const draftAnswer = () => {
        if(auth && auth.user && auth.user._id){
            httpPost('draft_answer_controller/create', {
                Answer: answer || "",
                QuestionID: question.id,
            }).then(res => {
                if (res) {
                    if (res.status) {
                        setAnswerPostDisplay(false)
                        toast.success(res.message);
                    } else {
                        toast.error(res.message);
                    }
                }
            }).catch(err => {
                console.log('err', err);
            })
        }else{
            window.location.href = "/signin?ref="+props.location.pathname;
        }
    }

    function handleSeeMore() {
        setCurrentPage(currentPage++)
        props.getPaginatedAnswerList(currentPage)
    }

    const setFilter = (name, sortValue) =>{
        setSortText(name)
        props.applySort(sortValue)
    }

    const getProfileDetail = () => {
        if(userId){
            httpPost('user_controller/retrive', { UserID: userId })
                .then(res => {
                    if (res && res.data) {
                        setUserDetail(res.data);
                    }
                })  
        }
    }

    const requestExpert = () => {
        if(auth && auth.user && auth.user._id){
            setReqAns(true)
        }else{
            window.location.href = "/signin?ref="+props.location.pathname;
        }
    }

    const notifyEdits = () => {
        if(auth && auth.user && auth.user._id){
            httpPost('question_controller/notify', { QuestionID: question.id })
                .then(res => {
                    if (res && res.status === 1) {
                        toast.success(res.message);
                    }
                })  
        }else{
            window.location.href = "/signin?ref="+props.location.pathname;
        }
    }

    let EducationDetails = userDetail.EducationDetails || [];
    let WorkDetails = userDetail.WorkDetails || [];

    const ProfileCard = (
        <Popover className="hoverCard" >
            <Popover.Content >
                <Link to={"/profile/" + (question && question.UserName)}>
                    <Image src={question.image ? question.image : Images.defaultProfile} width={40} height={40} roundedCircle />
                    &nbsp;&nbsp;{question.name}
                </Link>
                <div>
                    { WorkDetails && WorkDetails.length > 0 ? (<div>Company Name: {WorkDetails[0].Organization}</div>) : null }
                    { EducationDetails && EducationDetails.length > 0 ? (<div>Education: {EducationDetails[0].Degree}</div>) : null }
                    <div>
                        Home Town: {userDetail.HomeTown}
                    </div>
                    <div>
                        Current Location: {userDetail.CurrentLocation}
                    </div>
                    <div>
                        Number of Views: <strong>{userDetail.ViewCount}</strong>
                    </div>
                    <div>
                        Points: <strong>{userDetail.Rank}</strong>
                    </div>
                    <div>
                        Rank: <strong>{userDetail.Rank}</strong>
                    </div>
                </div>
            </Popover.Content>
        </Popover>
    );

    const profileName = () => (
        <OverlayTrigger trigger={["hover", "hover"]} placement="auto" overlay={ProfileCard}>
            <span className="capitalize" style={{}}><b>{question.name}</b></span>
        </OverlayTrigger>)

    return (
        <div className="p-2 pm-4 p-lg-4" style={{ width: "100%", backgroundColor: BaseColor.whiteColor, borderWidth: 'thin', borderRadius: 5, borderRadius: 5, boxShadow: "rgb(0 0 0 / 15%) 2px 4px 12px" }}>
            <div style={{ display: 'flex' }}>
                <div style={{ flex: '1' }} className="pr-2 pr-md-0 pr-lg-0 pr-sm-2">
                    <OverlayTrigger trigger={["hover", "hover"]} placement="auto" overlay={ProfileCard}>
                        <Link>
                            <Image src={question.image ? question.image : Images.defaultProfile} width={50} height={50} roundedCircle />
                        </Link>
                    </OverlayTrigger>
                    <div style={{ flex: '1' }} className="pr-2 pr-md-3 pr-lg-3 pr-sm-2 mt-4">
                        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                            <a className="upvote" onClick={e => handleQuestionvote(question.id,"upvote")} >{question.IsVoted && question.UpVote ? (<Image src={Images.upActive} height={20} width={30} />) : (<Image src={Images.up} height={20} width={30} />)}</a>
                            <span style={{ fontSize: 12 }}>{updating == question.id ? <Spinner animation="grow" variant="primary" size="sm" /> : (question.percent)}</span>
                            <a className="downvote" onClick={e => handleQuestionvote(question.id,"downvote")} >{question.IsVoted && question.DownVote ? (<Image src={Images.downActive} height={20} width={30} />) : (<Image src={Images.down} height={20} width={30} />)}</a>
                        </div>
                    </div>
                </div>
                <div style={{ flex: '12', display: 'flex', flexDirection: 'column' }}>
                    <Link className="reset" to={"/profile/" + (question && question.UserName)}>
                        <OverlayTrigger trigger={["hover", "hover"]} placement="auto" overlay={ProfileCard}>
                            <span className="capitalize" style={{}}>{question.name}</span>
                        </OverlayTrigger>
                        <small className="float-right" >{(question.post ? "Posted "+question.post : "")}</small>
                        <span style={{fontSize: 12, clear: 'both',float: 'left'}} >
                        {question.user_id ? (question.work ? question.work + ", " : "") + (question.education ?  question.education+", " : "")+ (question.HomeTown ?  "Home Town: "+ question.HomeTown+", " : "") + (question.CurrentLocation ?  "Current City: "+ question.CurrentLocation+", " : "") : ""}</span>
                    </Link>
                    <div>
                        { question.topics && question.topics.map((top) => {
                            return <Badge variant="secondary">{top.Title}</Badge>
                        }) }
                    </div>
                    <br />
                    {/*<Link className="reset" to={"#"} onClick={() => { window.location.href= "/question/" + (question.QuestionUrl) }} >*/}
                    {/*</Link>*/}
                    <h1 className="question-title" dangerouslySetInnerHTML={{ __html: question.question}} ></h1>
                    <div className="qna-html-content" dangerouslySetInnerHTML={{ __html: question.Description }} ></div>
                    <VoteList 
                        question={question} 
                        question={question} 
                        />
                    <div className="sub-links" >
                        {/*style={{ display: 'flex', flexWrap: "wrap", flexDirection: 'row' }}*/}
                        <label style={{ marginRight: 15 }} onClick={handleAnswer}>Answer</label>
                        <label style={{ marginRight: 15 }} onClick={() => {
                            if (isFollow) {
                                unFollowQuestion();
                            } else {
                                followQuestion();
                            }
                        }}>{isFollow ? 'Unfollow' : "Follow"}</label>
                        <label style={{ marginRight: 15 }} onClick={() => { }} className="for-dropdown">
                            <DropdownButton className="menu-three-dots-button" id="dropdown-item-share" title={"Share"}>
                                <Dropdown.Item as="button" ><FacebookShareButton url={window.location.href} quote={question.question} ><FacebookIcon size={"2rem"} round />&nbsp;Facebook</FacebookShareButton></Dropdown.Item>
                                <Dropdown.Item as="button" ><TwitterShareButton url={window.location.href} title={question.question} ><TwitterIcon size={"2rem"} round />&nbsp;Twitter</TwitterShareButton></Dropdown.Item>
                                <Dropdown.Item as="button" ><LinkedinShareButton url={window.location.href} title={question.question} summary={question.Description} ><LinkedinIcon size={"2rem"} round />&nbsp;LinkedIn</LinkedinShareButton></Dropdown.Item>
                                <Dropdown.Item as="button" ><WhatsappShareButton url={window.location.href} title={question.question} ><WhatsappIcon size={"2rem"} round />&nbsp;Whatsapp</WhatsappShareButton></Dropdown.Item>
                            </DropdownButton>
                        </label>
                        <label className="for-dropdown">
                            <DropdownButton className="menu-three-dots-button" id="dropdown-item-button" title={<Image src={Images.dotIcon} />}>
                                {auth && auth.user && auth.user._id && auth.user._id == question.user_id ? (
                                    <>
                                        <Dropdown.Item onClick={() => { props.history.push('/questionCreate?id=' + question._id) }} as="button">Edit</Dropdown.Item>
                                        <Dropdown.Item onClick={() => { setDeleteQn(question._id) }} as="button">Delete</Dropdown.Item>
                                        <Dropdown.Divider />
                                    </>
                                ) : (
                                        <>
                                            {/*<Dropdown.Item as="button" onClick={() => { answerLater() }}>Answer Later</Dropdown.Item>
                                             <Dropdown.Item as="button">Merge Questions</Dropdown.Item> */}
                                        </>
                                    )}
                                {/*<Dropdown.Item as="button" onClick={() => { console.log("WIP") }}>Follow Privately</Dropdown.Item>*/}

                                <Dropdown.Item as="button" onClick={() => { setMergeQn(true) }}>Merge Questions</Dropdown.Item>
                                <Dropdown.Item as="button" onClick={() => { notifyEdits() }}>Notify about Edits</Dropdown.Item>
                                <Dropdown.Item as="button" onClick={() => { setReportDisplay(!reportDisplay) }}>Report</Dropdown.Item>
                                <Dropdown.Item as="button" onClick={() => { requestExpert() }}>Request Experts to Answer</Dropdown.Item>
                                <Dropdown.Item as="button" onClick={() => { props.history.push('/questionlog?id=' + question.id) }}>View Stats and logs</Dropdown.Item>
                            </DropdownButton>
                        </label>
                    </div>
                    {answerpostdisplay ?
                        <Row className="row" style={{ paddingTop: 10, alignItems: 'center' }}>
                            <Col md="12" sm="12" xs="12" >
                                <div style={{ alignItems: 'center' }}>
                                    {/* <Image style={{ marginLeft: 5 }} src={Images.activity} height={35} width={35} roundedCircle /> */}
                                    {/* <InputGroup className="">
                                        <FormControl style={{ border: 0, boxShadow: 'none', marginRight: 5, backgroundColor: 'inherit' }} type="text" placeholder="start typing your answer..." size="lg" onChange={handleChangeInCreateAnswer} />
                                        <InputGroup.Append>
                                        </InputGroup.Append>
                                    </InputGroup> */}
                                    <Editor
                                        editorState={null}
                                        onUpdate={editorState => setAnswer(editorState)}
                                    />
                                </div>
                            </Col>
                            <Col md="12" sm="12" xs="12">
                                <Button className="mt-1 btn-sm" style={{ color: BaseColor.whiteColor, height: "100%" }} onClick={handleCreateAnswer}>Post</Button>
                                &nbsp;&nbsp;<Button className="mt-1 btn-sm" style={{ color: BaseColor.whiteColor, height: "100%" }} onClick={draftAnswer}>Save as Draft</Button>
                            </Col>
                        </Row>
                        : null}
                    {/* {reportDisplay ?
                        <Row className="row" style={{ paddingTop: 10, alignItems: 'center' }}>
                            <Col md="8" sm="12" xs="12" >
                                <div className="input-border" style={{ display: 'flex', alignItems: 'center' }}>
                                    <Image style={{ marginLeft: 5 }} src={Images.activity} height={35} width={35} roundedCircle />
                                    <InputGroup className="">
                                        <FormControl value={report} style={{ border: 0, boxShadow: 'none', marginRight: 5, backgroundColor: 'inherit' }} type="text" placeholder="reporting reason..." size="lg" onChange={(e) => {
                                            setReport(e && e.target && e.target.value || null);
                                        }} />
                                        <InputGroup.Append>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </div>
                            </Col>
                            <Col md="3" sm="12" xs="12">
                                <Button style={{ backgroundColor: BaseColor.primaryColor, color: BaseColor.whiteColor, borderColor: BaseColor.darkPrimaryColor, width: "100%", height: "100%" }} onClick={handleReport}>Report</Button>
                            </Col>
                        </Row>
                        : null} */}
                </div>
            </div >
            <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', margin: "10px 0 10px 10px", padding: '10px 0', borderWidth: 'thin', borderTop: "0.1px solid", borderBottom: "0.1px solid", borderColor: BaseColor.grayColor, color: BaseColor.darkGrayColor }}>
                <div style={{ flex: 1 }}>
                    <span>{(question.answers || 0 ) + " "}Answers</span>
                </div>
                <div style={{ flex: 2, textAlign: 'end' }}>
                    <Dropdown className="bg-transparent" >
                        <Dropdown.Toggle style={{ backgroundColor: 'inherit', borderColor: BaseColor.grayColor, color: BaseColor.darkGrayColor, fontSize: '12px' }} id="dropdown-basic">
                            Sort by: {sortText}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick = { e => setFilter('Best Answer', 'IsBestAnswer') } >Best Answer</Dropdown.Item>
                            <Dropdown.Item onClick = { e => setFilter('Verified Answer', 'IsVerified') } >Verified Answer</Dropdown.Item>
                            <Dropdown.Item onClick = { e => setFilter('High Votes', 'UpvoteCount') } >High Votes</Dropdown.Item>
                            <Dropdown.Item onClick = { e => setFilter("Expert's Answer", 'IsExpert') } >Expert's Answer</Dropdown.Item>
                            <Dropdown.Item onClick = { e => setFilter('Recommended', 'CreatedDate') } >All other answers</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            <div style={{ padding: '10 20'}}>
                {props.answers && props.answers.map((ele, index) => (
                    <div style={{ display: 'flex', paddingBottom: 25 }}>
                        <div style={{ flex: '1' }} className="pr-2 pr-md-0 pr-lg-0 pr-sm-2">
                            <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                                <a onClick={e => handleUpvote(ele.id)}>{ele.IsVoted && ele.UpVote ? (<Image src={Images.upActive} height={15} width={25} />) : (<Image src={Images.up} height={15} width={25} />)}</a>
                                <span style={{ fontSize: 12 }}>{updating == ele.id ? <Spinner animation="grow" variant="primary" size="sm" /> : (ele.percent)}</span>
                                <a onClick={e => handleDownvote(ele.id)}>{ele.IsVoted && ele.DownVote ? (<Image src={Images.downActive} height={15} width={25} />) : (<Image src={Images.down} height={15} width={25} />)}</a>
                            </div>
                        </div>
                        <div style={props.answers.length === index+1 ? {flex: '9'} : { flex: '9', borderWidth: 'thin', borderBottom: "0.1px solid", borderColor: BaseColor.grayColor, paddingBottom: 10 }} >
                            <div style={{ display: 'flex', alignItems: 'center', paddingBottom: 10 }}>
                                <div style={{ flex: '1' }} className="pr-2 pr-md-0 pr-lg-0 pr-sm-2">
                                    <Link to={"/profile/" + (ele && ele.UserName)}>
                                        <Image src={ele.image ? ele.image : Images.defaultProfile} width={40} height={40} roundedCircle />
                                    </Link>
                                </div>
                                <div style={{ flex: '12', display: 'flex', flexDirection: 'column' }}>
                                    <Link className="reset" to={"/profile/" + (ele && ele.UserName)}>
                                        <span className="capitalize" style={{}}><b>{ele.name}</b></span>
                                        <small className="post-date float-right" >{"answered . " + ele.answered}</small>
                                    </Link>
                                    <span style={{}}><b>{ele.text}</b></span>
                                </div>
                            </div>

                            {/* style={{background: "linear-gradient(360deg, #FAFAFA 23.89%, rgba(250, 250, 250, 0) 56.67%)"}} */}
                            {/*boxShadow: '0px -14px 20px ' + BaseColor.grayColor,*/}
                            <div className="qna-html-content" >
                                <div dangerouslySetInnerHTML={{ __html: (ele.detail ? !ele.showFull && ele.detail.length > 400 ? ele.detail.substring(0, 400) : ele.detail : null) }} ></div>
                                {ele.detail && ele.detail.length > 400 && !ele.showFull ? (
                                    <div style={{ marginTop: 5, borderTopLeftRadius: 20 }}>
                                        <Button variant="outline-primary" size="xs" className="btn-rounded"
                                            onClick={() => { props.showFullText(ele.id) }}
                                        >
                                            &nbsp;&nbsp;See More&nbsp;&nbsp;
                                    </Button>
                                    </div>
                                ) : null}
                            </div>
                            <Comments
                                history={props.history}
                                location={props.location}
                                commentID={commentID}
                                answerID={ele.id}
                                answer={ele}
                                questionID={props.questionID}
                                image={profilePicture}
                                commentsCount={ele.commentsCount}
                                updateList={() => props.questionArr()}
                            />
                        </div>
                    </div>
                )) || null}
            </div> 
            <div style={{ textAlign: 'center' }}>
                <label style={{ color: BaseColor.primaryColor, display: props.displaySeeMore, cursor: 'pointer' }} onClick={handleSeeMore}><b>See more answers</b></label>
            </div>
            <SweetAlert
                show={deleteQn ? true : false}
                warning
                showCancel
                confirmBtnText={"Yes"}
                cancelBtnText={"No"}
                title={<span style={{ fontSize: 25 }} >Do you want to delete this Question</span>}
                onConfirm={onConfirmDelete}
                onCancel={() => { setDeleteQn(null) }}
            />
            {/* Report Question  */}
            <Modal show={reportDisplay} onHide={() => { setReport({ text: '', type: null }); setReportDisplay(false); }}>
                <Modal.Header closeButton>
                    <Modal.Title as="h4" >Report Question</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="row" style={{ paddingTop: 10, alignItems: 'center' }}>
                        <Col md="12" sm="12" xs="12" >
                            <Form.Check onClick={() => setReport({ ...report, type: '1' })} checked={report.type == '1' ? true : false} type={'radio'} value={"1"} label="Harassment" />
                            <Form.Check onClick={() => setReport({ ...report, type: '2' })} checked={report.type == '2' ? true : false} type={'radio'} value={"2"} label="Spam" />
                            <Form.Check onClick={() => setReport({ ...report, type: '3' })} checked={report.type == '3' ? true : false} type={'radio'} value={"3"} label="Insincere" />
                            <Form.Check onClick={() => setReport({ ...report, type: '4' })} checked={report.type == '4' ? true : false} type={'radio'} value={"4"} label="Poorly Written" />
                            <Form.Check onClick={() => setReport({ ...report, type: '5' })} checked={report.type == '5' ? true : false} type={'radio'} value={"5"} label="Incorrect Topic" />
                            <Form.Check onClick={() => setReport({ ...report, type: '6' })} checked={report.type == '6' ? true : false} type={'radio'} value={"6"} label="Offensive" />
                        </Col>
                        <Col md="12" sm="12" xs="12" className='mt-3'>
                            <div className="input-border" style={{ display: 'flex', alignItems: 'center' }}>
                                <Image style={{ marginLeft: 5 }} src={profilePicture} height={35} width={35} roundedCircle />
                                <InputGroup className="">
                                    <FormControl value={report.text} style={{ border: 0, boxShadow: 'none', marginRight: 5, backgroundColor: 'inherit' }} type="text" placeholder="Explain the report" size="lg" onChange={(e) => {
                                        setReport({ ...report, text: e && e.target && e.target.value || null });
                                    }} />
                                    <InputGroup.Append>
                                        {/* <FontAwesomeIcon icon={"search"} /> */}
                                    </InputGroup.Append>
                                </InputGroup>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" className="btn-sm" onClick={() => { setReport({ text: '', type: null }); setReportDisplay(false); }}>
                        Cancel
                    </Button>
                    <Button disabled={loading} variant="primary" className="btn-sm" onClick={() => { handleReport() }}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Question Merge  */}
            <Modal show={mergeQn} onHide={() => { setMergeQn(false); setMergeQnId(null); }}>
                <Modal.Header closeButton>
                    <Modal.Title as="h4" >Merge Question</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <label>Merge To</label>
                    <Typeahead
                        id="user-example"
                        onInputChange={(e) => { searchQuestion(e) }}
                        onChange={(e) => { setMergeQnId(e) }}
                        options={searchQn && searchQn || []}
                        placeholder="Choose a question..."
                        labelKey={"Subject"}
                        selected={mergeQnId && mergeQnId || []}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" className="btn-sm" onClick={() => { setMergeQn(false); setMergeQnId(null); }}>
                        Cancel
                    </Button>
                    <Button disabled={loading} variant="primary" className="btn-sm" onClick={() => { mergeQuestion(false) }}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Request Answer */}
            <Modal show={reqAns} onHide={() => { setReqAns(false); setRequestQnId(null); }} size="lg" >
                <Modal.Header closeButton>
                    <Modal.Title as="h4" >Request Answer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <label>Request To</label>
                    <RequestAnswer question={question} />
                </Modal.Body>
            </Modal>
        </div >
    )
}

Question.prototype = {
    question: PropTypes.object,
    answers: PropTypes.array,
    showFullText: PropTypes.func,
    link: PropTypes.string,
    questionArr: PropTypes.func
}

Question.defaultProps = {
    question: {},
    answers: [],
    showFullText: () => { },
    link: null,
}
export default Question;