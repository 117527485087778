import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { BaseColor, Images } from "../config";

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';

import PropTypes from "prop-types";
import moment from 'moment';
import 'moment-timezone';
import { httpPost } from '../Services/HttpServices';
import { toast } from 'react-toastify';
function Voter(props) {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let { voter } = props;
    var auth = useSelector(({ auth }) => auth);
    let profilePicture = voter.UserID.ProfilePicture ? voter.UserID.ProfilePicture : Images.defaultProfile;
    return (
        <>
            <Card body className="mb-2" >
                <Row>
                    <Col md="2" >
                        <Image src={profilePicture} roundedCircle className="img-thumbnail" />
                    </Col>
                    <Col md="7" >
                        {voter.UserID.FirstName + ' ' + voter.UserID.LastName}
                    </Col>
                </Row>
            </Card>
        </>
    )
}

Voter.defaultProps = {
    question: {}
}
export default Voter;