import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import Form from 'react-bootstrap/Form';
import { httpPost } from '../Services/HttpServices';
import { toast } from 'react-toastify';
import moment from 'moment';
import { languageList } from '../AppRedux/Actions/Common';
// import 'bootstrap/dist/css/bootstrap.min.css';
import 'bs-stepper/dist/css/bs-stepper.min.css';
import Stepper from 'bs-stepper';

// import 'react-google-places-autocomplete/dist/assets/index.css';
// import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import GooglePlaces from '../Component/GooglePlaces';

import { YearPicker, MonthPicker, DayPicker } from 'react-dropdown-date';

class EditProfile extends Component {
	defaultEducationErr = {
		Institution: null,
		Degree: null,
		StartDate: null,
		EndDate: null,
	}
	defaultEducation = {
		Institution: "",
		Degree: "",
		Description: "",
		StartDate: "",
		EndDate: "",
	}
	defaultWork = {
		Organization: "",
		JobTitle: "",
		EmploymentType: "",
		Location: "",
		Description: "",
		StartDate: "",
		EndDate: "",
	}
	defaultLanguage = {
		Name: "",
		Proficiency: "",
		Read: 0,
		Write: 0,
		Speak: 0
	}
	constructor(props) {
		super(props);
		this.state = {
			isDisabled: false,
			userDetail: [],
			topics: [],
			male: false,
			female: false,
			year: null,
			month: null,
			day: null,
			formErr: null,
			learnigFiles: null,
			teachingFiles: null,
			Language: [],
			educationDetails: [],
			educationErr: [],
			WorkDetails: [],
			workErr: [],
			learnerFileList: ["TOEFL", "IELTS", "PET", "FCE", "CAE", "CPE", "GRE", "ECCE", "MELAB", "TSE", "Other"],
			englishFileList: ["CELTA", "DELTA", "CEELT", "CELTYL", "ICELT", "CertTESOL", "FTBE", "Other"]
		};
		
	}

	handleChange = (e) => {
		let name = e.target.id
		let value = e.target.value || ""
		let userDetail = { ...this.state.userDetail };
		let userTopics = userDetail.TopicID && userDetail.TopicID.split(',') || [];
		let LearnerCertificates = [...userDetail.LearnerCertificates];
		let TeachingEnglishCertificates = [...userDetail.TeachingEnglishCertificates];
		// console.log(e.target.name, name, value, "Change Data");return;
		if (e.target.id === "DOB") {
			value = moment(e.target.value).toDate()
			// console.log(userDetail.DOB, "DOB", e.target.value, moment(e.target.value).toDate());
		}
		// Comment For Changing Gender Radio to Drop Down
		// if (e.target.id === "male") {
		// 	name = "Gender";
		// 	value = 1;
		// } else if (e.target.id === "female") {
		// 	name = "Gender";
		// 	value = 2;
		// }

		if (e.target.name == "TopicID") {
			name = "TopicID";
			var ind = userTopics.indexOf(e.target.id);
			if (ind > -1) {
				userTopics.splice(ind, 1);
			} else {
				userTopics.push(e.target.id);
			}
			value = userTopics.toString();
			// userTopics.map(id => {
			// 	console.log(id, e.target.id)
			// })
		}
		if (e.target.name == "LearnerCertificates") {
			name = "LearnerCertificates";
			var ind = LearnerCertificates.findIndex(ele => ele.name == e.target.id);
			if (ind > -1) {
				LearnerCertificates.splice(ind, 1);
			} else {
				LearnerCertificates.push({
					name: e.target.id,
					other_name: "",
					file: "",
				});
			}
			value = [...LearnerCertificates];
		}
		if (e.target.name == "TeachingEnglishCertificates") {
			name = "TeachingEnglishCertificates";
			var ind = TeachingEnglishCertificates.findIndex(ele => ele.name == e.target.id);
			if (ind > -1) {
				TeachingEnglishCertificates.splice(ind, 1);
			} else {
				TeachingEnglishCertificates.push({
					name: e.target.id,
					other_name: "",
					file: "",
				});
			}
			value = [...TeachingEnglishCertificates];
		}
		userDetail[name] = value;

		this.setState({
			...this.state,
			userDetail: userDetail
		}, () => {
			console.log('user', this.state.userDetail);
		})
	}
	handleLevelOfEnglish = (value) => {
		this.setState({
			userDetail: {
				...this.state.userDetail,
				LevelOfEnglish: value
			}
		})
	}
	componentDidMount() {
		this.stepper = new Stepper(document.querySelector('#stepper1'), {
			linear: false,
			animation: true
		})
		this.props.languageList();
		this.getDetails();
		// console.log(this.state.educationDetails.length === 0, "this.state.educationDetails.length === 0")
		if(this.state.educationDetails.length === 0){
			this.addEducationDiv();
		}
		if(this.state.WorkDetails.length === 0){
			this.addWorkDiv();
		}
	}

	getDetails = () => {
		{/*httpPost("user_topic_controller/list")
			.then(res => {
				if (res && res.data) {
					if (res.data.length > 0) {
						if (res.data[0].TopicIDs && res.data[0].TopicIDs.length > 0) {
							this.setState({
								topics: res.data[0].TopicIDs || []
							})
						}
					}
				}
			})*/}
		httpPost("topic_controller/list")
            .then(res => {
                if (res && res.data) {
                    if (res.data.length > 0) {
                        if (res.data && res.data.length > 0) {
                            this.setState({
                                topics: res.data || []
                            })
                        }
                    }
                }
            })

		httpPost("user_controller/retrive", { UserID: this.props.auth.user._id })
			.then((res) => {
				if (res.data && res.data) {
					var topic = res.data.MyTopic;
					var topicArr = [];
					if (res.data.MyTopic && res.data.MyTopic.length > 0) {
						res.data.MyTopic.map(ele => {
							topicArr.push(ele._id);
						})
					}
					var educationArr = [];
					var workArr = [];
					if (res.data.EducationDetails && res.data.EducationDetails.length > 0) {
						res.data.EducationDetails.map((ele, index) => {
							educationArr.push({
								id: index + 1,
								EducationID: ele._id,
								Institution: ele.Institution,
								PrimaryMajor: ele.PrimaryMajor,
								SecondaryMajor: ele.SecondaryMajor,
								DegreeType: ele.DegreeType,
								GraduationYear: ele.GraduationYear,
							})
						})
					}
					if (res.data.WorkDetails && res.data.WorkDetails.length > 0) {
						res.data.WorkDetails.map((ele, index) => {
							workArr.push({
								id: index + 1,
								WorkID: ele._id,
								Organization: ele.Organization,
								JobTitle: ele.JobTitle,
								EmploymentType: ele.EmploymentType,
								Location: ele.Location,
								Description: ele.Description || "",
								StartDate: moment(ele.StartDate).format('YYYY-MM-DD'),
								EndDate: ele.EndDate && moment(ele.EndDate).format('YYYY-MM-DD') || "",
							})
						})
					}
					let dob = res.data.DOB ? res.data.DOB.split('-') : [];
					let year = dob[0];
					let month = dob[1]-1;
					let day = dob[2];
					this.setState({
						educationDetails: educationArr,
						WorkDetails: workArr,
						Language: res.data.LanguageDetails.NativeLanguage || [],
						userDetail: {
							"FirstName": res.data.FirstName,
							"LastName": res.data.LastName,
							"UserName": res.data.UserName,
							"DOB": res.data.DOB ? moment(res.data.DOB).format('YYYY-MM-DD') : null,
							"Gender": res.data.Gender,
							"HomeTown": res.data.HomeTown,
							"CurrentLocation": res.data.CurrentLocation,
							"RelationshipStatus": res.data.RelationshipStatus,
							"About": res.data.About,
							"PoliticalViews": res.data.PoliticalViews,
							"Spirituality": res.data.Spirituality,

							"Email": res.data.ContactInformation.Email,
							"CountryCode": res.data.ContactInformation.CountryCode, 
							"MobileNo": res.data.ContactInformation.MobileNo,
							"LandlineNumber": res.data.ContactInformation.LandlineNumber,
							"BusinessEmail": res.data.ContactInformation.BusinessEmail,
							"Website": res.data.ContactInformation.Website,
							"BlogAddress": res.data.ContactInformation.BlogAddress,

							"WantsEnglishChat": res.data.LanguageDetails.WantsEnglishChat || '0',
							"LevelOfEnglish": res.data.LanguageDetails.LevelOfEnglish,

							"TopicID": topicArr.toString(),
							"TopicOfInterest": res.data.TopicOfInterest,
							"EducationDetails": res.data.EducationDetails,
							"WorkDetails": res.data.WorkDetails,

							"SuggestionsForStudents": res.data.LearnersDetails.SuggestionsForStudents,
							"LearningEnglishReason": res.data.LearnersDetails.LearningEnglishReason,
							"LearnerCertificates": res.data.LearnersDetails.LearnerCertificates || [],
							"LearnerCertificateFiles": res.data.LearnersDetails.LearnerCertificateFiles || [],

							"TeachingEnglishExperience": res.data.TeachingEnglishDetails.TeachingEnglishExperience,
							"AdviceForStudents": res.data.TeachingEnglishDetails.AdviceForStudents,
							"TeachingEnglishCertificates": res.data.TeachingEnglishDetails.TeachingEnglishCertificates || [],
							"TeachingEnglishCertificateFiles": res.data.TeachingEnglishDetails.TeachingEnglishCertificateFiles || [],

							"InterestedIn": res.data.InterestDetails.InterestedIn,
							"FavouriteMusicBands": res.data.InterestDetails.FavouriteMusicBands,
							"FavouriteFilms": res.data.InterestDetails.FavouriteFilms,
							"FavouriteTvShows": res.data.InterestDetails.FavouriteTvShows,
							"FavouriteBooks": res.data.InterestDetails.FavouriteBooks,
							"FavouriteActivities": res.data.InterestDetails.FavouriteActivities,
							"Ambitions": res.data.InterestDetails.Ambitions,
							"WishToVisit": res.data.InterestDetails.WishToVisit,
							"PerfectWayToSpendDay": res.data.InterestDetails.PerfectWayToSpendDay,
							"Pets": res.data.InterestDetails.Pets,
							"LookingFor": res.data.InterestDetails.LookingFor
						},
						"male": res.data.Gender === 1 ? true : false,
						"female": res.data.Gender === 2 ? true : false,
						day: day,
						month: month,
						year: year
					})
				}
			})
	}
	validateStep = (step_no, callback) => {
		let { formErr, year,userDetail } = this.state;
		var err = { ...formErr };
		var stepStatus = {};
		if (step_no == '1' || !step_no) {
			stepStatus['1'] = true;
			var inerr = {
				FirstName: userDetail && userDetail.FirstName ? null : "Enter First Name",
				LastName: userDetail && userDetail.LastName ? null : "Enter Last Name",
				// DOB: userDetail && userDetail.DOB ? moment().diff(userDetail.DOB, 'years') >= 18 ? null : "Age must be 18 and above" : null,
				// DOB: year ? moment().diff(year, 'years') >= 18 ? null : "Age must be 18 and above" : "Please select year",
				Gender: userDetail && userDetail.Gender ? null : "Select Gender",
			}
			Object.entries(inerr).forEach((item, value) => {
				// console.log('item', item, value);
				if (item[1]) {
					// console.log('value', value);
					stepStatus['1'] = false
				}
			})
			err = { ...err, ...inerr }
		}
		if (step_no == '2' || !step_no) {
			const emailPat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			// const phonePat = /^\d{7}$/;
			const phonePat = /^\d+$/;
			const noPat = /^\d+$/;
			const urlPat = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
			stepStatus['2'] = true;
			var inerr = {
				Email: userDetail && userDetail.Email ? userDetail.Email.match(emailPat) ? null : "Enter Valid Email" : "Enter Email",
				MobileNo: userDetail && userDetail.MobileNo ? userDetail.MobileNo.match(phonePat) ? null : "Enter Valid Phone" : null,
				LandlineNumber: userDetail && userDetail.LandlineNumber ? userDetail.LandlineNumber.match(noPat) ? null : "Enter Valid Email" : null,
				BusinessEmail: userDetail && userDetail.BusinessEmail ? userDetail.BusinessEmail.match(emailPat) ? null : "Enter Valid Email" : null,
				Website: userDetail && userDetail.Website ? userDetail.Website.match(urlPat) ? null : "Enter Valid Website Url" : null,
				BlogAddress: userDetail && userDetail.BlogAddress ? userDetail.BlogAddress.match(urlPat) ? null : "Enter Valid Blog Url" : null,
			}
			Object.entries(inerr).forEach((item, value) => {
				if (item[1]) {
					stepStatus['2'] = false
				}
			})
			err = { ...err, ...inerr }
		}
		if (step_no == '3' || !step_no) {
			stepStatus['3'] = true;
			// var inerr = {
			// 	// WantsEnglishChat: userDetail && userDetail.WantsEnglishChat ? null : "Select Option",
			// 	// LevelOfEnglish: userDetail && userDetail.LevelOfEnglish ? null : "Select Option",
			// }
			// Object.entries(inerr).forEach((item, value) => {
			// 	if (item[1]) {
			// 		stepStatus['3'] = false
			// 	}
			// })
			var learnArr = [...this.state.userDetail.TeachingEnglishCertificates];
			var learnErr = [];
			learnArr.map(ele => {
				var obj = {};
				// if (!ele.file) {
				// 	obj.name = ele.name;
				// 	obj.fileErr = "Please Select File";
				// }
				if (ele.name == "Other" && !ele.other_name) {
					obj.name = ele.name;
					obj.otherErr = "Please Enter Name";
				}
				if (Object.keys(obj).length > 0) {
					learnErr.push(obj)
				}
			})
			var inerr = {
				TeachingEnglishCertificates: learnErr.length > 0 ? learnErr : null
			}
			Object.entries(inerr).forEach((item, value) => {
				if (item[1]) {
					stepStatus['3'] = false
				}
			})
			err = { ...err, ...inerr }
		}
		if (step_no == '4' || !step_no) {
			//No validation
			stepStatus['4'] = true;
		}
		if (step_no == '5' || !step_no) {
			//No validation
			stepStatus['5'] = true;
			var learnArr = [...this.state.userDetail.LearnerCertificates];
			var learnErr = [];
			learnArr.map(ele => {
				var obj = {};
				// if (!ele.file) {
				// 	obj.name = ele.name;
				// 	obj.fileErr = "Please Select File";
				// }
				if (ele.name == "Other" && !ele.other_name) {
					obj.name = ele.name;
					obj.otherErr = "Please Enter Name";
				}
				if (Object.keys(obj).length > 0) {
					learnErr.push(obj)
				}
			})
			var inerr = {
				LearnerCertificates: learnErr.length > 0 ? learnErr : null
			}
			// if (learnErr.length > 0) {
			// 	inerr.LearnerCertificates = learnErr
			// }
			Object.entries(inerr).forEach((item, value) => {
				if (item[1]) {
					stepStatus['5'] = false
				}
			})
			err = { ...err, ...inerr }
		}
		if (step_no == '6' || !step_no) {
			//No validation
			stepStatus['6'] = true;
			
		}
		this.setState({
			...this.state,
			formErr: err
		}, () => {
			var validate = true;
			if (step_no) {
				callback(stepStatus[step_no]);
			} else {
				if (this.state.formErr) {
					Object.entries(this.state.formErr).forEach((item, value) => {
						if (item[1]) {
							validate = false;
						}
					})
					callback(validate);
				} else {
					callback(false);
				}
			}
		});
		return false;
	}
	saveTillThis = (stepNo) => {
		// console.log('stepNo', stepNo);
		const _this = this;
		this.validateStep(stepNo, function (res) {
			if (res) {
				_this.handleSubmit();
			}
		})
	}
	onSubmit = (e) => {
		e.preventDefault();
		const _this = this;
		this.validateStep(null, function (res) {
			if (res) {
				_this.handleSubmit()
			} else {
				toast.error("Enter all required fields");
			}
		})
	}
	handleSubmit = () => {
		let month = parseInt(this.state.month) + 1;
		this.state.userDetail.DOB = this.state.year+'-'+month+'-'+this.state.day;
		this.state.userDetail.NativeLanguage = this.state.Language;
		httpPost("user_controller/update", this.state.userDetail)
			.then((res) => {
				if (res.status == 1) {
					toast.success(res.message);
					this.submitLearnigCertificate(this.state.learnigFiles);
					this.submitTeachingCertificate(this.state.teachingFiles);
					this.props.history.push('/profile');
				} else {
					toast.error(res.message)
				}
			})
	}
	nextStep = () => {
		this.stepper.next()
	}
	prevStep = () => {
		this.stepper.previous();
	}
	submitLearnigCertificate = (files) => {
		//return false;
		var formData = new FormData();
		if (files) {
			for (const key of Object.keys(files)) {
				formData.append('file', files[key])
			}

			formData.append('UserID', this.props.auth && this.props.auth && this.props.auth.user._id)
			httpPost("user_controller/update_learning_certificate", formData)
				.then((res) => {
					console.log(res);
					if (res.status == 1) {
						//	toast.success(res.message)
					} else {
						//	toast.error(res.message)
					}
				})
		}
	}
	submitTeachingCertificate = (files) => {
		// return false;
		var formData = new FormData();
		var formData = new FormData();
		if (files) {
			for (const key of Object.keys(files)) {
				formData.append('file', files[key])
			}

			formData.append('UserID', this.props.auth && this.props.auth && this.props.auth.user._id)
			httpPost("user_controller/update_teaching_certificate", formData)
				.then((res) => {
					console.log(res);
					if (res.status == 1) {
						//	toast.success(res.message)
					} else {
						//	toast.error(res.message)
					}
				})
		}
	}
	uploadIndividualFile = (e, cert, type) => {
		var formdata = new FormData();
		if (e && e.target && e.target.files && e.target.files[0]) {
			formdata.append('file', e.target.files[0]);
			httpPost('images/upload', formdata)
				.then(res => {
					console.log('res');
					if (res && res.status && res.fileUrl) {
						if (type == '1') {
							var learnArr = [...this.state.userDetail.LearnerCertificates];
							var ind = learnArr.findIndex(ele => ele.name == cert);
							if (ind > -1) {
								learnArr[ind].file = res.fileUrl;
								this.setState({
									...this.state,
									userDetail: {
										...this.state.userDetail,
										LearnerCertificates: learnArr
									}
								})
							}
						} else {
							var teachArr = [...this.state.userDetail.TeachingEnglishCertificates];
							var ind = teachArr.findIndex(ele => ele.name == cert);
							if (ind > -1) {
								teachArr[ind].file = res.fileUrl;
								this.setState({
									...this.state,
									userDetail: {
										...this.state.userDetail,
										TeachingEnglishCertificates: teachArr
									}
								})
							}
						}
					} else {
						toast.error('Failed to upload file')
					}
				}).catch(err => {
					console.log('err', err);
					toast.error('Failed to upload file')
				})
		}
	}
	getEducationDiv = () => this.state.educationDetails.map((ele, eduIndex) => (
		<Row className="mt-3">
			<label className="col-md-12 text-right" onClick={() => {
				this.removeEducation(ele)
			}}>
				X
			</label>
			<Form.Label column sm={4} className="mt-2">
				School<span>*</span>
			</Form.Label>
			<Col sm={7} className="mt-2">
				<Form.Control id="Institution" placeholder="School"
					defaultValue={ele.Institution}
					onChange={(e) => {
						var ed = this.state.educationDetails;
						var ind = ed.findIndex(edu => edu.id == ele.id)
						if (ind > -1) {
							ed[ind].Institution = e && e.target && e.target.value || ""
						}
						this.setState({
							...this.state,
							educationDetails: ed
						})
					}}
				/>
				<span className="error">{this.state.educationErr && this.state.educationErr[eduIndex] && this.state.educationErr[eduIndex].Institution}</span>
			</Col>
			<Form.Label column sm={4} className="mt-2">
				Primary Major
			</Form.Label>
			<Col sm={7} className="mt-2">
				<Form.Control id="PrimaryMajor" placeholder="Primary Major"
					defaultValue={ele.PrimaryMajor}
					onChange={(e) => {
						var ed = this.state.educationDetails;
						var ind = ed.findIndex(edu => edu.id == ele.id)
						if (ind > -1) {
							ed[ind].PrimaryMajor = e && e.target && e.target.value || ""
						}
						this.setState({
							...this.state,
							educationDetails: ed
						})
					}}
				/>
				<span className="error">{this.state.educationErr && this.state.educationErr[eduIndex] && this.state.educationErr[eduIndex].PrimaryMajor}</span>
			</Col>
			<Form.Label column sm={4} >
				Secondary Major
			</Form.Label>
			<Col sm={7} className="mt-2">
				<Form.Control id="SecondaryMajor" placeholder="Secondary Major"
					defaultValue={ele.SecondaryMajor}
					onChange={(e) => {
						var ed = this.state.educationDetails;
						var ind = ed.findIndex(edu => edu.id == ele.id)
						if (ind > -1) {
							ed[ind].SecondaryMajor = e && e.target && e.target.value || ""
						}
						this.setState({
							...this.state,
							educationDetails: ed
						})
					}}
				/>
			</Col>
			<Form.Label column sm={4} className="mt-2">
				Degree Type
			</Form.Label >
			<Col sm={7} className="mt-2">
				<Form.Control id="DegreeType" placeholder="Degree Type"
					defaultValue={ele.DegreeType}
					onChange={(e) => {
						var ed = this.state.educationDetails;
						var ind = ed.findIndex(edu => edu.id == ele.id)
						if (ind > -1) {
							ed[ind].DegreeType = e && e.target && e.target.value || ""
						}
						this.setState({
							...this.state,
							educationDetails: ed
						})
					}}
				/>
				<span className="error">{this.state.educationErr && this.state.educationErr[eduIndex] && this.state.educationErr[eduIndex].DegreeType}</span>
			</Col>
			<Form.Label column sm={4} className="mt-2">
				Graduation Year
			</Form.Label>
			<Col sm={7} className="mt-2">

				<Form.Control type="number" id="GraduationYear" placeholder="Year"
					defaultValue={ele.GraduationYear}
					onChange={(e) => {
						var ed = this.state.educationDetails;
						var ind = ed.findIndex(edu => edu.id == ele.id)
						if (ind > -1) {
							ed[ind].GraduationYear = e && e.target && e.target.value || null
						}
						this.setState({
							...this.state,
							educationDetails: ed
						})
					}}
				/>
				<span className="error">{this.state.educationErr && this.state.educationErr[eduIndex] && this.state.educationErr[eduIndex].GraduationYear}</span>
			</Col>
		</Row>
	))

	getWorkDiv = () => this.state.WorkDetails.map((ele, wkIndex) => (
		<Row className="mt-3">
			<span className="float-right" onClick={() => {
				this.removeWork(ele);
			}} style={{position: 'absolute', right: 0}} >
				X
			</span>
			<Form.Label column sm={4} >
				Title<span>*</span>
			</Form.Label>
			<Col sm={7} >
				<Form.Control id="JobTitle" placeholder="Job Title"
					defaultValue={ele.JobTitle}
					onChange={(e) => {
						var ed = this.state.WorkDetails;
						var ind = ed.findIndex(edu => edu.id == ele.id)
						if (ind > -1) {
							ed[ind].JobTitle = e && e.target && e.target.value || ""
						}
						this.setState({
							...this.state,
							WorkDetails: ed
						})
					}}
				/>
				<span className="error">{this.state.workErr && this.state.workErr[wkIndex] && this.state.workErr[wkIndex].JobTitle}</span>
			</Col>
			<Form.Label column sm={4} className="mt-2">
				Employment Type<span>*</span>
			</Form.Label>
			<Col sm={7} className="mt-2">
				<Form.Control as="select" id="EmploymentType" placeholder="Employment Type"
					value={ele.EmploymentType}
					onChange={(e) => {
						var ed = this.state.WorkDetails;
						var ind = ed.findIndex(edu => edu.id == ele.id)
						if (ind > -1) {
							ed[ind].EmploymentType = e && e.target && e.target.value || ""
						}
						this.setState({
							...this.state,
							WorkDetails: ed
						})
					}}
				>
					<option>Select Type</option>
					<option value="1">Full-Time</option>
					<option value="2">Parttime</option>
					<option value="3">Self-employed</option>
					<option value="4">Freelancer</option>
					<option value="5">Internship</option>
					<option value="6">Trainee</option>
				</Form.Control>
				<span className="error">{this.state.workErr && this.state.workErr[wkIndex] && this.state.workErr[wkIndex].EmploymentType}</span>
			</Col>
			<Form.Label column sm={4} className="mt-2">
				Company Name<span>*</span>
			</Form.Label>
			<Col sm={7} className="mt-2">
				<Form.Control id="Organization" placeholder="Company Name"
					defaultValue={ele.Organization}
					onChange={(e) => {
						var ed = this.state.WorkDetails;
						var ind = ed.findIndex(edu => edu.id == ele.id)
						if (ind > -1) {
							ed[ind].Organization = e && e.target && e.target.value || ""
						}
						this.setState({
							...this.state,
							WorkDetails: ed
						})
					}}
				/>
				<span className="error">{this.state.workErr && this.state.workErr[wkIndex] && this.state.workErr[wkIndex].Organization}</span>
			</Col>
			<Form.Label column sm={4} >
				Location<span>*</span>
			</Form.Label>
			<Col sm={7} className="mt-2">
				<Form.Control id="Location" placeholder="Location"
					defaultValue={ele.Location}
					onChange={(e) => {
						var ed = this.state.WorkDetails;
						var ind = ed.findIndex(edu => edu.id == ele.id)
						if (ind > -1) {
							ed[ind].Location = e && e.target && e.target.value || ""
						}
						this.setState({
							...this.state,
							WorkDetails: ed
						})
					}}
				/>
				<span className="error">{this.state.workErr && this.state.workErr[wkIndex] && this.state.workErr[wkIndex].Location}</span>
			</Col>
			{/*<Form.Label column sm={4} >
				Description
			</Form.Label>
			<Col sm={7} className="mt-2">
				<Form.Control as="textarea" rows={3} id="Description" placeholder="Description"
					defaultValue={ele.Description}
					onChange={(e) => {
						var ed = this.state.WorkDetails;
						var ind = ed.findIndex(edu => edu.id == ele.id)
						if (ind > -1) {
							ed[ind].Description = e && e.target && e.target.value || ""
						}
						this.setState({
							...this.state,
							WorkDetails: ed
						})
					}}
				/>
			</Col>*/}
			<Form.Label column sm={4} className="mt-2">
				Start Date<span>*</span>
			</Form.Label >
			<Col sm={7} className="mt-2">

				<Form.Control type="date" id="StartDate" placeholder="Start Date"
					defaultValue={ele.StartDate}
					onChange={(e) => {
						var ed = this.state.WorkDetails;
						var ind = ed.findIndex(edu => edu.id == ele.id)
						if (ind > -1) {
							ed[ind].StartDate = e && e.target && e.target.value && moment(e.target.value).toDate() || null
						}
						this.setState({
							...this.state,
							WorkDetails: ed
						})
					}}
				/>
				<span className="error">{this.state.workErr && this.state.workErr[wkIndex] && this.state.workErr[wkIndex].StartDate}</span>
			</Col>
			<Form.Label column sm={4} className="mt-2">
				End Date
			</Form.Label>
			<Col sm={7} className="mt-2">

				<Form.Control type="date" id="EndDate" placeholder="End Date"
					defaultValue={ele.EndDate}
					onChange={(e) => {
						var ed = this.state.WorkDetails;
						var ind = ed.findIndex(edu => edu.id == ele.id)
						if (ind > -1) {
							ed[ind].EndDate = e && e.target && e.target.value && moment(e.target.value).toDate() || null
						}
						this.setState({
							...this.state,
							WorkDetails: ed
						})
					}}
				/>
				<span className="error">{this.state.workErr && this.state.workErr[wkIndex] && this.state.workErr[wkIndex].EndDate}</span>
			</Col>
		</Row>
	))

	removeEducation = (ele) => {
		var ed = [...this.state.educationDetails];
		var ind = ed.findIndex(edu => edu.id == ele.id)
		if (ind > -1) {
			if (ele.EducationID) {
				httpPost("education_controller/delete", { EducationID: ele.EducationID })
					.then((res) => {
						if (res && res.status) {
							ed.splice(ind, 1);
							this.setState({
								...this.state,
								educationDetails: ed
							})
						} else {
							toast.error('Failed to Delete Education')
						}
					}).catch(err => {
						console.log('err', err);
					})
			} else {
				ed.splice(ind, 1);
				this.setState({
					...this.state,
					educationDetails: ed
				})
			}
		}
	}
	removeWork = (ele) => {
		var ed = [...this.state.WorkDetails];
		var ind = ed.findIndex(edu => edu.id == ele.id)
		if (ind > -1) {
			if (ele.WorkID) {
				httpPost("work_controller/delete", { WorkID: ele.WorkID })
					.then((res) => {
						if (res && res.status) {
							ed.splice(ind, 1);
							this.setState({
								...this.state,
								WorkDetails: ed
							})
						} else {
							toast.error('Failed to Delete Education')
						}
					}).catch(err => {
						console.log('err', err);
					})
			} else {
				ed.splice(ind, 1);
				this.setState({
					...this.state,
					WorkDetails: ed
				})
			}
		}

	}
	addEducationDiv = () => {
		var edu = [...this.state.educationDetails];
		var id;
		if (edu.length === 0) {
			id = 1;
		} else {
			id = edu[edu.length - 1].id + 1;
		}
		edu.push({ ...this.defaultEducation, id: id });
		this.setState({
			...this.state,
			educationDetails: edu
		})
	}
	addWorkDiv = () => {
		var edu = [...this.state.WorkDetails];
		var id;
		if (edu.length === 0) {
			id = 1;
		} else {
			id = edu[edu.length - 1].id + 1;
		}
		edu.push({ ...this.defaultWork, id: id });
		this.setState({
			...this.state,
			WorkDetails: edu
		})
	}
	saveEducation = async () => {
		let { educationDetails, educationErr } = this.state;
		if (educationDetails.length > 0) {
			var eduErr = [];
			var err = false;
			educationDetails.map(edu => {
				console.log(moment(edu.StartDate).format('YYYY-MM-DD'))
				var errObj = {
					Institution: edu.Institution ? null : "Enter Institution",
					// Degree: edu.Degree ? null : "Enter Degree",
					// StartDate: edu.StartDate ? !moment(edu.StartDate).isAfter(moment()) ? (edu.EndDate && !moment(edu.StartDate).isAfter(moment(edu.EndDate))) ? null : "Enter Valid Date" : "Enter Valid Date" : "Enter Start Date",
					// EndDate: edu.EndDate ? !moment(edu.EndDate).isAfter(moment()) ? null : "Enter Valid Date" : "Enter End Date",
				}
				eduErr.push(errObj);
				if (!err) {
					Object.entries(errObj).forEach((item, value) => {
						if (item[1]) {
							err = true
						}
					})
				}
			})
			this.setState({
				...this.state,
				educationErr: eduErr
			});

			if (!err) {
				this.setState({
					...this.state,
					isDisabled: true
				});
				for (var i = 0; i < educationDetails.length; i++) {
					var ins = await this.addEdu(educationDetails[i]);
					console.log('add edu', ins);
				}
				this.setState({
					...this.state,
					isDisabled: false
				});
				this.getDetails();
			}
		}
	}
	addEdu = (edu) => {
		return new Promise(async resolve => {
			var url;
			if (edu.EducationID) {
				url = "education_controller/update";
			} else {
				url = "education_controller/create";
			}
			httpPost(url, edu)
				.then((res) => {
					console.log(res);
					toast.success(res.message);
					resolve(true)
				}).catch(err => {
					console.log('err', err);
					resolve(false);
				})
		})

	}
	saveWork = async () => {
		let { WorkDetails, workErr } = this.state;
		if (WorkDetails.length > 0) {
			var wkErr = [];
			var err = false;
			WorkDetails.map(edu => {
				var errObj = {
					Organization: edu.Organization ? null : "Enter Organization",
					JobTitle: edu.JobTitle ? null : "Enter Job Title",
					EmploymentType: edu.EmploymentType ? null : "Select Employment Type",
					Location: edu.Location ? null : "Enter Location",
					StartDate: edu.StartDate ? !moment(edu.StartDate).isAfter(moment()) ? (edu.EndDate && !moment(edu.StartDate).isAfter(moment(edu.EndDate)) || !edu.EndDate) ? null : "Enter Valid Date" : "Enter Valid Date" : "Enter Start Date",
					EndDate: edu.EndDate ? !moment(edu.EndDate).isAfter(moment()) ? null : "Enter Valid Date" : null,
				}
				wkErr.push(errObj);
				if (!err) {
					Object.entries(errObj).forEach((item, value) => {
						// console.log('item', item, value);
						if (item[1]) {
							// console.log('value', value);
							err = true
						}
					})
				}
			})
			this.setState({
				...this.state,
				workErr: wkErr
			});

			if (!err) {
				//Enter All edu
				this.setState({
					...this.state,
					isDisabled: true
				});
				for (var i = 0; i < WorkDetails.length; i++) {
					var ins = await this.addWork(WorkDetails[i]);
					console.log('add work', ins);
				}
				this.setState({
					...this.state,
					isDisabled: false
				});
				this.getDetails();
			}
		}
	}
	addWork = (work) => {
		return new Promise(async resolve => {
			var url;
			if (work.WorkID) {
				url = "work_controller/update";
			} else {
				url = "work_controller/create";
			}
			httpPost(url, work)
				.then((res) => {
					console.log(res);
					resolve(true)
					toast.success(res.message);
				}).catch(err => {
					console.log('err', err);
					resolve(false);
				})
		})
	}
	getGoogleLocation = (e) => {
		console.log('e');
		geocodeByAddress(e.description)
			.then(results => getLatLng(results[0]))
			.then(({ lat, lng }) => {
				console.log('Successfully got latitude and longitude', { lat, lng });
				this.props.form.setFieldsValue({
					space_latitude: lat,
					space_longitude: lng,
					space_location: e.description,
				})
			}
			).catch((err) => {
				this.props.form.setFieldsValue({
					space_latitude: null,
					space_longitude: null,
				})
			});
	}

	addLanguage = () => {
		var lang = [...this.state.Language];
		var id;
		if (lang.length === 0) {
			id = 1;
		} else {
			id = lang[lang.length - 1].id + 1;
		}
		lang.push({ ...this.defaultLanguage, id: id });
		this.setState({
			...this.state,
			Language: lang
		})
	}

	removeLanguage = (ele) => {
		var lang = [...this.state.Language];
		var ind = lang.findIndex(ln => ln.id == ele.id)
		if (ind > -1) {
			lang.splice(ind, 1);
			this.setState({
				...this.state,
				Language: lang
			})
		}
	}

	getLanguage = () => this.state.Language.map((ele, lInd) => (
		<div>
		<Row className="mt-3">
			<Form.Label column sm={1} >
				<span>*</span>
			</Form.Label>
			<Col sm={10} >
				<Form.Control size="sm" as="select" id={"Name-"+lInd} placeholder="Language"
					value={ele.Name} 
					onChange={(e) => {
						var ln = this.state.Language;
						var ind = ln.findIndex(lang => lang.id == ele.id)
						if (ind > -1) {
							ln[ind].Name = e && e.target && e.target.value || ""
						}
						this.setState({
							...this.state,
							Language: ln
						})
					}} >
					<option>Language</option>
					{this.props.languages && this.props.languages.map(el => (
						<option value={el.LanguageName} >{el.LanguageName}</option>
					))}
				</Form.Control>
			</Col>
		</Row>
		<Row className="mt-3" >
			<Col sm={3} >
				<Form.Check type="checkbox" id={"Read-"+lInd} label="Read"
					value={ele.Read} 
					checked={ele.Read == "1" ? true : false} 
					onChange={(e) => {
						console.log(e, e.target.value)
						var ln = this.state.Language;
						var ind = ln.findIndex(lang => lang.id == ele.id)
						if (ind > -1) {
							ln[ind].Read = e.target.value == "1" ? "0" : "1"
						}
						this.setState({
							...this.state,
							Language: ln
						})
					}} />&nbsp;
			</Col>
			<Col sm={3} >
				<Form.Check type="checkbox" id={"Write-"+lInd} label="Write "
					value={ele.Write} 
					checked={ele.Write == "1" ? true : false} 
					onChange={(e) => {
						var ln = this.state.Language;
						var ind = ln.findIndex(lang => lang.id == ele.id)
						if (ind > -1) {
							ln[ind].Write = e.target.value == "1" ? "0" : "1"
						}
						this.setState({
							...this.state,
							Language: ln
						})
					}} />&nbsp;
			</Col>
			<Col sm={3} >
				<Form.Check type="checkbox" id={"Speak-"+lInd} label="Speak "
					value={ele.Speak} 
					checked={ele.Speak == "1" ? true : false} 
					onChange={(e) => {
						var ln = this.state.Language;
						var ind = ln.findIndex(lang => lang.id == ele.id)
						if (ind > -1) {
							ln[ind].Speak = e.target.value == "1" ? "0" : "1"
						}
						this.setState({
							...this.state,
							Language: ln
						})
					}} />
			</Col>
			<Col sm={3} >
				<Link to="#" onClick={() => { this.removeLanguage(ele); }} >Remove</Link>
			</Col>
		</Row>
		</div>
	))
	render() {
		let { formErr } = this.state;
		return (
			<div className="edit-profile" >
				{this.props.auth.user === null ? (<Redirect to="/signin" />) : null}
				<Container>
					<Row>
						<Col md="12" className="offset-0" >
							<div id="stepper1" class="bs-stepper">
								<div class="bs-stepper-header">
									<div class="step" data-target="#test-l-1">
										<button class="step-trigger">
											<span class="bs-stepper-circle">1</span>
											<span class="bs-stepper-label">Personal Information</span>
										</button>
									</div>
									<div class="line"></div>
									<div class="step" data-target="#test-l-2">
										<button class="step-trigger">
											<span class="bs-stepper-circle">2</span>
											<span class="bs-stepper-label">Contact Information</span>
										</button>
									</div>
									<div class="line"></div>
									<div class="step" data-target="#test-l-3">
										<button class="step-trigger">
											<span class="bs-stepper-circle">3</span>
											<span class="bs-stepper-label">Language Details</span>
										</button>
									</div>
									<div class="line"></div>
									<div class="step" data-target="#test-l-4">
										<button class="step-trigger">
											<span class="bs-stepper-circle">4</span>
											<span class="bs-stepper-label">Education and Work</span>
										</button>
									</div>
									{this.props.auth.user && this.props.auth.user.IsExpert === 1 ? (<div class="line"></div>) : null }
									{this.props.auth.user && this.props.auth.user.IsExpert === 1 ?
									<div class="step" data-target="#test-l-5">
										<button class="step-trigger">
											<span class="bs-stepper-circle">5</span>
											<span class="bs-stepper-label">Teaching English</span>
										</button>
									</div> : null}
									<div class="line"></div>
									<div class="step" data-target="#test-l-6">
										<button class="step-trigger">
											<span class="bs-stepper-circle">{this.props.auth.user && this.props.auth.user.IsExpert === 1 ? 6 : 5}</span>
											<span class="bs-stepper-label">Interests</span>
										</button>
									</div>
								</div>
								<div class="bs-stepper-content">
									<form onSubmit={this.onSubmit} >
										<div id="test-l-1" class="content">
											<div className="mt-5 col-md-8 offset-md-2" >
												<Form.Group as={Row} >
													<Form.Label column sm={4}>
														First Name<span>*</span>
													</Form.Label>
													<Col sm={7}>
														<Form.Control required type="text" id="FirstName" name="FirstName" placeholder="First Name" defaultValue={this.state.userDetail.FirstName} onChange={this.handleChange} />
														{/*error.FirstName ? (<span className="error" >{error.FirstName}</span>) : null*/}
														<span className="error">{formErr && formErr.FirstName}</span>
													</Col>
												</Form.Group>
												<Form.Group as={Row} >
													<Form.Label column sm={4}>
														Last Name<span>*</span>
													</Form.Label>
													<Col sm={7}>
														<Form.Control type="text" id="LastName" name="LastName" placeholder="Last Name" defaultValue={this.state.userDetail.LastName} onChange={this.handleChange} />
														{/*error.LastName ? (<span className="error" >{error.LastName}</span>) : null*/}
														<span className="error">{formErr && formErr.LastName}</span>
													</Col>
												</Form.Group>
												<Form.Group as={Row} >
													<Form.Label column sm={4}>
														Gender<span>*</span>
													</Form.Label>
													<Col sm={7}>
														<Form.Control size="sm" as="select" id="Gender" name="Gender" placeholder="Gender" value={this.state.userDetail ? this.state.userDetail.Gender : ""} onChange={this.handleChange} >
															<option>Select</option>
															<option value="2" >Female</option>
															<option value="1" >Male</option>
															<option value="3" >Non-Binary</option>
															<option value="4" >Prefer Not To Say</option>
														</Form.Control>
														{/*<div className="row" >
															<div className="col-md-2" >
																<Form.Check id="male" name="Gender" type="radio" label="Male" checked={this.state.userDetail.Gender == '1' ? true : false} onChange={this.handleChange} />
															</div>
															<div className="col-md-3" >
																<Form.Check id="female" name="Gender" type="radio" label="Female" checked={this.state.userDetail.Gender == '2' ? true : false} onChange={this.handleChange} />
															</div>
														</div>*/}
														<span className="error">{formErr && formErr.Gender}</span>
													</Col>
												</Form.Group>
												<Form.Group as={Row} >
													<Form.Label column sm={4}>
														Date of Birth
													</Form.Label>
													<Col sm={7}>
												        <YearPicker
												          defaultValue={'year'}
												          start={1920}                // default is 1900
												          end={2020}                  // default is current year
												          reverse                     // default is ASCENDING
												          required={false}
												          disabled={false}
												          value={this.state.year}
												          onChange={(year) => {  
												          	this.setState({ year });
												          }}
												          id={'year'}
												          name={'year'}
												          classes={'classes'}
												          optionClasses={'option classes'}
												        />&nbsp;&nbsp;&nbsp;
												        <MonthPicker
												          defaultValue={'month'}
												          numeric                   // to get months as numbers
												          short                     // default is full name
												          caps                      // default is Titlecase
												          endYearGiven              // mandatory if end={} is given in YearPicker
												          year={this.state.year}    // mandatory
												          required={false}         
												          disabled={false}        
												          value={this.state.month}
												          onChange={(month) => { 
												          	this.setState({ month });
												          }}
												          id={'month'}
												          name={'month'}
												          classes={'classes'}
												          optionClasses={'option classes'}
												        />&nbsp;&nbsp;&nbsp;
												        <DayPicker
												          defaultValue={'day'}
												          year={this.state.year}    // mandatory
												          month={this.state.month}  // mandatory
												          endYearGiven              // mandatory if end={} is given in YearPicker
												          required={false}           
												          disabled={false}          
												          value={this.state.day}    
												          onChange={(day) => {
												          	this.setState({ day });
												          }}
												          id={'day'}
												          name={'day'}
												          classes={'classes'}
												          optionClasses={'option classes'}
												        />
														{/*<Form.Control type="date" id="DOB" placeholder="DOB" defaultValue={this.state.userDetail.DOB} onChange={this.handleChange} />*/}
														<div className="error">{formErr && formErr.DOB}</div>
													</Col>
												</Form.Group>
												<Form.Group as={Row} >
													<Form.Label column sm={4}>
														Home Town
													</Form.Label>
													<Col sm={7}>
														<GooglePlaces
															placeholder="Home Town"
															value={this.state.userDetail.HomeTown || null}
															change={e => {
																this.setState({
																	...this.state,
																	userDetail: {
																		...this.state.userDetail,
																		HomeTown: e
																	}
																})
															}}
														/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} >
													<Form.Label column sm={4}>
														Current City
													</Form.Label>
													<Col sm={7}>
														<GooglePlaces
															placeholder="Current City"
															value={this.state.userDetail.CurrentLocation || null}
															change={e => {
																this.setState({
																	...this.state,
																	userDetail: {
																		...this.state.userDetail,
																		CurrentLocation: e
																	}
																})
															}}
														/>
													</Col>
												</Form.Group>
												<Form.Group as={Row} >
													<Form.Label column sm={4}>
														Introduce yourself
													</Form.Label>
													<Col sm={7}>
														<Form.Control as="textarea" rows={3} id="About" placeholder="About me" defaultValue={this.state.userDetail.About} onChange={this.handleChange} />
													</Col>
												</Form.Group>
											</div>
											<div className="text-center" >
												<Button type="button" variant="outline-primary" className="ml-2 mr-2"
													onClick={() => {
														this.saveTillThis('1');
													}}>Save & Exit</Button>
												<Button type="button" variant="outline-primary"
													onClick={() => {
														const _this = this;
														this.validateStep('1', function (res) {
															if (res) {
																console.log('validate')
																_this.nextStep()
															}
														})
													}}>Next</Button>
											</div>
										</div>
										<div id="test-l-2" class="content">
											<div className="mt-5 col-md-8 offset-md-2" >
												<Form.Group as={Row} >
													<Form.Label column sm={4}>
														Email<span>*</span>
													</Form.Label>
													<Col sm={7}>
														<Form.Control type="email" id="Email" name="Email" placeholder="eg. abc@mail.com" defaultValue={this.state.userDetail ? this.state.userDetail.Email : ""} onChange={this.handleChange} />
														<span className="error">{formErr && formErr.Email}</span>
													</Col>
												</Form.Group>
												{/*<Form.Group as={Row} >
													<Form.Label column sm={4}>
														Business Email
													</Form.Label>
													<Col sm={7}>
														<Form.Control type="email" id="BusinessEmail" placeholder="eg. abc@mail.com" defaultValue={this.state.userDetail ? this.state.userDetail.BusinessEmail : this.state.userDetail.BusinessEmail} onChange={this.handleChange} />
														<span className="error">{formErr && formErr.BusinessEmail}</span>
													</Col>
												</Form.Group>*/}
												<Form.Group as={Row} >
													<Form.Label column sm={4}>
														Mobile No
													</Form.Label>
													<Col sm={2}>
														<Form.Control size="sm" as="select" id="CountryCode" name="CountryCode" value={this.state.userDetail ? this.state.userDetail.CountryCode : this.state.userDetail.CountryCode} onChange={this.handleChange} >
															<option>+91</option>
															<option>+93</option>
															<option>+355</option>
															<option>+213</option>
															<option>+1684</option>
															<option>+376</option>
															<option>+244</option>
															<option>+1264</option>
															<option>+672</option>
															<option>+1268</option>
															<option>+54</option>
															<option>+374</option>
															<option>+297</option>
															<option>+61</option>
															<option>+43</option>
															<option>+994</option>
															<option>+1242</option>
															<option>+973</option>
															<option>+880</option>
															<option>+1246</option>
															<option>+375</option>
															<option>+32</option>
															<option>+501</option>
															<option>+229</option>
															<option>+1441</option>
															<option>+975</option>
															<option>+591</option>
															<option>+387</option>
															<option>+267</option>
															<option>+55</option>
															<option>+246</option>
															<option>+1284</option>
															<option>+673</option>
															<option>+359</option>
															<option>+226</option>
															<option>+257</option>
															<option>+855</option>
															<option>+237</option>
															<option>+1</option>
															<option>+238</option>
															<option>+1345</option>
															<option>+236</option>
															<option>+235</option>
															<option>+56</option>
															<option>+86</option>
															<option>+61</option>
															<option>+61</option>
															<option>+57</option>
															<option>+269</option>
															<option>+682</option>
															<option>+506</option>
															<option>+385</option>
															<option>+53</option>
															<option>+599</option>
															<option>+357</option>
															<option>+420</option>
															<option>+243</option>
															<option>+45</option>
															<option>+253</option>
															<option>+1767</option>
															<option>+1809</option>
															<option>+670</option>
															<option>+593</option>
															<option>+20</option>
															<option>+503</option>
															<option>+240</option>
															<option>+291</option>
															<option>+372</option>
															<option>+251</option>
															<option>+500</option>
															<option>+298</option>
															<option>+679</option>
															<option>+358</option>
															<option>+33</option>
															<option>+689</option>
															<option>+241</option>
															<option>+220</option>
															<option>+995</option>
															<option>+49</option>
															<option>+233</option>
															<option>+350</option>
															<option>+30</option>
															<option>+299</option>
															<option>+1473</option>
															<option>+1671</option>
															<option>+502</option>
															<option>+441481</option>
															<option>+224</option>
															<option>+245</option>
															<option>+592</option>
															<option>+509</option>
															<option>+504</option>
															<option>+852</option>
															<option>+36</option>
															<option>+354</option>
															<option>+62</option>
															<option>+98</option>
															<option>+964</option>
															<option>+353</option>
															<option>+441624</option>
															<option>+972</option>
															<option>+39</option>
															<option>+225</option>
															<option>+1876</option>
															<option>+81</option>
															<option>+441534</option>
															<option>+962</option>
															<option>+7</option>
															<option>+254</option>
															<option>+686</option>
															<option>+383</option>
															<option>+965</option>
															<option>+996</option>
															<option>+856</option>
															<option>+371</option>
															<option>+961</option>
															<option>+266</option>
															<option>+231</option>
															<option>+218</option>
															<option>+423</option>
															<option>+370</option>
															<option>+352</option>
															<option>+853</option>
															<option>+389</option>
															<option>+261</option>
															<option>+265</option>
															<option>+60</option>
															<option>+960</option>
															<option>+223</option>
															<option>+356</option>
															<option>+692</option>
															<option>+222</option>
															<option>+230</option>
															<option>+262</option>
															<option>+52</option>
															<option>+691</option>
															<option>+373</option>
															<option>+377</option>
															<option>+976</option>
															<option>+382</option>
															<option>+1664</option>
															<option>+212</option>
															<option>+258</option>
															<option>+95</option>
															<option>+264</option>
															<option>+674</option>
															<option>+977</option>
															<option>+31</option>
															<option>+599</option>
															<option>+687</option>
															<option>+64</option>
															<option>+505</option>
															<option>+227</option>
															<option>+234</option>
															<option>+683</option>
															<option>+850</option>
															<option>+1670</option>
															<option>+47</option>
															<option>+968</option>
															<option>+92</option>
															<option>+680</option>
															<option>+970</option>
															<option>+507</option>
															<option>+675</option>
															<option>+595</option>
															<option>+51</option>
															<option>+63</option>
															<option>+64</option>
															<option>+48</option>
															<option>+351</option>
															<option>+1787</option>
															<option>+974</option>
															<option>+242</option>
															<option>+262</option>
															<option>+40</option>
															<option>+7</option>
															<option>+250</option>
															<option>+590</option>
															<option>+290</option>
															<option>+1869</option>
															<option>+1758</option>
															<option>+590</option>
															<option>+508</option>
															<option>+1784</option>
															<option>+685</option>
															<option>+378</option>
															<option>+239</option>
															<option>+966</option>
															<option>+221</option>
															<option>+381</option>
															<option>+248</option>
															<option>+232</option>
															<option>+65</option>
															<option>+1721</option>
															<option>+421</option>
															<option>+386</option>
															<option>+677</option>
															<option>+252</option>
															<option>+27</option>
															<option>+82</option>
															<option>+211</option>
															<option>+34</option>
															<option>+94</option>
															<option>+249</option>
															<option>+597</option>
															<option>+47</option>
															<option>+268</option>
															<option>+46</option>
															<option>+41</option>
															<option>+963</option>
															<option>+886</option>
															<option>+992</option>
															<option>+255</option>
															<option>+66</option>
															<option>+228</option>
															<option>+690</option>
															<option>+676</option>
															<option>+1868</option>
															<option>+216</option>
															<option>+90</option>
															<option>+993</option>
															<option>+1649</option>
															<option>+688</option>
															<option>+1340</option>
															<option>+256</option>
															<option>+380</option>
															<option>+971</option>
															<option>+44</option>
															<option>+1</option>
															<option>+598</option>
															<option>+998</option>
															<option>+678</option>
															<option>+379</option>
															<option>+58</option>
															<option>+84</option>
															<option>+681</option>
															<option>+212</option>
															<option>+967</option>
															<option>+260</option>
															<option>+263</option>
														</Form.Control>
													</Col>
													<Col sm={5}>
														<Form.Control type="text" id="MobileNo" placeholder="eg. 9875652561" defaultValue={this.state.userDetail ? this.state.userDetail.MobileNo : ""} onChange={this.handleChange} />
														<span className="error">{formErr && formErr.MobileNo}</span>
													</Col>
												</Form.Group>
												<Form.Group as={Row} >
													<Form.Label column sm={4}>
														Website/Blog
													</Form.Label>
													<Col sm={7}>
														<Form.Control type="text" id="Website" placeholder="eg. https://lipsum.com" defaultValue={this.state.userDetail ? this.state.userDetail.Website : ""} onChange={this.handleChange} />
														<span className="error">{formErr && formErr.Website}</span>
													</Col>
												</Form.Group>
												{/*<Form.Group as={Row} >
													<Form.Label column sm={4}>
														Blog
													</Form.Label>
													<Col sm={7}>
														<Form.Control type="text" id="BlogAddress" placeholder="eg. https://abc.com/myblog" defaultValue={this.state.userDetail ? this.state.userDetail.BlogAddress : ""} onChange={this.handleChange} />
														<span className="error">{formErr && formErr.BlogAddress}</span>
													</Col>
												</Form.Group>*/}
											</div>
											<div className="text-center" >
												<Button type="button" variant="outline-secondary"  onClick={() => this.stepper.to(1)}>Back</Button>
												<Button type="button" variant="outline-secondary" className="ml-2" onClick={() => this.stepper.to(3)}>Skip</Button>
												<Button type="button" variant="outline-primary" className="ml-2 mr-2"
													onClick={() => {
														this.saveTillThis('2');
													}}>Save & Exit</Button>
												<Button type="button" variant="outline-primary" 
													onClick={() => {
														const _this = this;
														this.validateStep('2', function (res) {
															if (res) {
																console.log('validate')
																_this.nextStep()
															}
														})
													}}
												>Next</Button>
											</div>
										</div>
										<div id="test-l-3" class="content text-center">
											<div className="mt-5 col-md-8 offset-md-2" >
												<Form.Group as={Row} >
													<Form.Label column sm={4}>
														Do you want to practice your English with others
													</Form.Label>
													<Col sm={7}>
														<div className="row" >
															<div className="col-md-2" >
																<Form.Check name="WantsEnglishChat" type="radio" label="Yes" checked={this.state.userDetail.WantsEnglishChat == '1' ? true : false}
																	onClick={(e) => {
																		this.setState({
																			...this.state,
																			userDetail: {
																				...this.state.userDetail,
																				WantsEnglishChat: '1'
																			}
																		})
																	}} />
															</div>
															<div className="col-md-3" >
																<Form.Check name="WantsEnglishChat" type="radio" label="No" checked={this.state.userDetail.WantsEnglishChat == '0' ? true : false}
																	onClick={(e) => {
																		this.setState({
																			...this.state,
																			userDetail: {
																				...this.state.userDetail,
																				WantsEnglishChat: '0'
																			}
																		})
																	}}
																/>
															</div>
														</div>
														<p className="error text-left">{formErr && formErr.WantsEnglishChat}</p>
													</Col>
												</Form.Group>
												<Form.Group as={Row} >
													<Form.Label column sm={4}>
														Your native language
													</Form.Label>
													<Col sm={7}>
														{this.getLanguage()}
														<Button onClick={() => this.addLanguage()} variant="light" className="btn-sm btn-primary float-right" >Add Language</Button>
													
													</Col>
												</Form.Group>
												<Form.Group as={Row} >
													<Form.Label column sm={4}>
														English proficiency level
													</Form.Label>
													<Col sm={7}>
														<Form.Check type="checkbox" label="Native Speaker" checked={this.state.userDetail && (this.state.userDetail.LevelOfEnglish === "1" || this.state.userDetail.LevelOfEnglish == "Native Speaker") ? true : false} onChange={() => this.handleLevelOfEnglish('1')} />
														<Form.Check type="checkbox" label="English Teacher" checked={this.state.userDetail && (this.state.userDetail.LevelOfEnglish === "2" || this.state.userDetail.LevelOfEnglish == "English Teacher") ? true : false} onChange={() => this.handleLevelOfEnglish('2')} />
														<Form.Check type="checkbox" label="Fluent" checked={this.state.userDetail && (this.state.userDetail.LevelOfEnglish === "3" || this.state.userDetail.LevelOfEnglish == "Fluent") ? true : false} onChange={() => this.handleLevelOfEnglish('3')} />
														<Form.Check type="checkbox" label="Intermediate" checked={this.state.userDetail && (this.state.userDetail.LevelOfEnglish === "4" || this.state.userDetail.LevelOfEnglish == "Intermediate") ? true : false} onChange={() => this.handleLevelOfEnglish('4')} />
														<Form.Check type="checkbox" label="Beginner" checked={this.state.userDetail && (this.state.userDetail.LevelOfEnglish == "5" || this.state.userDetail.LevelOfEnglish == "Beginner") ? true : false} onChange={() => this.handleLevelOfEnglish('5')} />
														<p className="error text-left">{formErr && formErr.LevelOfEnglish}</p>
													</Col>
												</Form.Group>

												{/*<Form.Group as={Row} >
													<Form.Label column sm={4}>
														What suggestions to do you have for other students
													</Form.Label>
													<Col sm={7}>
														<Form.Control as="textarea" rows={3} id="SuggestionsForStudents" placeholder="What suggestions to do you have for other students" defaultValue={this.state.userDetail ? this.state.userDetail.SuggestionsForStudents : ""} onChange={this.handleChange} />
													</Col>
												</Form.Group>*/}
												<Form.Group as={Row} >
													<Form.Label column sm={4}>
														Do you hold any of these certificates
													</Form.Label>
													<Col sm={7}>
														{this.state.learnerFileList && this.state.learnerFileList.map(cert => (
															<>
																<Form.Check name="LearnerCertificates" type="checkbox" label={cert} id={cert}
																	checked={this.state.userDetail.LearnerCertificates && this.state.userDetail.LearnerCertificates.filter(ele => ele.name == cert).length > 0 ? true : false}
																	onChange={this.handleChange}
																/>
																{this.state.userDetail.LearnerCertificates && this.state.userDetail.LearnerCertificates.filter(ele => ele.name == cert).length > 0 ? (
																	<>
																		<Form.Control className="mt-2 mb-2" type="file" id={"file_" + cert}
																			onChange={(e) => {
																				this.uploadIndividualFile(e, cert, 1);
																			}} />
																		{this.state.userDetail.LearnerCertificates.filter(ele => ele.name == cert)[0].file ? (
																			<p><a href={this.state.userDetail.LearnerCertificates.filter(ele => ele.name == cert)[0].file} target="_blank">Certificate</a></p>
																		) : null}
																		<p className="error text-left">{formErr && formErr.LearnerCertificates && formErr.LearnerCertificates.filter(ele => ele.name == cert).length > 0 ? formErr.LearnerCertificates.filter(ele => ele.name == cert)[0].fileErr : null}</p>
																	</>
																) : null}


															</>
														))}
														{this.state.userDetail.LearnerCertificates && this.state.userDetail.LearnerCertificates.filter(ele => ele.name == 'Other').length > 0 ? (
															<>
																<Form.Control rows={3}
																	defaultValue={this.state.userDetail.LearnerCertificates && this.state.userDetail.LearnerCertificates.filter(ele => ele.name == 'Other').length > 0 ? this.state.userDetail.LearnerCertificates.filter(ele => ele.name == 'Other')[0].other_name : ""} placeholder="Other Name"
																	onChange={(e) => {
																		var lernArr = [...this.state.userDetail.LearnerCertificates];
																		var ind = lernArr && lernArr.findIndex(ele => ele.name == 'Other');
																		if (ind > -1) {
																			lernArr[ind].other_name = e && e.target && e.target.value || ""
																			this.setState({
																				...this.state,
																				userDetail: {
																					...this.state.userDetail,
																					LearnerCertificates: lernArr
																				}
																			}, () => {

																			})
																		}
																	}} />
																<p className="error text-left">{formErr && formErr.LearnerCertificates && formErr.LearnerCertificates.filter(ele => ele.name == "Other").length > 0 ? formErr.LearnerCertificates.filter(ele => ele.name == "Other")[0].otherErr : null}</p>
															</>
														) : null}
													</Col>
												</Form.Group>
												<Form.Group as={Row} >
													<Form.Label column sm={4}>
														Why are you learning English
													</Form.Label>
													<Col sm={7}>
														<Form.Control as="textarea" rows={3} id="LearningEnglishReason" defaultValue={this.state.userDetail.LearningEnglishReason ? this.state.userDetail.LearningEnglishReason : ""} placeholder="Why are you learning English" onChange={this.handleChange} />
													</Col>
												</Form.Group>
												{/* <Form.Group as={Row} >
													<Form.Label column sm={4}>
														Upload Certificate
													</Form.Label>
													<Col sm={7}>
														<Form.Control type="file" multiple id="LearningCertificate" onChange={(e) => {
															this.setState({
																...this.state,
																learnigFiles: e && e.target && e.target.files || null
															})
														}} />
														{this.state.userDetail && this.state.userDetail.LearnerCertificateFiles && this.state.userDetail.LearnerCertificateFiles.map((ele, index) => (
															<>
																<a href={ele} target="_blank">File {index + 1}</a>
																<br />
															</>
														))}
													</Col>
												</Form.Group> */}
											</div>
											<div className="text-center" >
												<Button type="button" variant="outline-secondary" onClick={() => this.stepper.to(2)}>Back</Button>
												<Button type="button" variant="outline-secondary" className="ml-2" onClick={() => this.stepper.to(4)}>Skip</Button>
												<Button type="button" variant="outline-primary" className="ml-2 mr-2"
													onClick={() => {
														this.saveTillThis('3');
													}}>Save & Exit</Button>
												<Button type="button" variant="outline-primary"
													onClick={() => {
														const _this = this;
														this.validateStep('3', function (res) {
															if (res) {
																console.log('validate')
																_this.nextStep()
															}
														})
													}}
												>Next</Button>
											</div>
										</div>
										<div id="test-l-4" class="content">
											<div className="mt-5 col-md-8 offset-md-2" >
												<Form.Group as={Row} >
													<h3 FavouriteMusicBands={12}>
														Education
													</h3>

												</Form.Group>
												{this.getEducationDiv()}
												<Row className="mt-3 mb-3">
													<Col md="12" className="text-center" >
														{this.state.educationDetails && this.state.educationDetails.length > 0 && (
															<Button onClick={() => this.saveEducation()} className="btn-sm" disabled={this.state.isDisabled} >Save</Button>
														) || (
																this.addEducationDiv()
															)}
													</Col>
													<Col md='12' className="mt-3" >
														<Button onClick={() => this.addEducationDiv()} variant="light" className="btn-sm btn-block" >Add Education</Button>
													</Col>
												</Row>
												<Form.Group as={Row} >
													<h3 md={12}>Occupation</h3>
												</Form.Group>
												{this.getWorkDiv()}
												<Row className="mb-3 mt-3">
													<Col md="12" className="text-center" >
														{this.state.WorkDetails && this.state.WorkDetails.length > 0 && (
															<Button onClick={() => this.saveWork()} disabled={this.state.isDisabled} >Save</Button>
														) || (
																this.addWorkDiv()
															)}
													</Col>
													<Col md="12" className="mt-3" >
														<Button onClick={() => this.addWorkDiv()} variant="light" className="btn-sm btn-block" >Add Occupaton</Button>
													</Col>
												</Row>
											</div>
											<div className="text-center" >
												<Button type="button" variant="outline-secondary" onClick={() => this.stepper.to(3)}>Back</Button>
												<Button type="button" variant="outline-secondary" className="ml-2" onClick={() => this.stepper.to(5)}>Skip</Button>
												<Button type="button" variant="outline-primary" className="ml-2 mr-2"
													onClick={() => {
														this.saveTillThis('5');
													}}>Save & Exit</Button>
												<Button type="button" variant="outline-primary" onClick={() => this.stepper.next()}>Next</Button>
											</div>
										</div>
										<div id="test-l-5" class="content">
											<div className="mt-5 col-md-8 offset-md-2" >
												<Form.Group as={Row} >
													<Form.Label column sm={4}>
														How long have you been teaching English
													</Form.Label>
													<Col sm={7}>
														<Form.Control as="textarea" rows={3} id="TeachingEnglishExperience" placeholder="How long have you been teaching English" defaultValue={this.state.userDetail.TeachingEnglishExperience ? this.state.userDetail.TeachingEnglishExperience : ""} onChange={this.handleChange} />
													</Col>
												</Form.Group>
												<Form.Group as={Row} >
													<Form.Label column sm={4}>
														What advice would you give to new students
													</Form.Label>
													<Col sm={7}>
														<Form.Control as="textarea" rows={3} id="AdviceForStudents" placeholder="What advice would you give to new students" defaultValue={this.state.userDetail.AdviceForStudents ? this.state.userDetail.AdviceForStudents : ""} onChange={this.handleChange} />
													</Col>
												</Form.Group>
												<Form.Group as={Row} >

													<Form.Label column sm={4}>
														Do you hold any of these certificates
													</Form.Label>
													<Col sm={7}>
														{this.state.englishFileList && this.state.englishFileList.map(cert => (
															<>
																<Form.Check name="TeachingEnglishCertificates" type="checkbox" label={cert} id={cert}
																	checked={this.state.userDetail.TeachingEnglishCertificates && this.state.userDetail.TeachingEnglishCertificates.filter(ele => ele.name == cert).length > 0 ? true : false}
																	onChange={this.handleChange}
																/>
																{this.state.userDetail.TeachingEnglishCertificates && this.state.userDetail.TeachingEnglishCertificates.filter(ele => ele.name == cert).length > 0 ? (
																	<>
																		<Form.Control className="mt-2 mb-2" type="file" id={"file_" + cert}
																			onChange={(e) => {
																				this.uploadIndividualFile(e, cert, 2);
																			}} />
																		{this.state.userDetail.TeachingEnglishCertificates.filter(ele => ele.name == cert)[0].file ? (
																			<p><a href={this.state.userDetail.TeachingEnglishCertificates.filter(ele => ele.name == cert)[0].file} target="_blank">Certificate</a></p>
																		) : null}
																		<p className="error text-left">{formErr && formErr.TeachingEnglishCertificates && formErr.TeachingEnglishCertificates.filter(ele => ele.name == cert).length > 0 ? formErr.TeachingEnglishCertificates.filter(ele => ele.name == cert)[0].fileErr : null}</p>
																	</>
																) : null}


															</>
														))}
														{this.state.userDetail.TeachingEnglishCertificates && this.state.userDetail.TeachingEnglishCertificates.filter(ele => ele.name == 'Other').length > 0 ? (
															<>
																<Form.Control rows={3}
																	defaultValue={this.state.userDetail.TeachingEnglishCertificates && this.state.userDetail.TeachingEnglishCertificates.filter(ele => ele.name == 'Other').length > 0 ? this.state.userDetail.TeachingEnglishCertificates.filter(ele => ele.name == 'Other')[0].other_name : ""} placeholder="Other Name"
																	onChange={(e) => {
																		var lernArr = [...this.state.userDetail.TeachingEnglishCertificates];
																		var ind = lernArr && lernArr.findIndex(ele => ele.name == 'Other');
																		if (ind > -1) {
																			lernArr[ind].other_name = e && e.target && e.target.value || ""
																			this.setState({
																				...this.state,
																				userDetail: {
																					...this.state.userDetail,
																					TeachingEnglishCertificates: lernArr
																				}
																			}, () => {

																			})
																		}
																	}} />
																<p className="error text-left">{formErr && formErr.TeachingEnglishCertificates && formErr.TeachingEnglishCertificates.filter(ele => ele.name == "Other").length > 0 ? formErr.TeachingEnglishCertificates.filter(ele => ele.name == "Other")[0].otherErr : null}</p>
															</>
														) : null}
													</Col>
												</Form.Group>
												{/* <Form.Group as={Row} >
													<Form.Label column sm={4}>
														Upload Certificate
													</Form.Label>
													<Col sm={7}>
														<Form.Control type="file" multiple id="TeachingCertificate" onChange={(e) => {
															this.setState({
																...this.state,
																teachingFiles: e && e.target && e.target.files || null
															})
														}} />
														{this.state.userDetail && this.state.userDetail.TeachingEnglishCertificateFiles && this.state.userDetail.TeachingEnglishCertificateFiles.map((ele, index) => (
															<>
																<a href={ele} target="_blank">File {index + 1}</a>
																<br />
															</>
														))}
													</Col>
												</Form.Group> */}
											</div>

											<div className="text-center" >
												<button type="button" class="btn btn-secondary btn-sm" onClick={() => this.stepper.to(5)}>Back</button>
												<button type="button" class="btn btn-secondary btn-sm ml-2" onClick={() => this.stepper.to(7)}>Skip</button>
												<button type="button" class="btn btn-primary btn-sm ml-2 mr-2"
													onClick={() => {
														this.saveTillThis('7');
													}}>Save & Exit</button>
												<button type="button" class="btn btn-primary btn-sm"
													onClick={() => {
														const _this = this;
														this.validateStep('6', function (res) {
															if (res) {
																console.log('validate')
																_this.nextStep()
															}
														})
													}}
												>Next</button>
											</div>
										</div>
										<div id="test-l-6" class="content">
											<div className="mt-5 col-md-8 offset-md-2" >
												{/*<Form.Group as={Row} >
													<Form.Label column sm={4}>
														Interests
													</Form.Label>
													<Col sm={7}>
														<Form.Control as="textarea" rows={3} id="InterestedIn" placeholder="Interests" defaultValue={this.state.userDetail ? this.state.userDetail.InterestedIn : this.state.userDetail.InterestedIn} onChange={this.handleChange} />
													</Col>
												</Form.Group>*/}
												<Form.Group as={Row} >
													<Form.Label column sm={4}>
														Favourite music/bands
													</Form.Label>
													<Col sm={7}>
														<Form.Control as="textarea" rows={3} id="FavouriteMusicBands" placeholder="Favourite music/bands" defaultValue={this.state.userDetail ? this.state.userDetail.FavouriteMusicBands : this.state.userDetail.FavouriteMusicBands} onChange={this.handleChange} />
													</Col>
												</Form.Group>
												<Form.Group as={Row} >
													<Form.Label column sm={4}>
														Favourite films
													</Form.Label>
													<Col sm={7}>
														<Form.Control as="textarea" rows={3} id="FavouriteFilms" placeholder="Favourite films" defaultValue={this.state.userDetail ? this.state.userDetail.FavouriteFilms : this.state.userDetail.FavouriteFilms} onChange={this.handleChange} />
													</Col>
												</Form.Group>
												<Form.Group as={Row} >
													<Form.Label column sm={4}>
														Favourite television shows
													</Form.Label>
													<Col sm={7}>
														<Form.Control as="textarea" rows={3} id="FavouriteTvShows" placeholder="Favourite television shows" defaultValue={this.state.userDetail ? this.state.userDetail.FavouriteTvShows : this.state.userDetail.FavouriteTvShows} onChange={this.handleChange} />
													</Col>
												</Form.Group>
												<Form.Group as={Row} >
													<Form.Label column sm={4}>
														Favourite books or magazines
													</Form.Label>
													<Col sm={7}>
														<Form.Control as="textarea" rows={3} id="FavouriteBooks" placeholder="Favourite books or magazines" defaultValue={this.state.userDetail ? this.state.userDetail.FavouriteBooks : this.state.userDetail.FavouriteBooks} onChange={this.handleChange} />
													</Col>
												</Form.Group>
												<Form.Group as={Row} >
													<Form.Label column sm={4}>
														Favourite Activities
													</Form.Label>
													<Col sm={7}>
														<Form.Control as="textarea" rows={3} id="FavouriteActivities" placeholder="Favourite Activities" defaultValue={this.state.userDetail ? this.state.userDetail.FavouriteActivities : this.state.userDetail.FavouriteActivities} onChange={this.handleChange} />
													</Col>
												</Form.Group>
												<Form.Group as={Row} >
													<Form.Label column sm={4}>
														Your ambitions
													</Form.Label>
													<Col sm={7}>
														<Form.Control as="textarea" rows={3} id="Ambitions" placeholder="Your ambitions" defaultValue={this.state.userDetail ? this.state.userDetail.Ambitions : ""} onChange={this.handleChange} />
													</Col>
												</Form.Group>
												<Form.Group as={Row} >
													<Form.Label column sm={4}>
														Anywhere you'd like to visit
													</Form.Label>
													<Col sm={7}>
														<Form.Control as="textarea" rows={3} id="WishToVisit" placeholder="Anywhere you'd like to visit" defaultValue={this.state.userDetail ? this.state.userDetail.WishToVisit : ""} onChange={this.handleChange} />
													</Col>
												</Form.Group>
												<Form.Group as={Row} >
													<Form.Label column sm={4}>
														What would be your perfect way to spend a day
													</Form.Label>
													<Col sm={7}>
														<Form.Control as="textarea" rows={3} id="PerfectWayToSpendDay" placeholder="What would be your perfect way to spend a day" defaultValue={this.state.userDetail ? this.state.userDetail.PerfectWayToSpendDay : ""} onChange={this.handleChange} />
													</Col>
												</Form.Group>
												<Form.Group as={Row} >
													<Form.Label column sm={4}>
														Any pets
													</Form.Label>
													<Col sm={7}>
														<Form.Control as="textarea" rows={3} id="Pets" placeholder="Any pets" defaultValue={this.state.userDetail ? this.state.userDetail.Pets : ""} onChange={this.handleChange} />
													</Col>
												</Form.Group>
												{/*<Form.Group as={Row} >
													<Form.Label column sm={4}>
														What you're looking for
													</Form.Label>
													<Col sm={7}>
														<Form.Control as="textarea" rows={3} id="LookingFor" placeholder="What you're looking for" defaultValue={this.state.userDetail ? this.state.userDetail.LookingFor : ""} onChange={this.handleChange} />
													</Col>
												</Form.Group>*/}
											</div>
											<div className="text-center" >
												<Button type="button" variant="outline-secondary" className="mr-2" onClick={() => this.stepper.to(6)}>Back</Button>
												<Button type="submit" variant="outline-primary" >Submit</Button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		)
	}
}

// export default EditProfile;
const mapStateToProps = ({ auth, common }) => {
	let { languages } = common;
	return { auth, languages };
}
export default connect(mapStateToProps, {
	languageList
})(EditProfile)