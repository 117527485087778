import React, { useEffect, useRef } from 'react';
import TagManager from 'react-gtm-module';
import { useSelector } from 'react-redux';
import Router from 'react-router'
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

//import 'font-awesome/css/font-awesome.min.css';
import MainApp from './MainApp';

// import 'bootstrap/dist/css/bootstrap.min.css';
// import './../assets/style.css';
import './../assets/App.scss';
import 'react-toastify/dist/ReactToastify.css';

function App(props) {
  var auth = useSelector(({ auth }) => auth);
  var userId = auth && auth.user && auth.user._id || "";
  var utcJoined = auth && auth.user && auth.user.CreateDate || "";
  var isRegistered = auth && auth.user && auth.user._id || false;
  isRegistered = isRegistered ? true : false;
  var isAdmin = false;
  var isEmailVerified = auth && auth.user && auth.user.EmailVerified || false;
  isEmailVerified = isEmailVerified ? true : false;
  var isModerator = auth && auth.user && auth.user.IsModerator || false;
  isModerator = isModerator ? true : false;
  var postCount = auth && auth.user && auth.user.NumberOfQuestionsAsked || 0;
  const tagManagerArgs = {
    gtmId: 'GTM-K2GPDRN',
    dataLayer: {
        userid: userId.toString(),
        utcJoined: utcJoined,
        isAdmin: isAdmin,
        isEmailVerified: isEmailVerified,
        isModerator: isModerator,
        isRegistered: isRegistered,
        postCount: postCount
    }
  }
  let { match } = props;

  TagManager.initialize(tagManagerArgs);


  console.log("Loading main app component");
  
  return (
    <>
      <BrowserRouter>
        <Switch>
          {/* <Route path="/register" component={SignUp} />
      <Route path="/dashboard" component={Dashboard} isPrivate /> */}
          {/* redirect user to SignIn page if route does not exist and user is not authenticated */}
          <Route path={`${match.url}`} component={MainApp} {...props} />
        </Switch>
      </BrowserRouter>
      <ToastContainer hideProgressBar={true} autoClose={3000} />
    </>
  )
}

export default App;
