import React, { Component } from 'react';
import { Button, Col, Container, FormControl, Image, InputGroup, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import { Images } from '../config';
import { httpPost } from '../Services/HttpServices';

class MediaKit extends Component {
    constructor(props) {
        super(props);
    }
  
    render() {
        return (
            <div className="login-page" >
                <Container fluid>
                    <Row>
                        <Col md="12" className="p-3 text-center" >
                            <h1>Media Kit</h1>
                        </Col>
                        <Col md="12" className="p-2 text-center" >
                            <h3>Coming Soon</h3>
                        </Col>
                    </Row>
                </Container>

            </div>
        )
    }
}

export default MediaKit;