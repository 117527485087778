import React, { Component, useState, useEffect } from 'react';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';

import { BaseColor, Images } from "../config";
import Form from 'react-bootstrap/Form';
import { httpPost } from '../Services/HttpServices';
import { Link, Redirect } from "react-router-dom";
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import Tag from '../Component/TagSingle';
import { Typeahead } from 'react-bootstrap-typeahead';
import { toast } from 'react-toastify';
import { showLoader, hideLoader } from '../AppRedux/Actions/Auth';
// import Editor from '../Component/RichTextEditor';
import Editor from '../Component/QuillEditor';
import { Multiselect } from 'multiselect-react-dropdown';
import Helmet from 'react-helmet';

function CreateQuestion(props) {
    var auth = useSelector(({ auth }) => auth);
    const [topics, setTopics] = useState([]);
    const defaultData = {
        QuestionID: null,
        Subject: "",
        Tags: [],
        TopicID: [],
        Content: "",
    }
    const dispatch = useDispatch();
    const [data, setData] = useState(defaultData);
    const defaultErr = {
        Subject: null,
    };
    const [dataErr, setDataErr] = useState(defaultErr);
    const [tags, setTags] = useState(null);
    useEffect(() => {
        if (props && props.location) {
            var search = new URLSearchParams(props.location.search);
            let id = search.get('id')
            if (id) {
                getQuestion(id);
            }
        }
        getTopics();
    }, [])
    const getQuestion = (id) => {
        httpPost('question_controller/retrive', {
            QuestionID: id
        }).then(res => {
            if (res && res.status && res.data) {
                if (res.data.UserID && auth && auth.user && auth.user._id == res.data.UserID._id) {
                    // console.log(res.data);return;
                    setData({
                        ...data,
                        QuestionID: res.data._id,
                        Subject: res.data.Subject,
                        Content: res.data.Content ? res.data.Content : "",
                        Tags: res.data.Tags ? res.data.Tags.split(',') : [],
                        TopicID: res.data.TopicID ? res.data.TopicID : [],
                        selectedTopics: res.data.TopicID ? res.data.TopicID : []

                    })
                } else {
                    props.history.goBack();
                }
            } else {
                props.history.goBack();
            }
        })
    }
    const getTopics = () => {
        httpPost('topic_controller/list', {IsParent: 1, Status: 1})
            .then(res => {
                setTopics(res && res.data || []);
            })
    }
    const submitQuestion = () => {
        if (validateForm()) {
            var sendData = { ...data };
            sendData.Tags = sendData.Tags.length > 0 ? sendData.Tags.toString() : null;
            sendData.TopicID = sendData.TopicID || [];
            sendData.TopicID = sendData.TopicID.toString();
            dispatch(showLoader());
            var url;
            if (data.QuestionID) {
                url = 'question_controller/update';
            } else {
                url = 'question_controller/create';
            }
            httpPost(url, sendData)
                .then(res => {
                    dispatch(hideLoader());
                    if (res) {
                        if (res.status) {
                            // console.log(res.data, "res.data");
                            toast.success(res.message);
                            setData(defaultData);
                            setDataErr(defaultErr)
                            if (res.data && res.data.QuestionUrl) {
                                props.history.push("/question/" + res.data.QuestionUrl)
                            }
                        } else {
                            toast.error(res.message)
                        }
                    } else {
                        toast.error('Network Error');
                    }
                }).catch(err => {
                    dispatch(hideLoader());
                    console.log('err', err);
                })
        }
    }
    const validateForm = () => {
        setError();
        return data.Subject;
    }
    const setError = () => {
        setDataErr({
            ...dataErr,
            Subject: data.Subject ? null : "Enter Subject",
        })
    }

    const onSelectTopics = (selectedList, selectedItem) => {
        let selectedTopics = [];
        let selectedTopicIDs = [];
        selectedList.map(e => {
            selectedTopics.push(e);
            selectedTopicIDs.push(e._id);
        })
        setData({ ...data, selectedTopics: selectedTopics })
        setData({ ...data, TopicID: selectedTopicIDs })
    }
    const [searchQn, setSearchQn] = useState([]);
    const searchQuestion = (e) => {
        setData({ ...data, Subject: e && e || null })
        httpPost('question_controller/list', {
            // UserID: auth && auth.user && auth.user._id,
            filter: {
                "Subject": e && e || ""
            },
        }).then(res => {
            const question = res.data || [];
            question.map(q => {
                q.Subject = q.Subject;
                // q.Subject = q.Subject+' https://lt.englishforward.com/question/'+q.QuestionUrl;
            })
            setSearchQn(res && res.data || []);
        })
    }
    return (
        <div className={"leader-board " + (props.inline ? "" : " p-4 p-md-5 p-lg-5 p-sm-4")} >
            <Helmet>
                <title>{"Ask Question"}</title>
            </Helmet>
            {auth.user === null ? (<Redirect to="/signin" />): null }
            {auth && auth.user ? (
                // <Container fluid>
                <Row>
                    <Col md={{ offset: props.inline ? 0 : 2, span: props.inline ? 12 : 8 }}>
                        <Col className="question-list p-4 mb-3" md="12" >
                            <div className="row">
                                {/* <Image className="close-icon" src={Images.close}
                                        onClick={() => {
                                            console.log('close click')
                                            props.history.goBack()
                                        }}
                                    /> */}
                                <Col className="text-center" xs={{ offset: 1, span: 11 }} >
                                    {/* <p className="recomanded-text">Answer: Recommend for you</p> */}
                                    <h2 className="text-center mb-4" >Ask a question</h2>
                                </Col>
                                <Col md="1" xs="2" className="pl-0 pl-md-2 pl-lg-2">
                                    <Image className="profile-pic" src={auth.user && auth.user.ProfilePicture ? auth.user.ProfilePicture : Images.defaultProfile} width={40} height={40} roundedCircle />
                                </Col>
                                <Col md="11" xs="10">
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <span className="user-name capitalize">{auth.user ? (auth.user.FirstName + " " + auth.user.LastName) : null}
                                        </span>
                                        {/*<small className="post-date">{"Ask question"}</small>*/}
                                    </div>
                                </Col>
                                <Col xs={{ offset: 1, span: 11 }} >
                                    <Row>
                                        <Col md={12} className="pb-2">
                                            {!props.inline && (<input className="form-control" id="Subject" value={data.Subject} onChange={(e) => { setData({ ...data, Subject: e && e.target && e.target.value || null }) }} type="text" placeholder="What is your question? Write here..." />)}
                                            {props.inline && (<Typeahead
                                                id="Subject"
                                                onInputChange={(e) => { searchQuestion(e) }}
                                                options={searchQn && searchQn || []}
                                                placeholder="What is your question? Write here..."
                                                labelKey={"Subject"}
                                                // selected={requestQnId && requestQnId || []}
                                            />)}
                                        </Col>
                                        {dataErr.Subject ? <p className="error " style={{ paddingLeft: 15 }}>{dataErr.Subject}</p> : null}
                                        <Col md={12} className="pb-2">
                                            <Editor
                                                editorState={data.Content}
                                                onUpdate={editorState => setData({ ...data, Content: editorState })}
                                                placeholder="Enter Description"
                                            />
                                        </Col>
                                        {/* <Col md={12} className="pb-2">
                                                <Form.Control value={data.Content} onChange={(e) => { setData({ ...data, Content: e && e.target && e.target.value || null }) }} as="textarea" placeholder="Content..." rows={3} />
                                            </Col> */}
                                        <Col md={12} className="pb-2">
                                            {/*Removed Tagging Option - As Discussed (Surya-Umang)<Row>
                                                    <Col md={12} className={"pb-2"} style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                        {data.Tags && data.Tags.length > 0 && data.Tags.map(ele => (
                                                            <div style={{ padding: 5 }}>
                                                                <Tag tag={ele} onTagClick={(id) => {
                                                                    var array = [...data.Tags];
                                                                    var index = array.indexOf(ele);
                                                                    if (index !== -1) {
                                                                        array.splice(index, 1);
                                                                    }
                                                                    setData({ ...data, Tags: array });
                                                                }} />
                                                            </div>
                                                        ))}
                                                    </Col>
                                                    {!props.inline && (
                                                        <>
                                                            <Col md={6}>
                                                                <Form.Control value={tags} onChange={e => setTags(e && e.target && e.target.value || null)} onKeyDown={(e) => {
                                                                    if (e.key === 'Enter' && (e && e.target && e.target.value)) {
                                                                        console.log('e', e && e.target && e.target.value);
                                                                        setData({ ...data, Tags: [...data.Tags, e.target.value] });
                                                                        setTags("");
                                                                    }
                                                                }} type="text" placeholder="Tags..." />
                                                            </Col>
                                                            <Col md={6}>
                                                                <Form.Control value={data.TopicID} onChange={(e) => { setData({ ...data, TopicID: e && e.target && e.target.value || null }) }} as="select" placeholder="Topic">
                                                                    <option value={""}>{"Topic"}</option>
                                                                    {topics && topics.length > 0 ? topics.map(ele => (
                                                                        <option value={ele._id}>{ele.Title}</option>
                                                                    )) : null}
                                                                </Form.Control>
                                                            </Col>
                                                        </>
                                                    )}
                                                </Row>*/}
                                            <Row>
                                                {/*{!props.inline && ( */}
                                                    <>
                                                        <Col md={12}>
                                                            <Multiselect
                                                                options={topics}
                                                                selectedValues={data.selectedTopics}
                                                                onSelect={onSelectTopics}
                                                                displayValue="Title"
                                                                placeholder="Select Topics"
                                                            />
                                                            {/*<Form.Control value={data.TopicID} onChange={(e) => { setData({ ...data, TopicID: e && e.target && e.target.value || null }) }} as="select" placeholder="Topic" multiple >
                                                                    <option value={""}>{"Topic"}</option>
                                                                    {topics && topics.length > 0 ? topics.map(ele => (
                                                                        <option value={ele._id}>{ele.Title}</option>
                                                                    )) : null}
                                                                </Form.Control>*/}
                                                        </Col>
                                                    </>
                                                {/* )} */}
                                            </Row>
                                        </Col>
                                        <Col md={12} className="pb-2">
                                            <Row>
                                                <Col md="12" sm="12" xs="12">
                                                    <Button onClick={() => { submitQuestion(); }} className="btn-sm">{data.QuestionID ? "Update" : "Add Question"}</Button>
                                                        &nbsp;&nbsp;&nbsp;
                                                        {!props.inline && (
                                                        <Button onClick={() => { props.history.push('/feed') }} className="btn-sm btn-secondary" >Cancel</Button>
                                                        )}
                                                        {props.inline && (
                                                        <Button onClick={() => { props.showBox(false) }} className="btn-sm btn-secondary" >Cancel</Button>
                                                        )}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </div >
                        </Col>
                    </Col>
                </Row>
                // </Container>
            ) : null}
        </div>
    )
}

export default CreateQuestion;