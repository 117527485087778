import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';

import { Images } from '../config';
import FollowCard from '../Component/FollowCard';
import { httpPost } from '../Services/HttpServices';

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    }, [value]); // Only re-run if value changes
    return ref.current;
}

function FollowList(props) {
    var auth = useSelector(({ auth }) => auth);
    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState(null);
    const [followList, setFollowList] = useState({
        page: 1,
        totalCount: 0,
        list: []
    })
    const prevList = usePrevious(followList);

    useEffect(() => {
        var search = new URLSearchParams(props.location.search);
        const id = search.get('id');
        if (id) {
            setUserId(id)
        } else {
            setUserId(auth && auth.user && auth.user._id);
        }

    }, [])
    useEffect(() => {
        if (prevList && (prevList.totalCount > followList.totalCount)) {
            //Get List if deleted
            getList();
        }
    }, [followList.totalCount])
    useEffect(() => {
        if (userId) {
            setLoading(true);
            getList();
        }
    }, [followList.page, userId])

    const getList = () => {
        httpPost('follow_user_controller/list', { FollowerID: userId, page: followList.page })//
            .then(res => {
                setLoading(false);
                if (res && res.status) {
                    var listAppend = [];
                    var oldArr = [...followList.list];
                    var followData = res.data;
                    followData.map(ele => {
                        var ind = oldArr.findIndex(oldEle => oldEle._id == ele._id);
                        if (ind == -1) {
                            listAppend.push(ele);
                        }
                    })
                    var newArr = oldArr.concat(listAppend);
                    setFollowList({ ...followList, totalCount: res.totalCount, list: newArr })
                }
            })
    }
    return (
        <Row className="no-gutters">
        {auth.user === null ? (<Redirect to="/signin" />): null }
            <Col md="3"></Col>
            <Col md="6">
                <FollowCard
                    followlist={followList.list}
                    totalFollow={followList.totalCount}
                    unFollow={(id) => {
                        console.log('id', id);
                        var arr = [...followList.list];
                        var newArr = arr.filter(ele => ele._id != id);
                        setFollowList({ ...followList, totalCount: followList.totalCount - 1, list: newArr });
                    }}
                    mute={(id) => {
                        var arr = [...followList.list];
                        var ind = arr.findIndex(ele => ele._id == id);
                        console.log('mute', id, ind)
                        if (ind > -1) {
                            arr[ind].IsMute = 1;
                            setFollowList({ ...followList, list: arr });
                        }
                    }}
                    unmute={(id) => {
                        var arr = [...followList.list];
                        var ind = arr.findIndex(ele => ele._id == id);
                        console.log('unmute', id, ind)
                        if (ind > -1) {
                            arr[ind].IsMute = 0;
                            setFollowList({ ...followList, list: arr });
                        }
                    }}
                />
                <div className="mx-3 my-3">
                    {loading ?
                        <Spinner animation="border" variant="primary" />
                        : followList.totalCount && followList.totalCount > followList.list.length ? //
                            <Button size="sm" onClick={() => { setFollowList({ ...followList, page: followList.page + 1 }) }}>see more</Button>
                            : null}
                </div>
            </Col>
            <Col md="3"></Col>
        </Row>
    )
}
export default FollowList;