import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { BaseColor, Images } from "../config";

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';

import PropTypes from "prop-types";
import moment from 'moment';
import 'moment-timezone';
import { httpPost } from '../Services/HttpServices';
import { toast } from 'react-toastify';
function Experts(props) {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let { question, expert } = props;
    var auth = useSelector(({ auth }) => auth);
    let profilePicture = auth.ProfilePicture ? auth.ProfilePicture : Images.defaultProfile;
    
    const [loading, setLoading] = useState(false);

    const [requested, setRequested] = useState(false);

    useEffect(() => {
        if (question) {
            setRequested(expert.isRequested  ? true : false)
        }
    }, [props.question])


    const requestExpert = (e) => {
        setRequested(true);
        httpPost('answer_request_controller/request', {
            QuestionID: question._id,
            ToUserID: expert._id
        }).then(res => {
            setLoading(false);
            if (res && res.status) {
                toast.success(res.message);
            }
            if (res && !res.status) {
                toast.error(res.message);
            }
        }).catch(err => {
            setLoading(false)
        })
    }
            
    return (
        <>
            <Card body className="mb-2" >
                <Row>
                    <Col md="2" >
                        <Image src={profilePicture} roundedCircle className="img-thumbnail" />
                    </Col>
                    <Col md="7" >
                        {expert.FirstName + ' ' + expert.LastName} <br />
                        {expert.UserName}
                    </Col>
                    <Col md="3" >
                        {requested ? "Requested" : <Link onClick={() => {requestExpert()}} >Request</Link>}
                    </Col>
                </Row>
            </Card>
        </>
    )
}

Experts.defaultProps = {
    question: {}
}
export default Experts;