import { Row, Col, Image, Button, ListGroup } from 'react-bootstrap';
import Card from 'react-bootstrap/Card'
import { Images } from '../config';
import FollowCard from '../Component/FollowCard';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import { useEffect, useState } from 'react';
import { httpPost } from '../Services/HttpServices';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
// import Loader from "react-loader-spinner";
import Loader from 'react-loader';
function NotificationSetting() {
    var auth = useSelector(({ auth }) => auth);
    const [Notify_when_someone_follows_me, setNotify_when_someone_follows_me] = useState(false)
    const [Notify_when_someone_mentions_me, setNotify_when_someone_mentions_me] = useState(false)
    const [Notify_when_someone_sends_a_message, setNotify_when_someone_sends_a_message] = useState(false)
    const [loaded, setloaded] = useState(false)
    useEffect(() => {
        getNotificationSetting()
    }, [])
    function getNotificationSetting() {
        httpPost("user_controller/retrive", { UserID: auth.user._id })
            .then((res) => {
                console.log("res.data", res.data);
                setNotify_when_someone_follows_me(res.data.Setting.Notify_when_someone_follows_me == 1 ? true : false)
                setNotify_when_someone_mentions_me(res.data.Setting.Notify_when_someone_mentions_me == 1 ? true : false)
                setNotify_when_someone_sends_a_message(res.data.Setting.Notify_when_someone_sends_a_message == 1 ? true : false)
                setloaded(true)
            })
    }
    function handleFollowsYou(e) {
        setNotify_when_someone_follows_me(e)
        if (e) {
            var obj = { Notify_when_someone_follows_me: 1 }
        } else {
            var obj = { Notify_when_someone_follows_me: '0' }
        }
        httpPost("user_controller/update_notification_setting", obj)
            .then((res) => {
                console.log("res", res);
                getNotificationSetting()
                toast.success(res.message)
            })
    }
    function handleSomeoneMentionsMe(e) {
        setNotify_when_someone_mentions_me(e)
        if (e) {
            var obj = { Notify_when_someone_mentions_me: 1 }
        } else {
            var obj = { Notify_when_someone_mentions_me: '0' }
        }
        httpPost("user_controller/update_notification_setting", obj)
            .then((res) => {
                console.log("res", res);
                getNotificationSetting()
                toast.success(res.message)
            })
    }
    function handleSomeoneSendsAMessage(e) {
        setNotify_when_someone_sends_a_message(e)
        if (e) {
            var obj = { Notify_when_someone_sends_a_message: 1 }
        } else {
            var obj = { Notify_when_someone_sends_a_message: '0' }
        }
        httpPost("user_controller/update_notification_setting", obj)
            .then((res) => {
                console.log("res", res);
                getNotificationSetting()
                toast.success(res.message)
            })
    }
    var options = {
        lines: 10,
        length: 20,
        width: 10,
        radius: 20,
        scale: 0.50,
        color: '#F8A519',
        opacity: 0.05,
        speed: 1,
        trail: 100,
        fps: 100,
        zIndex: 1,
        top: '50%',
        left: '50%',
        shadow: false,
        hwaccel: true,
        position: 'absolute'
    };
    return (
        <Loader loaded={loaded} options={options} >
            <Row className="no-gutters">
                <Col md="3"></Col>
                <Col md="6" className="my-3">
                    <Card style={{ width: '100%' }}>

                        <Card.Header><strong>Notifications</strong></Card.Header>
                        <Row className="no-gutters">
                            <Col md="9" xs="7" sm="7">
                                <Card.Body className="card-body-list-element">Someone follows you</Card.Body>
                            </Col>
                            <Col md="3" xs="5" sm="5">
                                <Card.Body className="card-body-list-element card-body-list-left-element-alignment"><BootstrapSwitchButton onChange={handleFollowsYou} checked={Notify_when_someone_follows_me} onstyle="primary" size="xs" /></Card.Body>
                            </Col>
                        </Row>
                        <Row className="no-gutters">
                            <Col md="9" xs="7" sm="7">
                                <Card.Body className="card-body-list-element">Someone mentions you</Card.Body>
                            </Col>
                            <Col md="3" xs="5" sm="5">
                                <Card.Body className="card-body-list-element card-body-list-left-element-alignment"><BootstrapSwitchButton onChange={handleSomeoneMentionsMe} checked={Notify_when_someone_mentions_me} onstyle="primary" size="xs" /></Card.Body>
                            </Col>
                        </Row>
                        <Row className="no-gutters">
                            <Col md="9" xs="7" sm="7">
                                <Card.Body className="card-body-list-element">Someone sends you a message</Card.Body>
                            </Col>
                            <Col md="3" xs="5" sm="5">
                                <Card.Body className="card-body-list-element card-body-list-left-element-alignment"><BootstrapSwitchButton onChange={handleSomeoneSendsAMessage} checked={Notify_when_someone_sends_a_message} onstyle="primary" size="xs" /></Card.Body>
                            </Col>
                        </Row>
                        {/* <hr></hr> */}

                        {/* <Row className="no-gutters">
                        <Card.Body style={{ paddingTop: 0, paddingBottom: 0 }}><p style={{ fontWeight: 500, color: "#484848" }}>Team Notifications</p></Card.Body>
                    </Row>
                    <Row className="no-gutters">
                        <Col md="9" xs="7" sm="7">
                            <Card.Body className="card-body-list-element">Someone follows you</Card.Body>
                        </Col>
                        <Col md="3" xs="5" sm="5">
                            <Card.Body className="card-body-list-element card-body-list-left-element-alignment"><BootstrapSwitchButton checked={true} onstyle="primary" size="xs" /></Card.Body>
                        </Col>
                    </Row>
                    <Row className="no-gutters">
                        <Col md="9" xs="7" sm="7">
                            <Card.Body className="card-body-list-element">Someone mentions you</Card.Body>
                        </Col>
                        <Col md="3" xs="5" sm="5">
                            <Card.Body className="card-body-list-element card-body-list-left-element-alignment"><BootstrapSwitchButton checked={true} onstyle="primary" size="xs" /></Card.Body>
                        </Col>
                    </Row>
                    <Row className="no-gutters">
                        <Col md="9" xs="7" sm="7">
                            <Card.Body className="card-body-list-element">Someone sends you a message</Card.Body>
                        </Col>
                        <Col md="3" xs="5" sm="5">
                            <Card.Body className="card-body-list-element card-body-list-left-element-alignment"><BootstrapSwitchButton checked={true} onstyle="primary" size="xs" /></Card.Body>
                        </Col>
                    </Row>
                    <hr></hr>
                    <Row className="no-gutters">
                        <Card.Body style={{ paddingTop: 0, paddingBottom: 0 }}><p style={{ fontWeight: 500, color: "#484848" }}>Project Notifications</p></Card.Body>
                    </Row>
                    <Row className="no-gutters">
                        <Col md="9" xs="7" sm="7">
                            <Card.Body className="card-body-list-element">Someone follows you</Card.Body>
                        </Col>
                        <Col md="3" xs="5" sm="5">
                            <Card.Body className="card-body-list-element card-body-list-left-element-alignment"><BootstrapSwitchButton checked={true} onstyle="primary" size="xs" /></Card.Body>
                        </Col>
                    </Row>
                    <Row className="no-gutters">
                        <Col md="9" xs="7" sm="7">
                            <Card.Body className="card-body-list-element">Someone mentions you</Card.Body>
                        </Col>
                        <Col md="3" xs="5" sm="5">
                            <Card.Body className="card-body-list-element card-body-list-left-element-alignment"><BootstrapSwitchButton checked={true} onstyle="primary" size="xs" /></Card.Body>
                        </Col>
                    </Row>
                    <Row className="no-gutters">
                        <Col md="9" xs="7" sm="7">
                            <Card.Body className="card-body-list-element">Someone sends you a message</Card.Body>
                        </Col>
                        <Col md="3" xs="5" sm="5">
                            <Card.Body className="card-body-list-element card-body-list-left-element-alignment"><BootstrapSwitchButton checked={true} onstyle="primary" size="xs" /></Card.Body>
                        </Col>
                    </Row>
                    <hr></hr>
                    <Row className="no-gutters">
                        <Card.Body style={{ paddingTop: 0, paddingBottom: 0 }}><p style={{ fontWeight: 500, color: "#484848" }}>News &amp; Trending</p></Card.Body>
                    </Row>
                    <Row className="no-gutters">
                        <Col md="9" xs="7" sm="7">
                            <Card.Body className="card-body-list-element">Someone follows you</Card.Body>
                        </Col>
                        <Col md="3" xs="5" sm="5">
                            <Card.Body className="card-body-list-element card-body-list-left-element-alignment"><BootstrapSwitchButton checked={true} onstyle="primary" size="xs" /></Card.Body>
                        </Col>
                    </Row>
                    <Row className="no-gutters">
                        <Col md="9" xs="7" sm="7">
                            <Card.Body className="card-body-list-element">Someone mentions you</Card.Body>
                        </Col>
                        <Col md="3" xs="5" sm="5">
                            <Card.Body className="card-body-list-element card-body-list-left-element-alignment"><BootstrapSwitchButton checked={true} onstyle="primary" size="xs" /></Card.Body>
                        </Col>
                    </Row>
                    <Row className="no-gutters">
                        <Col md="9" xs="7" sm="7">
                            <Card.Body className="card-body-list-element">Someone sends you a message</Card.Body>
                        </Col>
                        <Col md="3" xs="5" sm="5">
                            <Card.Body className="card-body-list-element card-body-list-left-element-alignment"><BootstrapSwitchButton checked={true} onstyle="primary" size="xs" /></Card.Body>
                        </Col>
                    </Row>
                 */}
                    </Card>
                </Col>
                <Col md="3"></Col>
            </Row>
        </Loader>
    )
}
export default NotificationSetting;