import React, { Component } from 'react';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import FormControl from 'react-bootstrap/FormControl';
import Image from 'react-bootstrap/Image';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';

import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import { Images } from '../config';
import { httpPost } from '../Services/HttpServices';

class Help extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fullName: "",
            emailAddress: "",
            subject: "",
            body: "",
            formData: {
                "message": {
                    "kind": "ticket",
                    "subject": "",
                    "body": "",
                    "channel": "web"
                },
                "customer": {
                    "fullName": "",
                    "emailAddress": "",
                }
          }
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleName = (e) => {
        this.setState({
            fullName: e.target.value
        })
    }
    handleEmail = (e) => {
        this.setState({
            emailAddress: e.target.value
        })
    }
    handleSubject = (e) => {
        this.setState({
            subject: e.target.value
        })
    }

    handleBody = (e) => {
        this.setState({
            body: e.target.value
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        var { fullName, emailAddress, subject, body } = this.state;
        if (fullName && emailAddress && subject && body) {
            this.setState({ disable: false })
            var helpyFormData = this.state.formData;
            helpyFormData.customer.fullName = fullName;
            helpyFormData.customer.emailAddress = emailAddress;
            helpyFormData.message.subject = subject;
            helpyFormData.message.body = body;
            httpPost("home_page/helpy",  {data: helpyFormData},{})
                .then(res => {
                    if (res.id > 0) {
                        this.setState({
                            fullName: "",
                            emailAddress: "",
                            subject: "",
                            body: ""})
                        toast.success("Request submitted successfully")
                    } else {
                        toast.error(res.message);
                    }
            })
        }
    }
  
    render() {
        return (
            <div className="login-page help-page" >
                <Container fluid>
                    <Row>
                        <Col md="12" className="p-3 text-center" >
                            <h1>Help</h1>
                        </Col>
                        <Col md={{span: 4, offset: 4}} className="p-2 text-center" >
                            <h3>Are you looking for help? Shoot us a message below ...</h3>
                        </Col>
                        <Col md={{span: 4, offset: 4}} className="p-2 text-center" >
                            <Form onSubmit={this.handleSubmit}>
                                <div className="box" >
                                    <FormControl type="text" placeholder="Name" name="fullName" onChange={this.handleName} value={this.state.fullName} />
                                </div>
                                <div className="box" >
                                    <FormControl type="email" placeholder="Email" name="emailAddress" onChange={this.handleEmail} value={this.state.emailAddress} />
                                </div>
                                <div className="box" >
                                    <FormControl type="text" placeholder="Subject" name="subject" onChange={this.handleSubject} value={this.state.subject} />
                                </div>
                                <div className="box" >
                                    <FormControl type="text" placeholder="Message" name="body" onChange={this.handleBody} value={this.state.body} />
                                </div>
                                <div>
                                    <Button type="submit" className="btn-block" variant="outline-primary" disabled={!(this.state.fullName && this.state.emailAddress && this.state.subject && this.state.body)}> Submit </Button>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </Container>

            </div>
        )
    }
}

export default Help;