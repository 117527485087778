import { Link } from "react-router-dom";
import { BaseColor, Images } from "../config";

import Button from 'react-bootstrap/Button';

import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { httpPost } from '../Services/HttpServices';
import { toast } from 'react-toastify';

import * as Emoji from "quill-emoji";
import "quill-emoji/dist/quill-emoji.css";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { useMemo } from "react";
import katex from "katex";
import "katex/dist/katex.min.css";
window.katex = katex;

const Quill = ReactQuill.Quill;

Quill.register("modules/emoji", Emoji);

    var icons = ReactQuill.Quill.import("ui/icons");
    icons["undo"] = `<svg viewbox="0 0 18 18">
        <polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon>
        <path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path>
        </svg>`;
    icons["redo"] = `<svg viewbox="0 0 18 18">
        <polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon>
        <path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path>
        </svg>`;
    icons[{"script": "sub"}] = `<svg viewBox="0 0 18 18"> 
        <path class="ql-fill" d="M15.5,15H13.861a3.858,3.858,0,0,0,1.914-2.975,1.8,1.8,0,0,0-1.6-1.751A1.921,1.921,0,0,0,12.021,11.7a0.50013,0.50013,0,1,0,.957.291h0a0.914,0.914,0,0,1,1.053-.725,0.81,0.81,0,0,1,.744.762c0,1.076-1.16971,1.86982-1.93971,2.43082A1.45639,1.45639,0,0,0,12,15.5a0.5,0.5,0,0,0,.5.5h3A0.5,0.5,0,0,0,15.5,15Z"></path> 
        <path class="ql-fill" d="M9.65,5.241a1,1,0,0,0-1.409.108L6,7.964,3.759,5.349A1,1,0,0,0,2.192,6.59178Q2.21541,6.6213,2.241,6.649L4.684,9.5,2.241,12.35A1,1,0,0,0,3.71,13.70722q0.02557-.02768.049-0.05722L6,11.036,8.241,13.65a1,1,0,1,0,1.567-1.24277Q9.78459,12.3777,9.759,12.35L7.316,9.5,9.759,6.651A1,1,0,0,0,9.65,5.241Z"></path> 
        </svg>`;
    icons[{"script": "super"}] = `<svg viewBox="0 0 18 18"> 
        <path class="ql-fill" d="M15.5,7H13.861a4.015,4.015,0,0,0,1.914-2.975,1.8,1.8,0,0,0-1.6-1.751A1.922,1.922,0,0,0,12.021,3.7a0.5,0.5,0,1,0,.957.291,0.917,0.917,0,0,1,1.053-.725,0.81,0.81,0,0,1,.744.762c0,1.077-1.164,1.925-1.934,2.486A1.423,1.423,0,0,0,12,7.5a0.5,0.5,0,0,0,.5.5h3A0.5,0.5,0,0,0,15.5,7Z"></path> 
        <path class="ql-fill" d="M9.651,5.241a1,1,0,0,0-1.41.108L6,7.964,3.759,5.349a1,1,0,1,0-1.519,1.3L4.683,9.5,2.241,12.35a1,1,0,1,0,1.519,1.3L6,11.036,8.241,13.65a1,1,0,0,0,1.519-1.3L7.317,9.5,9.759,6.651A1,1,0,0,0,9.651,5.241Z"></path> 
        </svg>`;

    var quillObj;
    function QuillEditor(props) {
        const [text, setText] = useState("");
        const [wordCount, setWordCount] = useState(0);
        const [placeholder, setPlaceholder] = useState("");
    // var quillRef = null;
        useEffect(() => {
            if (props.editorState && props.editorState != text) {
                setText(props.editorState);
            }
        }, [props.editorState])

    useEffect(() => {
        if (props.editorState !== text) {
            props.onUpdate(text);
            let enteredText = text.trim()
            if(enteredText === "<p><br></p>"){
                enteredText = "";
            }
            setWordCount(enteredText ? enteredText.split(/\s+/).length : 0) 
        }
    }, [text])
    
    // const attachQuillRefs = () => {
    //     // Ensure React-Quill reference is available:
    //     if (typeof quillObj.getEditor !== 'function') return;
    //     // Skip if Quill reference is defined:
    //     if (quillRef != null) return;

    //      quillRef = quillObj.getEditor();
    //     if (quillRef != null) quillRef = quillRef;
    // }

    const handleChange = (value) => {
        setText(value);
    }

    const embedAudio = () => {
        const selectedArray = quillObj.getEditor().getSelection();
        const start = selectedArray ? selectedArray.index : 0;
        const end = selectedArray ? selectedArray.length : 0;
        // quillObj.getEditor().insertText(0, 'Hello', 'audio', 'https://world.com', 'user');
        // quillObj.getEditor().setText('Hello\nWorld!');
        let selectedText = "";
        if(end > 0){
            let position = start+ end;
            selectedText = quillObj.getEditor().getContents(start, end).ops[0].insert;
            httpPost('home_page/poly', { text: selectedText })
            .then(res => {
                if (res && res.status) {
                    quillObj.getEditor().insertText(position, '[AUDIO file="'+res.data+'" AUDIO]')
                }
            })
        }else{
            toast.error("Please select text to add pronountiation")
        }
        // console.log(selectedText, end,"end")
        // const message = window.speakText('Hi Das How are you');
        // console.log(message, "messagemessage")
        // var range = quillObj.getEditorSelection() || 0;
        // let position = range ? range.index : 0;
        // //this.quillRef.insertText(position, 'Hello, World! ')
        // // quillObj.getEditor().insertEmbed(position, 'audio', 'https://www.sample-videos.com/audio/mp3/crowd-cheering.mp3', 'user');

        // quillObj.getEditor().insertEmbed(position, "audio", "https://www.sample-videos.com/audio/mp3/crowd-cheering.mp3", "user");
    }
    const _uploadImageCallBack = (file) => {
        // console.log('upload callback', file);
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();
        input.onchange = async () => {
            // var file: any = input.files[0];
            var file = input.files[0];
            if (file) {
                var formdata = new FormData();
                formdata.append('file', file);
                httpPost('images/upload', formdata)
                .then(res => {
                    console.log('res');
                    if (res && res.status && res.fileUrl) {
                        const range = quillObj.getEditorSelection() || 0;
                        quillObj.getEditor().insertEmbed(range.index, 'image', res.fileUrl);
                    } else {
                    }
                }).catch(err => {
                    console.log('err', err);
                })
            }
        }
    }

    const _undo = () => {
        let myEditor = quillObj.getEditor();
        return myEditor.history.undo();
    }

    const _redo = () => {
        let myEditor = quillObj.getEditor();
        return myEditor.history.redo();
    }

    const _emoji = () => {
        let myEditor = quillObj.getEditor();
        // return myEditor.history.redo();
    }
    const modules = useMemo(() => ({
        toolbar: {
            container: [
            /*[{ 'header': [1, 2, false] }],*/
            [{ 'size': [] }],
            [{ 'color': ["#000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff","#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#6b24b2", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466"] }, { 'background': ["#000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff","#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#6b24b2", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466"] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            ['undo' , 'redo' ],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'formula', 'image', 'video'],
            [{ 'script': 'sub'}, { 'script': 'super' }], ['emoji'],
            ['clean']
            ],
            handlers: {
                image: _uploadImageCallBack,
                'undo' : _undo,
                'redo' : _redo,
                // 'emoji': _emoji,
                'emoji': function(e) {}
            }
        },
        keyboard: { bindings: { tab: false } },
        "emoji-toolbar": true,
        "emoji-textarea": true,
        "emoji-shortname": true
    }), []);
    const formats = [
    'header', 'size',
    'bold', 'italic', 'underline', 'strike', 'script', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'formula', 'image', 'video', 'color', 'background'
    ]

    return (
        <>
        <ReactQuill
        ref={(el) => {
            quillObj = el;
        }}
        className="question-form__rich-text"
        modules={modules}
        defaultText={text}
        value={text}
        onChange={handleChange}
        formats={formats}
        placeholder={placeholder}
        />
        <label>{wordCount} { wordCount > 1 ? "words" : "word" }</label>
        <Button className="mt-2 btn-xs float-right" onClick={embedAudio} >Add Pronunciation</Button>
        </>
        )
    }

    QuillEditor.prototype = {
        editorState: PropTypes.string,
        onUpdate: PropTypes.func,
    }

    QuillEditor.defaultProps = {
        editorState: "",
        onUpdate: () => { },
    }
    export default QuillEditor;