import { Switch, Route } from 'react-router-dom';
import { useRef, useEffect } from 'react';
// import { useSelector } from 'react-redux';
import Home from './Home';
import Feed from './Feed';
import Login from './Login';
import AboutUs from './AboutUs';
import Profile from './Profile';
import Signup from './Signup';
import QuestionDetails from './QuestionDetails';
import QuestionLog from './QuestionLog'
import Leaderboard from './Leaderboard';
import QuestionList from './QuestionList';
import FollowList from './FollowList';
import Bookmarks from './Bookmarks';
import BlockList from './BlockList';
import Followers from './Followers';
import AccountSetting from './AccountSetting';
import NotificationSetting from './NotificationSetting';
// import ContactUs from './ContactUs';
import NotificationList from './NotificationList';
import ResetPassword from './ResetPassword';
import ForgotPassword from './ForgotPassword';
import ChangePassword from './ChangePassword';
import CreateQuestion from './CreateQuestion';
import UserDraft from './UserDraft';
import EditProfile from './EditProfile';
import AnswerLog from './AnswerLog';
import Topic from './Topic';
import SelectTopic from './SelectTopics';
import PrivateArea from './PrivateArea';
import { LinkedInPopUp } from 'react-linkedin-login-oauth2';
import ConfirmAccount from './ConfirmAccount';
import VerifyEmail from './VerifyEmail';
import DiscussEnglish from './DiscussEnglish';

import Help from './Help';
import Careers from './Careers';
import Partners from './Partners';
import MediaKit from './MediaKit';
import Terms from './Terms';
// import FireChat from './FireChat';
import ReactChat from './ReactChat';


import { AuthProvider } from './../Component/RChat/context/AuthContext';

import NotFound from './NotFound';
import Privacy from './Privacy';
function usePrevious(value) {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef();
    // Store current value in ref
    useEffect(() => {
        ref.current = value;
    }, [value]); // Only re-run if value changes
    // Return previous value (happens before update in useEffect above)
    return ref.current;
}

const App = ({ match, location }) => {
    const prevLocation = usePrevious(location);
    // var auth = useSelector(({ auth }) => auth);
    // console.log(auth, auth.user, auth.user === null, "MainAuth");
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        if (prevLocation) {
            if (location.pathname !== prevLocation.pathname) {
                // console.log('**scroll**')
                window.scrollTo(0, 0);
            }
        }
    }, [location])

    return (
        <AuthProvider>
            <Switch>
                <Route exact path={`${match.url}`} component={Home} title="Home" />
                <Route path={`${match.url}home`} component={Home} />
                <Route path={`${match.url}feed`} component={Feed} />
                <Route path={`${match.url}signin`} component={Login} />
                <Route path={`${match.url}aboutus`} component={AboutUs} />
                <Route exact path={`${match.url}profile/:id`} component={Profile} />
                <Route path={`${match.url}profile`} component={Profile} />
                <Route path={`${match.url}signup`} component={Signup} />
                {/*<Route path={`${match.url}questiondetails`} component={QuestionDetails} />*/}
                <Route exact path={`${match.url}question/:id`} component={QuestionDetails} />
                <Route path={`${match.url}questionlog`} component={QuestionLog} />
                <Route path={`${match.url}answerlog`} component={AnswerLog} />
                <Route path={`${match.url}questionCreate`} component={CreateQuestion} />
                <Route path={`${match.url}leaderboard`} component={Leaderboard} />
                <Route path={`${match.url}questionlist`} component={QuestionList} />
                <Route path={`${match.url}followlist`} component={FollowList} />
                <Route path={`${match.url}blocklist`} component={BlockList} />
                <Route path={`${match.url}followers`} component={Followers} />
                <Route path={`${match.url}bookmarks`} component={Bookmarks} />
                <Route path={`${match.url}accountsetting`} component={AccountSetting} />
                <Route path={`${match.url}notificationsetting`} component={NotificationSetting} />
                {/*<Route path={`${match.url}contactus`} component={ContactUs} />*/}
                <Route path={`${match.url}notifications`} component={NotificationList} />
                <Route path={`${match.url}resetpassword`} component={ResetPassword} />
                <Route path={`${match.url}forgotpassword`} component={ForgotPassword} />
                <Route path={`${match.url}changepassword`} component={ChangePassword} />
                <Route path={`${match.url}drafts`} component={UserDraft} />
                <Route path={`${match.url}edit-profile`} component={EditProfile} />
                {/*<Route exact path={`${match.url}topics`} component={Topic} />*/}
                <Route exact path={`${match.url}topics/:id`} component={Topic} />
                <Route exact path="/linkedin" component={LinkedInPopUp} />
                <Route path={`${match.url}private-area`} component={PrivateArea} />
                <Route path={`${match.url}select-topic`} component={SelectTopic} />
                <Route path={`${match.url}confirm`} component={ConfirmAccount} />
                <Route path={`${match.url}VerifyEmail`} component={VerifyEmail} />

                <Route path={`${match.url}help`} component={Help} />
                <Route path={`${match.url}who-we-are`} component={AboutUs} />
                <Route path={`${match.url}careers`} component={Careers} />
                <Route path={`${match.url}partners`} component={Partners} />
                <Route path={`${match.url}media-kit`} component={MediaKit} />
                <Route path={`${match.url}terms`} component={Terms} />
                <Route path={`${match.url}privacy`} component={Privacy} />
                <Route exact path={`${match.url}chat/:id`} component={ReactChat} />
                <Route path={`${match.url}chat`} component={ReactChat} />
                <Route path={`${match.url}discuss-english`} component={DiscussEnglish} />

                <Route component={NotFound} />
                {/*<Route path="topic/:id" children={<Topic />} />*/}
                {/* <Route component={Feed} /> */}
            </Switch>
        </AuthProvider>
    )
}
export default App;