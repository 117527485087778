import { Link } from "react-router-dom";
import { BaseColor, Images } from "../config";

import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';

import moment from 'moment';
import 'moment-timezone';
import PropTypes from "prop-types";

function ActivityTabList(props) {
    let { activity } = props;
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let percentage = 0;
    let postedDate = ""
    if(activity.Type == "QUESTION"){
        postedDate = moment.tz(activity.CreatedDate, timeZone).fromNow()
        percentage = ((activity.QuestionID.UpvoteCount / (activity.QuestionID.UpvoteCount + activity.QuestionID.DownvoteCount))*100);
    }else if(activity.Type == "ANSWER"){
        postedDate = moment.tz(activity.AnswerID?.CreatedDate || null, timeZone).fromNow()
        percentage = ((activity.AnswerID.UpvoteCount / (activity.AnswerID.UpvoteCount + activity.AnswerID.DownvoteCount))*100);
    }else if(activity.Type == "COMMENT"){
        postedDate = moment.tz(activity.CommentID?.QuestionID?.CreatedDate || null, timeZone).fromNow()
        percentage = ((activity.CommentID.UpvoteCount / (activity.CommentID.UpvoteCount + activity.CommentID.DownvoteCount))*100);
    }
    percentage = (Math.round(isNaN(percentage) ? 0 : percentage)) + "%";
    const question = () => (
        <>
            {/*<Col xs={{ span: 12 }}>
                <p className="tab-content-head "> <Image className="mr-2" src={Images.forwardBlue} /> {activity.Message}</p>
            </Col>*/}
            <Col md="1" xs="2" className="pl-1 pl-md-2 pl-lg-2">
                <Image className="profile-pic" src={activity.UserID && activity.UserID.ProfilePicture ? activity.UserID.ProfilePicture : Images.defaultProfile} width={40} height={40} roundedCircle />
                <div className="mt-2" style={{ flex: '1', alignSelf: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <div className="upvote" >
                            {activity.QuestionID.IsVoted && activity.QuestionID.UpVote ? (<Image src={Images.upActive} height={15} width={25} />) : (<Image src={Images.up} height={15} width={25} />)}
                        </div>
                        <span style={{ fontSize: 12 }}>{percentage}</span>
                        <div className="downvote" >
                            {question.IsVoted && question.DownVote ? (<Image src={Images.downActive} height={15} width={25} />) : (<Image src={Images.down} height={15} width={25} />)}
                        </div>
                    </div>
                </div>
            </Col>
            <Col md="11" xs="10" className="p-0 mt-1" >
                <div>
                    <span className="user-name">
                        <Link to={"/profile/" + activity.UserID.UserName} >
                            {activity.UserID && (activity.UserID.FirstName + " " + activity.UserID.LastName) || ""}
                        </Link>
                    </span>
                    {/*<small className="post-date float-right">{"posted " + moment.tz(activity.CreatedDate, timeZone).fromNow()}</small>*/}
                </div>
                {activity.QuestionID && (
                    <Link to={"/question/" + activity.QuestionID.QuestionUrl} style={{ textDecoration: "none" }}>
                        {/* target="_blank" */}
                        <h5 className="question mt-2" dangerouslySetInnerHTML={{ __html: activity.QuestionID?.Subject}} ></h5>
                    </Link>
                )}
                {activity.images && activity.images.length > 0 ? (
                    <Row className="mb-3">
                        {activity.images.map(ele => (
                            <Col md="4" xs="6">
                                <Image src={ele} width="100%" />
                            </Col>
                        ))}
                    </Row>
                ) : null}
            </Col>
        </>
    );
    const answer = () => (
        <>
            {/*{activity.Message ? (
                <Col xs={{ span: 12 }}>
                    <p className="tab-content-head "> <Image className="mr-2" src={Images.forwardBlue} /> {activity.Message}</p>
                </Col>
            ) : null}*/}
            <Col md="12" xs="12">
                <Row>
                    <Col md="1" xs="2" className="pl-1 pl-md-3 pl-lg-3" style={{ display: 'flex', flexDirection: 'column' }}>
                        <Image style={{}} className="profile-pic" src={activity.AnswerID?.UserID?.ProfilePicture || Images.defaultProfile} width={40} height={40} roundedCircle />
                        <div style={{ textAlign: 'center' }}>
                            <div className="upvote" >
                                <Image src={Images.up} height={15} width={25} />
                            </div>
                            <span style={{ fontSize: 12 }}>{percentage}</span>
                            <div className="downvote" >
                                <Image src={Images.down} height={15} width={25} />
                            </div>
                        </div>
                    </Col>
                    <Col md="11" xs="10" className="p-0 mt-1" >
                        <div>
                            <span className="user-name">
                                <Link to={"/profile/" + activity.UserID?.UserName || "" } >
                                    {activity.UserID?.FirstName || ""} {activity.UserID?.LastName || ""}
                                </Link>
                            </span>
                            {/*<small className="post-date float-right">{"posted " + moment.tz(activity.AnswerID?.CreatedDate || null, timeZone).fromNow()}</small>*/}
                        </div>
                        {activity.AnswerID?.QuestionID && (
                            <Link to={"/question/" + (activity.AnswerID?.QuestionID?.QuestionUrl)} style={{ textDecoration: "none" }}>
                                {/* target="_blank" */}
                                <h5 className="question mt-2" dangerouslySetInnerHTML={{ __html: activity.AnswerID?.QuestionID?.Subject}} ></h5>
                            </Link>
                        )}
                        {activity.images && activity.images.length > 0 ? (
                            <Row className="mb-3">
                                {activity.images.map(ele => (
                                    <Col md="4" xs="6">
                                        <Image src={ele} width="100%" />
                                    </Col>
                                ))}
                            </Row>
                        ) : null}
                        <Row className="mt-3">
                            <Col md="1" xs="2" className="mr-2" >
                                <Image className="profile-pic" src={activity.AnswerID?.UserID?.ProfilePicture || Images.defaultProfile} width={40} height={40} roundedCircle />
                            </Col>
                            <Col md="10" xs="10" className="pl-0 mt-2">
                                <div>
                                    <span className="user-name">
                                        <Link to={"/profile/" + activity.UserID.UserName} >
                                            {activity.UserID && (activity.AnswerID.UserID.FirstName + " " + activity.AnswerID.UserID.LastName) || ""}
                                        </Link>
                                    </span>
                                    <div className="reply-text" dangerouslySetInnerHTML={{__html: activity.AnswerID?.Answer}} ></div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </>
    )
    const comment = () => (
        <>
            <Col md="12" xs="12">
                <Row>
                    <Col md="1" xs="2" className="pl-1 pl-md-3 pl-lg-3" style={{ display: 'flex', flexDirection: 'column' }}>
                        <Image style={{}} className="profile-pic" src={activity.QuestionID?.UserID?.ProfilePicture || Images.defaultProfile} width={40} height={40} roundedCircle />
                        <div style={{ flex: '9', display: 'flex', textAlign: 'center' }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div className="upvote" >
                                    <Image src={Images.up} height={15} width={25} />
                                </div>
                                <span style={{ fontSize: 12 }}>{percentage}</span>
                                <div className="downvote" >
                                    <Image src={Images.down} height={15} width={25} />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md="11" xs="10" className="pl-1 mt-1">
                        <div>
                            <span className="user-name">{activity.CommentID?.QuestionID?.UserID?.FirstName || ""} {activity.CommentID?.QuestionID?.UserID?.LastName || ""}</span>
                            {/*<small className="post-date float-right">{"posted " + moment.tz(activity.CommentID?.QuestionID?.CreatedDate || null, timeZone).fromNow()}</small>*/}
                        </div>
                        {activity.CommentID?.QuestionID && (
                            <Link to={"/question/" + (activity.CommentID?.QuestionID?.QuestionUrl)} style={{ textDecoration: "none" }}>
                                {/* target="_blank" */}
                                {/*<h5 className="question mt-2" >{activity.CommentID?.QuestionID?.Subject}</h5>*/}
                                <h5 className="question mt-2" dangerouslySetInnerHTML={{ __html: activity.CommentID?.QuestionID?.Subject}} ></h5>
                            </Link>
                        )}
                        {activity.images && activity.images.length > 0 ? (
                            <Row className="mb-3">
                                {activity.images.map(ele => (
                                    <Col md="4" xs="6">
                                        <Image src={ele} width="100%" />
                                    </Col>
                                ))}
                            </Row>
                        ) : null}
                        <Row>
                            <Col md="1" xs="2" className="mr-2" >
                                <Image className="profile-pic" src={activity.UserID?.ProfilePicture || Images.defaultProfile} width={40} height={40} roundedCircle />
                            </Col>
                            <Col md="10" xs="10" className="pl-0 mt-2">
                                <div>
                                    <span className="user-name">{activity.UserID && (activity.UserID.FirstName + " " + activity.UserID.LastName) || ""}
                                    </span>
                                    <small className="post-date float-right">{"posted " + moment.tz(activity.CreatedDate, timeZone).fromNow()}</small>
                                    <p className="reply-text">{activity.CommentID?.CommentText}</p>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>

            </Col>
        </>
    )

    return (
        <>
            <Col className="" md="12" >
                <div className="row mt-3 p-2 p-md-3 p-lg-3 question-list-tab" >
                    <Col className="message-box" xs={{ span: 12 }}>
                        <p className="tab-content-head mb-2">
                            {activity.Message}
                            <small className="float-right" >{"posted " + postedDate}</small>
                        </p>
                    </Col>
                    {
                        activity.Type == "QUESTION" ? question()
                            : activity.Type == "COMMENT" ? comment()
                                : activity.Type == "ANSWER" ? answer()
                                    : null
                    }
                </div>
            </Col>
        </>
    )
}

ActivityTabList.prototype = {
    activity: PropTypes.object
}

ActivityTabList.defaultProps = {
    activity: {}
}
export default ActivityTabList;