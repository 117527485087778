import { Link } from "react-router-dom";
import { BaseColor, Images } from "../config";
import { Button, Col, Image, Row } from 'react-bootstrap';
import PropTypes from "prop-types";

function PhotoListCard(props) {
    return (
        <>
            <Row className="pt-5" className="photo-list">
                {props.photos.map((ele, index) => (
                    <Col md="4" xs="4" className='pr-md-0'>
                        <img src={ele} className="img" />
                    </Col>
                ))}
                <Col md="12" className="pt-3 pr-md-0">
                    <span className="see-more">See more Rose’s Photos &nbsp;<img src={Images.arrowRightBlack}/></span>
                </Col>
            </Row>
        </>
    )
}

PhotoListCard.prototype = {
    photos: PropTypes.array
}

PhotoListCard.defaultProps = {
    photos: []
}
export default PhotoListCard;

// margin-left: 27px;margin-top: -14px;