import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';

import { Images } from '../config';
import PropTypes from "prop-types";
import { BaseColor } from '../config/index';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment-timezone';
import SweetAlert from 'react-bootstrap-sweetalert';
import { httpPost } from '../Services/HttpServices';
import { toast } from 'react-toastify';

function FollowersCard(props) {
    const timeZone  = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const [block, setBlock] = useState(null);
    const [mute, setMute] = useState(null);
    const [unMute, setUnMute] = useState(null);
    const [unBlock, setUnBlock] = useState(null);
    const onConfirm = () => {
        httpPost('block_user_controller/create', {
            MainUser: block && block.UserID && block.UserID._id || null,
            BlockedUser: block && block.FollowerID && block.FollowerID._id || null

        }).then(res => {
            if (res) {
                if (res.status) {
                    toast.success(res.message);
                    props.blockUser(block._id);
                } else {
                    //props.blockUser(block._id);
                    toast.error(res.message);
                }
            }
            setBlock(null);
        }).catch(err => {
            console.log('err', err);
            setBlock(null);
            toast.error('Network Error')
        })
    }

    const onCancel = () => {
        setBlock(null);
    }
    //Unblock
    const onConfirmUnBlock = () => {
        httpPost('block_user_controller/delete', {
            MainUser: unBlock && unBlock.UserID && unBlock.UserID._id || null,
            BlockedUser: unBlock && unBlock.FollowerID && unBlock.FollowerID._id || null
        }).then(res => {
            if (res) {
                if (res.status) {
                    toast.success(res.message);
                    props.unblock(unBlock._id);
                } else {
                    toast.error(res.message);
                }
            }
            setUnBlock(null);
        }).catch(err => {
            console.log('err', err);
            setUnBlock(null);
            toast.error('Network Error')
        })
    }

    const onCancelUnBlock = () => {
        setUnBlock(null);
    }
    //Mute
    const onConfirmMute = () => {
        httpPost('mute_user_controller/create', {
            MainUser: mute && mute.UserID && mute.UserID._id || null,
            MutedUser: mute && mute.FollowerID && mute.FollowerID._id || null
        }).then(res => {
            if (res) {
                if (res.status) {
                    toast.success(res.message);
                    props.mute(mute._id);
                } else {
                    toast.error(res.message);
                }
            }
            setMute(null);
        }).catch(err => {
            console.log('err', err);
            setMute(null);
            toast.error('Network Error')
        })
    }

    const onCancelMute = () => {
        setMute(null);
    }

    //UnMute
    const onConfirmUnMute = () => {
        httpPost('mute_user_controller/delete', {
            MainUser: unMute && unMute.UserID && unMute.UserID._id || null,
            MutedUser: unMute && unMute.FollowerID && unMute.FollowerID._id || null
        }).then(res => {
            if (res) {
                if (res.status) {
                    toast.success(res.message);
                    props.unmute(unMute._id);
                } else {
                    toast.error(res.message);
                }
            }
            setUnMute(null);
        }).catch(err => {
            console.log('err', err);
            setUnMute(null);
            toast.error('Network Error')
        })
    }

    const onCancelUnMute = () => {
        setUnBlock(null);
    }

    return (
        <>
            <Card style={{ width: '100%' }} className="mx-3 my-3 px-3 " >
                <Card.Title className="ml-4 mt-3">People who follow you</Card.Title>
                <Card.Subtitle className="ml-4 text-muted">{props.totalFollowers} followers</Card.Subtitle>
                {props.followerslist.map((ele, index) => (
                    <Row className="cardStyleListingRow" style={{ borderBottom: "0.5px solid #DEE2E6" }}>
                        <Col md="1" xs="1" sm="1" className="cardStyleListingCol">
                            <Image src={ele.FollowerID && ele.FollowerID.ProfilePicture || Images.defaultProfile} className="image-follow-card" />
                        </Col>
                        <Col md="6" xs="9" sm="6" className="cardUserNameCol">
                            <p className="cardUserName"><Link to={"/profile/"+ele.UserID.UserName} >{ele.FollowerID ? ele.FollowerID.FirstName + ' ' + ele.FollowerID.LastName : null}</Link></p>
                            {/* <br></br> */}
                            <p className="cardUserDetails">
                                {moment.tz(ele.CreatedDate, timeZone).fromNow()}
                            </p>
                        </Col>
                        {/* <Col md="1"></Col> */}
                        <Col md="4" xs="12" sm="4">
                            <div className="text-white cardButtonsList" >
                                {/*<Button className="btn-sm mr-1 px-1" style={{ padding: 0, margin: 0, backgroundColor: BaseColor.primaryColor, fontSize: 14, color: BaseColor.whiteColor, borderColor: BaseColor.darkPrimaryColor }} >{ele.follow_detail}</Button>*/}
                                {ele.IsMute == 0 ? (
                                    <Button onClick={() => setMute(ele || null)} className="btn-sm mr-1 px-1" style={{ padding: 0, margin: 0, backgroundColor: BaseColor.kashmir, fontSize: 14, color: BaseColor.whiteColor, borderColor: BaseColor.kashmir }} >Mute</Button>
                                ) : (
                                    <Button onClick={() => setUnMute(ele || null)} className="btn-sm mr-1 px-1" style={{ padding: 0, margin: 0, backgroundColor: BaseColor.kashmir, fontSize: 14, color: BaseColor.whiteColor, borderColor: BaseColor.kashmir }} >UnMute</Button>
                                )}
                                {ele.IsBlock == 0 ? (
                                    <Button onClick={() => setBlock(ele || null)} className="btn-sm mr-1 px-1" style={{ padding: 0, margin: 0, backgroundColor: BaseColor.lightBlue, fontSize: 14, color: BaseColor.whiteColor, borderColor: BaseColor.lightBlue }} >Block</Button>
                                ) : (
                                    <Button onClick={() => setUnBlock(ele || null)} className="btn-sm mr-1 px-1" style={{ padding: 0, margin: 0, backgroundColor: BaseColor.lightBlue, fontSize: 14, color: BaseColor.whiteColor, borderColor: BaseColor.lightBlue }} >UnBlock</Button>
                                )}


                            </div>
                        </Col>
                    </Row>
                ))}
            </Card>
            <SweetAlert
                show={block ? true : false}
                warning
                showCancel
                confirmBtnText={"Yes"}
                cancelBtnText={"No"}
                title={<span style={{ fontSize: 25 }} >Do you want to block this User</span>}
                onConfirm={onConfirm}
                onCancel={onCancel}
            />
            <SweetAlert
                show={mute ? true : false}
                warning
                showCancel
                confirmBtnText={"Yes"}
                cancelBtnText={"No"}
                title={<span style={{ fontSize: 25 }} >Do you want to mute this User</span>}
                onConfirm={onConfirmMute}
                onCancel={onCancelMute}
            />
            <SweetAlert
                show={unMute ? true : false}
                warning
                showCancel
                confirmBtnText={"Yes"}
                cancelBtnText={"No"}
                title={<span style={{ fontSize: 25 }} >Do you want to un-mute this User</span>}
                onConfirm={onConfirmUnMute}
                onCancel={onCancelUnMute}
            />
            <SweetAlert
                show={unBlock ? true : false}
                warning
                showCancel
                confirmBtnText={"Yes"}
                cancelBtnText={"No"}
                title={<span style={{ fontSize: 25 }} >Do you want to un-block this User</span>}
                onConfirm={onConfirmUnBlock}
                onCancel={onCancelUnBlock}
            />
        </>
    )
}

FollowersCard.prototype = {
    followerslist: PropTypes.array,
    totalFollowers: PropTypes.string,
    blockUser: PropTypes.func,
    unblock: PropTypes.func,
    mute: PropTypes.func,
    unmute: PropTypes.func

}

FollowersCard.defaultProps = {
    followerslist: [],
    totalFollowers: '0',
    blockUser: () => { },
    unblock: () => { },
    mute: () => { },
    unmute: () => { },
}
export default FollowersCard;

// margin-left: 27px;margin-top: -14px;