import React, { Component } from 'react';
import { Button, Col, Container, FormControl, Image, InputGroup, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';

import { addUserSuccess, hideLoader, registerUserSuccess, resetSignUp, showLoader } from '../AppRedux/Actions/Auth';
import { Images } from '../config';
import { httpPost } from '../Services/HttpServices';

const defaultData = {
	FirstName: "",
	LastName: "",
	UserName: "",
	Password: "",
	Email: "",
	MobileNo: "",
	FacebookID: null,
	SocialToken: null,
	Type: null,
	GoogleID: null
}
const defaultErr = {
	FirstName: null,
	LastName: null,
	UserName: null,
	Password: null,
	Email: null,
}
class Signup extends Component {

	constructor(props) {
		super(props);
		this.state = {
			type: "password",
			data: defaultData,
			formErr: defaultErr,
			ref: new URLSearchParams(props.location.search).get('ref')
		}
		this.handleSubmit = this.handleSubmit.bind(this);
	}
	handlePassword = () => {
		this.setState({
			type: this.state.type === 'text' ? 'password' : 'text'
		});
	}
	handleSubmit = (e) => {
		e.preventDefault();
		console.log(e);
		if (this.validateForm()) {
			//this.props.registerUser(this.state.data);
			this.props.showLoader();
			httpPost('user_controller/signup_email', this.state.data)
				.then(res => {
					this.props.hideLoader();
					if (res) {
						if (res.status) {
							this.setState({
								...this.state,
								data: defaultData,

							}, () => {
								this.setState({
									formErr: defaultErr
								})
							})
							if (res && res.data) {
								res.data._id = res.data.UserID;
							} else {
								toast.success(res.message);
							}
							this.props.registerUserSuccess(res && res.data || null)
							let ref = this.state.ref ? "?ref="+this.state.ref : "";
							this.props.history.push('/signin'+ref);
						} else {
							toast.error(res.message);
						}
					}
					if (res && !res.status) {
					}
				})
				.catch(err => {
					this.props.hideLoader();
					console.log('errr', err)
				})
		}
	}
	validateForm = () => {
		let { data } = this.state;
		this.setError();
		return data.Password && data.FirstName && data.LastName && data.Email;
	}

	checkUserName = (username) => {
		let { data } = this.state;
		this.setState({
			...this.state,
			formErr: {
				...this.state.formErr,
				UserName: username ? null : "Enter Valid Username"
			}
		})
		this.setState({
			...this.state,
			data: {
				...this.state.data,
				UserName: username
			}
		})
		httpPost('user_controller/check_username', this.state.data)
				.then(res => {
					if (res) {
						if (res.status) {
							this.setState({
								...this.state,
								formErr: {
									...this.state.formErr,
									UserName: res.message
								}
							})
						}
					}
				})
				.catch(err => {
					this.props.hideLoader();
					console.log('errr', err)
				})
	}
	setError = () => {
		let { data } = this.state;
		this.setState({
			...this.state,
			formErr: {
				...this.state.formErr,
				FirstName: data.FirstName.trim() ? null : "Enter First Name",
				LastName: data.LastName.trim() ? null : "Enter Last Name",
				//UserName: data.UserName ? null : "Enter User Name",
				Email: data.Email ? null : "Enter Email",
				Password: data.Password ? null : "Enter Password"
			}
		})
	}
	componentDidMount() {
		let { location } = this.props;
		if (location.state && (location.state.GoogleID || location.state.FacebookID)) {
			this.setState({
				...this.state,
				data: {
					...this.state.data,
					FirstName: location.state.FirstName.trim(),
					LastName: location.state.LastName.trim(),
					Email: location.state.Email.trim(),
					SocialToken: location.state.SocialToken,
					Type: location.state.Type,
					GoogleID: location.state.GoogleID && location.state.GoogleID || null,
					FacebookID: location.state.FacebookID && location.state.FacebookID || null
				}
			})
		}
		if (this.props.auth && this.props.auth.user) {
			if(this.props.auth.user.TopicID && this.props.auth.user.TopicID.length > 0){
				if(this.state.ref){
					this.props.history.push(this.state.ref);
				}else{
					this.props.history.push('/feed');
				}
			}
			else
				this.props.history.push('/select-topic');
		}
	}
	componentDidUpdate(prevProps, prevState) {
		// if (prevState.data != this.state.data) {
		// 	this.setError();
		// }
		if (this.props.auth && this.props.auth.user) {
			if(this.props.auth.user.TopicID && this.props.auth.user.TopicID.length > 0){
				if(this.state.ref){
					this.props.history.push(this.state.ref);
				}else{
					this.props.history.push('/feed');
				}
			}
			else{
				this.props.history.push('/select-topic');
			}
		}
	}

	responseFacebook = (res) => {
		console.log(res);
		if (res && res.email) {
			httpPost('user_controller/check_social', {
				SocialID: res.id,
				Email: res.email,
				SocialToken: res.accessToken,
				Type: "2",
			}).then(apiRes => {
				if (apiRes) {
					if (apiRes.status) {
						this.props.addUserSuccess(apiRes.data);
					} else {
						var nameArr = res.name.split(' ');
						this.props.history.push('signup', {
							FirstName: nameArr[0] && nameArr[0] || null,
							LastName: nameArr[1] && nameArr[1] || null,
							Email: res.email,
							SocialToken: res.accessToken,
							Type: "2",
							FacebookID: res.id
						})
					}
				} else {
					toast.error('Failed to login with Google');
				}
			})
		} else {
			toast.error('Please allow English forward to use your email address');
		}
	}
	responseGoogle = (res) => {
		console.log('res', res);
		if (res && res.profileObj && res.profileObj.email) {
			httpPost('user_controller/check_social', {
				SocialID: res.profileObj.googleId,
				Email: res.profileObj.email,
				SocialToken: res.tokenId,
				Type: "1"
			}).then(apiRes => {
				if (apiRes) {
					if (apiRes.status) {
						this.props.addUserSuccess(apiRes.data);
					} else {
						this.props.history.push('signup', {
							FirstName: res.profileObj.givenName,
							LastName: res.profileObj.familyName,
							Email: res.profileObj.email,
							SocialToken: res.tokenId,
							Type: "1",
							GoogleID: res.profileObj.googleId
						})
					}
				} else {
					toast.error('Failed to login with Google');
				}
			})
		} else {
			toast.error('Please allow English forward to use your email address');
		}
	}
	onGoogleFail = (err) => {
		console.log('err', err);
		//toast.error('Failed to login');
	}

	render() {
		return (
			<div className="login-page" >
	            <Helmet>
	                <title>{"Sign Up"}</title>
	            </Helmet>
				<Container>
					<Row>
						<Col md="6" className="p-5">
							<h2>The Best Platform to <b>Learn English</b> and <b>Make Money</b> in the process.</h2>
							<p>Sign up and connect to a community of passionate people - just like you 😁 👍</p>
							<Form onSubmit={this.handleSubmit}>
								<div className="box" >
									<FormControl value={this.state.data.FirstName} onChange={(e) => {
										this.setState((oldState) => {
											return {
												...this.state,
												data: { ...this.state.data, FirstName: e && e.target && e.target.value || null }
											}
										})
									}} placeholder="First Name *" name="fname" />
								</div>
								{this.state.formErr.FirstName ? <p className="error err-login">{this.state.formErr.FirstName}</p> : null}
								<div className="box" >
									<FormControl value={this.state.data.LastName} onChange={(e) => {
										this.setState((oldState) => {
											return {
												...this.state,
												data: { ...this.state.data, LastName: e && e.target && e.target.value || null }
											}
										})
									}} placeholder="Last Name *" name="lname" />
								</div>
								{this.state.formErr.LastName ? <p className="error err-login">{this.state.formErr.LastName}</p> : null}
								{/*<div className="box" >
									<FormControl value={this.state.data.UserName} onChange={(e) => {
										this.setState((oldState) => {
											return {
												...this.state,
												data: { ...this.state.data, UserName: e && e.target && e.target.value || null }
											}
										})
									}} onKeyUp={(e) => {
										this.checkUserName(e.target.value)
									}} placeholder="User Name *" name="username" />
								</div>
								{this.state.formErr.UserName ? <p className="error err-login">{this.state.formErr.UserName}</p> : null}*/}
								<div className="box" >
									<FormControl value={this.state.data.Email} onChange={(e) => {
										this.setState((oldState) => {
											return {
												...this.state,
												data: { ...this.state.data, Email: e && e.target && e.target.value || null }
											}
										})
									}} placeholder="Enter your email address *" name="email" />
								</div>
								{this.state.formErr.Email ? <p className="error err-login">{this.state.formErr.Email}</p> : null}
								<div className="box" >
									<FormControl value={this.state.data.MobileNo} onChange={(e) => {
										this.setState((oldState) => {
											return {
												...this.state,
												data: { ...this.state.data, MobileNo: e && e.target && e.target.value || null }
											}
										})
									}} placeholder="Mobile number" name="phone" />
								</div>
								<div className="box" >
									<InputGroup >
										<FormControl value={this.state.data.Password} onChange={(e) => {
											this.setState((oldState) => {
												return {
													...this.state,
													data: { ...this.state.data, Password: e && e.target && e.target.value || null }
												}
											})
										}} type={this.state.type} placeholder="Password *" name="password" />
										<div>
											<Image className="img-responsive" src={Images.lockIcon} onClick={this.handlePassword} />
										</div>
									</InputGroup>
								</div>
								{this.state.formErr.Password ? <p className="error err-login">{this.state.formErr.Password}</p> : null}
								<div>
									<Button type="submit" className="btn-md btn-block btn-rounded sign-up" variant="outline-primary" >Sign up</Button>
									{/* Removed From Button <Image src={Images.arrowRight} />*/}
								</div>
							</Form>
							<Row className="socialButton" >
								<Col md="6" sm="12" >
									<FacebookLogin
										appId="163597855587563"
										autoLoad={false}
										fields="name,email,picture"
										callback={(res) => { this.responseFacebook(res) }}
										textButton={<div><Image className="img-responsive s-img" src={Images.signInWithFacebook} /></div>}
										cssClass={"btn-lg btn-block btn-fb"}
									// icon={<Image className="img-responsive" src={Images.fb} />}
									/>
									{/* <Button className="btn-lg btn-block btn-fb" >Sign in with <Image className="img-responsive" src={Images.fb} /></Button> */}
								</Col>
								<Col md="6" sm="12" >
									<GoogleLogin
										clientId="242651927357-fubf2rb1c4cr668mf9dh1dpj5boopibm.apps.googleusercontent.com"
										render={renderProps => (
											<Button onClick={renderProps.onClick} variant="" className="btn-lg btn-block btn-google" ><Image className="img-responsive s-img" src={Images.signInWithGoogle} /></Button>
										)}
										//buttonText="Login"
										autoLoad={false}
										onSuccess={this.responseGoogle}
										onFailure={this.onGoogleFail}
										cookiePolicy={'single_host_origin'}
									/>
									{/* <Button className="btn-lg btn-block btn-google" >Sign in with <Image className="img-responsive" src={Images.google} /><Image className="img-responsive" src={Images.plus} /></Button> */}
								</Col>
							</Row>
							{/* <Row>
								<Col md="6" >
									<Button className="btn-lg btn-block btn-fb" >Sign up with <Image className="img-responsive" src={Images.fb} /></Button>
								</Col>
								<Col md="6" >
									<Button className="btn-lg btn-block btn-google" >Sign up with <Image className="img-responsive" src={Images.google} /><Image className="img-responsive" src={Images.plus} /></Button>
								</Col>
							</Row> 
							<p><span style={{ color: '#F8A519' }} >Are you a teacher?</span> Help people and get paid</p>*/}
						</Col>
						<Col md="6" className="p-5 d-none d-sm-none d-md-block" >
							{/*<div className="text-right" >
								<p><span>Already have the account?</span></p>
								<Link to='/signin'>
									<Button className="btn-rounded-blue sign-up" >Sign In now ✌</Button>
								</Link>
							</div>*/}
							<div style={{ backgroundImage: `url(${Images.LoginBg})`, backgroundSize: 'cover', width: '457px', height: '456px' }} >
								<Image className="img-responsive" width="480" style={{ marginTop: -36, marginLeft: -70 }} src={Images.signupRightSideImage} />
							</div>
						</Col>
					</Row>
					<div className="text-right" >
						<span className="help-float-icon" >
							<Link to='/help'>
								<div>Need help? <div className="help-icon" > <span>?</span> </div></div> 
							</Link>
						</span>
					</div>
				</Container>

			</div>
		)
	}
}
const mapStateToProps = ({ auth }) => {
	return { auth };
}
export default connect(mapStateToProps, {
	//registerUser,
	resetSignUp,
	registerUserSuccess,
	showLoader,
	hideLoader,
	addUserSuccess
})(Signup)
//export default Signup;