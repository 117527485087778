import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';

import { Images } from '../config';
import PropTypes from "prop-types";
import { BaseColor } from '../config/index';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment-timezone';
import SweetAlert from 'react-bootstrap-sweetalert';
import { httpPost } from '../Services/HttpServices';
import { toast } from 'react-toastify';

function FollowCard(props) {
    const timeZone  = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const [unFollow, setUnFollow] = useState(null);
    const [mute, setMute] = useState(null);
    const [unMute, setUnMute] = useState(null);
    const onConfirmFollow = () => {
        httpPost('follow_user_controller/delete', {
            FollowUserId: unFollow
        }).then(res => {
            if (res) {
                if (res.status) {
                    toast.success(res.message);
                    props.unFollow(unFollow);
                } else {
                    toast.error(res.message);
                }
            }
            setUnFollow(null);
        }).catch(err => {
            console.log('err', err);
            setUnFollow(null);
            toast.error('Network Error')
        })
    }

    const onCancelFollow = () => {
        setUnFollow(null);
    }

    const onConfirmMute = () => {
        httpPost('mute_user_controller/create', {
            MutedUser: mute && mute.UserID && mute.UserID._id || null,
            MainUser: mute && mute.FollowerID && mute.FollowerID._id || null
        }).then(res => {
            if (res) {
                if (res.status) {
                    toast.success(res.message);
                    props.mute(mute._id);
                } else {
                    //props.mute(mute._id);
                    toast.error(res.message);
                }
            }
            setMute(null);
        }).catch(err => {
            console.log('err', err);
            setMute(null);
            toast.error('Network Error')
        })
    }

    const onCancelMute = () => {
        setMute(null);
    }
    const onConfirmUnMute = () => {
        httpPost('mute_user_controller/delete', {
            MutedUser: unMute && unMute.UserID && unMute.UserID._id || null,
            MainUser: unMute && unMute.FollowerID && unMute.FollowerID._id || null
        }).then(res => {
            if (res) {
                if (res.status) {
                    toast.success(res.message);
                    props.unmute(unMute._id);
                } else {
                    toast.error(res.message);
                }
            }
            setUnMute(null);
        }).catch(err => {
            console.log('err', err);
            setUnMute(null);
            toast.error('Network Error')
        })
    }

    const onCancelUnMute = () => {
        setUnMute(null);
    }
    return (
        <>
            {/* // unfollow, mute */}
            <Card style={{ width: '100%' }} className="mx-3 my-3 px-3 ">
                <Card.Title className="ml-4 mt-3">People you follow</Card.Title>
                <Card.Subtitle className="ml-4 text-muted">{props.totalFollow} following</Card.Subtitle>
                {props.followlist.map((ele, index) => (
                    <Row className="cardStyleListingRow" style={{ borderBottom: "0.5px solid #DEE2E6" }}>
                        <Col md="1" xs="1" sm="1" className="cardStyleListingCol">
                            <Image src={ele.UserID && ele.UserID.ProfilePicture || Images.defaultProfile} className="image-follow-card" />
                        </Col>
                        <Col md="6" xs="9" sm="6" className="cardUserNameCol">
                            <p className="cardUserName"><Link to={"/profile/"+ele.UserID.UserName} >{ele.UserID ? ele.UserID.FirstName + ' ' + ele.UserID.LastName : null}</Link></p>
                            {/* <br></br> */}
                            <p className="cardUserDetails">
                               {moment.tz(ele.CreatedDate, timeZone).fromNow()}
                            </p>
                        </Col>
                        {/* <Col md="1"></Col> */}
                        <Col md="4" xs="12" sm="4">
                            <div className="text-white cardButtonsList" >
                                {/* ele.UserID && ele.UserID._id */}
                                <Button onClick={() => setUnFollow(ele._id || null)} className="btn-sm mr-1 px-1" style={{ padding: 0, margin: 0, backgroundColor: BaseColor.primaryColor, fontSize: 14, color: BaseColor.whiteColor, borderColor: BaseColor.darkPrimaryColor }}  >Unfollow</Button>
                                {ele.IsMute == 0 ? (
                                    <Button onClick={() => setMute(ele || null)} className="btn-sm mr-1 px-1" style={{ padding: 0, margin: 0, backgroundColor: BaseColor.kashmir, fontSize: 14, color: BaseColor.whiteColor, borderColor: BaseColor.kashmir }} >Mute</Button>
                                ) : (
                                        <Button onClick={() => setUnMute(ele || null)} className="btn-sm mr-1 px-1" style={{ padding: 0, margin: 0, backgroundColor: BaseColor.kashmir, fontSize: 14, color: BaseColor.whiteColor, borderColor: BaseColor.kashmir }} >UnMute</Button>
                                    )}
                            </div>
                        </Col>
                    </Row>
                ))}
                <SweetAlert
                    show={unFollow ? true : false}
                    warning
                    showCancel
                    confirmBtnText={"Yes"}
                    cancelBtnText={"No"}
                    title={<span style={{ fontSize: 25 }} >Do you want to unfollow this user?</span>}
                    onConfirm={onConfirmFollow}
                    onCancel={onCancelFollow}
                />
                <SweetAlert
                    show={mute ? true : false}
                    warning
                    showCancel
                    confirmBtnText={"Yes"}
                    cancelBtnText={"No"}
                    title={<span style={{ fontSize: 25 }} >Do you want to mute this user?</span>}
                    onConfirm={onConfirmMute}
                    onCancel={onCancelMute}
                />
                <SweetAlert
                    show={unMute ? true : false}
                    warning
                    showCancel
                    confirmBtnText={"Yes"}
                    cancelBtnText={"No"}
                    title={<span style={{ fontSize: 25 }} >Do you want to un-mute this user?</span>}
                    onConfirm={onConfirmUnMute}
                    onCancel={onCancelUnMute}
                />
            </Card>
        </>
    )
}

FollowCard.prototype = {
    followlist: PropTypes.array,
    totalFollow: PropTypes.string,
    unFollow: PropTypes.func,
    mute: PropTypes.func,
    unmute: PropTypes.func
}

FollowCard.defaultProps = {
    followlist: [],
    totalFollow: "0",
    unFollow: () => { },
    mute: () => { },
    unmute: () => { },
}
export default FollowCard;

// margin-left: 27px;margin-top: -14px;