export const Images = {
    audio: require('../assets/icon/audio.png').default,
    logo: require('../assets/image/logo.png').default,
    defaultProfile: require('../assets/image/defaultProfile.png').default,
    mail: require('../assets/icon/email.png').default,
    mailWhite:require('../assets/icon/mailWhite.png').default,
    notification: require('../assets/icon/notification.png').default, 
    new_notification: require('../assets/icon/new-notification.svg').default, 
    more: require('../assets/icon/more.png').default,
    moreBlue: require('../assets/icon/moreBlue.png').default,
    grid: require('../assets/icon/grid.png').default,
    more_horizontal: require('../assets/icon/more_horizontal.png').default,
    up: require('../assets/icon/upVote.svg').default,
    upActive: require('../assets/icon/upVoteActive.svg').default,
    down: require('../assets/icon/downVote.svg').default,
    downActive: require('../assets/icon/downVoteActive.svg').default,
    filter:require('../assets/icon/filter.png').default,
    message: require('../assets/icon/message.png').default,
    forward: require('../assets/icon/share.svg').default,
    reply: require('../assets/icon/reply.png').default,
    forwardBlue: require('../assets/icon/forwardBlue.png').default,
    search: require('../assets/icon/search.png').default,
    close: require('../assets/icon/close.svg').default,
    openWhite:require('../assets/icon/openWhite.png').default,
    lockIcon: require('../assets/icon/lockIcon.png').default,
    comment: require('../assets/icon/comment.svg').default,
    facebook: require('../assets/icon/social/facebook.svg').default,
    fb: require('../assets/icon/social/fb.png').default,
    google: require('../assets/icon/social/google.png').default,
    plus: require('../assets/icon/social/plus.png').default,
    // instagram:require('../assets/icon/social/instagram.svg').default,
    twitter:require('../assets/icon/social/twitter.svg').default,
    youtube:require('../assets/icon/social/youtube.svg').default,
    medium:require('../assets/icon/social/medium.svg').default,
    paperPlane:require('../assets/icon/social/paperPlane.svg').default,
    // dotIcon:require('../assets/icon/dotIcon.png').default,
    dotIcon:require('../assets/icon/dotIcon.svg').default,

    signIn:require('../assets/image/sign-in-button.svg').default,
    signInWithFacebook:require('../assets/image/sign-in-with-facebook.svg').default,
    signInWithGoogle:require('../assets/image/sign-in-with-google.svg').default,

    blog1: require("../assets/image/blog1.png").default,
    blog2: require("../assets/image/blog2.png").default,
    blog3: require("../assets/image/blog3.png").default,
    blog4: require("../assets/image/blog4.png").default,
    // Home page

    Group: require("../assets/image/Group.png").default,

    follow:require('../assets/icon/follow.svg').default,
    // bell:require('../assets/icon/bell.png').default,
    bell:require('../assets/icon/bell-icon.svg').default,
    // bell_off:require('../assets/icon/bell-off.png').default,
    bell_off:require('../assets/icon/crossed-bell-icon.svg').default,
    checked:require('../assets/icon/checked.png').default,

    EFUnique:require('../assets/image/EF-Unique.png').default,
    RealBenefit:require('../assets/image/Real-Benefit.png').default,
    FeatureGlobe:require('../assets/image/FeatureGlobe.png').default,

    meet:require('../assets/icon/meet.png').default,
    handshake:require('../assets/icon/handshake.png').default,
    mic:require('../assets/icon/mic.png').default,
    volume:require('../assets/icon/volume.png').default,
    writing:require('../assets/icon/writing.png').default,
    idea:require('../assets/icon/idea.png').default,
    // check:require('../assets/icon/check.png').default,
    check:require('../assets/icon/verified.svg').default,
    Question:require('../assets/icon/Question.png').default,
    image:require('../assets/icon/image.png').default,
    pin:require('../assets/icon/pin.png').default,
    LoginBg:require('../assets/image/LoginBg.png').default,
    loginRightImage:require('../assets/image/loginRightImage.png').default,
    signupRightSideImage: require('../assets/image/signupRightSideImage.png').default,
    // helpIcon:require('../assets/image/helpIcon.png').default,

    user:require('../assets/icon/user.svg').default,
    user1:require('../assets/image/4.png').default,
    user2:require('../assets/image/8.png').default,
    user3:require('../assets/image/9.png').default,
    news1:require('../assets/image/2.png').default,
    news2:require('../assets/image/3.png').default,
    news3:require('../assets/image/4.png').default,

    // home- our featured users

    p1:require('../assets/image/p1.png').default,
    p2:require('../assets/image/p2.png').default,
    p3:require('../assets/image/p3.png').default,
    p4:require('../assets/image/p4.png').default,

    //About us icon
    aboutus1:require('../assets/image/aboutus1.png').default,
    aboutus2:require('../assets/image/aboutus2.png').default,
    aboutus3:require('../assets/image/aboutus3.png').default,
    arrowDown:require('../assets/icon/ArrowDown.png').default,
    arrowRight:require('../assets/icon/ArrowRight.png').default,
    arrowRightBlack:require('../assets/icon/ArrowRightBlack.png').default,
    arrowLeftBlack:require('../assets/icon/ArrowLeftBlack.png').default,
    team1:require('../assets/image/team1.png').default,
    team2:require('../assets/image/team2.png').default,
    team3:require('../assets/image/team3.png').default,
    team4:require('../assets/image/team4.png').default,
    
    // Profile 
    profile1:require('../assets/image/profile1.png').default,
    profile2:require('../assets/image/profile2.png').default,
    profile3:require('../assets/image/profile3.png').default,

    activity:require('../assets/icon/activity.png').default,
    activityActive:require('../assets/icon/activityActive.png').default,
    questions:require('../assets/icon/questions.png').default,
    questionsActive:require('../assets/icon/questionsActive.png').default,
    answers:require('../assets/icon/answers.png').default,
    answersActive:require('../assets/icon/answersActive.png').default,
    comments:require('../assets/icon/comments.png').default,
    commentsActive:require('../assets/icon/commentsActive.png').default,
    warning:require('../assets/icon/warning.png').default,

    // Dashboard 
    dashboard :require('../assets/icon/dashboard.png').default,
    menu_icon:require('../assets/icon/menu-icon.png').default,

    collapseOpen:require('../assets/icon/collapseOpen.png').default,
    collapseClose:require('../assets/icon/collapseClose.png').default,
    language:require('../assets/icon/language.png').default,
    contact:require('../assets/icon/contact.png').default,

    question1:require('../assets/image/question1.png').default,
    question2:require('../assets/image/question2.png').default,

    follows:require('../assets/icon/follows.png').default,
    views:require('../assets/icon/view.png').default,
    
    edit: require('../assets/icon/edit-icon.svg').default,
    delete: require('../assets/icon/delete-icon.svg').default,
    // edit:require('../assets/icon/edit.png').default,
    // delete:require('../assets/icon/delete.png').default,
    
    //Profile
    personal_icon:require('../assets/icon/personal-icon.svg').default,
    contact_icon:require('../assets/icon/contact-icon.svg').default,
    language_icon:require('../assets/icon/language-icon.svg').default,
    topic_icon:require('../assets/icon/topic-icon.svg').default,
    education_icon:require('../assets/icon/education-icon.svg').default,
    learners_icon:require('../assets/icon/learners-icon.svg').default,
    teaching_icon:require('../assets/icon/teaching-icon.svg').default,
    interest_icon:require('../assets/icon/education-icon.svg').default,
    badge_icon:require('../assets/icon/badge-icon.svg').default,

    ic_default_avatar: require('../assets/image/chat/ic_default_avatar.png').default,
    ic_input_file: require('../assets/image/chat/ic_input_file.png').default,
    ic_photo: require('../assets/image/chat/ic_photo.png').default,
    ic_sticker: require('../assets/image/chat/ic_sticker.png').default,
    ic_send: require('../assets/image/chat/ic_send.png').default,
    ic_wave_hand: require('../assets/image/chat/ic_wave_hand.png').default,

    mimi1: require('../assets/image/chat/mimi1.gif').default,
    mimi2: require('../assets/image/chat/mimi2.gif').default,
    mimi3: require('../assets/image/chat/mimi3.gif').default,
    mimi4: require('../assets/image/chat/mimi4.gif').default,
    mimi5: require('../assets/image/chat/mimi5.gif').default,
    mimi6: require('../assets/image/chat/mimi6.gif').default,
    mimi7: require('../assets/image/chat/mimi7.gif').default,
    mimi8: require('../assets/image/chat/mimi8.gif').default,
    mimi9: require('../assets/image/chat/mimi9.gif').default
}