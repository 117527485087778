import React, {useState} from 'react'
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import DashboardComponent from "../Component/RChat/Dashboard"
import { UsersProvider } from '../Component/RChat/context/UsersContext';

const ReactChat = (props) => {
	var auth = useSelector(({ auth }) => auth);
	const [userId, setUserId] = useState(props.match.params.id ? props.match.params.id : "")
    return (
        <>
        	{auth.user === null ? (<Redirect to="/signin" />) : null }
            <UsersProvider>
       		   <DashboardComponent userId={userId} />
            </UsersProvider>
        </>
    )
}

export default ReactChat