import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';

import Related from '../Component/Related';
import Tag from '../Component/Tag';
import { Images } from '../config';
import moment from "moment";
import 'moment-timezone';
import { httpPost } from '../Services/HttpServices';
import { showLoader, hideLoader } from '../AppRedux/Actions/Auth';
import { toast } from 'react-toastify';

const QuestionLog = (props) => {
    const timeZone  = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const [question, setQuestion] = useState(null);
    const [logs, setLogs] = useState({ page: 1, totalCount: 0, list: [] })
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        var search = new URLSearchParams(props.location.search);
        let id = search.get('id')
        if (!id) {
            props.history.goBack()
        } else {
            dispatch(showLoader());
            getQuestion(id)
        }
    }, [])

    useEffect(() => {
        if (question) {
            getLogs();
        }
    }, [question, logs.page]);

    const getQuestion = (id) => {
        httpPost('question_controller/retrive', { QuestionID: id })
            .then(res => {
                dispatch(hideLoader())
                if (res) {
                    if (res.status) {
                        setQuestion(res.data);
                    } else {
                        props.history.goBack();
                        toast.error(res.message);
                    }
                } else {
                    props.history.goBack();
                    toast.error('Network Error');
                }
            }).catch(err => {
                dispatch(hideLoader())
                props.history.goBack();
                console.log('err');
            })
    }

    const getLogs = () => {
        setLoading(true);
        httpPost('logs_controller/list', { QuestionID: question._id, page: logs.page })//auth && auth.user && auth.user._id
            .then(res => {
                setLoading(false);
                if (res && res.status) {
                    var oldArr = [...logs.list];
                    var newData = res.data;
                    newData.forEach(element => {
                        element.CreatedDate = element.CreatedDate ? moment.tz(element.CreatedDate, timeZone).fromNow() : "";
                        // element.CreatedDate = element.CreatedDate;
                    })
                    var newArr = oldArr.concat(newData);
                    setLogs({ ...logs, totalCount: res.totalCount, list: newArr });
                }
            })
    }
    return (
        <div className="p-4 p-md-5 p-lg-5 p-sm-4 question-log">
            <Row>
                <Col md={{ offset: '1', span: '7' }}>
                    <Col className="q-detail" md="12" >
                        <div className='tags-div'>
                            {question && question.Tags && question.Tags.split(",").map(ele => (
                                <div className='tags'>
                                    {ele}
                                </div>
                            ))}
                        </div>
                        <h3 className="mt-2">
                            {question && question.Subject}
                            <Link to={"/question/"+question?.QuestionUrl} className="float-right" >Go Back</Link>
                        </h3>
                    </Col>
                    <Col className="l-detail mt-4" md="12" >
                        {logs && logs.list && logs.list.length > 0 ? logs.list.map((log, index) => (
                            <div className={"l-div " + (logs.list.length - 1 == index ? 'last' : '')}>
                                <p className='l-text'>
                                    {log.Message}
                                </p>
                                <p className='l-time'>
                                    {log.UserID.FirstName+" "+log.UserID.LastName} - {log.CreatedDate ? '#On ' + log.CreatedDate : ""}
                                </p>
                            </div>
                        )) : <p>No Logs</p>}
                        {loading ?
                            <Spinner animation="border" variant="primary" />
                            : logs.totalCount && logs.totalCount > logs.list.length ? //
                                <Button className='mt-2' size="sm" onClick={() => { setLogs({ ...logs, page: logs.page + 1 }) }}>see more</Button>
                                : null}
                    </Col>
                </Col>
                <Col md="3">
                    <Col className="q-stat" md="12" >
                        <h4 className='q-heading'>Question Stats</h4>
                        {question && (
                            <>
                                <p>
                                    <Image src={Images.follows} className="mr-2" height="20" />
                                    {question.FollowCount + ' Public Followers'}
                                </p>
                                <p>
                                    <Image src={Images.views} className="mr-2" height="20" />
                                    {question.ViewCount + ' Views'}
                                </p>
                                <p>
                                    <Image src={Images.up} className="mr-2" height="20" />
                                    {question.UpvoteCount + ' Upvotes'}
                                </p>
                                <p>
                                    <Image src={Images.down} className="mr-2" height="20" />
                                    {question.DownvoteCount + ' Downvotes'}
                                </p>
                            </>
                        )}
                    </Col>
                </Col>
            </Row>
        </div>
    )
}

export default QuestionLog;