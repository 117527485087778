 import React, { Component } from 'react';
import { Button, Col, Container, FormControl, Image, InputGroup, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import { Images } from '../config';
import { httpGet, httpPost } from '../Services/HttpServices';
import { showLoader, hideLoader } from '../AppRedux/Actions/Auth';
import { connect } from 'react-redux';

class ConfirmAccount extends Component {
	constructor(props) {
		super(props);
		this.state = {
			type: "password",
			emailforVerification: "",
			token: ""
		}
		this.handleSubmit = this.handleSubmit.bind(this);
	}
	componentDidMount() {
		var search = new URLSearchParams(this.props.location.search);
		const token = search.get('token')
		const verify = search.get('verify')
		console.log(search, token, "search, token, verify");
		this.setState({ token })
		if (token == null) {
			// this.props.history.push('/home')
		} else {
			if (verify) {
				this.props.history.push('/resetpassword')
			} else if (token != "" || token != "") {
				// call API
				var tokenObj = { token }
				// http://localhost:3000/forgotpassword?token=03f01dc4ba36f96d14537f548d061f8a
				httpGet('forgotpassword', tokenObj)
					.then(res => {
						console.log("res ========================> ", res);
						if (res.status == 1) {
							this.props.history.push({
								pathname: '/resetpassword',
								state: { userID: res.UserID }
							});
						} else {
							toast.error(res.message);
						}
					})
			} else {
				this.props.history.push('/home')
			}
		}
	}
	handleEmail = (e) => {
		console.log("e", e.target.value);
		this.setState({
			emailforVerification: e.target.value
		});
	}
	// forgot_password_controller/forgot_password
	// Email
	handleSubmit = (e) => {
		e.preventDefault();
		var passObj = { Email: this.state.emailforVerification }
		// console.log("e", passObj);
		this.props.showLoader();
		httpPost('forgot_password_controller/forgot_password', passObj)
			.then(res => {
				this.props.hideLoader();
				if (res.status == 1) {
					toast.success(res.message)
				} else {
					toast.error(res.message);
				}
			}).catch(err => {
				this.props.hideLoader();
			})
	}
	render() {
		return (
			<div className="login-page" >
				<Container fluid>
					<Row>
						<Col md="3" className="p-5"></Col>
						<Col md="6" className="p-5 mb-4">
							<h2>Verify! it's you</h2>
							{/* <p>Reset password 😁 👍</p> */}
							<Form onSubmit={this.handleSubmit}>
								<div className="box" >
									<FormControl type="email" placeholder="Enter your email address" name="email" onChange={this.handleEmail} />
								</div>
								<div>
									<Button type="submit" className="btn-lg btn-block" >Verify <Image src={Images.arrowRight} /></Button>
								</div>
							</Form>
						</Col>
						<Col md="3" className="p-5" >
						</Col>
					</Row>
				</Container>

			</div>
		)
	}
}
const mapStateToProps = ({ auth }) => {
	return { auth };
}
export default connect(mapStateToProps, {
	showLoader,
	hideLoader
})(ConfirmAccount)