import { Link } from "react-router-dom";
import { BaseColor, Images } from "../config";

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';

import { useSelector } from 'react-redux';

function Banner(props) {
    const { auth } = useSelector(state => state);
    return (
        <div className="banner-text ml-5 mr-5" >
        <Row>
            <Col md="6" className="content" >
            <div>
            <strong>The Best Platform to Learn English</strong> from a Passionate Community - for Free!
            </div>
            <p>310+ million users have learned English with English Forward - now it's your turn!</p>
            {/*<p>310+ Million Students have used English Forward to learn English - now it's your turn.
​Learn English online and improve your skills ​with the help​ of our high​ly qualified experts​ and resources – designed to help you communicate effectively in English.</p>*/}
            <div >
                <Button className="ask-question" variant="" onClick={() => {
                    if (auth && auth.user) {
                        props.history.push('/questionCreate');
                    } else {
                        props.history.push('/signin?ref=/questionCreate');
                    } }
                    } >ask a question</Button>
            </div>
            </Col>
            <Col md="6" ><Image src={Images.signupRightSideImage} width="450" className="section-img" /></Col>
        </Row>
            {/*<ul>
            	<li>Do you speak English?</li>
				<li>#$GSB?</li>
				<li>Let’s start!</li>
            </ul>*/}
        </div >
    )
}

export default Banner;