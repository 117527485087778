import React, { Component } from 'react';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import FormControl from 'react-bootstrap/FormControl';
import Image from 'react-bootstrap/Image';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';

import Form from 'react-bootstrap/Form';
import { Images } from '../config';
import { httpPost } from '../Services/HttpServices';
import { toast } from 'react-toastify';
import { showLoader, hideLoader } from '../AppRedux/Actions/Auth';
import { connect } from 'react-redux';
class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: "password",
            newtype: "password",
            oldtype: "password",
            visibility: "hidden",
            oldpassword: "",
            newpassword: "",
            repeatpassword: "",
            colorname: "",
            textmsg: ""
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleOldPassword = (e) => {
        this.setState({
            oldpassword: e.target.value
        });
    }
    handleNewPassword = (e) => {
        this.setState({
            newpassword: e.target.value
        });
    }
    handleHideShowRepeatPassword = () => {
        this.setState({ newtype: this.state.newtype === 'text' ? 'password' : 'text' })
    }
    handleHideShowPassword = () => {
        this.setState({ type: this.state.type === 'text' ? 'password' : 'text' })
    }
    handleHideShowOldPassword = () => {
        this.setState({ oldtype: this.state.oldtype === 'text' ? 'password' : 'text' })
    }
    handleRepeatPassword = (e) => {
        if (e.target.value == "") {
            var colorname = ""
            var textmsg = ""
        } else {
            if (e.target.value == this.state.newpassword) {
                var colorname = "#009900"
                var textmsg = "Password matched"
            } else {
                var colorname = "#FF5151"
                var textmsg = "Password did not match"
            }
        }
        this.setState({
            visibility: "visible", repeatpassword: e.target.value, colorname, textmsg
        });
    }
    handleSubmit = (e) => {
        e.preventDefault();
        var passObj = { UpdateType: 1, OldPassword: this.state.oldpassword, NewPassword: this.state.newpassword, RepeatPassword: this.state.repeatpassword }
        console.log("e", passObj);
        this.props.showLoader();
        httpPost('user_controller/update_password', passObj)
            .then(res => {
                this.props.hideLoader();
                if (res.status == 1) {
                    toast.success(res.message);
                    this.setState({
                        oldpassword:"",
                        newpassword:"",
                        repeatpassword:"",
                        textmsg:"",
                    })
                    this.props.history.push('/feed');
                } else {
                    toast.error(res.message);
                }
            }).catch(err => {
                this.props.hideLoader();
            })
    }
    render() {
        return (
            <div className="login-page" >
                <Container fluid>
                    <Row>
                        <Col md="3" className="p-5"></Col>
                        <Col md="6"  className="pt-4 pl-5 pr-5 pb-5 mb-4">
                            <h2>Change Password </h2>
                            {/* <p>Reset password 😁 👍</p> */}
                            <Form onSubmit={this.handleSubmit}>
                                <div className="box" >
                                    <InputGroup >
                                        <FormControl value={this.state.oldpassword} type={this.state.oldtype} placeholder="Enter old password" name="old_password" onChange={e => this.handleOldPassword(e)} />
                                        <div>
                                            <Image className="img-responsive" src={Images.lockIcon} onClick={this.handleHideShowOldPassword} />
                                        </div>
                                    </InputGroup>
                                </div>
                                <div className="box" >
                                    <InputGroup>
                                        <FormControl value={this.state.newpassword} type={this.state.type} placeholder="Enter new password" name="new_password" onChange={e => this.handleNewPassword(e)} />
                                        <div>
                                            <Image className="img-responsive" src={Images.lockIcon} onClick={this.handleHideShowPassword} />
                                        </div>
                                    </InputGroup>
                                </div>
                                <div className="box" >
                                    <InputGroup >
                                        <FormControl value={this.state.repeatpassword} type={this.state.newtype} placeholder="Enter new password again" name="repeat_password" onChange={e => this.handleRepeatPassword(e)} />
                                        <div>
                                            <Image className="img-responsive" src={Images.lockIcon} onClick={this.handleHideShowRepeatPassword} />
                                        </div>
                                    </InputGroup>
                                </div>
                                <p style={{ visibility: this.state.visibility, color: this.state.colorname, fontSize: 15, paddingLeft: 25, marginTop: -25, minHeight: 33 }}>{this.state.textmsg}</p>
                                <div>
                                    <Button type="submit" className="btn-lg btn-block" >Change Password <Image src={Images.arrowRight} /></Button>
                                </div>
                            </Form>
                        </Col>
                        <Col md="3" className="p-5" >
                        </Col>
                    </Row>
                </Container>

            </div>
        )
    }
}

const mapStateToProps = ({ auth }) => {
    return { auth };
}
export default connect(mapStateToProps, {
    showLoader,
    hideLoader
})(ChangePassword)
//export default ChangePassword;