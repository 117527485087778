import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { httpPost } from '../Services/HttpServices';
import { showLoader, hideLoader } from '../AppRedux/Actions/Auth';
import { connect } from 'react-redux';
import { addUserSuccess } from '../AppRedux/Actions/Auth';

class VerifyAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: "",
            loading: true,
            errMsg: "",
        }
    }
    componentDidMount() {
        this.props.showLoader();
        var search = new URLSearchParams(this.props.location.search);
        const token = search.get('token')
        // const verify = search.get('verify')
        console.log(search, token, "search, token, verify");
        this.setState({ token })
        if (token) {
            // var tokenObj = { token }
            // http://localhost:3000/forgotpassword?token=03f01dc4ba36f96d14537f548d061f8a
            httpPost(`profile_activation_controller/confirm/${token}`)
                .then(res => {
                    this.props.hideLoader();
                    if (res && res.status === 1) {
                        this.props.addUserSuccess(res.data);
                        toast.success(res.message);
                        // this.props.history.push({
                        //     pathname: '/select-topic',
                        // });
                    } else {
                        toast.error(res.message || "Failed to verify email");
                        this.setState({
                            loading: false,
                            errMsg: res.message || "Failed to verify email"
                        })
                    }
                }).catch(err => {
                    this.props.hideLoader();
                    this.setState({
                        loading: false,
                        errMsg: "Failed to verify email"
                    })
                })
        } else {
            this.props.hideLoader();
            this.setState({
                loading: false,
                errMsg: "Token not found"
            })
        }

    }
    componentDidUpdate() {
        if (this.props.auth && this.props.auth.user) {
            this.props.history.push('/select-topic');
        }
    }
    handleEmail = (e) => {
        console.log("e", e.target.value);
        this.setState({
            emailforVerification: e.target.value
        });
    }
    // forgot_password_controller/forgot_password
    // Email
    render() {
        return (
            <div className="login-page" >
                <Container fluid >
                    <Row className="pt-5 pb-5 text-center">
                        <Col md="3" className="p-5"></Col>
                        <Col md="6" className="p-5 mb-4">
                            <h3>Please wait a moment we are verifying your account.</h3>
                            {/* <p>Reset password 😁 👍</p> */}
                            {this.state.errMsg && (
                                <h3 className="error-red mt-4">{this.state.errMsg}</h3>
                            )}
                        </Col>
                        <Col md="3" className="p-5" >
                        </Col>
                    </Row>
                </Container>

            </div>
        )
    }
}
const mapStateToProps = ({ auth }) => {
    return { auth };
}
export default connect(mapStateToProps, {
    showLoader,
    hideLoader,
    addUserSuccess
})(VerifyAccount)