import { BaseColor, Images } from "../config";
import { Image } from 'react-bootstrap';
import PropTypes from "prop-types";

function TagSingle(props) {
    let { tag } = props;
    return (
        <div style={{
            padding: 5, width: 'fit-content', borderRadius: 20,
            backgroundColor: tag.active ? BaseColor.secondaryColor : BaseColor.grayColor,
            color: tag.active ? BaseColor.whiteColor : BaseColor.blackColor,
            webkitBoxShadow: '3px 7px 11px #d8cece',
            mozBoxShadow: '3px 7px 11px #d8cece',
            boxShadow: '3px 7px 11px #d8cece'
        }}

        >
            &nbsp;&nbsp;{tag}&nbsp;
            <Image onClick={() => { props.onTagClick(tag) }} src={Images.close} height={12} width={12} />&nbsp;&nbsp;
        </div>
    )
}

TagSingle.prototype = {
    tag: PropTypes.string,
    onTagClick: PropTypes.func,
}

TagSingle.defaultProps = {
    tag: "",
    onTagClick: () => { },
}
export default TagSingle;