import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';

import { Images } from '../config';
import BookmarksCard from '../Component/BookmarksCard';
import { httpPost } from '../Services/HttpServices';
import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Helmet from 'react-helmet';

function usePrevious(value) {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef();
    // Store current value in ref
    useEffect(() => {
        ref.current = value;
    }, [value]); // Only re-run if value changes
    // Return previous value (happens before update in useEffect above)
    return ref.current;
}

function Bookmarks(props) {
    var auth = useSelector(({ auth }) => auth);
    const [loading, setLoading] = useState(false);
    const [metaData, setMetaData] = useState([]);
    const [bookmarksList, setBookmarksList] = useState({
        page: 1,
        totalCount: 0,
        list: []
    })
    const prevList = usePrevious(bookmarksList);
    useEffect(() => {
        if (prevList && (prevList.totalCount > bookmarksList.totalCount)) {
            //Get List if deleted
            getList();
        }
    }, [bookmarksList.totalCount])
    useEffect(() => {
        setLoading(true);
        httpPost("home_page/get_meta_tag", { PageName: "Feed Page" })
            .then(res => {
                if (res && res.status) {
                    setMetaData(res.data);
                }
            })
        getList()
    }, [bookmarksList.page])

    const getList = () => {
        httpPost('bookmark_controller/list', { UserID: auth && auth.user._id, page: bookmarksList.page })
            .then(res => {
                setLoading(false);
                if (res && res.status) {
                    // percent
                    var listAppend = [];
                    var bookMarkData = res.data;
                    var oldArr = [...bookmarksList.list];
                    bookMarkData.map(ele => {
                        // console.log('ele', ele);
                        // console.log('UpvoteCount', ele.UpvoteCount);
                        // console.log('DownvoteCount', ele.DownvoteCount);
                        var percentage = 0;
                        if (ele.AnswerID) {
                            percentage = ((ele.AnswerID.UpvoteCount / (ele.AnswerID.UpvoteCount + ele.AnswerID.DownvoteCount)) * 100);
                        }
                        percentage = (Math.round(isNaN(percentage) ? 0 : percentage)) + "%";
                        ele.percent = percentage;
                        var ind = oldArr.findIndex(oldEle => oldEle._id == ele._id);
                        // console.log('ele.id', ele._id)
                        // console.log('ind', ind);
                        if (ind == -1) {
                            listAppend.push(ele);
                        }
                    })
                    // console.log(listAppend);
                    var newArr = oldArr.concat(listAppend);
                    setBookmarksList({ ...bookmarksList, totalCount: res.totalCount, list: newArr })
                }
            })
    }

    const updateBookMarkdata = (bookMarkData) => {
        var arr = [...bookmarksList.list];
        var ind = arr.findIndex(ele => ele._id == bookMarkData._id);
        // console.log(ind, "ind");
    }
    return (
        <div>
            <Helmet>
                <title>{metaData && metaData.Title ? metaData.Title : "Bookmarks"}</title>
                <meta name="description" content={metaData && metaData.Description ? metaData.Description : ""} />
                <meta name="keyword" content={metaData ? metaData.Keywords : ""} />
            </Helmet>
            {auth.user === null ? (<Redirect to="/signin" />) : null}
            <Row className="no-gutters">
                <Col md="3"></Col>
                <Col md="6">
                    <BookmarksCard
                        bookmarkList={bookmarksList.list}
                        totalBookmark={bookmarksList.totalCount}
                        voteChange={(answerId, type) => {
                            var arr = [...bookmarksList.list];
                            var newArr = arr.filter(ele => ele.AnswerID && (ele.AnswerID._id == answerId));
                            if (newArr.length > 0) {
                                var upV = parseInt(newArr[0].AnswerID.UpvoteCount) + (type == '1' ? 1 : -1);
                                var dwV = parseInt(newArr[0].AnswerID.DownvoteCount) + (type == '2' ? 1 : -1);
                                var perce = ((upV / (upV + dwV)) * 100);
                                perce = (Math.round(isNaN(perce) ? 0 : perce)) + "%";
                                arr.map(ele => {
                                    if (ele.AnswerID && (ele.AnswerID._id == answerId)) {
                                        ele.percent = perce;
                                        ele.AnswerID.UpvoteCount = upV;
                                        ele.AnswerID.DownvoteCount = dwV;
                                    }
                                })
                                setBookmarksList({ ...bookmarksList, list: arr });
                            }
                        }}
                        removeBookmark={(id) => {
                            // console.log('delete id', id)
                            var arr = [...bookmarksList.list];
                            var newArr = arr.filter(ele => ele._id != id);
                            //ele.BlockedUser && ele.BlockedUser._id
                            setBookmarksList({ ...bookmarksList, totalCount: bookmarksList.totalCount - 1, list: newArr });
                        }}
                    />
                    <div className="mx-3 my-3">
                        {/* {console.log('bookmarksList.totalCount', bookmarksList.totalCount)} */}
                        {/* {console.log('bookmarksList.list.length', bookmarksList.list.length)} */}
                        {loading ?
                            <Spinner animation="border" variant="primary" />
                            : bookmarksList.totalCount && bookmarksList.totalCount > bookmarksList.list.length ?
                                <Button size="sm" onClick={() => { setBookmarksList({ ...bookmarksList, page: bookmarksList.page + 1 }) }}>see more</Button>
                                : null}
                    </div>
                </Col>
                <Col md="3"></Col>
            </Row>
        </div>
    )
}
export default Bookmarks;