import { useState } from 'react';

import Card from 'react-bootstrap/Card';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Image from 'react-bootstrap/Image';
import Spinner from 'react-bootstrap/Spinner';

import { Images } from '../config';
import PropTypes from "prop-types";
import { BaseColor } from '../config/index';
import { Link } from 'react-router-dom';
import moment from 'moment';
import SweetAlert from 'react-bootstrap-sweetalert';
import { httpPost } from '../Services/HttpServices';
import { toast } from 'react-toastify';

function BookmarksCard(props) {
    const [updating, setUpdating] = useState(null);

    function handleUpvote(e) {
        var upVoteObj = {
            QuestionID: e.AnswerID.QuestionID._id,
            AnswerID: e.AnswerID._id,
            upVote: 1
        }

        setUpdating(e);
        httpPost("answer_votes_controller/create", upVoteObj)
            .then(res => {
                setUpdating(false);
                if (res.status) {
                    props.voteChange(e.AnswerID._id,'1');
                    toast.success(res.message);
                } else {
                    toast.error(res.message)
                }
            })
            .catch(err => {
                setUpdating(false);
                console.log('errr', err)
            })
    }

    function handleDownvote(e) {
        var downVoteObj = {
            QuestionID: e.AnswerID.QuestionID._id,
            AnswerID: e.AnswerID._id,
            downVote: 1
        }

        setUpdating(e);
        httpPost("answer_votes_controller/create", downVoteObj)
            .then(res => {
                setUpdating(false);
                // console.log("res", res);
                if (res.status) {
                    props.voteChange(e.AnswerID._id, '2');
                    toast.success(res.message);
                } else {
                    toast.error(res.message)
                }
            })
            .catch(err => {
                setUpdating(false);
                console.log('errr', err)
            })
    }

    function removeBookMark(e) {
        console.log(e, "BookMark")
        httpPost("bookmark_controller/delete", { BookmarkId: e._id })
            .then(res => {
                setUpdating(false);
                // console.log("res", res);
                if (res.status) {
                    props.removeBookmark(e._id);
                    toast.success(res.message);
                } else {
                    toast.error(res.message)
                }
            })
            .catch(err => {
                setUpdating(false);
                console.log('errr', err)
            })
    }
    return (
        <>
            <Card style={{ width: '100%' }} className="my-3 px-3 " >
                <Card.Title className="ml-4 mt-3">Bookmarks</Card.Title>
                <Card.Subtitle className="ml-4 text-muted">{props.totalBookmark} records found</Card.Subtitle>
            </Card>
            {props.bookmarkList.map((question, index) => (
                <div className="mb-3 p-2 pm-4 p-lg-4" style={{ width: "100%", backgroundColor: BaseColor.whiteColor, borderWidth: 'thin', borderRadius: 5, borderRadius: 5, boxShadow: "0px 22px 100px rgba(0, 0, 0, 0.15)" }}>
                    <div style={{ display: 'flex' }}>
                        <div style={{ flex: '0.7 1 0%' }} className="pr-2 pr-md-0 pr-lg-0 pr-sm-2">
                            <Link to={"/profile?id=" + (question.UserID && question.UserID.UserName)}>
                                <Image src={question.UserID && question.UserID.ProfilePicture ? question.UserID.ProfilePicture : Images.defaultProfile} width={40} height={40} roundedCircle />
                            </Link>
                            {/* {question.percent+'ass'} */}
                            <div style={{ flex: '1' }} className="pr-2 pr-md-3 pr-lg-3 pr-sm-2 mt-4">
                                <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                                    <a className="upvote" onClick={e => handleUpvote(question)} ><Image src={Images.up} height={20} width={30} /></a>
                                    <span className="" style={{ fontSize: 12 }}>{updating == question._id ? <Spinner animation="grow" variant="primary" size="sm" /> : (question.percent)}</span>
                                    <a className="downvote" onClick={e => handleDownvote(question)} ><Image src={Images.down} height={20} width={30} /></a>
                                </div>
                            </div>
                        </div>
                        <div style={{ flex: '9', display: 'flex', flexDirection: 'column' }}>
                            <Link className="reset" to={"/profile/" + (question.UserID && question.UserID.UserName)}>
                                <span className="capitalize" style={{}}>{question.UserID ? (question.UserID.FirstName + ' ' + question.UserID.LastName) : null}</span>
                                <small className="float-right" >{"posted " + question.CreatedDate ? moment(question.CreatedDate).fromNow() : null}</small>
                            </Link>
                            <br />
                            <Link className="reset question-link" to={"/question/" + (question.AnswerID.QuestionID?.QuestionUrl)}><h5 dangerouslySetInnerHTML={{ __html: question.AnswerID.QuestionID?.Subject && question.AnswerID.QuestionID?.Subject.length > 140 ? question.AnswerID.QuestionID?.Subject.substring(0, 140) + '...' : question.AnswerID.QuestionID?.Subject }} ></h5></Link>
                            <div className="qna-html-content" dangerouslySetInnerHTML={{ __html: question.AnswerID.Answer }} ></div>
                            <div className="sub-links" >
                                <label className="for-dropdown mb-0">
                                    <DropdownButton className="menu-three-dots-button" id="dropdown-item-button" title={<Image src={Images.dotIcon} />}>
                                        <Dropdown.Item as="button" onClick={() => { removeBookMark(question) }}>Remove</Dropdown.Item>
                                    </DropdownButton>
                                </label>
                            </div>
                        </div>
                    </div>
                </div >
            ))}
        </>
    )
}

BookmarksCard.prototype = {
    bookmarkList: PropTypes.array,
    totalBookmark: PropTypes.string,
    updateBookMark: PropTypes.func,
    voteChange: PropTypes.func,
    removeBookMark: PropTypes.func
}

BookmarksCard.defaultProps = {
    bookmarkList: [],
    totalBookmark: '0',
    updateBookMark: () => { },
    voteChange: () => { },
    removeBookMark: () => { },
}
export default BookmarksCard;