import { Images } from '../config';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import FormControl from 'react-bootstrap/FormControl';
import Image from 'react-bootstrap/Image';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';

import Banner from '../Component/Banner';
import QuestionCard from '../Component/QuestionCard';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { httpGet, httpPost } from '../Services/HttpServices';
// import { postUser } from '../AppRedux/Actions/Auth';
import moment from 'moment';
import 'moment-timezone';
import { toast } from 'react-toastify';
import Helmet from 'react-helmet';
import { bounceIn, bounceInLeft } from 'react-animations';
import Radium, {StyleRoot} from 'radium';


function Home(props) {
	const { auth } = useSelector(state => state);
	const input = useRef(null);
    const timeZone  = Intl.DateTimeFormat().resolvedOptions().timeZone;
	let dispatch = useDispatch();
	const [disabled, setDisabled] = useState(true);
	const [UserCount, setUserCount] = useState(0);
	const [productionDate, setproductionDate] = useState("");
	const [Email, setEmail] = useState("");
	const [topics, setTopics] = useState([]);
	const [topicQuestions, setTopicQuestions] = useState([]);
	const [metaData, setMetaData] = useState([]);
	const [featuredUsers, setFeaturedUsers] = useState([]);
	const state = useSelector(state => state)
	const styles = {
	  bounceIn: {
	    animation: 'x 1s',
	    animationName: Radium.keyframes(bounceIn, 'bounceIn')
	  },
	  bounceInLeft: {
	    animation: 'x 1s',
	    animationName: Radium.keyframes(bounceInLeft, 'bounceInLeft')
	  }
	}
	
	// Comment For Load testing
	useEffect(() => {
		getTopicList();
		httpGet("about_us")
			.then(res => {
				if (res && res.status) {
					var productionDate = res.impactData.productionDate.replace(/[ ,.]/g, ",").split(",")[2]
					var now = moment().format("y")
					setUserCount(res.impactData.UserCount)
					setproductionDate(now - productionDate)
				}
			})
		httpPost("home_page/get_meta_tag",{PageName: "Home Page"})
			.then(res => {
				if (res && res.status) {
					setMetaData(res.data);
				}
				// setFeaturedUsers((res.featuredUsers.sort(() => Math.random() - 0.5)));
				// setTopicQuestions(res.latestQuestions || [])
			})
		httpPost("home_page/search_questions",{  page: 0, sort: {NumberOfQuestionsAsked: 1, NumberOfAnswer: 1}, type: 'users' })
			.then(res => {
				if (res && res.status) {
					setFeaturedUsers((res.data.sort(() => Math.random() - 0.5)));
				}
			})
	}, [])

	// Comment For Load Testing
	useEffect(() => {
		if (topics && topics.length > 0) {
			var fltr = topics.filter(ele => ele.active == 1);
			if (fltr.length > 0)
				getTopicQuestions(fltr[0])
		}
	}, [topics])

	const getTopicQuestions = (topic) => {
		httpPost("home_page/questions", {
			filter: { "TopicID": [topic._id] }
		})
		.then(res => {
			if (res && res.data) {
				var flt = res.data.filter((ele, index) => index < 4);
				if (flt.length > 0) {
					flt[0].active = 1
				}
				setTopicQuestions(flt || [])
			} else {
				setTopicQuestions([]);
			}
		})
	}
	const getTopicList = () => {
		httpPost("topic_controller/list", {Trending: 1, IsPrivate: 0, IsParent: 1, limit: 4, offset: 1})
			.then(res => {
				if (res && res.data) {
					var flt = res.data.filter((ele, index) => index < 5);
					if (flt.length > 0) {
						flt[0].active = 1
					}
					setTopics(flt || [])
				}
			})
	}

	function emailValidation() {
    	const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    	return !(!Email || regex.test(Email) === false);
  	}

	function handleEmailChange(e) {
		setEmail(e.target.value)
		const isValidEmail = emailValidation();
	    if(isValidEmail && disabled){
	    	setDisabled(false)
	    }
	}
	const handleSubscribe = () => {
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (re.test(String(Email).toLowerCase())) {
			setDisabled(true)
			httpPost('subscribe_user_controller/create', { Email })
				.then(res => {
					if (res.status == 1){
						toast.success(res.message)
						input.current.value = "";						
					}
					else{
						toast.error(res.message)	
					} 
				})
		} else {
			toast.error("Enter valid email")
		}
	}

	// useEffect(() => {
	// 	console.log(state);
	// }, [state])

	return (
		<div className="home" >
			<Helmet>
                <title>{metaData && metaData.Title ? metaData.Title : "Learn English Online | English Forward"}</title>
                <meta name="description" content={metaData && metaData.Description ? metaData.Description : "English Forward is the web's Largest 'Learn English' Community and Q&A Site. Gain answers and share insights in order to grow today."} />
            </Helmet>
            <div className="section center-content banner-section" >
				<Banner {...props} />
			</div>
			<Container>
				{/*<Row className="section center-content" >
					<Col md="6" >
						<Banner {...props} />
					</Col>
					<Col md="6" className="">
						<Image src={Images.Group} className="section-img" />
					</Col>
				</Row>
				<Row className="section " >
					<Col md='12' className="text-content">
						<img src={Images.arrowDown} />
						<p className="bottom-text">scroll more</p>
					</Col>
				</Row>*/}
				<div className="text-center latest-question" >
					<Row>
						<Col md="12" className="offset-md-0" >
							<h3>Latest Questions{/*<span className="heading-underline" ></span>*/}</h3>
						</Col>
					</Row>
					<Row className="horiZontalScroller flex-row flex-nowrap" >
						{topics && topics.map(ele => (
							<Col className="mt-4 mb-3" >
								<Button className={ele.active ? "btn-rounded px-4" : "bg-transparent text-dark"}
									style={{ border: "none", fontSize: 15, boxShadow: "inherit", borderRadius: "3000px" }}
									onClick={(e) => {
										e.target.blur()
										var arr = [...topics];
										arr.map(map => {
											if (map._id == ele._id) {
												map.active = true
											} else {
												map.active = false
											}
										})
										setTopics(arr);
									}}
								>{ele.Title}</Button>
							</Col>
						))}
					</Row>
				</div>
				{topicQuestions && topicQuestions.length > 0 ?
					<>
						<Row>
							<Col md="12" className="offset-md-0" >
								<QuestionCard questions={topicQuestions} />
							</Col>
						</Row>
						<div className="section" >
							<Col md="12" className="text-center" >
								<Button className="btn-rounded check-more" style={{borderRadius: "3000px"}} onClick={() => props.history.push('/questionlist')} >check more <Image src={Images.arrowRight} /></Button>
							</Col>
						</div>
					</>
					: <div className="text-center">No Questions</div>}
				<Row className="section" >
					<Col md="6" >
						<Image src={Images.EFUnique} className="section-img" />
					</Col>
					<Col md="6" className="content" >
						<div className="mt-3" ></div>
						<h3>Here is why English Forward is unique</h3>
						<p>
							Connect with the world's top English experts​ and meet other learners​​ on the web's largest "Learn English" community and Q&A website. It’s your space to learn and practice your English.
	        			</p>
					</Col>
				</Row>
				<Row className="section" >
					<Col md="6" className="content" >
						<h3>The real benefits for the students</h3>
						<p>
							Students are able to access over 2 million Questions and Answers that have been helpful to others learning English, in an environment that is non threatening and encouraging. This is your fast track to English mastery.
	        			</p>
					</Col>
					<Col md="6" >
						<Image src={Images.RealBenefit} className="section-img" />
					</Col>
				</Row>
				<Row className="section feature" >
					<Col md="8" className="text-center offset-md-2" >
						<h3>Our Mission{/*<span className="heading-underline" ></span>*/}</h3>
						<p>
							Our mission is to help 1 Billion people learn English over the next 10 years, enabling them with the most powerful communication tool on the planet, the English language.
	        			</p>
					</Col>
					<Col md="12" className="text-center" >
						<Image className="section-img" src={Images.aboutus3} />
					</Col>
				</Row>
				<Row className="section stat" >
					<Col lg="3" md="6" className="text-center" >
						<StyleRoot>
							<h3 style={styles.bounceIn} >13K</h3>
						</StyleRoot>
						<p>
							Teachers
	        			</p>
						<span className="" ></span>
					</Col>
					<Col lg="3" md="6" className="text-center" >
						<StyleRoot>
							<h3 style={styles.bounceIn} >310M</h3>
						</StyleRoot>
						<p>
							Users
	        			</p>
						<span className="" ></span>
					</Col>
					<Col lg="3" md="6" className="text-center" >
						<StyleRoot>
							<h3 style={styles.bounceIn} >500K</h3>
						</StyleRoot>
						<p>
							Questions
	        			</p>
						<span className="" ></span>
					</Col>
					<Col lg="3" md="6" className="text-center" >
						<StyleRoot>
							<h3 style={styles.bounceIn} >2M</h3>
						</StyleRoot>
						<p>
							Answers
	        			</p>
					</Col>
				</Row>
				<Row className="section feature ">
					<Col md="8" className="text-center offset-md-2"  >
						<h3>Our Featured Users{/*<span className="heading-underline" ></span>*/}</h3>
						<p>
							Meet some of our highly qualified moderators who have been helping students learn English on this platform for the past two decades
	        			</p>
					</Col>

					<Col md="12" className="text-center d-none d-sm-none d-md-none d-lg-block" >
						<Image className="img-responsive" src={Images.FeatureGlobe} />
						<Row className="">
							<StyleRoot>
							<Col className="md-3" style={styles.bounceIn}>
								<div style={{ position: "relative", bottom: 294, left: 146, width: 250, background: "white", borderRadius: 20, boxShadow: "0px 22px 100px rgba(0, 0, 0, 0.15)" }} >
									<Row className="no-gutters">
										<Col md="4" className="mt-1" >
											<Image style={{ width: 50, paddingTop: 8, paddingBottom: 8, position: "relative" }} src={featuredUsers[5]?.ProfilePicture || Images.defaultProfile} />
										</Col>
										<Col md="8" className="mt-2" >
											<Row style={{ marginLeft: -8 }}>
												<p style={{ fontWeight: 600, fontSize: 10, marginBottom: 0, paddingTop: '8px', minHeight: '20px' }}><strong>{featuredUsers[5]?.FirstName} {featuredUsers[5]?.LastName}</strong></p>
											</Row>
											<Row style={{ marginLeft: -8 }}>
												<p style={{ fontSize: 8, padding: 0, margin: "12px 0 0 0", position: "relative", bottom: 13 }}>Posts: {featuredUsers[5]?.NumberOfQuestionsAsked}</p>
											</Row>
										</Col>
									</Row>
									<div className="right-icon" >
										<Image src={Images.follow} />
									</div>
								</div>
							</Col>
							</StyleRoot>
							<Col className="md-3">
								<div style={{ position: "relative", bottom: 150, left: 50, width: 250, background: "white", borderRadius: 20, boxShadow: "0px 22px 100px rgba(0, 0, 0, 0.15)" }}>
									<Row className="no-gutters">
										<Col md="4" className="mt-1" >
											<Image style={{ width: 50, paddingTop: 8, paddingBottom: 8, position: "relative" }} src={featuredUsers[0]?.ProfilePicture || Images.defaultProfile} />
										</Col>
										<Col md="8" className="mt-2">
											<Row style={{ marginLeft: -8 }}>
												<p style={{ fontWeight: 600, fontSize: 10, marginBottom: 0, paddingTop: '8px', minHeight: '20px' }}><strong>{featuredUsers[0]?.FirstName} {featuredUsers[0]?.LastName}</strong></p>
											</Row>
											<Row style={{ marginLeft: -8 }}>
												<p style={{ fontSize: 8, padding: 0, margin: "12px 0 0 0", position: "relative", bottom: 13 }}>Posts: {featuredUsers[0]?.NumberOfQuestionsAsked}</p>
											</Row>
										</Col>
									</Row>
									<div className="right-icon" >
										<Image src={Images.follow} />
									</div>
								</div>
							</Col>
							<Col className="md-3">
								<div style={{ position: "relative", bottom: 250, left: 80, width: 250, background: "white", borderRadius: 20, boxShadow: "0px 22px 100px rgba(0, 0, 0, 0.15)" }}>
									<Row className="no-gutters">
										<Col md="4" className="mt-1" >
											<Image style={{ width: 50, paddingTop: 8, paddingBottom: 8, position: "relative" }} src={featuredUsers[1]?.ProfilePicture || Images.defaultProfile} />
										</Col>
										<Col md="8" className="mt-2">
											<Row style={{ marginLeft: -8 }}>
												<p style={{ fontWeight: 600, fontSize: 10, marginBottom: 0, paddingTop: '8px', minHeight: '20px' }}><strong>{featuredUsers[1]?.FirstName} {featuredUsers[1]?.LastName}</strong></p>
											</Row>
											<Row style={{ marginLeft: -8 }}>
												<p style={{ fontSize: 8, padding: 0, margin: "12px 0 0 0", position: "relative", bottom: 13 }}>Posts: {featuredUsers[1]?.NumberOfQuestionsAsked}</p>
											</Row>
										</Col>
									</Row>
									<div className="right-icon" >
										<Image src={Images.follow} />
									</div>
								</div>
							</Col>
							<Col className="md-3">
								<div style={{ position: "relative", bottom: 360, right: 50, width: 250, background: "white", borderRadius: 20, boxShadow: "0px 22px 100px rgba(0, 0, 0, 0.15)" }}>
									<Row className="no-gutters">
										<Col md="4" className="mt-1" >
											<Image style={{ width: 50, paddingTop: 8, paddingBottom: 8, position: "relative" }} src={featuredUsers[2]?.ProfilePicture || Images.defaultProfile} />
										</Col>
										<Col md="8" className="mt-2">
											<Row style={{ marginLeft: -8 }}>
												<p style={{ fontWeight: 600, fontSize: 10, marginBottom: 0, paddingTop: '8px', minHeight: '20px' }}><strong>{featuredUsers[2]?.FirstName} {featuredUsers[2]?.LastName}</strong></p>
											</Row>
											<Row style={{ marginLeft: -8 }}>
												<p style={{ fontSize: 8, padding: 0, margin: "12px 0 0 0", position: "relative", bottom: 13 }}>Posts: {featuredUsers[2]?.NumberOfQuestionsAsked}</p>
											</Row>
										</Col>
									</Row>
									<div className="right-icon" >
										<Image src={Images.follow} />
									</div>
								</div>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row className="section center-content" >
					<Col md="6" className="content" >
						<h3 className="heading-text">Subscribe to our newsletter and get the latest updates sent directly to you 😁 </h3>
						<p className="detail-medium">
							The best part of learning English is realising the power which now lies in your hands. New friends, new business associates, incredible opportunities, access to information. Start now.
	        			</p>
						<div className="input-button mt-5">
							<span className="content-text"><input type="email" placeholder="Enter email address" ref={input} className="aboutusinputbox" onChange={handleEmailChange}></input></span>
							{/*disabled={disabled}*/}
							<Button className="btn content-button" onClick={handleSubscribe} >Subscribe </Button>
						</div>
					</Col>
					<Col md="6" className="webkit-center aboutuswebkit">
						<div className='spear'>
							<div className="left-top">
								<div className="icon" ><Image src={Images.idea} /></div>
								<p className="detail">New words & ideas every week</p>
							</div>
							<div className="left-bottom">
								<div className="icon" ><Image src={Images.volume} /></div>
								<p className="detail">Daily News to keep you updated</p>
							</div>
							<div className="right-top">
								<div className="icon" ><Image src={Images.user} /></div>
								<p className="detail">Suggested profiles & questions</p>
							</div>
							<div className="right-bottom">
								<div className="icon" ><Image src={Images.writing} /></div>
								<p className="detail">New Blogs every day to keep you on track</p>
							</div>
						</div>
					</Col>

				</Row>
				<Row className="section feature" style={{ marginTop: 100 }}>
					<Col md="3">
					</Col>
					<Col md="6" className="text-center" >
						<h3>A community for everyone</h3>
					</Col>
					<Col md="3">
					</Col>
				</Row>
				<Row className="section" >
					<Col lg="3" md="6" className="text-center" style={styles.bounceInLeft} >
						<div className="smallTile" >
							<div className="icon" ><Image src={Images.meet} /></div>
							<p>Travel and meet new people</p>
						</div>
					</Col>
					<Col lg="3" md="6" className="text-center" >
						<div className="smallTile" >
							<div className="icon" ><Image src={Images.writing} /></div>
							<p>Writing</p>
						</div>
					</Col>
					<Col lg="3" md="6" className="text-center" >
						<div className="smallTile" >
							<div className="icon" ><Image src={Images.volume} /></div>
							<p>Business Communication</p>
						</div>
					</Col>
					<Col lg="3" md="6" className="text-center" >
						<div className="smallTile" >
							<div className="icon" ><Image src={Images.mic} /></div>
							<p>Public Speaking</p>
						</div>
					</Col>
				</Row>
				<Row className="section user-helped pb-5" >
					<Col md="6" className="text-left" >
						<h3>310+ Million users assisted</h3>
						<p>in the last 19 years</p>
						{/*<h3>{UserCount} users helped</h3>
						<p>in the last {productionDate} years</p>*/}
					</Col>
					<Col md="6" className="text-right" >
						<Button className="ask-question" variant="primary" style={{boxShadow: 'inherit'}} onClick={() => {
                    if (auth && auth.user) {
                        props.history.push('/questionCreate');
                    } else {
                        props.history.push('/signin?ref=/questionCreate');
                    } } } >ask a question</Button>
					</Col>
				</Row>
			</Container>
		</div>
	)
}
export default Home;