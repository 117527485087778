import React from 'react'
import { useSelector } from 'react-redux';
import "./StartChat.css"
import { Images } from "../../../../config";
import {db} from "../../firebase_config"

const StartChat = (props) => {
    const auth = useSelector(({ auth }) => auth);
    if(props.currentUserInfo.length === 0){
        props.currentUserInfo.push({
            firstName: auth.user.FirstName,
            lastName: auth.user.LastName,
            profilePic: auth.user.ProfilePicture
        })
    }
    return (
        <div id="start-chat" >
            <div className="start-chat-container" >
            	<h2>{ props.currentUserInfo[0].firstName + " " +props.currentUserInfo[0].lastName }</h2>
                <img alt="" src={ props.currentUserInfo[0].profilePic ||  Images.defaultProfile } />
                <p>Search for someone to start a conversation. <br/> Click , write, send messages and make new friends</p>
            </div>
        </div>
    )
}

export default StartChat
