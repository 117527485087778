import React, { Component } from 'react';
import { Button, Col, Container, FormControl, Image, InputGroup, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Images } from '../config';
import { httpPost } from '../Services/HttpServices';
import { toast } from 'react-toastify';

class Signup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			type: "password",
			newtype: "password",
			visibility: "hidden",
			newpassword: "",
			repeatpassword: "",
			colorname: "",
			textmsg: "",
			userID: ""
		}
		this.handleSubmit = this.handleSubmit.bind(this);
	}
	componentDidMount() {
		console.log("props", this.props.location.state.userID);
		this.setState({ userID: this.props.location.state.userID })
	}
	handleHideShowRepeatPassword = () => {
		this.setState({ newtype: this.state.newtype === 'text' ? 'password' : 'text' })
	}
	handleHideShowPassword = () => {
		this.setState({ type: this.state.type === 'text' ? 'password' : 'text' })
	}
	handleNewPassword = (e) => {
		this.setState({
			newpassword: e.target.value
		});
	}
	handleRepeatPassword = (e) => {
		if (e.target.value == "") {
			var colorname = ""
			var textmsg = ""
		} else {
			if (e.target.value == this.state.newpassword) {
				var colorname = "#009900"
				var textmsg = "Password matched"
			} else {
				var colorname = "#FF5151"
				var textmsg = "Password did not match"
			}
		}
		this.setState({
			visibility: "visible", repeatpassword: e.target.value, colorname, textmsg
		});
	}
	handleSubmit = (e) => {
		e.preventDefault();
		var passObj = { UserID: this.state.userID, UpdateType: 3, NewPassword: this.state.newpassword, RepeatPassword: this.state.repeatpassword }
		console.log("e", passObj);
		httpPost('user_controller/update_password', passObj)
			.then(res => {
				if (res.status == 1) {
					toast.success(res.message, {
						onClose: ({ uid }) => this.props.history.push("/signin")
					});
				} else {
					toast.error(res.message);
				}
			})
	}
	render() {
		return (
			<div className="login-page" >
				<Container fluid>
					<Row>
						<Col md="3" className="p-5"></Col>
						<Col md="6" className="pt-4 pl-5 pr-5 pb-5 mb-4">
							<h2>Reset Password</h2>
							{/* <p>Reset password 😁 👍</p> */}
							<Form onSubmit={this.handleSubmit}>
								<div className="box" >
									<InputGroup >
										<FormControl type={this.state.type} placeholder="Enter new password" name="new_password" onChange={e => this.handleNewPassword(e)} />
										<div>
											<Image className="img-responsive" src={Images.lockIcon} onClick={this.handleHideShowPassword} />
										</div>
									</InputGroup>
								</div>
								<div className="box" >
									<InputGroup >
										<FormControl type={this.state.newtype} placeholder="Enter password again" name="repeat_password" onChange={e => this.handleRepeatPassword(e)} />
										<div>
											<Image className="img-responsive" src={Images.lockIcon} onClick={this.handleHideShowRepeatPassword} />
										</div>
									</InputGroup>
								</div>
								<p style={{ visibility: this.state.visibility, color: this.state.colorname, fontSize: 15, paddingLeft: 25, marginTop: -25 }}>{this.state.textmsg}</p>
								<div>
									<Button type="submit" className="btn-lg btn-block btn-rounded" variant="outline-primary" >Reset Password<Image src={Images.arrowRight} /></Button>
								</div>
							</Form>
						</Col>
						<Col md="3" className="p-5" >
						</Col>
					</Row>
				</Container>

			</div>
		)
	}
}

export default Signup;