import { Link } from 'react-router-dom';
import { BaseColor, Images } from "../config";
import { Row, Card, Col, Container, Form, Image, Spinner, Button, ProgressBar } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faTh } from '@fortawesome/free-solid-svg-icons';
import moment from "moment";

import PropTypes from "prop-types";

function TopicCard(props) {
    let { topic } = props;
    return (
        <div className="topic-card" >
            <h3><FontAwesomeIcon icon={faLock} size="sm" /> &nbsp; <Link to={'/topics/' + topic.Slug} >{topic.Title}</Link></h3>
            <div>{topic.Description}</div>
            <div className="question" >
                {topic.LatestQuestion ? (<span><Link to={'/question/'+topic.LatestQuestion.QuestionUrl} ><div dangerouslySetInnerHTML={{__html: topic.LatestQuestion.Subject && topic.LatestQuestion.Subject.length > 100 ? topic.LatestQuestion.Subject.substring(0, 100) : null }} >{}</div></Link> <span className="class-date" >{moment(Date(topic.LatestQuestion.CreatedDate)).fromNow()}</span></span>) : null}
            </div>
        </div>
    )
}

TopicCard.prototype = {
    tag: PropTypes.object,
    onTagClick: PropTypes.func,
}

TopicCard.defaultProps = {
    tag: {},
    onTagClick: () => { },
}
export default TopicCard;