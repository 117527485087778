import { useState } from "react";
import { Link } from "react-router-dom";
import { BaseColor, Images } from "../config";

import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';

import PropTypes from "prop-types";
import { propTypes } from "react-bootstrap/esm/Image";
import { faSleigh } from "@fortawesome/free-solid-svg-icons";

function Collapse(props) {
    const [active, setActive] = useState(props.defaultOpen);

    return (
        <>
            <Row className="mb-2">
                <Col className="custom-collpase" md="12" >
                    <div className="col-heading pb-2" style={{ display: 'flex',alignItems:'center' }} onClick={() => {
                        setActive(!active);
                    }}>
                        <div style={{ flex: 9 }}>
                            {props.heading}
                        </div>
                        <div style={{ flex: 1,textAlign:'end' }}>
                            <Image  src={active ? Images.collapseOpen : Images.collapseClose} />
                        </div>
                    </div>
                </Col>
                {active ? 
                <Col className={"mt-2"}>
                    {props.children}
                </Col> : null}

            </Row>
        </>
    )
}

Collapse.prototype = {
    heading: PropTypes.element || PropTypes.string,
    defaultOpen: PropTypes.bool,
}

Collapse.defaultProps = {
    heading: <></>,
    defaultOpen: false,
}
export default Collapse;