import React, { Component } from 'react';
import { Button, Col, Container, FormControl, Image, InputGroup, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';

import { postUser } from '../AppRedux/Actions/Auth';
import { addUserSuccess } from '../AppRedux/Actions/Auth';
import { Images } from '../config';
import { httpPost } from '../Services/HttpServices';

class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			type: "Password",
			data: {
				UserName: null,
				Password: null
			},
			formErr: {
				UserName: null,
				Password: null,
			},
			ref: new URLSearchParams(props.location.search).get('ref')
		}
		this.handleSubmit = this.handleSubmit.bind(this);
	}
	handlePassword = (e) => {
		e.preventDefault();
		this.setState({
			type: this.state.type === 'text' ? 'Password' : 'text'
		});
	}
	handleSubmit = (e) => {
		e.preventDefault();
		if (this.validateForm()) {
			this.props.postUser(this.state.data);
		}
	}

	validateForm = () => {
		let { data } = this.state;
		this.setError();
		return data.UserName && data.Password;
	}

	setError = () => {
		let { data } = this.state;
		this.setState({
			...this.state,
			formErr: {
				...this.state.formErr,
				UserName: data.UserName ? null : "Enter User Name",
				Password: data.Password ? null : "Enter Password"
			}
		})
	}

	componentDidMount() {
		if(this.props.auth.signUp === true){
			this.props.auth.signUp = false;
			let ref = this.state.ref ? "?ref="+this.state.ref : "";
			this.props.history.push('/signup'+ref);
		}else{
			if (this.props.auth && this.props.auth.user) {
				if(this.props.auth.user.TopicID && this.props.auth.user.TopicID.length > 0){
					if(this.state.ref){
						this.props.history.push(this.state.ref);
					}else{
						this.props.history.push('/feed');
					}
				}
				else
					this.props.history.push('/select-topic');
			}
		}
	}

	componentDidUpdate() {
		if(this.props.auth.signUp === true){
			this.props.auth.signUp = false;
			let ref = this.state.ref ? "?ref="+this.state.ref : "";
			this.props.history.push('/signup'+ref);
		}else{
			if (this.props.auth && this.props.auth.user) {
				if(this.props.auth.user.TopicID && this.props.auth.user.TopicID.length > 0)
					if(this.state.ref){
						this.props.history.push(this.state.ref);
					}else{
						this.props.history.push('/feed');
					}
				else
					this.props.history.push('/select-topic');
			}
		}
	}

	responseFacebook = (res) => {
		if (res && res.email) {
			httpPost('user_controller/check_social', {
				SocialID: res.id,
				Email: res.email,
				SocialToken: res.accessToken,
				Type: "2",
			}).then(apiRes => {
				if (apiRes) {
					if (apiRes.status) {
						this.props.addUserSuccess(apiRes.data);
					} else {
						var nameArr = res.name.split(' ');
						this.props.history.push('signup', {
							FirstName: nameArr[0] && nameArr[0] || null,
							LastName: nameArr[1] && nameArr[1] || null,
							Email: res.email,
							SocialToken: res.accessToken,
							Type: "2",
							FacebookID: res.id
						})
					}
				} else {
					toast.error('Failed to login with Google');
				}
			})
		} else {
			toast.error('Please allow English forward to use your email address');
		}
	}
	responseGoogle = (res) => {
		console.log('res', res);
		if (res && res.profileObj && res.profileObj.email) {
			httpPost('user_controller/check_social', {
				SocialID: res.profileObj.googleId,
				Email: res.profileObj.email,
				SocialToken: res.tokenId,
				Type: "1"
			}).then(apiRes => {
				if (apiRes) {
					if (apiRes.status) {
						this.props.addUserSuccess(apiRes.data);
					} else {
						this.props.history.push('signup', {
							FirstName: res.profileObj.givenName,
							LastName: res.profileObj.familyName,
							Email: res.profileObj.email,
							SocialToken: res.tokenId,
							Type: "1",
							GoogleID: res.profileObj.googleId
						})
					}
				} else {
					toast.error('Failed to login with Google');
				}
			})
		} else {
			toast.error('Please allow English forward to use your email address');
		}
	}
	onGoogleFail = (err) => {
		console.log('err', err);
		//toast.error('Failed to login');
	}

	render() {
		return (
			<div className="login-page" >
	            <Helmet>
	                <title>{"Login"}</title>
	            </Helmet>
				<Container>
					<Row>
						<Col md="7" className="p-5">
							<h2>The Best Platform to <b>Learn English</b> and <b>Make Money</b> in the process.</h2>
							<p>Sign in and connect to a community of passionate people - just like you! 😁 👍</p>
							<Form onSubmit={this.handleSubmit}>
								<div className="box l-input" >
									<FormControl value={this.state.data.UserName} onChange={(e) => {
										this.setState((oldState) => {
											return {
												...this.state,
												data: { ...this.state.data, UserName: e && e.target && e.target.value || null }
											}
										})
									}} placeholder="Enter your email address" name="email" />
								</div>
								{this.state.formErr.UserName ? <p className="error err-login">{this.state.formErr.UserName}</p> : null}
								<div className="box l-input" >
									<InputGroup >
										<FormControl value={this.state.data.Password} onChange={(e) => {
											this.setState((oldState) => {
												return {
													...this.state,
													data: { ...this.state.data, Password: e && e.target && e.target.value || null }
												}
											})
										}} type={this.state.type} placeholder="Enter your Password" name="Password" />
										<div>
											<Image className="img-responsive" src={Images.lockIcon} onClick={this.handlePassword} />
										</div>
									</InputGroup>
								</div>
								{this.state.formErr.Password ? <p className="error err-login">{this.state.formErr.Password}</p> : null}
								<div className="fg-pass-text">
									<Link to={"/forgotpassword"}>
										Forgot Password
									</Link>
								</div>
								<div>
									<Button type="submit" className="btn-lg btn-block login" >Sign in <Image src={Images.arrowRight} /></Button>
								</div>
							</Form>
							<Row className="socialButton" >
								<Col md="6" sm="12" >
									<FacebookLogin
										appId="163597855587563"
										autoLoad={false}
										fields="name,email,picture"
										callback={(res) => { this.responseFacebook(res) }}
										textButton={<div><Image className="img-responsive" src={Images.signInWithFacebook} /></div>}
										cssClass={"btn-lg btn-block btn-fb"}
									// icon={<Image className="img-responsive" src={Images.signInWithGoogle} />}
									/>
									{/* <Button className="btn-lg btn-block btn-fb" >Sign in with <Image className="img-responsive" src={Images.fb} /></Button> */}
								</Col>
								<Col md="6" sm="12" >
									<GoogleLogin
										clientId="242651927357-fubf2rb1c4cr668mf9dh1dpj5boopibm.apps.googleusercontent.com"
										render={renderProps => (
											<Button onClick={renderProps.onClick} variant="" className="btn-lg btn-block btn-google" ><Image className="img-responsive" src={Images.signInWithGoogle} /></Button>
										)}
										//buttonText="Login"
										autoLoad={false}
										onSuccess={this.responseGoogle}
										onFailure={this.onGoogleFail}
										cookiePolicy={'single_host_origin'}
									/>
									{/* <Button className="btn-lg btn-block btn-google" >Sign in with <Image className="img-responsive" src={Images.google} /><Image className="img-responsive" src={Images.plus} /></Button> */}
								</Col>
							</Row>
							{/*<p><span style={{ color: '$color-primary' }} >Are you a teacher?</span> Help people and get paid</p>*/}
						</Col>
						<Col md="5" className="p-5 d-none d-sm-none d-md-block" >
							{/*<div className="text-right" >
								<p><span>Don’t have the account yet?</span></p>
								<Link to={"/signup"}>
									<Button className="btn-rounded-blue sign-up" >Sign Up now ✌</Button>
								</Link>
							</div>*/}
							<div style={{ backgroundImage: `url(${Images.LoginBg})`, backgroundSize: 'cover', width: '457px', height: '456px' }} >
								<Image className="img-responsive" width="300" style={{ marginLeft: 70, marginTop: 120 }} src={Images.loginRightImage} />
							</div>
							{/*<<div className="text-right" >
								<p><div>Need help? <div className="help-icon" > <span>?</span> </div></div> </p>
							</div>*/}
						</Col>
					</Row>
				</Container>

			</div>
		)
	}
}

const mapStateToProps = ({ auth }) => {
	return { auth };
}
export default connect(mapStateToProps, {
	postUser,
	addUserSuccess
})(Login)
//export default Login;