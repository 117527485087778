import {Link} from "react-router-dom";
import { BaseColor, Images } from "../config";

import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';

import PropTypes from "prop-types";

function Follow(props) {
    return (
        <div className="follow-list" style={{ width: "100%",padding: 20, borderWidth:'thin',borderRadius: 5, borderColor: BaseColor.grayColor }}>
            <h5>{props.heading}</h5>
            {props.list && props.list.map((ele, i) => (
                <div className="" >
                    <Row>
                        <Col md="2" >
                            <Image src={ele.image ? ele.image : null} width={50} height={50} roundedCircle />
                        </Col>
                        <Col md="8" >
                            <h2 style={{}}><b>{ele.name}</b></h2>
                            <span>{ele.follower} followers</span>
                            <span className="pull-right" >{ele.following} following</span>
                        </Col>
                        <Col md="2" >
                        <div className="smallTile" >
                            <div className="icon" style={{ textAlign: 'center' }} >
                                <Image src={Images.Follow} />
                            </div>
                        </div>
                        </Col>
                    </Row>
                    <hr />
                </div>
            )) || null}
            <div style={{textAlign:'end'}}>
                <label style={{color:BaseColor.secondaryColor}}><b>See More</b></label>
            </div>
        </div>
    )
}

Follow.prototype = {
    heading: PropTypes.string,
    list: PropTypes.array,
    link: PropTypes.string
}

Follow.defaultProps = {
    heading: "",
    list: [],
    link: null,
}
export default Follow;