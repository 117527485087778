import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import PropTypes from "prop-types";
import moment from 'moment';
import 'moment-timezone';
import { Images } from "../config";
import Voter from '../Component/Voter';
import { httpPost } from '../Services/HttpServices';
function VoteList(props) {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let { question } = props;
    var auth = useSelector(({ auth }) => auth);
    let profilePicture = auth.ProfilePicture ? auth.ProfilePicture : Images.defaultProfile;

    const [voteCount, setVoteCount] = useState(0);
    const [voteList, setVoteList] = useState([]);
    const [showPopUp, setShowPopUp] = useState(false);

    useEffect(() => {
        if (question && question.id) {
            getVoteList();
        }
    }, [props.question])

    const getVoteList = () => {
        var voteObj = {
            QuestionID: question.id,
        }
        httpPost('question_votes_controller/list', voteObj)
            .then(res => {
                if (res.status) {
                    setVoteCount(res.totalCount);
                    setVoteList(res.data);
                }
            })
            .catch(err => {
                console.log('errr', err)
            })
    }
    return (
        <>
            <Row>
                <Col md="12" >
                    <div style={{maxHeight: 300, overflowY: 'auto'}} >
                        {voteCount > 0 ? "Recent votes: " : "" }
                        {voteCount > 0 && voteList.slice(0, 3).map(voter => {
                            return <Image title={voter.UserID.FirstName + ' '+ voter.UserID.LastName} src={voter.UserID.ProfilePicture || "https://lt.englishforward.com/profile.png" } roundedCircle width="20" height="20" />
                        })}
                        &nbsp;&nbsp;{voteCount > 3 ? <Link to="#" onClick= {() => { setShowPopUp(true) }} >View + {voteCount-3} more </Link> : ""}
                        <Modal show={showPopUp} onHide={() => { setShowPopUp(false); }} size="md" >
                            <Modal.Header closeButton>
                                <Modal.Title as="h4" >Vote List</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                { voteList.map(voter => {
                                   return <Voter voter={voter} />
                                })}
                            </Modal.Body>
                        </Modal>
                    </div>
                </Col>
            </Row>
        </>
    )
}

VoteList.prototype = {
    question: PropTypes.object
}

VoteList.defaultProps = {
    question: {}
}
export default VoteList;