import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';

import { Images } from '../config';
import FollowersCard from '../Component/FollowersCard';
import { httpPost } from '../Services/HttpServices';


function FollowersList(props) {
    var auth = useSelector(({ auth }) => auth);
    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState(null);
    const [followersList, setFollowersList] = useState({
        page: 1,
        totalCount: 0,
        list: []
    })
    useEffect(() => {
        var search = new URLSearchParams(props.location.search);
        const id = search.get('id');
        if (id) {
            setUserId(id)
        } else {
            setUserId(auth && auth.user && auth.user._id);
        }

    }, [])
    useEffect(() => {
        if (userId) {
            setLoading(true);
            getList();
            // console.log('get list');
        }
    }, [followersList.page, userId])

    const getList = () => {
        // auth && auth.user && auth.user._id
        //601e656b458d741a53d7c5df
        httpPost('follow_user_controller/list', { UserID: userId, page: followersList.page })//followersList.page
            .then(res => {
                setLoading(false);
                console.log(res);
                if (res && res.status) {
                    var listAppend = [];
                    var oldArr = [...followersList.list];
                    var followData = res.data;
                    followData.map(ele => {
                        var ind = oldArr.findIndex(oldEle => oldEle._id == ele._id);
                        if (ind == -1) {
                            listAppend.push(ele);
                        }
                    })
                    var newArr = oldArr.concat(listAppend);
                    setFollowersList({ ...followersList, totalCount: res.totalCount, list: newArr })
                }
            })
    }
    return (
        <Row className="no-gutters">
            <Col md="3"></Col>
            <Col md="6">
                <FollowersCard
                    followerslist={followersList.list}
                    totalFollowers={followersList.totalCount}
                    blockUser={(id) => {
                        var arr = [...followersList.list];
                        var ind = arr.findIndex(ele => ele._id == id);
                        if (ind > -1) {
                            arr[ind].IsBlock = 1;
                            setFollowersList({ ...followersList, list: arr });
                        }
                    }}
                    unblock={(id) => {
                        var arr = [...followersList.list];
                        var ind = arr.findIndex(ele => ele._id == id);
                        console.log('mute', id, ind)
                        if (ind > -1) {
                            arr[ind].IsBlock = 0;
                            setFollowersList({ ...followersList, list: arr });
                        }
                    }}
                    mute={(id) => {
                        var arr = [...followersList.list];
                        var ind = arr.findIndex(ele => ele._id == id);
                        console.log('mute', id, ind)
                        if (ind > -1) {
                            arr[ind].IsMute = 1;
                            //console.log(newArr);
                            setFollowersList({ ...followersList, list: arr });
                        }
                    }}
                    unmute={(id) => {
                        var arr = [...followersList.list];
                        var ind = arr.findIndex(ele => ele._id == id);
                        console.log('unmute', id, ind)
                        if (ind > -1) {
                            arr[ind].IsMute = 0;
                            //console.log(newArr);
                            setFollowersList({ ...followersList, list: arr });
                        }
                    }}
                />
                <div className="mx-3 my-3">
                    {loading ?
                        <Spinner animation="border" variant="primary" />
                        : followersList.totalCount && followersList.totalCount > followersList.list.length ? //followersList.list.length
                            <Button size="sm" onClick={() => { setFollowersList({ ...followersList, page: followersList.page + 1 }) }}>see more</Button>
                            : null}
                </div>
            </Col>
            <Col md="3"></Col>
        </Row>
    )
}
export default FollowersList;