import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';

import Related from '../Component/Related';
import Feed from '../Component/Feed';
import { BaseColor, Images } from '../config';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { httpPost, httpGet } from '../Services/HttpServices';
import { Link, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import Helmet from 'react-helmet';
//Custom use previous hook
function usePrevious(value) { 
    const ref = useRef();
    // Store current value in ref
    useEffect(() => {
        ref.current = value;
    }, [value]); // Only re-run if value changes
    return ref.current;
}

function Topics(props) {

    // const state = useSelector(state => state);
    var auth = useSelector(({ auth }) => auth);
    // const path = props.location.pathname;
    const [TopicID, setTopicID] = useState(null);
    const TopicUrl = props.match.params.id;
    
    const [topics, setTopics] = useState([]);
    const [topicDetail, setTopicDetail] = useState([]);
    const [trendingQuestions, setTrendingQuestions] = useState([]);
    const prevTopics = usePrevious(topics);

    const [isFollow, setIsFollow] = useState(null);

    useEffect(() => {
        getTopicList();
        if(TopicUrl){
            httpPost("topic_controller/retrieve",{Slug: TopicUrl})
                .then(res => {
                    if (res && res.status) {
                        setTopicDetail(res.data);
                        setTopicID(res.data._id);
                        setTopics(res.data.RelatedTopics || [])
                        setFeeds({ page: 1, totalCount: 0, list: [] });
                    }else{
                        toast.error(res.message);
                        // props.history.goBack();
                    }
                })
        }
        getTrendingQuestions();
        getFollowDetails();
    }, [TopicUrl])

    useEffect(() => {
        if (prevTopics && prevTopics.length > 0) {
            setLoading(true);
            if (feeds.page == 1) {
                setFeeds({ page: 1, totalCount: 0, list: [] });
                getList();
            } else {
                setFeeds({ ...feeds, page: 1, list: [] });
            }
        }

    }, [TopicID, topics])

    const getTopicList = () => {
        // httpPost("user_topic_controller/list")
        //     .then(res => {
        //     if (res && res.data) {
        //         if (res.data.length > 0) {
        //             if (res.data[0].TopicIDs && res.data[0].TopicIDs.length > 0)
        //                 setTopics(res.data[0].TopicIDs || [])
        //         }
        //     }
        // })
    }
    const [feeds, setFeeds] = useState({ page: 1, totalCount: 0, list: [] });
    const [feedLength, setFeedlength] = useState(0)
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        setLoading(true);
        if(TopicID){
            getList();   
        }
    }, [TopicID, feeds.page])

    const getList = () => {
        httpPost('dashboard_controller/feed', {
            page: feeds.page,
            TopicIDs: TopicID
        })
            .then(res => {
                setLoading(false);
                if (res && res.status) {
                    let feedData = res.data;
                    feedData.map(ele => {
                        if (ele.Type === "ANSWER") {
                            let CommentCount = ele.CommentCount ? ele.CommentCount : 0;
                            ele.id = ele._id;
                            ele.image = (!ele.UserID.ProfilePicture || ele.UserID.ProfilePicture == "") ? Images.defaultProfile : ele.UserID.ProfilePicture;
                            ele.commentsCount = CommentCount > 1000000 ? Math.round((CommentCount / 1000000) * 10) / 10 + "M" : (CommentCount > 1000 ? Math.round((CommentCount / 1000) * 10) / 10 + "K" : CommentCount)
                        }else if(ele.Type === "QUESTION"){
                            ele.topics = ele.TopicID;
                        }
                        ele.user_id = ele.UserID._id;
                        // ele.percent = isNaN(ele.UpvoteCount - ele.DownvoteCount) ? 0 : ele.UpvoteCount - ele.DownvoteCount;
                        let percentage = ((ele.UpvoteCount / (ele.UpvoteCount + ele.DownvoteCount))*100);
                        percentage = (Math.round(isNaN(percentage) ? 0 : percentage)) + "%";
                        ele.percent = percentage
                    })
                    var oldArr = [...feeds.list];
                    var newArr = oldArr.concat(feedData);
                    setFeeds({ ...feeds, totalCount: res.totalCount, list: newArr });
                    setFeedlength(feedData.length)
                }
            })
    }
    const updateFeed = (feedData) => {
        var arr = [...feeds.list];
        var ind = arr.findIndex(ele => ele._id == feedData._id);
        if (ind > -1) {
            if(feedData.Type === "QUESTION"){
                httpGet('question_controller/' + arr[ind].QuestionUrl).then(res => {
                    if (res && res.status && res.data) {
                        res.data.Type = "QUESTION";
                        res.data.percent = isNaN(res.data.UpvoteCount - res.data.DownvoteCount) ? 0 : res.data.UpvoteCount - res.data.DownvoteCount;
                        arr[ind] = res.data;
                        setFeeds({ ...feeds, list: arr });
                    }
                })
            }else{
                httpPost('answer_controller/retrive',{AnswerID: feedData._id}).then(res => {
                    if (res && res.status && res.data) {
                        res.data.Type = "ANSWER";
                        res.data.percent = isNaN(res.data.UpvoteCount - res.data.DownvoteCount) ? 0 : res.data.UpvoteCount - res.data.DownvoteCount;
                        arr[ind] = res.data;
                        setFeeds({ ...feeds, list: arr });
                    }
                })
            }
        }
    }
    var trendingQuestionList = []
    const getTrendingQuestions = () => {
        httpPost("question_controller/trending", { page: 1})
            .then(res => {
                if (res && res.data) {
                    if (res.totalCount > 0) {
                        res.data.slice(0, 5).map((element, i) => {
                            trendingQuestionList.push({
                                id: element._id,
                                image: (element.UserID && element.UserID.ProfilePicture ? element.UserID.ProfilePicture : Images.defaultProfile),
                                url: element.QuestionUrl,
                                text: element.Subject,
                                // post: moment(Date(element.CreatedDate)).fromNow(),
                                // by: (element.UserID) ? element.UserID.FirstName + " " + element.UserID.LastName : ""
                            })
                        });
                    }
                    setTrendingQuestions(trendingQuestionList)
                }
            })
    }

    const unFollowTopic = () => {
        if (auth && auth.user && auth.user._id) {
            httpPost('user_topic_controller/unfollow', {
                TopicIDs: TopicID,
            }).then(res => {
                if (res && res.status) {
                    // toast.success(res.message);
                    setIsFollow(null);
                } else {
                    toast.error(res.message);
                }
            })
        }else{
            window.location.href = "/signin?ref="+props.location.pathname;
        }
    }
    const followTopic = () => {
        if (auth && auth.user && auth.user._id) {
            var obj = {
                TopicIDs: TopicID,
            }
            httpPost('user_topic_controller/follow', obj).then(res => {
                if (res && res.status) {
                    toast.success(res.message);
                    getFollowDetails();
                } else {
                    toast.error(res.message);
                }
            })
        }else{
            window.location.href = "/signin?ref="+props.location.pathname;
        }
    }

    const getFollowDetails = () => {
        httpPost('user_topic_controller/list', {
            TopicID: TopicID,
        })
            .then(res => {
                setIsFollow(res && res.status || null);
            })
    }
    return (
        <div className="p-2 p-md-3 p-lg-3 p-sm-2 question-feed">
            <Helmet>
                <title>{topicDetail && topicDetail.MetaTitle ? topicDetail.MetaTitle : (topicDetail.Title ? topicDetail.Title+" |" : "")+" English Forward"}</title>
                <meta name="description" content={topicDetail && topicDetail.MetaDescription ? topicDetail.MetaDescription : "A dedicated place to share your questions and answers on "+(topicDetail.Title ? topicDetail.Title : "")+". Learn about "+(topicDetail.Title ? topicDetail.Title : "")+" with English Forward."} />
            </Helmet>
            {topicDetail.IsPrivate === 1 && auth.user === null ? (<Redirect to="/" />): null }
            <Container fluid>
                <Row>
                    <Col lg="3" md="4" className="p-2 p-md-3 p-lg-3 p-sm-2">
                        <Card className="ml-4" style={{ width: '18rem', zIndex: 1 }}>
                            <Card.Body>
                                <h4 className="border-bottom" style={{ paddingBottom: 10 }}>
                                    <Image src={Images.Question} /> Related Topics</h4>
                                    <Form className="ml-2" >
                                        {topics.map(ele => (
                                            <div className="mb-3">
                                                <Form.Check >
                                                    <Form.Check.Label ><Link to={"/topics/"+ele.Slug} >{ele.Title}</Link></Form.Check.Label>
                                                </Form.Check>
                                            </div>
                                        ))}
                                    </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg="6" md="6" className="p-2 p-md-3 p-lg-3 p-sm-2">
                        <Row>
                            <Col md="12" >
                                {topicDetail ? (
                                <div className="mb-3 p-2 pm-4 p-lg-3" style={{ width: "100%", backgroundColor: BaseColor.whiteColor, borderWidth: 'thin', borderRadius: 5, borderRadius: 5, boxShadow: "0px 22px 100px rgba(0, 0, 0, 0.15)" }}>
                                    <div>
                                        <h3 className="mb-2" >
                                            <b>{topicDetail.Title}</b>
                                            <Link to="#" ><span style={{ float: 'right' }} onClick={() => {
                                                if (isFollow) {
                                                    unFollowTopic();
                                                } else {
                                                    followTopic();
                                                }
                                            }}>{isFollow ? 'Unfollow' : "Follow"}</span></Link>
                                        </h3>
                                        <div>{topicDetail.Description}</div>
                                    </div>
                                </div>) : null}
                                {feeds && feeds.list && feeds.list.length > 0 ? feeds.list.map(ele => (
                                    <Feed
                                        key={ele._id}
                                        showFullText={(questionId, answerId) => {
                                            var arr = [...feeds.list];
                                            var ind = arr.findIndex(ele => ele._id == questionId);
                                            if (ind > -1) {
                                                var ansArr = [...arr[ind].AnswerID];
                                                var ind2 = ansArr.findIndex(ele => ele._id == answerId);
                                                if (ind2 > -1) {
                                                    ansArr[ind2].showFull = 1;
                                                    arr[ind].AnswerID = ansArr;
                                                    setFeeds({ ...feeds, list: arr })
                                                }
                                            }
                                        }}
                                        question={ele}
                                        location={props.location}
                                        updateQn={() => {
                                            updateFeed(ele)
                                        }}
                                        history = {props.history}
                                    />
                                )) : null}
                                {loading ?
                                    <Spinner animation="border" variant="primary" />
                                    : feeds.totalCount && feeds.totalCount > feeds.list.length && feedLength === 10 ?
                                        (<div class="text-center" ><Button className="ask-question" size="sm" onClick={() => { setFeeds({ ...feeds, page: feeds.page + 1 }) }} >see more</Button></div>)
                                        : <p>No data found</p>}
                            </Col>
                        </Row>
                    </Col>
                    <Col lg="3" className="p-2 p-md-3 p-lg-3 p-sm-2 d-none d-lg-block d-xl-none" >
                        <Row style={{ paddingBottom: 20 }}>
                            <Card style={{ width: '18rem' }}>
                                <Card.Body>
                                    <Related
                                        heading="Trending Questions"
                                        list={trendingQuestions}
                                        link=""
                                    />
                                </Card.Body>
                            </Card>
                        </Row>
                    </Col>
                </Row>
            </Container>

        </div>
    )
}
export default Topics;