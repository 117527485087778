import React, {useState, useContext, useEffect} from 'react'
import { useSelector } from 'react-redux';
import Loading from '../Loading/Loading';
import {db} from "../firebase_config"
import { useAuth } from "./AuthContext"

const UsersContext = React.createContext();


export function useUsers() {
  return useContext(UsersContext)
}

export function UsersProvider({ children }) {

    const auth = useSelector(({ auth }) => auth);
    const {login, signup, currentUser} = useAuth()
    const [currentUserInfo, setCurrentUserInfo] = useState([])
    const [loading, setLoading] = useState(true);
  
    const getUsers = () => {
      db.collection("users").onSnapshot(function(querySnapshot) {
        setCurrentUserInfo(
          querySnapshot.docs.map((doc) => ({
            id: doc.id,
            firstName: doc.data().firstName,
            lastName: doc.data().lastName,
            email: doc.data().email,
            profilePic: doc.data().profilePic
          })).filter((user) => {
            return user.id === currentUser.uid
          })
        )
      });
    }

    const checkLogin = async () => {
      try {
        await login(auth.user.Email, auth.user._id)
      } catch (e) {
        if(e.code === "auth/user-not-found"){
          try {
            await signup(auth.user.Email, auth.user._id, auth.user.FirstName, auth.user.LastName);
          }catch (e) {

          }
        }else if (e.code === "auth/internal-error"){
        }
      }
    }

    useEffect(() => {
      if(currentUser === null){
          checkLogin()
      }else{
        getUsers()
      }

      setTimeout(() => {
       setLoading(false)
      }, 2000)
      
    }, [])
  
    const value = {
      currentUserInfo
    }
  
    return(
      <UsersContext.Provider value={value}>
        {!loading && children}
        {loading && <Loading />}
      </UsersContext.Provider>
    )
}
  
