import React, { Component, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import FormCheck from 'react-bootstrap/FormCheck';
import Row from 'react-bootstrap/Row';
	
import { Images } from '../config';
import { httpPost } from '../Services/HttpServices';

function Leaderboard() {
	var auth = useSelector(({ auth }) => auth);
	const [activeOtherUser, setActiveOtherUser] = useState(null)
	const [users, setUsers] = useState([])
	const [leaderDay, setLeaderDay] = useState(1);
	useEffect(() => {
		getList(leaderDay)
	}, [leaderDay])
	const getList = (days) => {
		var data = {};
		if (leaderDay && leaderDay != '0') {
			data.dayrange = days;
		}
		httpPost('leaderboard_controller/list', data)
			.then(res => {
				setUsers(res && res.data || []);
			})
	}

	return (
		<div className="leader-board p-4 p-md-5 p-lg-5 p-sm-4" >
			{auth.user === null ? (<Redirect to="/signin" />): null }
			<Container>
				<Row>
					<Col md="12" className="p-4">
						<h1><Image src={Images.dashboard} /> Leaderboard <span>for</span>
							<select className="btn-primary btn-rounded p-1 pl-2 pr-2" 
								style={{position: 'absolute', bottom: '39px',marginLeft: '10px'}}
								value={leaderDay} 
								onChange={(e) => { setLeaderDay(e && e.target && e.target.value || null) }}>
								<option value="1">Today</option>
								<option value="7">7 Days</option>
								<option value="30">1 Month</option>
								<option value="0">All</option>
							</select>
						</h1>
					</Col>
				</Row>
				{users && users.length > 0 ? (
					<>
						<Row className="text-center mt-3 mb-3" >
							{users.filter((ele, index) => index < 3).map((ele, index) => (
								<Col md="4" >
									<div className="impact-card pt-2 pb-4" >
										<Row>
											<Col xs="12" >
												<Image src={ele.UserID.ProfilePicture || Images.defaultProfile} roundedCircle />
												<div className="spot active" />
												<h4><Link to={"profile/"+ele._id} target="_blank" >{ele.UserID.FirstName + " " + ele.UserID.LastName}</Link></h4>
												<p>{ele.CurrentLocation || " "}</p>
											</Col>
											<Col xs="6" >
												<h1 className="rank-ans" >{index + 1}</h1>
												<span>Rank</span>
											</Col>
											<Col xs="6" >
												<h1 className="point-ans">{ele.Points}</h1>
												<span>Point</span>
											</Col>
											{/*<div className="icon-left">
												<Image src={Images.Follow} />
											</div>
											<div className="icon-right">
												<Image src={Images.mailWhite} />
											</div>*/}
										</Row>
									</div>
								</Col>
							))}
						</Row>
						<Row className="mb-5 p-4 p-md-5 p-lg-5 other-users" >
							{users.filter((ele, index) => index >= 3).length > 0 ? (
								<div className="mb-5 row col-md-12">
									<Col md="10" xs="6">
										<h2>Other Users </h2>
									</Col>
									{/*<Col md="1" xs="3">
										<p className="action-text">EDIT</p>
									</Col>
									<Col md="1" xs="3">
										<p className="action-text">DELETE</p>
									</Col>*/}
								</div>
							) : null}
							{
								users.filter((ele, index) => index >= 3).map((user, index) => (
									<Col onClick={() => {
										//setActiveOtherUser(user._id);
										// user._id == activeOtherUser ? " active" : ""
									}} md="12" className={"row user-row " + (users.length - 1 == index ? " last " : "") + (index == 1 ? " active" : "")}>
										{/*<Col md="1" xs="3">
											<FormCheck className="primary" type="checkbox" />
										</Col>*/}
										<Col md="1" xs="3">
											<Image className="profile-pic" src={user.UserID.ProfilePicture || Images.defaultProfile} roundedCircle />
										</Col>
										<Col md="3" xs="5">
											<p><Link to={"profile/"+user._id} >{user.UserID.FirstName + ' ' + user.UserID.LastName}</Link></p>
										</Col>
										<Col md="2" xs="3">
											<p>{user.CurrentLocation}</p>
										</Col>
										<Col md="2" xs="3">
											<p>{"Rank " + (index + 4)}</p>
										</Col>
										<Col md="2" xs="3">
											<p>{"Point "+user.Points}</p>
										</Col>
										{/*<Col md="1" xs="3" className="text-right">
											<Image src={Images.moreBlue} />
										</Col>*/}
									</Col>
								))
							}
						</Row>
					</>
				) : (
					<div className="text-center">
						<h3>No data found</h3>
					</div>
				)}
			</Container>
		</div>
	)
}

export default Leaderboard;