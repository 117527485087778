import React from 'react';
import { BaseColor, Images } from "../config";

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Image from 'react-bootstrap/Image';
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';

import PropTypes from "prop-types";
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from "moment";
import 'moment-timezone';
import { httpPost } from '../Services/HttpServices';
import { toast } from 'react-toastify';
// import Editor from './RichTextEditor';
import Editor from './QuillEditor';
// import { Link } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import Reply from '../Component/Reply';

function Comments(props) {
    const timeZone  = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // const state = useSelector(state => state)
    let selectedComment = props.commentID;
    var auth = useSelector(({ auth }) => auth);
    let profilePicture = auth.ProfilePicture ? auth.ProfilePicture : Images.defaultProfile;
    const [loading, setLoading] = useState(false);
    const [showComment, setshowComment] = useState(selectedComment ? true : false)
    const [commentListArr, setCommentList] = useState([])
    const [comment, setcomment] = useState("");

    const [commentCount, setCommentCount] = useState(0)
    const [totalCount, settotalCount] = useState(0)
    const [shareCount, setShareCount] = useState(0)
    var   [currentPage, setCurrentPage] = useState(0)
    const [editAns, setEditAns] = useState(false);
    const [delAnswer, setDelAnswer] = useState(false);
    const [suggestAns, setSuggestAns] = useState(false);
    const [ans, setAnswer] = useState("");

    const [commentID, setCommentID] = useState("")

    const [reportType, setReportType] = useState("")
    const [reportDisplay, setReportDisplay] = useState(false);
    const [report, setReport] = useState({
        type: null,
        text: ""
    });

    useEffect(() => {
        getCommentList()
    }, [])
    useEffect(() => {
        setCommentCount(props.commentsCount);
        settotalCount(props.commentsCount);
    }, [props.commentsCount]);
    useEffect(() => {
        setShareCount(props.shareCount);
    }, [props.shareCount])
    function getCommentList() {
        httpPost("comment_controller/list", { AnswerID: props.answerID,CommentID: selectedComment, page: currentPage })
            .then((res) => {
                if (res.status === 1){
                    let commentData = res.data;
                    commentData.map(ele => {
                        let percentage = ((ele.UpvoteCount / (ele.UpvoteCount + ele.DownvoteCount))*100);
                        percentage = (Math.round(isNaN(percentage) ? 0 : percentage)) + "%";
                        ele.percent = percentage
                    })
                    setCommentList(commentData);
                }
                else
                    settotalCount(0)
            })
    }

    let { question, answer } = props;
    function handlePostComment() {
        if(auth && auth.user && auth.user._id){
            if(comment){
                var commentObj = {
                    AnswerID: props.answerID,
                    QuestionID: props.questionID,
                    CommentText: comment
                }
                httpPost("comment_controller/create", commentObj)
                    .then((res) => {
                        if (res.status == 1) {
                            setcomment("");
                            setCommentCount(commentCount + 1)
                            toast.success(res.message)
                        } else {
                            toast.error(res.message)
                        }
                        getCommentList()
                    })
            }
        }else{
            window.location.href = "/signin?ref="+props.location.pathname;
        }
    }

    function handleCommentChange(e) {
        setcomment(e.target.value)
    }

    function showFullText(id) {
        if (commentListArr && commentListArr.length > 0) {
            let arr = [...commentListArr];
            arr.map(ele => {
                if (ele._id === id) {
                    ele.showFull = 1
                }
            })
            setCommentList(arr);
        }
    }

    function showCommentData() {
        if (showComment)
            setshowComment(false)
        else
            setshowComment(true)
    }

    function handleLoadMore() {
        currentPage++;
        setCurrentPage(currentPage);
        getCommentList();
    }

    const setBookMark = () => {
        if(auth && auth.user && auth.user._id){
            httpPost('bookmark_controller/create', {
                AnswerID: answer.id,
                UserID: auth.user._id,
            }).then(res => {
                if (res) {
                    if (res.status) {
                        toast.success(res.message);
                    } else {
                        toast.error(res.message);
                    }
                }
            }).catch(err => {
                console.log('err', err);
            })
        }else{
            window.location.href = "/signin?ref="+props.location.pathname;
        }
    }

    const pinToProfile = () => {
        if(auth && auth.user && auth.user._id){
            httpPost('answer_pin_controller/create', {
                AnswerID: answer.id,
                UserID: auth.user._id,
            }).then(res => {
                if (res) {
                    if (res.status) {
                        toast.success(res.message);
                    } else {
                        toast.error(res.message);
                    }
                }
            }).catch(err => {
                console.log('err', err);
            })
        }else{
            window.location.href = "/signin?ref="+props.location.pathname;
        }
    }

    const disableComment = () => {
        if(auth && auth.user && auth.user._id){
            httpPost('answer_controller/update', {
                AnswerID: answer.id,
                IsDisableComment: 1
            }).then(res => {
                if (res) {
                    if (res.status) {
                        toast.success(res.message);
                    } else {
                        toast.error(res.message);
                    }
                }
            }).catch(err => {
                console.log('err', err);
            })
        }else{
            window.location.href = "/signin?ref="+props.location.pathname;
        }
    }

    const deleteAnswer = () => {
        if(auth && auth.user && auth.user._id){
            httpPost('answer_controller/delete', {
                AnswerID: answer.id
            }).then(res => {
                if (res) {
                    if (res.status) {
                        toast.success(res.message);
                        props.updateList();
                    } else {
                        toast.error(res.message);
                    }
                }
            }).catch(err => {
                console.log('err', err);
            })
        }else{
            window.location.href = "/signin?ref="+props.location.pathname;
        }
    }
    const updateAnswer = () => {
        if(auth && auth.user && auth.user._id){
            setLoading(true);
            httpPost('answer_controller/update', {
                AnswerID: answer.id,
                Answer: ans,
            }).then(res => {
                setLoading(false);
                if (res) {
                    if (res.status) {
                        setEditAns(false);
                        props.updateList();
                        toast.success(res.message);
                    } else {
                        toast.error(res.message);
                    }
                }
            }).catch(err => {
                setLoading(false);
                console.log('err', err);
            })
        }else{
            window.location.href = "/signin?ref="+props.location.pathname;
        }
    }

    const suggestAnswer = () => {
        if(auth && auth.user && auth.user._id){
            if(suggestAns){
                setLoading(true);
                httpPost('answer_controller/suggest_edits', {
                    UserID: suggestAns.user_id,
                    AnswerID: suggestAns.id,
                    SuggestedText: ans,
                }).then(res => {
                    setLoading(false);
                    if (res) {
                        if (res.status) {
                            setSuggestAns(false);
                            toast.success(res.message);
                        } else {
                            toast.error(res.message || res.error);
                        }
                    }
                }).catch(err => {
                    setLoading(false);
                    console.log('err', err);
                })    
            }
        }else{
            window.location.href = "/signin?ref="+props.location.pathname;
        }
    }

    function handleCommentVote(e, type) {
        if(auth && auth.user && auth.user._id){
            if(type === "upvote"){
                var voteObj = {
                    QuestionID: e.QuestionID._id,
                    AnswerID: e.AnswerID._id,
                    CommentID: e._id,
                    upVote: 1
                }
            }else{
                var voteObj = {
                    QuestionID: e.QuestionID._id,
                    AnswerID: e.AnswerID._id,
                    CommentID: e._id,
                    downVote: 1
                }
            }
            httpPost('comment_votes_controller/create', voteObj)
                .then(res => {
                    console.log("res", res);
                    if (res.status) {
                        getCommentList()
                        toast.success(res.message);
                    } else {
                        toast.error(res.message)
                    }
                })
                .catch(err => {
                    console.log('errr', err)
                })
        }else{
            window.location.href = "/signin?ref="+props.location.pathname;
        }
    }

    const handleReport = () => {
        if(auth && auth.user && auth.user._id){
            setLoading(true);
            let url = 'answer_report_controller/create';
            let reportData = {
                QuestionID: props.questionID,
                AnswerID: props.answerID,
                ReportType: report.type,
                ReportText: report.text
            }
            if(reportType === "Comment"){
                url = 'comment_report_controller/create';
                reportData.CommentID = commentID;
            }
            httpPost(url, reportData).then(res => {
                setLoading(false);
                if (res) {
                    if (res.status) {
                        setReport({
                            type: null,
                            text: ""
                        });
                        setReportDisplay(false);
                        setCommentID("");
                        setReportType("");
                        toast.success(res.message)
                    } else {
                        toast.error(res.message);
                    }
                } else {
                    toast.error('Network Error')
                }
            }).catch(err => {
                setLoading(false);
                toast.error('Network Error')
            })
        }else{
            window.location.href = "/signin?ref="+props.location.pathname;
        }
    }

    if (showComment) {
        return (
            <div className={props.show ? "sub-links-comment" : ""}>
                <div >
                    <div className="sub-links" >
                        <Image src={Images.comment} width={15} height={15} onClick={showCommentData} style={{ cursor: 'pointer' }} />
                        <span style={{ marginRight: 15, marginLeft: 7 }}>{commentCount}</span>
                        <span style={{ marginRight: 15 }}>{shareCount || ""}</span>
                        {/*<Image style={{ marginRight: 15 }} src={Images.more_horizontal} width={25} height={25} />*/}
                        <label className="for-dropdown mb-0">
                            <DropdownButton className="menu-three-dots-button" id="dropdown-item-button" title={<Image src={Images.dotIcon} />}>
                                {auth && auth.user && auth.user._id && auth.user._id == answer.user_id ? (
                                    <>
                                        <Dropdown.Item as="button" onClick={() => { pinToProfile() }}>Pin to profile</Dropdown.Item>
                                        <Dropdown.Item as="button" onClick={() => { setBookMark() }}>Bookmark</Dropdown.Item>
                                        <Dropdown.Item as="button" onClick={() => { disableComment() }} >Disable comments</Dropdown.Item>
                                        <Dropdown.Item as="button" onClick={() => { setEditAns(true) }} >Edit Answer</Dropdown.Item>
                                        <Dropdown.Item as="button" onClick={() => { props.history.push('/answerlog?id='+ props.answerID) } }>Log</Dropdown.Item>
                                        <Dropdown.Item as="button" onClick={() => { setDelAnswer(true) }}>Delete Answer</Dropdown.Item>
                                    </>
                                ) : (
                                        <>
                                            <Dropdown.Item as="button" onClick={() => { setBookMark() }}>Bookmark</Dropdown.Item>
                                            <Dropdown.Item as="button" onClick={() => { setSuggestAns(answer) }}>Suggest Edits</Dropdown.Item>
                                            <Dropdown.Item as="button" onClick={() => { props.history.push('/answerlog?id='+ props.answerID) } }>Log</Dropdown.Item>
                                            <Dropdown.Item as="button" onClick={() => { setReportDisplay(!reportDisplay); setReportType("Answer") }}>Report</Dropdown.Item>
                                        </>
                                    )}
                            </DropdownButton>
                        </label>
                        {/* Edit Answer  */}
                        <Modal show={editAns} onHide={() => { setEditAns(false); }} size="md">
                            <Modal.Header closeButton>
                                <Modal.Title>Edit Answer</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Row>
                                    <Col md="12">
                                        <Editor
                                            editorState={answer && answer.PostContent || null}
                                            onUpdate={editorState => setAnswer(editorState)}
                                        />
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" className="btn-sm" onClick={() => { setEditAns(false); }}>
                                    Close
                                </Button>
                                <Button disabled={loading} className="btn-sm" variant="primary" onClick={() => { updateAnswer() }}>
                                    Save
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <SweetAlert
                            show={delAnswer ? true : false}
                            warning
                            showCancel
                            confirmBtnText={"Yes"}
                            cancelBtnText={"No"}
                            title={<span style={{ fontSize: 25 }} >Do you want to delete this answer?</span>}
                            onConfirm={deleteAnswer}
                            onCancel={() => { setDelAnswer(false) }}
                        />
                    </div>
                </div>
                <Image className="float-right" height={15} onClick={showCommentData} src={Images.close} style={{cursor: 'pointer', opacity: '0.5'}} title="Close" width={15}  />
                <Row className="row" style={{ paddingTop: 10, alignItems: 'center' }}>
                    <Col md="9" sm="12" xs="12" >
                        <div className="input-border" style={{ display: 'flex', alignItems: 'center' }}>
                            <Image style={{ marginLeft: 5 }} src={props.image} height={35} width={35} roundedCircle />
                            <InputGroup className="">
                                <FormControl style={{ border: 0, boxShadow: 'none', marginRight: 5, backgroundColor: 'inherit' }} type="text" value={comment} placeholder="start typing your comment..." size="lg" onChange={handleCommentChange} answerID={props.answerID} />
                                <InputGroup.Append>
                                    {/* <FontAwesomeIcon icon={"search"} /> */}
                                </InputGroup.Append>
                            </InputGroup>
                        </div>
                    </Col>
                    <Col md="3" sm="12" xs="12">
                        <Button style={{ color: BaseColor.whiteColor, width: "100%", height: "100%" }} onClick={handlePostComment}>Post</Button>
                    </Col>
                </Row>
                {props.show && commentListArr && commentListArr.map(ele => (
                    <div className="mt-2" >
                        <div style={{ display: 'flex', alignItems: 'center', paddingBottom: 20}}>
                            <div style={{ flex: '0.5' }} className="pr-2 pr-md-2 pr-lg-2 pr-sm-2">
                                <a href={"/profile?id="+(ele.UserID && ele.UserID._id)} >
                                    <Image src={ele.UserID.ProfilePicture ? ele.UserID.ProfilePicture : Images.defaultProfile} width={35} height={35} roundedCircle />
                                </a>
                            </div>
                            <div style={{ flex: '9', display: 'flex', flexDirection: 'column' }}>
                                <span className="capitalize" style={{}}><b>{ele.UserID && (ele.UserID.FirstName + ' ' + ele.UserID.LastName)}</b></span>
                                <span style={{}}><b>{ele.text}</b></span>
                                <small>{"commented . " + moment.tz(ele.CreatedDate, timeZone).fromNow()}</small>
                            </div>
                        </div>
                        <Row>
                            <Col xs={{ span: 1 }} >
                                <div style={{ flex: '1' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                                        <a onClick={e => handleCommentVote(ele, "upvote")} >{ele.IsVoted && ele.UpVote ? (<Image src={Images.upActive} height={15} width={20} />) : (<Image src={Images.up} height={15} width={20} />)}</a>
                                        <span className="mb-1 mt-1" style={{ fontSize: 12 }}>{ele.percent}</span>
                                        <a onClick={e => handleCommentVote(ele, "downvote")} >{ele.IsVoted && ele.DownVote ? (<Image src={Images.downActive} height={15} width={20} />) : (<Image src={Images.down} height={15} width={20} />)}</a>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={{ span: 11 }} >
                                <p>{ele.CommentText ? !ele.showFull && ele.CommentText.length > 400 ? ele.CommentText.substring(0, 400) : ele.CommentText : null}</p>
                                {ele.CommentText && ele.CommentText.length > 400 && !ele.showFull ? (
                                    <div style={{ marginTop: 5, borderTopLeftRadius: 20 }}>
                                        <div style={{ padding: 3, fontSize: 12, width: 'fit-content', borderRadius: 20, backgroundColor: BaseColor.primaryColor, color: BaseColor.whiteColor }}
                                            onClick={() => { showFullText(ele._id) }}
                                        >
                                            &nbsp;&nbsp;See More&nbsp;&nbsp;
                                        </div>
                                    </div>
                                ) : null}
                                <Reply comment={ele} history={props.history} commentCount={commentCount} updateCount={(e) => {
                                    setCommentCount(e)
                                }} removeComment={(comntId) => {
                                    const items = commentListArr.filter(ele => ele._id !== comntId);
                                    setCommentList(items)
                                }} />
                            </Col>
                        </Row>
                    </div>
                ))}
                {totalCount > 10 ? (<div style={{ textAlign: 'center' }}>
                    <label style={{ color: BaseColor.secondaryColor, display: props.displaySeeMore, cursor: "pointer" }} onClick={handleLoadMore}><b>See more comments</b></label>
                </div>) : null}
                {/* Report Answer  */}
                <Modal show={reportDisplay} onHide={() => { setReport({ text: '', type: null }); setReportDisplay(false); }}>
                    <Modal.Header closeButton>
                        <Modal.Title as="h4" >Report {reportType}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="row" style={{ paddingTop: 10, alignItems: 'center' }}>
                            <Col md="12" sm="12" xs="12" >
                                <Form.Check onClick={() => setReport({ ...report, type: '1' })} checked={report.type == '1' ? true : false} type={'radio'} value={"1"} label="Harassment" />
                                <Form.Check onClick={() => setReport({ ...report, type: '2' })} checked={report.type == '2' ? true : false} type={'radio'} value={"2"} label="Spam" />
                                <Form.Check onClick={() => setReport({ ...report, type: '3' })} checked={report.type == '3' ? true : false} type={'radio'} value={"3"} label="Insincere" />
                                <Form.Check onClick={() => setReport({ ...report, type: '4' })} checked={report.type == '4' ? true : false} type={'radio'} value={"4"} label="Poorly Written" />
                                <Form.Check onClick={() => setReport({ ...report, type: '5' })} checked={report.type == '5' ? true : false} type={'radio'} value={"5"} label="Incorrect Topic" />
                                <Form.Check onClick={() => setReport({ ...report, type: '6' })} checked={report.type == '6' ? true : false} type={'radio'} value={"6"} label="Offensive" />
                            </Col>
                            <Col md="12" sm="12" xs="12" className='mt-3'>
                                <div className="input-border" style={{ display: 'flex', alignItems: 'center' }}>
                                    <Image style={{ marginLeft: 5 }} src={profilePicture} height={35} width={35} roundedCircle />
                                    <InputGroup className="">
                                        <FormControl value={report.text} style={{ border: 0, boxShadow: 'none', marginRight: 5, backgroundColor: 'inherit' }} type="text" placeholder="Explain the report" size="lg" onChange={(e) => {
                                            setReport({ ...report, text: e && e.target && e.target.value || null });
                                        }} />
                                        <InputGroup.Append>
                                            {/* <FontAwesomeIcon icon={"search"} /> */}
                                        </InputGroup.Append>
                                    </InputGroup>
                                </div>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" className="btn-sm" onClick={() => { setReport({ text: '', type: null }); setReportDisplay(false); }}>
                            Cancel
                        </Button>
                        <Button disabled={loading} variant="primary" className="btn-sm" onClick={() => { handleReport() }}>
                            Submit
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    } else {
        return (
            <div className={props.show ? "sub-links-comment" : ""} >
                <div>
                    <div className="sub-links" >
                        <Image src={Images.comment} width={15} height={15} onClick={showCommentData} style={{ cursor: 'pointer' }} />
                        <span style={{ marginRight: 15, marginLeft: 7 }}>{commentCount}</span>
                        {/*<Image style={{ marginRight: 15 }} src={Images.forward} width={15} height={15} />
                        ShareUI <DropdownButton className="menu-three-dots-button" id="dropdown-item-share" title={<Image style={{ marginRight: 15 }} src={Images.forward} width={15} height={15} />}>
                            <Dropdown.Item as="button" ><FacebookShareButton url={window.location.href} ><FacebookIcon size={"2rem"} round/>&nbsp;Facebook</FacebookShareButton></Dropdown.Item>
                            <Dropdown.Item as="button" ><TwitterShareButton url={window.location.href}><TwitterIcon size={"2rem"} round/>&nbsp;Twitter</TwitterShareButton></Dropdown.Item>
                            <Dropdown.Item as="button" ><LinkedinShareButton url={window.location.href}><LinkedinIcon size={"2rem"} round/>&nbsp;LinkedIn</LinkedinShareButton></Dropdown.Item>
                            <Dropdown.Item as="button" ><WhatsappShareButton url={window.location.href}><WhatsappIcon size={"2rem"} round/>&nbsp;Whatsapp</WhatsappShareButton></Dropdown.Item>
                        </DropdownButton>*/}
                        <span style={{ marginRight: 15 }}>{shareCount || ""}</span>
                        {/*<Image style={{ marginRight: 15 }} src={Images.more_horizontal} width={25} height={25} />*/}
                        <label className="for-dropdown mb-0">
                            <DropdownButton className="menu-three-dots-button" id="dropdown-item-button" title={<Image src={Images.dotIcon} />}>
                                {auth && auth.user && auth.user._id && auth.user._id == answer.user_id ? (
                                    <>
                                        <Dropdown.Item as="button" onClick={() => { pinToProfile() }}>Pin to profile</Dropdown.Item>
                                        <Dropdown.Item as="button" onClick={() => { setBookMark() }}>Bookmark</Dropdown.Item>
                                        <Dropdown.Item as="button" onClick={() => { disableComment() }}>Disable comments</Dropdown.Item>
                                        <Dropdown.Item as="button" onClick={() => { setEditAns(true) }}>Edit Answer</Dropdown.Item>
                                        <Dropdown.Item as="button" onClick={() => { props.history.push('/answerlog?id='+ props.answerID) } }>Log</Dropdown.Item>
                                        <Dropdown.Item as="button" onClick={() => { setDelAnswer(true) }}>Delete Answer</Dropdown.Item>
                                    </>
                                ) : (
                                        <>
                                            <Dropdown.Item as="button" onClick={() => { setBookMark() }}>Bookmark</Dropdown.Item>
                                            <Dropdown.Item as="button" onClick={( ) => { setSuggestAns(answer)}}>Suggest Edits</Dropdown.Item>
                                            <Dropdown.Item as="button" onClick={() => { props.history.push('/answerlog?id='+ props.answerID) } }>Log</Dropdown.Item>
                                            <Dropdown.Item as="button" onClick={() => { setReportDisplay(!reportDisplay); setReportType("Answer") }}>Report</Dropdown.Item>
                                        </>
                                    )}
                            </DropdownButton>
                        </label>
                        {/* Edit Answer  */}
                        <Modal show={editAns} onHide={() => { setEditAns(false); }} size="md">
                            <Modal.Header closeButton>
                                <Modal.Title>Edit Answer</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Row>
                                    <Col md="12">
                                        <Editor
                                            editorState={answer && answer.PostContent || null}
                                            onUpdate={editorState => setAnswer(editorState)}
                                        />
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" className="btn-sm" onClick={() => { setEditAns(false); }}>
                                    Close
                                </Button>
                                <Button disabled={loading} className="btn-sm" variant="primary" onClick={() => { updateAnswer() }}>
                                    Save
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <SweetAlert
                            show={delAnswer ? true : false}
                            warning
                            showCancel
                            confirmBtnText={"Yes"}
                            cancelBtnText={"No"}
                            title={<span style={{ fontSize: 25 }} >Do you want to delete this answer?</span>}
                            onConfirm={deleteAnswer}
                            onCancel={() => { setDelAnswer(false) }}
                        />
                        {/* Suggest Answer  */}
                        <Modal show={suggestAns} onHide={() => { setSuggestAns(false); }} size="lg">
                            <Modal.Header closeButton>
                                <Modal.Title>Suggest Answer</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Row>
                                    <Col md="12">
                                        <Editor
                                            editorState={answer && answer.PostContent || null}
                                            onUpdate={editorState => setAnswer(editorState)}
                                        />
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => { setSuggestAns(false); }}>
                                    Close
                                </Button>
                                <Button disabled={loading} variant="primary" onClick={() => { suggestAnswer() }}>
                                    Submit
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        {/* Report Answer  */}
                        <Modal show={reportDisplay} onHide={() => { setReport({ text: '', type: null }); setReportDisplay(false); }}>
                            <Modal.Header closeButton>
                                <Modal.Title as="h4" >Report {reportType}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Row className="row" style={{ paddingTop: 10, alignItems: 'center' }}>
                                    <Col md="12" sm="12" xs="12" >
                                        <Form.Check onClick={() => setReport({ ...report, type: '1' })} checked={report.type == '1' ? true : false} type={'radio'} value={"1"} label="Harassment" />
                                        <Form.Check onClick={() => setReport({ ...report, type: '2' })} checked={report.type == '2' ? true : false} type={'radio'} value={"2"} label="Spam" />
                                        <Form.Check onClick={() => setReport({ ...report, type: '3' })} checked={report.type == '3' ? true : false} type={'radio'} value={"3"} label="Insincere" />
                                        <Form.Check onClick={() => setReport({ ...report, type: '4' })} checked={report.type == '4' ? true : false} type={'radio'} value={"4"} label="Poorly Written" />
                                        <Form.Check onClick={() => setReport({ ...report, type: '5' })} checked={report.type == '5' ? true : false} type={'radio'} value={"5"} label="Incorrect Topic" />
                                        <Form.Check onClick={() => setReport({ ...report, type: '6' })} checked={report.type == '6' ? true : false} type={'radio'} value={"6"} label="Offensive" />
                                    </Col>
                                    <Col md="12" sm="12" xs="12" className='mt-3'>
                                        <div className="input-border" style={{ display: 'flex', alignItems: 'center' }}>
                                            <Image style={{ marginLeft: 5 }} src={profilePicture} height={35} width={35} roundedCircle />
                                            <InputGroup className="">
                                                <FormControl value={report.text} style={{ border: 0, boxShadow: 'none', marginRight: 5, backgroundColor: 'inherit' }} type="text" placeholder="Explain the report" size="lg" onChange={(e) => {
                                                    setReport({ ...report, text: e && e.target && e.target.value || null });
                                                }} />
                                                <InputGroup.Append>
                                                    {/* <FontAwesomeIcon icon={"search"} /> */}
                                                </InputGroup.Append>
                                            </InputGroup>
                                        </div>
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" className="btn-sm" onClick={() => { setReport({ text: '', type: null }); setReportDisplay(false); }}>
                                    Cancel
                                </Button>
                                <Button disabled={loading} variant="primary" className="btn-sm" onClick={() => { handleReport() }}>
                                    Submit
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>
            </div>
        )
    }
}

Comments.prototype = {
    question: PropTypes.object,
    showFullText: PropTypes.func,
    link: PropTypes.string,
    show: PropTypes.bool,
    updateList: PropTypes.func,
}

Comments.defaultProps = {
    question: {},
    showFullText: () => { },
    link: null,
    show: true,
    updateList: () => { },
}
export default Comments;