import { Button, Col, Container, FormControl, Image, InputGroup, Row } from 'react-bootstrap';
import { Images } from '../config';
import ImpactCard from '../Component/ImpactCard';
import TeamCard from '../Component/TeamCard';
import Form from 'react-bootstrap/Form';
import { httpGet, httpPost } from '../Services/HttpServices';
import { useState, useEffect } from 'react';
import moment from 'moment'
import { toast } from 'react-toastify';

const learnings = [
    {
        id: 1,
        icon: Images.handshake,
        detail: "Travel and meet new people"
    },
    {
        id: 2,
        icon: Images.writing,
        detail: "Writing non-fiction & fiction"
    },
    {
        id: 3,
        icon: Images.volume,
        detail: "Business communication"
    },
    {
        id: 4,
        icon: Images.mic,
        detail: "Public Speaking"
    }
]

function AboutUs(props) {
    // const [team, setTeam] = useState([
    //     {
    //         id: 1,
    //         profile: Images.team3,
    //         name: "Alex Heavens",
    //         position: "Founder, CEO",
    //         active: true
    //     },
    //     {
    //         id: 2,
    //         profile: Images.team2,
    //         name: "Alex Heavens",
    //         position: "Founder, CEO",
    //     },
    //     {
    //         id: 3,
    //         profile: Images.team3,
    //         name: "Alex Heavens",
    //         position: "Founder, CEO",
    //     },
    //     {
    //         id: 4,
    //         profile: Images.team2,
    //         name: "Alex Heavens",
    //         position: "Founder, CEO",
    //     }
    //     , {
    //         id: 5,
    //         profile: Images.team2,
    //         name: "Alex Heavens",
    //         position: "Founder, CEO",
    //     }
    // ])

    const [QuestionCount, setQuestionCount] = useState(0)
    const [StudentsCount, setStudentsCount] = useState(0)
    const [answerCount, setAnswerCount] = useState(0)
    const [TeachersCount, setTeachersCount] = useState(0)
    const [UserCount, setUserCount] = useState(0)
    const [Email, setEmail] = useState("")
    const [team, setTeam] = useState([])

    const changeTagStatus = (id, val) => {
        var arr = [...team];
        arr.map(ele => {
            if (ele.id == id) {
                ele.active = val
            }
        })
        setTeam(arr);
    }
    const impacts = [
        {
            id: 2,
            count: StudentsCount,
            name: 'Users'
        },
        {
            id: 3,
            count: QuestionCount,
            name: 'Questions'
        },
        {
            id: 4,
            count: answerCount,
            name: 'Answers'
        }

    ];
    var teamMembers = []
    useEffect(() => {
        httpGet("about_us")
            .then(res => {
                setQuestionCount(res.impactData.QuestionCount)
                setStudentsCount(res.impactData.StudentsCount)
                setAnswerCount(res.impactData.AnswerCount ? res.impactData.AnswerCount : 0)
                setTeachersCount(res.impactData.TeachersCount)
                setUserCount(res.impactData.UserCount)
                // res.teamMembers.forEach(element => {
                //     // console.log("element.TeamMemberImage", element.TeamMemberImage);
                //     teamMembers.push({
                //         id: element._id,
                //         // profile: element.TeamMemberImage,
                //         profile: Images.team3,
                //         name: element.TeamMemberName,
                //         position: element.TeamMemberPosition,
                //         // active: (element.IsActive == 1) ? true : false
                //     })
                // });
                setTeam(teamMembers)
            })
    }, [])

    function handleEmailChange(e) {
        setEmail(e.target.value)
    }
    function handleSubscribe() {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(String(Email).toLowerCase())) {
            httpPost('subscribe', { Email })
                .then(res => {
                    if (res.status == 1) toast.success(res.message)
                    else toast.error(res.message)
                })
        } else {
            toast.error("Enter valid email")
        }
    }
    return (
        <div className="aboutus" >
            <Container fluid >
                <Row className="section center-content" >
                    <Col md="6" className="content " >
                        <h3 className="heading-text">About Us</h3>
                        <p className="detail-text">
                            English Forward is 19 years old, and has had over 300+ million students use the website to access over 2 million questions and answers that have been helpful to others learning English, in an environment that is non threatening and encouraging. 
	        			</p>
                        <p>
                            It is the largest learn English community on the internet, and this is your fast track to English mastery.
                        </p>
                    </Col>
                    <Col md="6" className="">
                        <Image src={Images.aboutus1} className="section-img" />
                    </Col>
                </Row>
                {/*<Row className="section " >
                    <Col md='12' className="text-content">
                        <img src={Images.arrowDown} />
                        <p className="bottom-text">scroll more</p>
                    </Col>
                </Row>*/}
                <Row className="selction">
                    <Col md='12' className="text-content">
                        <h3 className="heading-text">Our Mission</h3>
                    </Col>
                    <Col md='12' className="text-content pl-5 pr-5">
                        <p className="detail-small">Our mission is to help 1 Billion people learn English over the next 10 years, enabling them with a communication tool, the English language, that will give them access to the knowledge they need to change their lives for the better.</p>
                    </Col>
                    <Col md={{ offset: 2, span: 8 }} className="text-content pl-5 pr-5">
                        <Image src={Images.aboutus3} className="section-img" />
                    </Col>
                </Row>
                <Row className="section center-content" >
                    <Col md="6" className="">
                        <Image src={Images.aboutus2} className="section-img" />
                    </Col>

                    <Col md="6" className="content " >
                        <h3 className="heading-text">Our History</h3>
                        <p className="detail-text">
                            We are proud of our rich history in helping people all over the world learn English...19 years old, 240 countries, 310+ Million users, 500+ Million page views, 2 million Questions and Answers, and the largest Learn English community on the internet.
	        			</p>
                        <a href="/blog" style={{ textDecoration: "none" }}><Button className="btn-rounded" >check our blog <Image src={Images.arrowRight} /></Button></a>
                    </Col>
                </Row>
                <Row className="selction pt-3">
                    <Col md='12' className="text-content">
                        <h3 className="heading-text">Our Impact</h3>
                    </Col>
                    <Col md={{ offset: 2, span: 8 }} className="text-content ">
                    </Col>
                </Row>
                <ImpactCard impacts={impacts} />
                {/*<Row className="selction pt-3">
                    <Col md='12' className="text-content">
                        <h3 className="heading-text">Our Team</h3>
                    </Col>
                    <Col md={{ offset: 2, span: 8 }} className="text-content ">
                        <p className="detail-small">Meet our super friendly and creative team of developers, admins and experts to assist you in your quest for English Proficiency!</p>
                    </Col>
                </Row>
                <TeamCard team={team}
                    setActive={(e) => {
                        console.log('set active', e);
                        changeTagStatus(e, true)
                    }} setDeActive={(e) => {
                        console.log('set deactive', e);
                        changeTagStatus(e, false)
                    }} />*/}
            </Container>
        </div>
    )
}
export default AboutUs;