import { useState } from 'react';
import { Link } from "react-router-dom";
import { BaseColor, Images } from "../config";

import { toast } from 'react-toastify';
import PropTypes from "prop-types";

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import SweetAlert from 'react-bootstrap-sweetalert';
import { httpPost } from '../Services/HttpServices';
function Photos(props) {
  const { photos } = props
  const [delPhoto, setDelPhoto] = useState(false)
  const [selectedPhoto, setSelectedPhoto] = useState("")
  const removePhoto = () => {
    if(selectedPhoto){
      let filterPhotos = photos.filter(item => item !== selectedPhoto)
      httpPost('user_controller/update', {
        UserImages: filterPhotos
      }).then(res => {
        if (res && res.status) {
          props.removePhoto(filterPhotos)
          setDelPhoto(false)
          toast.success(res.message)
        } else {
          toast.error(res.message);
        }
      })
    }else{
      toast.error('Oops! Please try again')
    }
  }
  return (
    <>
      <Row className="pt-5" className="photo-list">
        {photos.map((ele, index) => (
          <Col md="4" xs="4" className='pr-md-0'>
            <img src={Images.close} className="img" style={{width: '30px', cursor: 'pointer'}} onClick={(e) => { setSelectedPhoto(ele); setDelPhoto(true) }} />
            <img src={ele} className="img" />
          </Col>
        ))}
        <SweetAlert
          show={delPhoto ? true : false}
          warning
          showCancel
          confirmBtnText={"Yes"}
          cancelBtnText={"No"}
          title={<span style={{ fontSize: 25 }} >Do you want to remove this picture?</span>}
          onConfirm={removePhoto}
          onCancel={() => { setDelPhoto(false) }}
        />
      </Row>
    </>
  )
}

Photos.prototype = {
    photos: PropTypes.array
}

Photos.defaultProps = {
    photos: []
}
export default Photos;