import React from 'react';
import { BaseColor, Images } from "../config";

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Image from 'react-bootstrap/Image';
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';

import PropTypes from "prop-types";
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment";
import 'moment-timezone';
import { httpPost } from '../Services/HttpServices';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

function Reply(props) {
    const timeZone  = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const state = useSelector(state => state)
    var auth = useSelector(({ auth }) => auth);
    let profilePicture = auth.ProfilePicture ? auth.ProfilePicture : Images.defaultProfile;
    const [loading, setLoading] = useState(false);

    const [commentCount, setCommentCount] = useState(props.commentCount)
    const [editComment, setEditComment] = useState(false);
    const [deleteComment, setDeleteComment] = useState(false);
    const [commentText, setCommentText] = useState("");

    const [replyList, setReplyList] = useState([])
    const [answerID, setAnswerID] = useState("");

    const [reply, setReply] = useState("");
    const [editReply, setEditReply] = useState(false);
    const [deleteReply, setDeleteReply] = useState(false);
    const [replyId, setReplyId] = useState("");
    const [replyText, setReplyText] = useState("");
    const [showReply, setShowReply] = useState(true)

    const [currentPage, setCurrentPage] = useState(1)
   

    const [commentID, setCommentID] = useState("")
    const [totalCount, setTotalCount] = useState(0)

    const [reportDisplay, setReportDisplay] = useState(false);
    const [reportTypes, setReportTypes] = useState([]);
    const [report, setReport] = useState({
        type: null,
        text: ""
    });

    useEffect(() => {
        getReplyList()
    }, [])
    function getReplyList() {
        var commentObj = {
            QuestionID: comment.QuestionID._id,
            AnswerID: comment.AnswerID._id,
            ParentID: comment._id,
            page: currentPage
        }
        httpPost("comment_controller/list", commentObj)
            .then((res) => {
                // console.log(res.data, res.data.length, "ReplyList");
                if (res.status === 1){
                    let replyData = res.data;
                    replyData.map(ele => {
                        let percentage = ((ele.UpvoteCount / (ele.UpvoteCount + ele.DownvoteCount))*100);
                        percentage = (Math.round(isNaN(percentage) ? 0 : percentage)) + "%";
                        ele.percent = percentage
                    })
                    setReplyList(replyData);
                    if(totalCount === res.data.length)
                        setTotalCount(0)
                    else
                        setTotalCount(totalCount+res.data.length)
                }
                else
                    setTotalCount(0)
            })
    }

    let { question, answer, comment } = props;
 
    function handlePostReply() {
        var commentObj = {
            QuestionID: comment.QuestionID._id,
            AnswerID: comment.AnswerID._id,
            ParentID: comment._id,
            CommentText: reply
        }
        if(reply){
            httpPost("comment_controller/create", commentObj)
                .then((res) => {
                    if (res.status == 1) {
                        setReply("");
                        // setCommentCount(commentCount + 1)
                        toast.success(res.message)
                    } else {
                        toast.error(res.message)
                    }
                    getReplyList()
                })
        }
    }

    function handleReplyChange(e) {
        setReply(e.target.value)
    }

    function handleLoadMore() {
        currentPage++;
        setCurrentPage(currentPage);
        getReplyList();
    }

    const handleReport = () => {
        setLoading(true);
        let reportData = {
            QuestionID: comment.QuestionID._id,
            AnswerID: comment.AnswerID._id,
            CommentID: comment._id,
            ReportType: report.type,
            ReportText: report.text
        }
        httpPost('comment_report_controller/create', reportData).then(res => {
            setLoading(false);
            if (res) {
                if (res.status) {
                    setReport({
                        type: null,
                        text: ""
                    });
                    setReportDisplay(false);
                    setCommentID("");
                    toast.success(res.message)
                } else {
                    toast.error(res.message);
                }
            } else {
                toast.error('Network Error')
            }
        }).catch(err => {
            setLoading(false);
            toast.error('Network Error')
        })
    }

    const updateComment = () => {
        setLoading(true);
        httpPost('comment_controller/update', {
            AnswerID: comment.AnswerID._id,
            QuestionID: comment.QuestionID._id,
            CommentID: comment._id,
            CommentText: commentText,
        }).then(res => {
            setLoading(false);
            if (res) {
                if (res.status) {
                    setEditComment(false);
                    toast.success(res.message);
                } else {
                    toast.error(res.message);
                }
            }
        }).catch(err => {
            setLoading(false);
            console.log('err', err);
        })
    }

    const deleteCommentData = () => {
        httpPost('comment_controller/delete', {
            CommentID: comment._id
        }).then(res => {
            if (res) {
                if (res.status) {
                    props.removeComment(comment._id)
                    props.updateCount(commentCount - 1)
                    setDeleteComment(false)
                    toast.success(res.message);
                } else {
                    toast.error(res.message);
                }
            }
        }).catch(err => {
            console.log('err', err);
        })
    }

    const openReplyModal = (e) => {
        setReplyId(e._id);
        setReplyText(e.CommentText);
        setEditReply(true)
    }

    const showReplyDeleteToast = (e) => {
        setReplyId(e._id);
        setReplyText(e.CommentText);
        setDeleteReply(true)
    }
    const updateReply = () => {
        setLoading(true);
        httpPost('comment_controller/update', {
            AnswerID: comment.AnswerID._id,
            QuestionID: comment.QuestionID._id,
            CommentID: replyId,
            CommentText: replyText,
        }).then(res => {
            setLoading(false);
            if (res) {
                if (res.status) {
                    setEditReply(false);
                    getReplyList()
                    toast.success(res.message);
                } else {
                    toast.error(res.message);
                }
            }
        }).catch(err => {
            setLoading(false);
            console.log('err', err);
        })
    }

    const deleteReplyData = () => {
        httpPost('comment_controller/delete', {
            CommentID: replyId
        }).then(res => {
            if (res) {
                if (res.status) {
                    setDeleteReply(false)
                    getReplyList()
                    toast.success(res.message);
                } else {
                    toast.error(res.message);
                }
            }
        }).catch(err => {
            console.log('err', err);
        })
    }

    return (
        <div className="" style={{ flex: '9', display: 'flex', flexDirection: 'column' }}>
            <div className="action" >
                <label onClick={e => setShowReply(showReply ? false : true) } style={{ marginRight: 20, fontSize: 12 }}><span style={{verticalAlign: 'inherit'}} >Reply <Image style={{ marginRight: 15 }} src={Images.reply} width={15} height={15} /></span></label>
                {auth && auth.user && auth.user._id && auth.user._id === comment.UserID._id ? (
                    <label style={{ marginRight: 15 }} className="for-dropdown">
                    <DropdownButton className="menu-three-dots-button" id="dropdown-item-button" title={<Image src={Images.dotIcon} />}>
                        <Dropdown.Item as="button" onClick={() => { setEditComment(true); }}>Edit</Dropdown.Item>
                        <Dropdown.Item as="button" onClick={() => { setDeleteComment(true); }}>Delete</Dropdown.Item>
                    </DropdownButton>
                </label>
                ) : (
                    <>
                        <label>
                            <Dropdown.Item as="button" onClick={() => { setReportDisplay(!reportDisplay); setCommentID(comment._id); }}>Report</Dropdown.Item>
                        </label>
                    </>
                    )}
            </div>
            {/* Edit Comment  */}
            <Modal show={editComment} onHide={() => { setEditComment(false); }} size="md">
                <Modal.Header closeButton>
                    <Modal.Title>Edit Comment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md="12">
                            <Form.Control defaultValue={comment.CommentText} onChange={(e) => { setCommentText(e && e.target && e.target.value || null) }} as="textarea" placeholder="Content..." rows={3} />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { setEditComment(false); }}>
                        Cancel
                    </Button>
                    <Button disabled={loading} variant="primary" onClick={() => { updateComment() }}>
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>
            <SweetAlert
                show={deleteComment ? true : false}
                warning
                showCancel
                confirmBtnText={"Yes"}
                cancelBtnText={"No"}
                title={<span style={{ fontSize: 25 }} >Do you want to delete this Comment</span>}
                onConfirm={deleteCommentData}
                onCancel={() => { setDeleteComment(false) }}
            />
            {/* Edit Reply  */}
            <Modal show={editReply} onHide={() => { setEditReply(false); }} size="md">
                <Modal.Header closeButton>
                    <Modal.Title>Edit Reply</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md="12">
                            <Form.Control defaultValue={replyText} onChange={(e) => { setReplyText(e && e.target && e.target.value || null) }} as="textarea" placeholder="Content..." rows={3} />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { setEditReply(false); }}>
                        Cancel
                    </Button>
                    <Button disabled={loading} variant="primary" onClick={() => { updateReply() }}>
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>
            <SweetAlert
                show={deleteReply ? true : false}
                warning
                showCancel
                confirmBtnText={"Yes"}
                cancelBtnText={"No"}
                title={<span style={{ fontSize: 25 }} >Do you want to delete this reply?</span>}
                onConfirm={deleteReplyData}
                onCancel={() => { setDeleteReply(false) }}
            />
            {showReply ? (
                <Row className="row" style={{ paddingTop: 10, alignItems: 'center' }}>
                    <Col md="9" sm="9" xs="9" >
                        <div className="input-border" style={{ display: 'flex', alignItems: 'center' }}>
                            <Image style={{ marginLeft: 5 }} src={profilePicture} height={35} width={35} roundedCircle />
                            <InputGroup className="">
                                <FormControl style={{ border: 0, boxShadow: 'none', marginRight: 5, backgroundColor: 'inherit' }} type="text" value={reply} placeholder="Reply..." size="lg" onChange={handleReplyChange} commentID={comment._id} />
                                <InputGroup.Append>
                                </InputGroup.Append>
                            </InputGroup>
                        </div>
                    </Col>
                    <Col md="2" sm="3" xs="3">
                        <Button style={{ color: BaseColor.whiteColor, width: "100%", height: "100%" }} onClick={handlePostReply}>Reply</Button>
                    </Col>
                </Row>
            ) : null}
            {showReply && replyList && replyList.map(ele => (
                <>
                <div className="mt-2 mb-2" >
                    <div style={{ display: 'flex', alignItems: 'center', paddingBottom: 5}}>
                        <div style={{ flex: '0.6' }} className="pr-2 pr-md-0 pr-lg-0 pr-sm-2">
                            <a href={"/profile?id="+(ele.UserID && ele.UserID._id)} >
                                <Image src={ele.UserID.ProfilePicture ? ele.UserID.ProfilePicture : Images.defaultProfile} width={35} height={35} roundedCircle />
                            </a>
                        </div>
                        <div style={{ flex: '9', display: 'flex', flexDirection: 'column' }}>
                            <span className="capitalize" style={{}}><b>{ele.UserID && (ele.UserID.FirstName + ' ' + ele.UserID.LastName)}</b>
                            {auth && auth.user && auth.user._id && auth.user._id === comment.UserID._id ? (
                                <label style={{ marginRight: 15 }} className="for-dropdown ml-4">
                                <DropdownButton className="menu-three-dots-button" id="dropdown-item-button" title={<Image src={Images.dotIcon} />}>
                                    <Dropdown.Item as="button" onClick={() => { openReplyModal(ele); }}>Edit</Dropdown.Item>
                                    <Dropdown.Item as="button" onClick={() => { showReplyDeleteToast(ele); }}>Delete</Dropdown.Item>
                                </DropdownButton>
                            </label>
                            ) : ("")}
                            <small className="post-date float-right" >{"replied . " + moment.tz(ele.CreatedDate, timeZone).fromNow()}</small></span>
                            <span style={{}}><b>{ele.text}</b></span>
                        </div>
                    </div>
                    <Row>
                        <Col xs={{ span: 11, offset: 1 }} >
                            <p>{ele.CommentText ? !ele.showFull && ele.CommentText.length > 400 ? ele.CommentText.substring(0, 400) : ele.CommentText : null}</p>
                        </Col>
                    </Row>
                    <div className="action" >
                    </div>
                </div>
                </>
            ))}
            {totalCount > 10 ? (<div style={{ textAlign: 'center' }}>
                    <label style={{ color: BaseColor.secondaryColor, display: props.displaySeeMore, cursor: "pointer" }} onClick={handleLoadMore}><b>See more reply</b></label>
                </div>) : null}

            {/* Report Comment  */}
            <Modal show={reportDisplay} onHide={() => { setReport({ text: '', type: null }); setReportDisplay(false); }}>
                <Modal.Header closeButton>
                    <Modal.Title as="h4" >Report Comment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="row" style={{ paddingTop: 10, alignItems: 'center' }}>
                        <Col md="12" sm="12" xs="12" >
                            <Form.Check onClick={() => setReport({ ...report, type: '1' })} checked={report.type == '1' ? true : false} type={'radio'} value={"1"} label="Harassment" />
                            <Form.Check onClick={() => setReport({ ...report, type: '2' })} checked={report.type == '2' ? true : false} type={'radio'} value={"2"} label="Spam" />
                            <Form.Check onClick={() => setReport({ ...report, type: '3' })} checked={report.type == '3' ? true : false} type={'radio'} value={"3"} label="Insincere" />
                            <Form.Check onClick={() => setReport({ ...report, type: '4' })} checked={report.type == '4' ? true : false} type={'radio'} value={"4"} label="Poorly Written" />
                            <Form.Check onClick={() => setReport({ ...report, type: '5' })} checked={report.type == '5' ? true : false} type={'radio'} value={"5"} label="Incorrect Topic" />
                            <Form.Check onClick={() => setReport({ ...report, type: '6' })} checked={report.type == '6' ? true : false} type={'radio'} value={"6"} label="Offensive" />
                        </Col>
                        <Col md="12" sm="12" xs="12" className='mt-3'>
                            <div className="input-border" style={{ display: 'flex', alignItems: 'center' }}>
                                <Image style={{ marginLeft: 5 }} src={profilePicture} height={25} width={25} roundedCircle />
                                <InputGroup className="">
                                    <FormControl value={report.text} style={{ border: 0, boxShadow: 'none', marginRight: 5, backgroundColor: 'inherit' }} type="text" placeholder="Explain the report" size="lg" onChange={(e) => {
                                        setReport({ ...report, text: e && e.target && e.target.value || null });
                                    }} />
                                    <InputGroup.Append>
                                    </InputGroup.Append>
                                </InputGroup>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" className="btn-sm" onClick={() => { setReport({ text: '', type: null }); setReportDisplay(false); }}>
                        Cancel
                    </Button>
                    <Button disabled={loading} variant="primary" className="btn-sm" onClick={() => { handleReport() }}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

Reply.prototype = {
    question: PropTypes.object,
    showFullText: PropTypes.func,
    link: PropTypes.string,
    show: PropTypes.bool,
    updateList: PropTypes.func,
}

Reply.defaultProps = {
    question: {},
    showFullText: () => { },
    link: null,
    show: true,
    updateList: () => { },
}
export default Reply;