import { useEffect, useRef, useState } from 'react';
import { Row, Card, Col, Container, Form, Image, Spinner, Button, ProgressBar } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Related from '../Component/Related';
import TopicCard from '../Component/TopicCard';
import { BaseColor, Images } from '../config';
import { httpPost, httpGet } from '../Services/HttpServices';
import { Link, Redirect, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Helmet from 'react-helmet';
//Custom use previous hook
function usePrevious(value) {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef();
    // Store current value in ref
    useEffect(() => {
        ref.current = value;
    }, [value]); // Only re-run if value changes
    // Return previous value (happens before update in useEffect above)
    return ref.current;
}

function PrivateArea(props) {

    const state = useSelector(state => state);
    var auth = useSelector(({ auth }) => auth);
    // const path = props.location.pathname;
    const TopicID = props.match.params.id;
    console.log(TopicID, "IDTopic")
    
    let profilePicture = auth.ProfilePicture ? auth.ProfilePicture : Images.defaultProfile;
    const [userDetail, setUserDetail] = useState(null);
    const [topics, setTopics] = useState([]);
    const [topicDetail, setTopicDetail] = useState([]);
    const [trendingQuestions, setTrendingQuestions] = useState([]);

    const [metaData, setMetaData] = useState([]);

    useEffect(() => {
        getTopicList();
        httpPost("home_page/get_meta_tag",{PageName: "Topic Page"})
            .then(res => {
                if (res && res.status) {
                    setMetaData(res.data);
                }
            })
        getTrendingQuestions();
    }, [TopicID])

    const getTopicList = () => {
        //user_
        // httpPost("topic_controller/list")
        httpPost("topic_controller/private_list")
            .then(res => {
            if (res && res.data) {
                if (res.data.length > 0) {
                    setTopics(res.data)
                }
            }
        })
    }
    
    var trendingQuestionList = []
    const getTrendingQuestions = () => {
        httpPost("question_controller/trending", { page: 1})
            .then(res => {
                if (res && res.data) {
                    if (res.totalCount > 0) {
                        res.data.slice(0, 5).map((element, i) => {
                            trendingQuestionList.push({
                                id: element._id,
                                image: (element.UserID && element.UserID.ProfilePicture ? element.UserID.ProfilePicture : Images.defaultProfile),
                                url: element.QuestionUrl,
                                text: element.Subject,
                                // post: moment(Date(element.CreatedDate)).fromNow(),
                                // by: (element.UserID) ? element.UserID.FirstName + " " + element.UserID.LastName : ""
                            })
                        });
                    }
                    setTrendingQuestions(trendingQuestionList)
                }
            })
    }
    return (
        <div className="p-2 p-md-3 p-lg-3 p-sm-2 question-feed">
            <Helmet>
                <title>{metaData && metaData.Title ? metaData.Title : "Restricted Area"}</title>
                <meta name="description" content={metaData && metaData.Description ? metaData.Description : "English Forward Feed"} />
                <meta name="keyword" content={metaData ? metaData.Keywords : ""} />
            </Helmet>
            {auth.user === null ? (<Redirect to="/signin" />): null }
            <Container fluid>
                <Row>
                    <Col md="1" className="p-2 p-md-3 p-lg-3 p-sm-2">
                    </Col>
                    <Col md="8" className="p-2 p-md-3 p-lg-3 p-sm-2">
                        <Row className="ml-4" >
                            <Col md="12" >
                                {topicDetail ? (
                                <div className="mb-3" >
                                    <div>
                                        <h3 className="mb-2" ><b>Restricted Area</b></h3>
                                        <div>This area has {topics.length} sections and 11712 posts.</div>
                                    </div>
                                </div>) : null}
                            </Col>

                            {topics.map(topic => (
                                <Col md="6" >
                                    <TopicCard topic={topic} />
                                </Col> 
                            ))}
                        </Row>
                    </Col>
                    <Col md="3" className="p-2 p-md-3 p-lg-3 p-sm-2" >
                        <Row style={{ paddingBottom: 20 }}>
                            <Card style={{ width: '18rem' }}>
                                <Card.Body>
                                    <Related
                                        heading="Trending Questions"
                                        list={trendingQuestions}
                                        link=""
                                    />
                                </Card.Body>
                            </Card>
                        </Row>
                    </Col>
                </Row>
            </Container>

        </div>
    )
}
export default PrivateArea;