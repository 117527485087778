import React, { Component, useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import Form from 'react-bootstrap/Form';
import { Images } from '../config';
import { httpPost } from '../Services/HttpServices';
import SweetAlert from 'react-bootstrap-sweetalert';
import { toast } from 'react-toastify';
import moment from 'moment';

class SelectTopics extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userDetail: [],
            topics: []
        };
    }

    handleChange = (e) => {
        let name = e.target.id
        let value = e.target.value || ""
        let userDetail = { ...this.state.userDetail };
        let userTopics = userDetail.TopicID && userDetail.TopicID.split(',') || [];
        if (e.target.name == "TopicID") {
            name = "TopicID";
            console.log('user', e.target.name, e.target.value);
            var ind = userTopics.indexOf(e.target.id);
            if (ind > -1) {
                userTopics.splice(ind, 1);
            } else {
                userTopics.push(e.target.id);
            }
            value = userTopics.toString();
        }
        userDetail[name] = value;

        this.setState({
            ...this.state,
            userDetail: userDetail
        }, () => {
            console.log('user', this.state.userDetail);
        })
    }
   
    componentDidMount() {
        this.getDetails();
    }

    getDetails = () => {
        httpPost("topic_controller/list")
            .then(res => {
                if (res && res.data) {
                    if (res.data.length > 0) {
                        if (res.data && res.data.length > 0) {
                            this.setState({
                                topics: res.data || []
                            })
                        }
                    }
                }
            })

        httpPost("user_controller/retrive", { UserID: this.props.auth.user._id })
            .then((res) => {
                if (res.data && res.data) {
                    var topic = res.data.MyTopic;
                    var topicArr = [];
                    if (res.data.MyTopic && res.data.MyTopic.length > 0) {
                        res.data.MyTopic.map(ele => {
                            topicArr.push(ele._id);
                        })
                    }
                    this.setState({
                        userDetail: {
                            "TopicID": topicArr.toString(),
                        },
                    })
                }
            })
    }
    onSubmit = (e) => {
        e.preventDefault();
        const _this = this;
        if (_this.TopicID !== "") {
            httpPost("user_controller/update", _this.state.userDetail)
                .then((res) => {
                    console.log(res);
                    if (res.status == 1) {
                        toast.success(res.message);
                        _this.props.history.push('/feed');
                    } else {
                        toast.error(res.message)
                    }
                })
        } else {
            toast.error("Please select topics");
        }
    }
 
    render() {
        let { formErr } = this.state;
        return (
            <div className="edit-profile select-topic" >
                {this.props.auth.user === null ? (<Redirect to="/signin" />) : null}
                <Container>
                    <Row>
                        <Col className="text-center" >
                            <h2>Select Topics</h2>
                            <p>Select topics that you would like to follow. Questions and answers in these topics will shown on your feed.</p>
                        </Col>
                    </Row>
                    <form onSubmit={this.onSubmit} >
                    <Row>
                                        
                        {this.state.topics.map(ele => (
                            <div className="col-md-4" >
                                <Card className={this.state.userDetail.TopicID && this.state.userDetail.TopicID.split(',').includes(ele._id) ? 'active' : ''}
                                 >
                                    <Card.Body style={{wordBreak: 'break-word', padding: 'inherit'}} >
                                        <Form.Check className="" name="TopicID" id={ele._id} type="checkbox" label={ele.Title}
                                            checked={this.state.userDetail.TopicID && this.state.userDetail.TopicID.split(',').includes(ele._id) ? true : false}
                                            onChange={this.handleChange} />
                                    </Card.Body>
                                </Card>
                            </div>
                        ))}
                    </Row>
                    <Row>
                        <Col md="12" className="text-center" >
                            <Button type="submit" variant="outline-primary" >Submit</Button>
                        </Col>
                    </Row>
                    </form>
                </Container>
            </div>
        )
    }
}

const mapStateToProps = ({ auth }) => {
    return { auth };
}
export default connect(mapStateToProps, {
})(SelectTopics)