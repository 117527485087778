import React, { Component } from "react";
import {
    Button,
    Col,
    Container,
    FormControl,
    Image,
    InputGroup,
    Row,
    Table,
} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import { Images } from "../config";
import { httpPost } from "../Services/HttpServices";

class Privacy extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="privacy">
                <Container>
                    <Row>
                        <Col md="12" className="p-3 text-center">
                            <h1>Privacy</h1>
                        </Col>
                        <Col md="12" className="p-2">
                            <p>
                                This privacy policy explains how EnglishForums.com (trading as
                                EnglishForward.com) or ‘we’ use any personal information we
                                collect about you when you use this website.
                            </p>
                            <p>
                                <b>Topics:</b>
                                <br />
                                <ul>
                                    <li>
                                        <b>What information do we collect about you?</b>
                                    </li>
                                    <li>
                                        <b>Why we need your data</b>
                                    </li>
                                    <li>
                                        <b>How long will we store your data?</b>
                                    </li>
                                    <li>
                                        <b>Marketing</b>
                                    </li>
                                    <li>
                                        <b>Access to your information and correction</b>
                                    </li>
                                    <li>
                                        <b>Cookies</b>
                                    </li>
                                    <li>
                                        <b>Other websites</b>
                                    </li>
                                    <li>
                                        <b>Changes to our privacy policy</b>
                                    </li>
                                    <li>
                                        <b>How to contact us</b>
                                    </li>
                                </ul>
                            </p>
                            <br />

                            <p>
                                <b>What information do we collect about you?</b>
                            </p>
                            <p>
                                We collect information about you when you fill in a contact form
                                or email us directly. We also collect information when you
                                voluntarily comment on our blog posts, provide feedback and
                                participate in competitions. Website usage information is
                                collected using cookies.
                            </p>

                            <p>
                                The information we collect about you may include; your name,
                                email address, address, phone number, IP address, location,
                                company name, sex, age, your chosen internet browser and service
                                provider, and device plus any information you supply
                                voluntarily.
                            </p>
                            <p>
                                If you call the English Forward office your call may be recorded
                                for training and monitoring purposes. English Forward’s policy
                                states that all call recordings are stored securely for 30 days
                                and then deleted, however, if a call is needed for further
                                analysis it may be saved and stored securely for longer than 30
                                days.
                            </p>
                            <p>
                                If you email anyone at English Forward your email will be stored
                                securely on our chosen email provider which is Gmail and may be
                                shared within the English Forward team unless you have stated
                                that the email is confidential. You can read Gmail’s privacy
                                policy here:
                            </p>
                            <p>
                                <a href="https://policies.google.com/privacy">
                                    https://policies.google.com/privacy
                                </a>{" "}
                                . Note, everyone who works at English Forward has an email
                                address ending in .com for your security, never email someone at
                                English Forward if they do not have this email alias.
                            </p>
                            <p>
                                If you have opted in to receive email updates from English
                                Forward we distribute all our emails through Amazon SES, you can
                                read their privacy policy here:{" "}
                                <a href="https://aws.amazon.com/privacy">
                                    https://aws.amazon.com/privacy
                                </a>
                            </p>
                            <p>
                                <b>Why we need your data</b>
                            </p>
                            <p>
                                We collect information about you to; fulfill your request e.g.
                                if you fill out a ‘request a callback’ form we use this
                                information to contact you directly, process your order, manage
                                your account and, if you agree, to email you about other
                                products, services, and information we think may be of interest
                                to you. We may use your contact information to advertise our
                                service to you on third-party websites such as Facebook and
                                Twitter. We may also contact you directly to follow up on the
                                service and user experience we provide. We use your information
                                collected from the website to personalise your repeat visits to
                                our website. If you agree, we shall pass on your personal
                                information to third parties so that they may offer you their
                                products and services. If English Forward is ever sold to
                                another company your contact information will be passed on to
                                this new party.
                            </p>
                            <p>
                                We will take cautionary measure to ensure we do not collect any
                                personal data from you we do not need in order to provide and
                                oversee the above services to you.
                            </p>
                            <p>
                                <b>How long will we store your data?</b>
                                <br />
                                If English Forward provides any paid for services to you, we are
                                required under Malta tax law to keep your basic personal data
                                (name, address, contact details) for a minimum of 10 years after
                                which time it will be destroyed. Your information we use for
                                marketing purposes will be kept with us until you notify us that
                                you no longer wish to receive this information.
                            </p>
                            <p>
                                <b>Marketing</b>
                            </p>
                            <p>
                                We would like to send you updates and information about products
                                and services of ours and other companies which may be of
                                interest to you. If you have consented to receive marketing, you
                                may opt out at a later date. You have a right at any time to
                                stop us from contacting you for marketing purposes or giving
                                your information to other parties. If you no longer wish to be
                                contacted for marketing purposes please click here.
                            </p>
                            <p>
                                <b>What are your rights?</b>
                            </p>
                            <p>
                                You have the right to request a copy of the information that we
                                hold about you. If you would like a copy of some or all of your
                                personal information, please email or write to us at the
                                following address.
                            </p>
                            <p>Email: hello@EnglishForward.com</p>
                            <p>
                                Address: English Forward, 15, Level 1, Suite 4, Naxxar Road,
                                Birkirkara, BKR9049, Malta
                            </p>
                            <p>
                                We want to make sure that your personal information is accurate
                                and up to date. You may ask us to correct or remove information
                                you think is inaccurate.
                            </p>
                            <p>
                                If you are not satisfied with our response or believe we are
                                processing your personal data not in accordance with the law you
                                can complain to the
                                <a href="https://idpc.org.mt/en/Pages/contact/complaints.aspx">
                                    <i>Information Commissioner’s Office</i>
                                </a>
                            </p>
                            <p>
                                <b>Cookies</b>
                            </p>
                            <p>
                                Cookies are text files placed on your computer to collect
                                standard internet log information and visitor behaviour
                                information. This information is used to track visitor use of
                                the website and to compile statistical reports on website
                                activity. For further information visit www.aboutcookies.org or
                                www.allaboutcookies.org. You can set your browser not to accept
                                cookies and the above websites tell you how to remove cookies
                                from your browser. However, in a few cases, some of our website
                                features may not function as a result.
                            </p>
                            <p>
                                English Forward's website uses cookies. We use cookies to
                                personalise content and ads, to provide social media features
                                and to analyse our traffic. We also share information about your
                                use of our site with our social media, advertising and analytics
                                partners who may combine it with other information that you’ve
                                provided to them or that they’ve collected from your use of
                                their services. You consent to our cookies if you continue to
                                use our website.
                            </p>
                            <p>
                                Cookies are small text files that can be used by websites to
                                make a user's experience more efficient.
                            </p>
                            <p>
                                The law states that we can store cookies on your device if they
                                are strictly necessary for the operation of this site. For all
                                other types of cookies we need your permission.
                            </p>
                            <p>
                                This site uses different types of cookies. Some cookies are
                                placed by third party services that appear on our pages.
                            </p>
                            <p>
                                You can at any time change or withdraw your consent from the
                                Cookie Declaration on our website.
                            </p>
                            <p>
                                Learn more about who we are, how you can contact us and how we
                                process personal data in our Privacy Policy.
                            </p>
                            <p>
                                Your consent applies to the following domains:
                                EnglishForward.com
                            </p>
                            <p>Your current state: Use necessary cookies only.</p>
                            <p>Change your consent</p>
                            <p>
                                Cookie declaration last updated on 24/11/2018 by{" "}
                                <a href="https://www.cookiebot.com/">Cookiebot:</a>
                            </p>

                            <h2>Advertising Privacy Statement</h2>
                            <p>
                                This Site is affiliated with CMI Marketing, Inc., d/b/a
                                Raptive (“Raptive”) for the purposes of placing advertising on the Site,
                                and Raptive will collect and use certain data for advertising purposes.
                                To learn more about Raptive’s data usage, click <a href="https://raptive.com/creator-advertising-privacy-statement/">  here:  </a>
                            </p>
                            <p>
                                <b>Necessary(3)</b>
                            </p>
                            <p>
                                Necessary cookies help make a website usable by enabling basic
                                functions like page navigation and access to secure areas of the
                                website. The website cannot function properly without these
                                cookies.
                            </p>
                            <p>
                                <Table striped bordered hover variant="dark">
                                    <thead>
                                        <tr align="left">
                                            <th>NAME</th>
                                            <th>PROVIDER</th>
                                            <th>PURPOSE</th>
                                            <th>EXPIRY</th>
                                            <th>TYPE</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>__cfduid</td>
                                            <td>English Forward.com</td>
                                            <td>
                                                Used by the content network, Cloudflare, to identify
                                                trusted web traffic.
                                            </td>
                                            <td>1 year</td>
                                            <td>HTTP Cookie</td>
                                        </tr>
                                        <tr>
                                            <td>CookieConsent</td>
                                            <td>English Forward.com</td>
                                            <td>
                                                Stores the user's cookie consent state for the current
                                                domain
                                            </td>
                                            <td>1 year</td>
                                            <td>HTTP Cookie</td>
                                        </tr>
                                        <tr>
                                            <td>DYNSRV</td>
                                            <td>English Forward.com</td>
                                            <td>
                                                This cookie is used in context with load balancing -
                                                This optimizes the response rate between the visitor and
                                                the site, by distributing the traffic load on multiple
                                                network links or servers.
                                            </td>
                                            <td>session</td>
                                            <td>HTTP Cookie</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </p>
                            <p>
                                <b>Marketing(20)</b>
                            </p>
                            <p>
                                Marketing cookies are used to track visitors across websites.
                                The intention is to display ads that are relevant and engaging
                                for the individual user and thereby more valuable for publishers
                                and third party advertisers.
                            </p>
                            <p>
                                <table striped bordered hover variant="dark">
                                    <thead>
                                        <tr align="left">
                                            <th>NAME</th>
                                            <th>PROVIDER</th>
                                            <th>PURPOSE</th>
                                            <th>EXPIRY</th>
                                            <th>TYPE</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>_fbp</td>

                                            <td>English Forward.com</td>
                                            <td>
                                                Used by Facebook to deliver a series of advertisement
                                                products such as real time bidding from third party
                                                advertisers.
                                            </td>
                                            <td>1 day</td>
                                            <td>HTTP Cookie</td>
                                        </tr>
                                        <tr>
                                            <td>ads/ga-audiences</td>
                                            <td>google.com</td>
                                            <td>
                                                Used by Google AdWords to re-engage visitors that are
                                                likely to convert to customers based on the visitor's
                                                online behaviour across websites.
                                            </td>
                                            <td>Session</td>
                                            <td>Pixel Tracker</td>
                                        </tr>
                                        <tr>
                                            <td>CacheStorageVersion</td>
                                            <td>facebook.com</td>
                                            <td>
                                                Used to track visitors on multiple websites, in order to
                                                present relevant advertisement based on the visitor's
                                                preferences.
                                            </td>
                                            <td>Persistent</td>
                                            <td>HTML Local Storage</td>
                                        </tr>
                                        <tr>
                                            <td>fr</td>
                                            <td>facebook.com</td>
                                            <td>
                                                Used by Facebook to deliver a series of advertisement
                                                products such as real time bidding from third party
                                                advertisers.
                                            </td>
                                            <td>3 months</td>
                                            <td>HTTP Cookie</td>
                                        </tr>
                                        <tr>
                                            <td>GPS</td>
                                            <td>youtube.com</td>
                                            <td>
                                                Registers a unique ID on mobile devices to enable
                                                tracking based on geographical GPS location.
                                            </td>
                                            <td>1 day</td>
                                            <td>HTTP Cookie</td>
                                        </tr>
                                        <tr>
                                            <td>IDE</td>
                                            <td>doubleclick.net</td>
                                            <td>
                                                Used by Google DoubleClick to register and report the
                                                website user's actions after viewing or clicking one of
                                                the advertiser's ads with the purpose of measuring the
                                                efficacy of an ad and to present targeted ads to the
                                                user.
                                            </td>
                                            <td>1 year</td>
                                            <td>HTTP Cookie</td>
                                        </tr>
                                        <tr>
                                            <td>pagead/1p-user-list/#</td>
                                            <td>google.com</td>
                                            <td>Pending</td>
                                            <td>Session</td>
                                            <td>Pixel Tracker</td>
                                        </tr>
                                        <tr>
                                            <td>PREF</td>
                                            <td>youtube.com</td>
                                            <td>
                                                Registers a unique ID that is used by Google to keep
                                                statistics of how the visitor uses YouTube videos across
                                                different websites.
                                            </td>
                                            <td>8 months</td>
                                            <td>HTTP Cookie</td>
                                        </tr>
                                        <tr>
                                            <td>test_cookie</td>
                                            <td>doubleclick.net</td>
                                            <td>
                                                Used to check if the user's browser supports cookies.
                                            </td>
                                            <td>1 day</td>
                                            <td>HTTP Cookie</td>
                                        </tr>
                                        <tr>
                                            <td>tr</td>
                                            <td>facebook.com</td>
                                            <td>
                                                Used by Facebook to deliver a series of advertisement
                                                products such as real time bidding from third party
                                                advertisers.
                                            </td>
                                            <td>Session</td>
                                            <td>Pixel Tracker</td>
                                        </tr>
                                        <tr>
                                            <td>VISITOR_INFO1_LIVE</td>
                                            <td>youtube.com</td>
                                            <td>
                                                Tries to estimate the users' bandwidth on pages with
                                                integrated YouTube videos.
                                            </td>
                                            <td>179 days</td>
                                            <td>HTTP Cookie</td>
                                        </tr>
                                        <tr>
                                            <td>YSC</td>
                                            <td>youtube.com</td>
                                            <td>
                                                Registers a unique ID to keep statistics of what videos
                                                from YouTube the user has seen.
                                            </td>
                                            <td>Session</td>
                                            <td>HTTP Cookie</td>
                                        </tr>
                                        <tr>
                                            <td>yt.innertube::nextId</td>
                                            <td>youtube.com</td>
                                            <td>
                                                Registers a unique ID to keep statistics of what videos
                                                from YouTube the user has seen.
                                            </td>
                                            <td>Persistent</td>
                                            <td>HTML Local Storage</td>
                                        </tr>
                                        <tr>
                                            <td>yt.innertube::requests</td>
                                            <td>youtube.com</td>
                                            <td>
                                                Registers a unique ID to keep statistics of what videos
                                                from YouTube the user has seen.
                                            </td>
                                            <td>Persistent</td>
                                            <td>HTML Local Storage</td>
                                        </tr>
                                        <tr>
                                            <td>yt-remote-cast-installed</td>
                                            <td>youtube.com</td>
                                            <td>
                                                Stores the user's video player preferences using
                                                embedded YouTube video
                                            </td>
                                            <td>Session</td>
                                            <td>HTML Local Storage</td>
                                        </tr>
                                        <tr>
                                            <td>yt-remote-connected-devices</td>
                                            <td>youtube.com</td>
                                            <td>
                                                Stores the user's video player preferences using
                                                embedded YouTube video
                                            </td>
                                            <td>Persistent</td>
                                            <td>HTML Local Storage</td>
                                        </tr>
                                        <tr>
                                            <td>yt-remote-device-id</td>
                                            <td>youtube.com</td>
                                            <td>
                                                Stores the user's video player preferences using
                                                embedded YouTube video
                                            </td>
                                            <td>Persistent</td>
                                            <td>HTML Local Storage</td>
                                        </tr>
                                        <tr>
                                            <td>yt-remote-fast-check-period</td>
                                            <td>youtube.com</td>
                                            <td>
                                                Stores the user's video player preferences using
                                                embedded YouTube video
                                            </td>
                                            <td>Session</td>
                                            <td>HTML Local Storage</td>
                                        </tr>
                                        <tr>
                                            <td>yt-remote-session-app</td>
                                            <td>youtube.com</td>
                                            <td>
                                                Stores the user's video player preferences using
                                                embedded YouTube video
                                            </td>
                                            <td>Session</td>
                                            <td>HTML Local Storage</td>
                                        </tr>
                                        <tr>
                                            <td>yt-remote-session-name</td>
                                            <td>youtube.com</td>
                                            <td>
                                                Stores the user's video player preferences using
                                                embedded YouTube video
                                            </td>
                                            <td>Session</td>
                                            <td>HTML Local Stor</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </p>
                            <p>
                                <b>Unclassified(1)</b>
                            </p>
                            <p>
                                Unclassified cookies are cookies that we are in the process of
                                classifying, together with the providers of individual cookies.
                            </p>
                            <p>
                                <Table striped bordered hover variant="dark">
                                    <thead>
                                        <tr align="left">
                                            <th>NAME</th>
                                            <th>PROVIDER</th>
                                            <th>PURPOSE</th>
                                            <th>EXPIRY</th>
                                            <th>TYPE</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                wp_teambooking_session_dd593daae2b50dbf93110c29ddcc8ae7
                                            </td>

                                            <td>English Forward.com</td>
                                            <td>Pending</td>
                                            <td>1 day</td>
                                            <td>HTTP Cookie</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </p>
                            <p>
                                <b>Other websites</b>
                            </p>
                            <p>
                                Our website contains links to other websites. This privacy
                                policy only applies to this website so when you link to other
                                websites you should read their own privacy policies.
                            </p>
                            <p>
                                <b>Changes to our privacy policy</b>
                            </p>
                            <p>
                                We keep our privacy policy under regular review and we will
                                place any updates on this web page. This privacy policy was last
                                updated on 24th May 2018.
                            </p>
                            <p>
                                <b>How to contact us</b>
                            </p>
                            <p>
                                Please contact us if you have any questions about our privacy
                                policy or information we hold about you:
                            </p>
                            <p>Email: hello@EnglishForward.com</p>
                            <p>
                                Address: English Forward, 15, Level 1, Suite 4, Naxxar Road,
                                Birkirkara, BKR9049, Malta
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Privacy;
