import { Link } from "react-router-dom";
import { BaseColor, Images } from "../config";

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Feed from '../Component/Feed';
import Comments from '../Component/Comments';
import PropTypes from "prop-types";
import { httpGet, httpPost } from '../Services/HttpServices';
import { toast } from 'react-toastify';
import moment from 'moment';
import 'moment-timezone';
import Editor from '../Component/QuillEditor';
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
    WhatsappIcon
  } from "react-share";
function QuestionList(props) {
    const timeZone  = Intl.DateTimeFormat().resolvedOptions().timeZone;
    var auth = useSelector(({ auth }) => auth);
    let profilePicture = auth.ProfilePicture ? auth.ProfilePicture : Images.defaultProfile;
    const [updating, setUpdating] = useState(null);
    const [answerpostdisplay, setAnswerPostDisplay] = useState(false)
    const [answer, setAnswer] = useState("")
    const [followdetail, setFollowDetail] = useState("Follow")
    const [isFollow, setIsFollow] = useState(null);
    const [questions, setQuestions] = useState([]);

    useEffect(() => {
        setQuestions(props.questions);
    }, [props.questions]);

    function handleAnswer() {
        setAnswerPostDisplay(answerpostdisplay ? false : true)
    }

    function handleCreateAnswer() {
        var answerObj = {
            Answer: answer,
            // QuestionID: question._id
        }
        httpPost("answer_controller/create", answerObj)
            .then(res => {
                if (res.status == 1) {
                    // props.updateQn(question._id);
                    toast.success(res.message);
                }
            })
        setAnswerPostDisplay(false)
    }

    const answerLater = (qData) => {
        let postData = {}
        postData.QuestionID = qData._id
        httpPost('answer_request_controller/answer_later', postData).then(res => {
            if (res) {
                if (res.status) {
                    toast.success(res.message);
                } else {
                    toast.error(res.message);
                }
            }
        }).catch(err => {
            console.log('err', err);
        })
    }
    const updateFeed = (feedData) => {
        // var arr = questions;
        // var ind = arr.findIndex(ele => ele._id == feedData._id);
        // if (ind > -1) {
        //     if(feedData.Type === "QUESTION"){
        //         httpGet('question_controller/' + arr[ind].QuestionUrl).then(res => {
        //             if (res && res.status && res.data) {
        //                 res.data.Type = "QUESTION";
        //                 res.data.percent = isNaN(res.data.UpvoteCount - res.data.DownvoteCount) ? 0 : res.data.UpvoteCount - res.data.DownvoteCount;
        //                 arr[ind] = res.data;
        //                 setQuestions(arr);
        //             }
        //         })
        //     }else{
        //         httpPost('answer_controller/retrive',{AnswerID: feedData._id}).then(res => {
        //             if (res && res.status && res.data) {
        //                 res.data.Type = "ANSWER";
        //                 res.data.percent = isNaN(res.data.UpvoteCount - res.data.DownvoteCount) ? 0 : res.data.UpvoteCount - res.data.DownvoteCount;
        //                 arr[ind] = res.data;
        //                 setQuestions(arr);
        //             }
        //         })
        //     }
        // }
    }
    return (
        <>
            {questions.map(question => (
                <Col className="mb-3" md="12" >
                    {question.Type === "QUESTION" || question.Type === "ANSWER" ? (
                        <Feed
                            key={question._id}
                            question={question}
                            location={props.location}
                            updateQn={() => {
                                updateFeed(question)
                            }}
                            history = {props.history}
                        />
                    ) : (
                        <div className="mb-1 p-2 pm-4 p-lg-3" style={{ width: "100%", backgroundColor: BaseColor.whiteColor, borderWidth: 'thin', borderRadius: 5, borderRadius: 5, boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.15)" }}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: '0.7 1 0%' }} className="pr-2 pr-md-0 pr-lg-0 pr-sm-2">
                                    {question.Type === "USER" ? (<Link to={"/profile/" + (question._id)}>
                                        <Image src={question && question.ProfilePicture ? question.ProfilePicture : Images.defaultProfile} width={40} height={40} roundedCircle />
                                    </Link>) : (<Link to={"/profile/" + (question.UserID && question.UserID.UserName)}>
                                        <Image src={question.UserID && question.UserID.ProfilePicture ? question.UserID.ProfilePicture : Images.defaultProfile} width={40} height={40} roundedCircle />
                                    </Link>)}
                                    
                                    <div style={{ flex: '1' }} className="pr-1 mt-3">
                                        {question.Type !== "USER" ? (<div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                                            <div className="upvote" >
                                                <a>{question.IsVoted && question.UpVote ? (<Image src={Images.upActive} height={15} width={30} />) : (<Image src={Images.up} height={15} width={30} />)}</a>
                                            </div>
                                            <span className="" style={{ fontSize: 12 }}>{updating == question._id ? <Spinner animation="grow" variant="primary" size="sm" /> : (question.percent)}</span>
                                            <div className="downvote" >
                                                <a >{question.IsVoted && question.DownVote ? (<Image src={Images.downActive} height={15} width={30} />) : (<Image src={Images.down} height={15} width={30} />)}</a>
                                            </div>
                                        </div>) : null }
                                    </div>
                                </div>
                                <div style={{ flex: '9', display: 'flex', flexDirection: 'column' }}>
                                    {question.Type === "USER" ? (<Link className="reset mt-2" to={"/profile/" + (question && question.UserName)}>
                                        <span className="capitalize" style={{}}>{question ? (question.FirstName + ' ' + question.LastName) : null}</span>
                                        <small className="float-right" >{ "" +question.CreatedDate ? moment.tz(question.CreatedDate, timeZone).fromNow() : null}</small>
                                    </Link>) : (<Link className="reset mt-2" to={"/profile/" + (question.UserID && question.UserID.UserName)}>
                                        <span className="capitalize" style={{}}>{question.UserID ? (question.UserID.FirstName + ' ' + question.UserID.LastName) : (question.UserID.FirstName + ' ' + question.UserID.LastName)}</span>
                                        <small className="float-right" >{"posted " + question.CreatedDate ? moment.tz(question.CreatedDate, timeZone).fromNow() : null}</small>
                                    </Link>)}
                                    <div>
                                    </div>
                                    <br />
                                    {question.Type === "ANSWER" || question.Type === "COMMENT" ? (<Link className="reset question-link" to={"/question/" + (question.QuestionID.QuestionUrl)}>
                                        <h5 dangerouslySetInnerHTML={{ __html: question.QuestionID.Subject && question.QuestionID.Subject.length > 140 ? question.QuestionID.Subject.substring(0, 140) + '...' : question.QuestionID.Subject }} ></h5>
                                    </Link>) : null}
                                    <div className="qna-html-content" >
                                        {question.Type === "ANSWER" ? (<Link className="reset" to={"/question/" + (question.QuestionID.QuestionUrl)}><div dangerouslySetInnerHTML={{ __html: question.Answer && question.Answer.length > 350 ? question.Answer.substring(0, 350) + '...' : question.Answer }} ></div></Link>) : null }
                                        <p>{question.Type === "USER"  ? (question.work ? question.work + ", " : "") + (question.education ?  question.education+", " : "")+ (question.HomeTown ?  "Home Town: "+ question.HomeTown+", " : "") + (question.CurrentLocation ?  "Current City: "+ question.CurrentLocation+", " : "") : ""}</p>
                                    </div>
                                    {question.Type === "ANSWER" ? (<Comments answerID={question._id} answer={question} questionID={question.QuestionID._id} image={profilePicture}  commentsCount={question.CommentCount} history={props.history} />) : null }
                                    {question.Type === "COMMENT" ? (<Link className="reset" to={'/question/'+ (question.QuestionID.QuestionUrl) +'?a='+ question.AnswerID._id +'&c='+ question._id } ><div dangerouslySetInnerHTML={{ __html: question.CommentText && question.CommentText.length > 350 ? question.CommentText.substring(0, 350) + '...' : question.CommentText }} ></div></Link>) : null }
                                    <p>{question.Type === "USER" ? (question.work ? question.work : "") + (question.education ? question.education : "")+ (question.HomeTown ? question.HomeTown : "") + (question.CurrentLocation ? "Current City: "+ question.CurrentLocation : "") : ""}</p>
                                </div>
                            </div>
                        </div >
                    )}
                </Col>
            ))}
        </>
    )
}

QuestionList.prototype = {
    questions: PropTypes.array,
    showFullText: PropTypes.func,
    history: PropTypes.object,
}

QuestionList.defaultProps = {
    questions: [],
    showFullText: () => { },
    history: {}
}
export default QuestionList;