import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';

import { Images } from '../config';
import PropTypes from "prop-types";
import { BaseColor } from '../config/index';
import { useState } from 'react';
import { httpPost } from '../Services/HttpServices';

function NotificationCard(props) {
    const markallasread = () => {
        console.log("ksjdfkjsfs");
        httpPost("notification_controller/read_all")
            .then(res => {
                if (res.status == 1) {
                    setMarkAsReadColor("white")
                    props.notificationArr()
                    // console.log("res-read all", res);
                }
            })
    }
    const [markAsReadColor, setMarkAsReadColor] = useState("")

    return (
        <>
            {props.notificationlist.length > 0 ?
                <Card style={{ width: '100%', background: "" }} className="">
                    <Row className="no-gutters">
                        <Col md="7" xs="7" sm="7" className="cardStyleListingCol">
                            <Card.Title className="mt-2">All your notifications</Card.Title>
                        </Col>
                        <Col md="4" xs="4" sm="4">
                            <a onClick={markallasread} style={{ textDecoration: 'underline', float: "right", fontSize: 13, color: 'black', marginTop: 15, cursor: "pointer" }}>Mark all as read</a>
                        </Col>
                    </Row>
                    {props.notificationlist.map((ele, index) => (
                        <a style={{textDecoration : "none"}} href={"/profile/"+(ele.OtherUser && ele.OtherUser._id)}  >
                            <Row className="cardStyleListingRow no-gutters" style={{ marginTop: 0, backgroundColor: ele.color, borderBottom: "0.5px solid #DEE2E6" }}>
                                <Col md="1" sm="2" className="cardStyleListingCol">
                                    <Image src={ele.image} className="rounded-circle" width={50} height={50} />
                                </Col>
                                <Col md="10" sm="10" className="cardUserNameCol">
                                    <p className="cardUserName">{/*{ele.username}*/}<small className="card-notification-time float-right">{ele.duration}</small></p>
                                    {/* <br></br> */}
                                    <div className="cardUserDetails qna-html-content" dangerouslySetInnerHTML={{__html: ele.detail1}} >
                                    </div>
                                </Col>
                            </Row>
                        </a>
                    ))}
                </Card>
                : (
                    <Card style={{ width: '100%', background: "" }} className="text-center">
                        <Row className="">
                            <Col md="12" xs="7" sm="7" className="cardStyleListingCol">
                                <Card.Title className="mt-2">No Notifications</Card.Title>
                            </Col>
                        </Row>
                    </Card>
                )}

        </>
    )
}

NotificationCard.prototype = {
    notificationlist: PropTypes.array,
    notificationArr: PropTypes.func,
}

NotificationCard.defaultProps = {
    notificationlist: []
}
export default NotificationCard;

// margin-left: 27px;margin-top: -14px;