import { Row, Col, Spinner, Button } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { httpPost } from '../Services/HttpServices';
import { showLoader, hideLoader } from '../AppRedux/Actions/Auth';
import { toast } from 'react-toastify';
import moment from 'moment';
import 'moment-timezone';

const AnswerLog = (props) => {
    const timeZone  = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const [answer, setAnswer] = useState(null);
    const [logs, setLogs] = useState({ page: 1, totalCount: 0, list: [] })
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        var search = new URLSearchParams(props.location.search);
        let id = search.get('id')
        if (!id) {
            //props.history.goBack()
        } else {
            dispatch(showLoader());
            getAnswer(id)
        }
    }, [])
    useEffect(() => {
        if (answer) {
            getLogs();
        }
    }, [answer, logs.page]);

    const getAnswer = (id) => {
        httpPost('answer_controller/retrive', { AnswerID: id })
            .then(res => {
                dispatch(hideLoader())
                if (res) {
                    if (res.status) {
                        setAnswer(res.data);
                    } else {
                        //props.history.goBack();
                        toast.error(res.message);
                    }
                } else {
                    props.history.goBack();
                    toast.error('Network Error');
                }
            }).catch(err => {
                dispatch(hideLoader())
                props.history.goBack();
                toast.error('Network Error');
                console.log('err');
            })
    }

    const getLogs = () => {
        setLoading(true);
        httpPost('logs_controller/list', { CollectionID: answer._id, page: logs.page })//auth && auth.user && auth.user._id
            .then(res => {
                setLoading(false);
                if (res && res.status) {
                    console.log("activity", res);
                    var oldArr = [...logs.list];
                    var newArr = oldArr.concat(res.data);
                    setLogs({ ...logs, totalCount: res.totalCount, list: newArr });
                }
            })
    }
    return (
        <div className="p-4 p-md-5 p-lg-5 p-sm-4 question-log">
            <Row>
                <Col md={{ offset: '2', span: '8' }}>
                    <Col className="q-detail" md="12" >
                        <div className='tags-div'>
                            {answer && answer.QuestionID && answer.QuestionID.Tags && answer.QuestionID.Tags.split(",").map(ele => (
                                <div className='tags' >
                                    {ele}
                                </div>
                            ))}
                        </div>
                        <div className="mt-2" dangerouslySetInnerHTML={{__html: answer && answer.QuestionID && answer.QuestionID.Subject || "" }} ></div>
                    </Col>
                    <Col className="l-detail mt-4" md="12" >
                    {logs && logs.list && logs.list.length > 0 && (<h4 className="l-heading">Answer Logs <Button className='float-right' size="sm" onClick={() => { props.history.goBack() }}>Back</Button> </h4> ) }
                        {logs && logs.list && logs.list.length > 0 ? logs.list.map((log, index) => (
                            (log.UserID && <div className={"l-div " + (logs.list.length - 1 == index ? 'last' : '')} >
                                <Link to={"/profile/" + log.UserID._id} >{log.UserID.FirstName + '' + log.UserID.LastName}</Link>:&nbsp;&nbsp; 
                                <span className='l-text qna-html-content' dangerouslySetInnerHTML={{__html: log.Message}} ></span>
                                <p className='l-time'>
                                    {log.CreatedDate ? ('#On ' + moment.tz(log.CreatedDate, timeZone).fromNow()) : ""}
                                </p>
                            </div>)
                        )) : <p>No Logs</p>}
                        {loading ?
                            <Spinner animation="border" variant="primary" />
                            : logs.totalCount && logs.totalCount > logs.list.length ? //
                                <Button className='mt-2' size="sm" onClick={() => { setLogs({ ...logs, page: logs.page + 1 }) }}>see more</Button>
                                : null}
                    </Col>
                </Col>
            </Row>
        </div>
    )
}

export default AnswerLog;