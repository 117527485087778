import { Link } from "react-router-dom";
import { BaseColor, Images } from "../config";
import Image from 'react-bootstrap/Image';
import PropTypes from "prop-types";

function Related(props) {
    return (
        <div style={{ width: "100%", borderWidth: 'thin', borderRadius: 5, borderColor: BaseColor.grayColor }}>
            <h4 className="border-bottom" style={{ paddingBottom: 10 }}>
                <Image src={Images.Question} style={{marginTop: -10}}  /> {props.heading}</h4>
            {props.list && props.list.map(ele => (
                <a href={ele.url} style={{ textDecoration: "none" }}>
                    <div style={{ display: 'flex', borderWidth: 'thin', borderColor: BaseColor.grayColor, paddingTop: 5 }}>
                        <div style={{ flex: '0.7' }}>
                            <Image src={ele.image ? ele.image : null} width={30} height={30} roundedCircle={props.imageType == '1' ? true : false} />
                        </div>
                        <div style={{ flex: '4', display: 'flex', flexDirection: 'column' }}>
                        <h2 className="related-head" dangerouslySetInnerHTML = {{__html: ele.text}} ></h2>
                            {ele.by && ele.post ? (<small>{ele.by + ", " + ele.post}</small>) : null}
                        </div>
                    </div>
                    <hr style={{marginTop: '7px', marginBottom: '7px'}}  />
                </a>
            )) || null}
            <div style={{ textAlign: 'right' }}>
                <span><a style={{ textDecoration: "underline", fontSize: 12 }} href={props.link} >See More</a></span>
            </div>
        </div>
    )
}

Related.prototype = {
    heading: PropTypes.string,
    imageType: PropTypes.string,
    list: PropTypes.array,
    link: PropTypes.string
}

Related.defaultProps = {
    heading: "",
    imageType: "1",
    list: [],
    link: null,
}
export default Related;