import React, { Component } from 'react';
import { Button, Col, Container, FormControl, Image, InputGroup, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import { Images } from '../config';
import { httpGet, httpPost } from '../Services/HttpServices';
import { showLoader, hideLoader } from '../AppRedux/Actions/Auth';
import { connect } from 'react-redux';

class Signup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			type: "password",
			emailforVerification: "",
			token: "",
			disabled: true
		}
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentDidMount() {
		var search = new URLSearchParams(this.props.location.search);
		const token = search.get('token')
		const verify = search.get('verify')
		this.setState({ token })
		if (token == null) {
			// this.props.history.push('/home')
		} else {
			if (verify) {
				this.props.history.push('/resetpassword')
			} else if (token != "" || token != "") {
				// call API
				var tokenObj = { token }
				httpGet('forgotpassword', tokenObj)
					.then(res => {
						if (res.status == 1) {
							// 6050c37b6063434fa435bd41
							// this.props.history.push('/forgotpassword?verify=true')
							this.props.history.push({
								pathname: '/resetpassword',
								state: { userID: res.UserID }
							});
						} else {
							toast.error(res.message);
						}
					})
			} else {
				this.props.history.push('/home')
			}
		}
	}
	handleEmail = (e) => {
		this.setState({
			emailforVerification: e.target.value
		});
	    const isValidEmail = this.emailValidation();
	    if(isValidEmail){
	    	this.setState({disabled: false})
	    }
	}
	pasteEmail = (e) => {
		var email = {target: { value: e.clipboardData.getData('Text')}}
		this.handleEmail(email);
	}
	emailValidation() {
    	const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    	return !(!this.state.emailforVerification || regex.test(this.state.emailforVerification) === false);
  	}
	// forgot_password_controller/forgot_password
	// Email
	handleSubmit = (e) => {
		e.preventDefault();
		var passObj = { Email: this.state.emailforVerification }
		// console.log("e", passObj);
		this.props.showLoader();
		httpPost('forgot_password_controller/forgot_password', passObj)
			.then(res => {
				this.props.hideLoader();
				if (res.status == 1) {
					toast.success(res.message)
				} else {
					toast.error(res.message);
				}
			}).catch(err => {
				this.props.hideLoader();
			})
	}
	render() {
		return (
			<div className="login-page" >
				<Container fluid>
					<Row>
						<Col md="3" className="p-5"></Col>
						<Col md="6" className="p-5 mb-4">
							<h2>Verify! it's you</h2>
							{/* <p>Reset password 😁 👍</p> */}
							<Form onSubmit={this.handleSubmit}>
								<div className="box" >
									<FormControl type="email" placeholder="Enter your email address" name="email" onPaste={this.pasteEmail} onChange={this.handleEmail} />
								</div>
								<div>
									<Button type="submit" className="btn-md btn-block btn-rounded" variant="outline-primary" disabled={this.state.disabled} >Verify <Image src={Images.arrowRight} /></Button>
								</div>
							</Form>
						</Col>
						<Col md="3" className="p-5" >
						</Col>
					</Row>
				</Container>

			</div>
		)
	}
}
const mapStateToProps = ({ auth }) => {
	return { auth };
}
export default connect(mapStateToProps, {
	showLoader,
	hideLoader
})(Signup)
//export default Signup;