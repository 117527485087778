import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { BaseColor, Images } from "../config";

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Image from 'react-bootstrap/Image';
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';

import { httpPost } from '../Services/HttpServices';
import PropTypes from "prop-types";
import moment from 'moment';
import 'moment-timezone';
import { toast } from 'react-toastify';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Typeahead } from 'react-bootstrap-typeahead';
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
    WhatsappIcon
} from "react-share";
import Editor from '../Component/QuillEditor';
import  RequestAnswer  from '../Component/RequestAnswer';
function QuestionTabList(props) {
    let { question } = props;

    const [loading, setLoading] = useState(false);

    var auth = useSelector(({ auth }) => auth);
    let profilePicture = auth.ProfilePicture ? auth.ProfilePicture : Images.defaultProfile;

    const [userId, setUserId] = useState(question.user_id);
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
   
    const [deleteQn, setDeleteQn] = useState(null);
    // Merge Question
    const [mergeQn, setMergeQn] = useState(false);
    const [searchQn, setSearchQn] = useState([]);
    const [mergeQnId, setMergeQnId] = useState(null);

    const [answer, setAnswer] = useState("");
    const [answerpostdisplay, setAnswerPostDisplay] = useState(false);
    const [isFollow, setIsFollow] = useState(null);

    // Report List
    const [reportDisplay, setReportDisplay] = useState(false);
    const [reportTypes, setReportTypes] = useState([]);
    const [report, setReport] = useState({
        type: null,
        text: ""
    });

    const [reqAns, setReqAns] = useState(false);
    const [requestQnId, setRequestQnId] = useState(null);
    const [searchUser, setSearchUser] = useState([]);

    useEffect(() => {
        if (question) {
            setUserId(question.user_id)
            getFollowDetails();
        }
    }, [props.question])
    function handleAnswer() {
        setAnswerPostDisplay(!answerpostdisplay)
    }

    const unFollowQuestion = () => {
        if (userId != (auth && auth.user && auth.user._id)) {
            httpPost('follow_question_controller/delete', {
                FollowQuestionId: isFollow && isFollow._id
            }).then(res => {
                console.log("follow_question_controller/delete => res => ", res);
                if (res && res.status) {
                    toast.success(res.message);
                    setIsFollow(null);
                } else {
                    toast.error(res.message);
                }
            })
        }
    }

    const followQuestion = () => {
        var obj = {
            UserID: auth && auth.user && auth.user._id,
            QuestionID: question._id,
        }
        httpPost('follow_question_controller/create', obj).then(res => {
            if (res && res.status) {
                toast.success(res.message);
                getFollowDetails();
            } else {
                toast.error(res.message);
            }
        })
    }

    function handleCreateAnswer() {
        var answerObj = {
            Answer: answer,
            QuestionID: question._id
        }
        httpPost("answer_controller/create", answerObj)
            .then(res => {
                if (res.status) {
                    toast.success(res.message);
                }
            })
        setAnswerPostDisplay(false)
    }

    const draftAnswer = () => {
        httpPost('draft_answer_controller/create', {
            Answer: answer || "",
            QuestionID: question.id,
        }).then(res => {
            if (res) {
                if (res.status) {
                    setAnswerPostDisplay(false)
                    toast.success(res.message);
                } else {
                    toast.error(res.message);
                }
            }
        }).catch(err => {
            console.log('err', err);
        })
    }
    const getFollowDetails = () => {
        httpPost('follow_question_controller/retrieve', {
            QuestionID: question._id,
            UserID: auth && auth.user && auth.user._id || null,
        })
            .then(res => {
                setIsFollow(res && res.data || null);
            })
    }
    const onConfirmDelete = () => {
        setDeleteQn(null);
        httpPost('question_controller/delete', {
            QuestionID: question._id
        }).then(res => {
            if (res) {
                if (res.status) {
                    toast.success(res.message);
                    props.history.push('/feed');
                } else {
                    toast.error(res.message);
                }
            }
        })
    }
    const handleReport = () => {
        setLoading(true);
        httpPost('question_report_controlller/create', {
            QuestionID: question.id,
            ReportType: report.type,
            ReportText: report.text
        }).then(res => {
            setLoading(false);
            if (res) {
                if (res.status) {
                    setReport({
                        type: null,
                        text: ""
                    });
                    setReportDisplay(false);
                    toast.success(res.message)
                } else {
                    toast.error(res.message);
                }
            } else {
                toast.error('Network Error')
            }
        }).catch(err => {
            setLoading(false);
            toast.error('Network Error')
        })
    }
    const searchQuestion = (e) => {
        httpPost('question_controller/list', {
            filter: {
                "Subject": e && e || ""
            },
        }).then(res => {
            let qData = [];
            res.data.map(ele => {
                if(question.id !== ele._id){
                    qData.push(ele);
                }
            })
            setSearchQn(qData || []);
        })
    }
    const mergeQuestion = (e) => {
        if (mergeQnId && mergeQnId.length > 0) {
            setLoading(true)
            httpPost('merge_question_controller/create', {
                QuestionFromID: question.id,
                QuestionToID: mergeQnId && mergeQnId.length > 0 && mergeQnId[0]._id
            }).then(res => {
                setLoading(false);
                if (res && res.status) {
                    toast.success(res.message);
                    setMergeQn(false);
                    setMergeQnId(null);
                    setSearchQn([]);
                }
                if (res && !res.status) {
                    toast.error(res.message);
                }
            }).catch(err => {
                setLoading(false)
            })
        } else {
            toast.error('Please select question');
        }
    }
    const searchuser = (e) => {
        httpPost('follow_user_controller/list', {
            FollowerID: auth && auth.user && auth.user._id,
            page: 1,
            filter: {
                'FirstName': e && e || ""
            }
        })
            .then(res => {
                // console.log('search user', res);
                var arr = [];
                if (res && res.data) {
                    res.data.map(ele => {
                        arr.push(ele.UserID || []);
                    })
                    setSearchUser(arr);
                }
            })
    }
    const notifyEdits = () => {
        if(auth && auth.user && auth.user._id){
            httpPost('question_controller/notify', { QuestionID: question._id })
                .then(res => {
                    if (res && res.status === 1) {
                        toast.success(res.message);
                    }
                })  
        }else{
            window.location.href = "/signin?ref="+props.location.pathname;
        }
    }
    return (
        <>
            <Row>
                <Col md="12" >
                    <div className="row mt-2 p-2 p-md-3 p-lg-3 question-list-tab" style={{ backgroundColor: BaseColor.whiteColor, borderWidth: 'thin', borderRadius: 5, border: "0.1px solid", borderColor: BaseColor.grayColor, boxShadow: "0px 34px 39px -42px rgba(0, 0, 0, 0.2);" }}>
                        <Col md="1" xs="2" className="pl-0 pl-md-2 pl-lg-2">
                            <Image className="profile-pic" src={question.UserID && question.UserID.ProfilePicture ? question.UserID.ProfilePicture : Images.defaultProfile} width={40} height={40} roundedCircle />
                            <div className="mt-2" style={{ flex: '1', alignSelf: 'center' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    {question.IsVoted && question.UpVote ? (<Image src={Images.upActive} height={15} width={25} />) : (<Image src={Images.up} height={15} width={25} />)}
                                    <span style={{ fontSize: 12 }}>{question.percent}</span>
                                    {question.IsVoted && question.DownVote ? (<Image src={Images.downActive} height={15} width={25} />) : (<Image src={Images.down} height={15} width={25} />)}
                                </div>
                            </div>
                        </Col>
                        <Col md="11" xs="8" className="p-0" >
                            <div>
                                <span className="user-name capitalize">
                                    <Link to={"/profile/" + question.UserID.UserName} >
                                        {question.UserID && question.UserID.FirstName + " " + question.UserID.LastName}
                                    </Link>
                                </span>
                                <small className="post-date float-right">{"Posted " + moment.tz(question.CreatedDate, timeZone).fromNow()}</small>
                            </div>

                            <Link to={"/question/" + question.QuestionUrl} style={{ textDecoration: "none" }}>
                                {/* target="_blank" */}
                                <h5 className="question mt-2" dangerouslySetInnerHTML={{ __html: question.Subject}} ></h5>
                            </Link>

                            <div className="sub-links" style={{ display: 'flex', flexWrap: "wrap", flexDirection: 'row', float: 'right' }}>
                                <label style={{ marginRight: 15 }} onClick={handleAnswer} >Answer</label>
                                <label style={{ marginRight: 15 }} onClick={() => {
                                    if (isFollow) {
                                        unFollowQuestion();
                                    } else {
                                        followQuestion();
                                    }
                                }}>{isFollow ? 'Unfollow' : "Follow"}</label>
                                <label style={{ marginRight: 15 }} onClick={() => { }} className="for-dropdown">
                                    <DropdownButton className="menu-three-dots-button" id="dropdown-item-share" title={"Share"}>
                                        <Dropdown.Item as="button" ><FacebookShareButton url={window.location.origin+"/question/" + (question.QuestionUrl)} quote={question.Subject} ><FacebookIcon size={"2rem"} round />&nbsp;Facebook</FacebookShareButton></Dropdown.Item>
                                        <Dropdown.Item as="button" ><TwitterShareButton  url={window.location.origin+"/question/" + (question.QuestionUrl)} title={question.Subject} ><TwitterIcon size={"2rem"} round />&nbsp;Twitter</TwitterShareButton></Dropdown.Item>
                                        <Dropdown.Item as="button" ><LinkedinShareButton url={window.location.origin+"/question/" + (question.QuestionUrl)} title={question.Subject} summary={question.Content} ><LinkedinIcon size={"2rem"} round />&nbsp;LinkedIn</LinkedinShareButton></Dropdown.Item>
                                        <Dropdown.Item as="button" ><WhatsappShareButton url={window.location.origin+"/question/" + (question.QuestionUrl)} title={question.Subject} ><WhatsappIcon size={"2rem"} round />&nbsp;Whatsapp</WhatsappShareButton></Dropdown.Item>
                                    </DropdownButton>
                                </label>
                                <label className="for-dropdown">
                                    <DropdownButton className="menu-three-dots-button" id="dropdown-item-button" title={<Image src={Images.dotIcon} />}>
                                        {auth && auth.user && auth.user._id && auth.user._id == question.user_id ? (
                                            <>
                                                <Dropdown.Item onClick={() => { props.history.push('/questionCreate?id=' + question._id) }} as="button">Edit</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { setDeleteQn(question.id) }} as="button">Delete</Dropdown.Item>
                                                <Dropdown.Divider />
                                            </>
                                        ) : (
                                                <>
                                                    {/*<Dropdown.Item as="button" onClick={() => { answerLater() }}>Answer Later</Dropdown.Item>
                                                     <Dropdown.Item as="button">Merge Questions</Dropdown.Item> */}
                                                </>
                                            )}

                                        <Dropdown.Item as="button" onClick={() => { setMergeQn(true) }}>Merge Questions</Dropdown.Item>
                                        <Dropdown.Item as="button" onClick={() => { notifyEdits() }}>Notify about Edits</Dropdown.Item>
                                        <Dropdown.Item as="button" onClick={() => { setReportDisplay(!reportDisplay) }}>Report</Dropdown.Item>
                                        <Dropdown.Item as="button" onClick={() => { setReqAns(true) }}>Request Experts to Answer</Dropdown.Item>
                                        <Dropdown.Item as="button" onClick={() => { props.history.push('/questionlog?id=' + question._id) }}>View Stats and logs</Dropdown.Item>
                                    </DropdownButton>
                                </label>
                                {/*<div style={{position: 'absolute', right: 0}} >
                                     marginRight: 50, 
                                    <label style={{ marginBottom: 0 }}><Image src={Images.up} width={15} height={15} />&nbsp;<span>{question.UpvoteCount}</span></label>
                                    <label style={{ marginBottom: 0 }}><Image src={Images.comment} width={15} height={15} />&nbsp;<span>{question.ReportCount}</span></label>
                                    <label style={{ marginLeft: 20, marginBottom: 0 }}><Image src={Images.forward} width={15} height={15} />&nbsp;<span>{question.ShareCount}</span></label>
                                </div>*/}
                            </div>

                            {answerpostdisplay ?
                                <Row className="row" style={{ paddingTop: 10, alignItems: 'center' }}>
                                    <Col md="12" sm="12" xs="12" >
                                        <div style={{ alignItems: 'center' }}>
                                            <Editor
                                                editorState={null}
                                                onUpdate={editorState => setAnswer(editorState)}
                                            />
                                        </div>
                                    </Col>
                                    <Col md="12" sm="12" xs="12">
                                        <Button className="mt-1 btn-sm" style={{ color: BaseColor.whiteColor, height: "100%" }} onClick={handleCreateAnswer}>Post</Button>
                                        &nbsp;&nbsp;<Button className="mt-1 btn-sm" style={{ color: BaseColor.whiteColor, height: "100%" }} onClick={draftAnswer}>Draft</Button>
                                    </Col>
                                </Row>
                                : null}
                                <SweetAlert
                                    show={deleteQn ? true : false}
                                    warning
                                    showCancel
                                    confirmBtnText={"Yes"}
                                    cancelBtnText={"No"}
                                    title={<span style={{ fontSize: 25 }} >Do you want to delete this Question</span>}
                                    onConfirm={onConfirmDelete}
                                    onCancel={() => { setDeleteQn(null) }}
                                />
                                {/* Report Question  */}
                                <Modal show={reportDisplay} onHide={() => { setReport({ text: '', type: null }); setReportDisplay(false); }}>
                                    <Modal.Header closeButton>
                                        <Modal.Title as="h4" >Report Question</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Row className="row" style={{ paddingTop: 10, alignItems: 'center' }}>
                                            <Col md="12" sm="12" xs="12" >
                                                <Form.Check onClick={() => setReport({ ...report, type: '1' })} checked={report.type == '1' ? true : false} type={'radio'} value={"1"} label="Harassment" />
                                                <Form.Check onClick={() => setReport({ ...report, type: '2' })} checked={report.type == '2' ? true : false} type={'radio'} value={"2"} label="Spam" />
                                                <Form.Check onClick={() => setReport({ ...report, type: '3' })} checked={report.type == '3' ? true : false} type={'radio'} value={"3"} label="Insincere" />
                                                <Form.Check onClick={() => setReport({ ...report, type: '4' })} checked={report.type == '4' ? true : false} type={'radio'} value={"4"} label="Poorly Written" />
                                                <Form.Check onClick={() => setReport({ ...report, type: '5' })} checked={report.type == '5' ? true : false} type={'radio'} value={"5"} label="Incorrect Topic" />
                                                <Form.Check onClick={() => setReport({ ...report, type: '6' })} checked={report.type == '6' ? true : false} type={'radio'} value={"6"} label="Offensive" />
                                            </Col>
                                            <Col md="12" sm="12" xs="12" className='mt-3'>
                                                <div className="input-border" style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Image style={{ marginLeft: 5 }} src={profilePicture} height={35} width={35} roundedCircle />
                                                    <InputGroup className="">
                                                        <FormControl value={report.text} style={{ border: 0, boxShadow: 'none', marginRight: 5, backgroundColor: 'inherit' }} type="text" placeholder="Explain the report" size="lg" onChange={(e) => {
                                                            setReport({ ...report, text: e && e.target && e.target.value || null });
                                                        }} />
                                                        <InputGroup.Append>
                                                            {/* <FontAwesomeIcon icon={"search"} /> */}
                                                        </InputGroup.Append>
                                                    </InputGroup>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" className="btn-sm" onClick={() => { setReport({ text: '', type: null }); setReportDisplay(false); }}>
                                            Cancel
                                        </Button>
                                        <Button disabled={loading} variant="primary" className="btn-sm" onClick={() => { handleReport() }}>
                                            Submit
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                                {/* Question Merge  */}
                                <Modal show={mergeQn} onHide={() => { setMergeQn(false); setMergeQnId(null); }}>
                                    <Modal.Header closeButton>
                                        <Modal.Title as="h4" >Merge Question</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <label>Merge To</label>
                                        <Typeahead
                                            id="user-example"
                                            onInputChange={(e) => { searchQuestion(e) }}
                                            onChange={(e) => { setMergeQnId(e) }}
                                            options={searchQn && searchQn || []}
                                            placeholder="Choose a question..."
                                            labelKey={"Subject"}
                                            selected={mergeQnId && mergeQnId || []}
                                        />
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" className="btn-sm" onClick={() => { setMergeQn(false); setMergeQnId(null); }}>
                                            Cancel
                                        </Button>
                                        <Button disabled={loading} variant="primary" className="btn-sm" onClick={() => { mergeQuestion(false) }}>
                                            Submit
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                                {/* Request Answer */}
                                <Modal show={reqAns} onHide={() => { setReqAns(false); setRequestQnId(null); }} size="lg" >
                                    <Modal.Header closeButton>
                                        <Modal.Title as="h4" >Request Answer</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <label>Request To</label>
                                        <RequestAnswer question={question} />
                                    </Modal.Body>
                                </Modal>
                            <div style={{ flex: '9', display: 'flex', flexDirection: 'column' }}>
                            </div>
                        </Col>

                    </div>
                </Col>
            </Row>
        </>
    )
}

QuestionTabList.prototype = {
    question: PropTypes.object
}

QuestionTabList.defaultProps = {
    question: {}
}
export default QuestionTabList;