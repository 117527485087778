import { BaseColor, Images } from "../config";
import { Image } from 'react-bootstrap';
import PropTypes from "prop-types";

function Tag(props) {
	let { tag } = props;
	return (
		<div style={{
			padding: 5, width: 'fit-content', borderRadius: 20,
			backgroundColor: tag.active ? BaseColor.secondaryColor : BaseColor.grayColor,
			color: tag.active ? BaseColor.whiteColor : BaseColor.blackColor,
			webkitBoxShadow: '3px 7px 11px #d8cece',
			mozBoxShadow: '3px 7px 11px #d8cece',
			boxShadow: '3px 7px 11px #d8cece'
		}}
			onClick={() => { props.onTagClick(tag.id) }}
		>
			&nbsp;&nbsp;{tag.tag}&nbsp;
			<Image src={Images.close} height={12} width={12} />&nbsp;&nbsp;
		</div>
	)
}

Tag.prototype = {
	tag: PropTypes.object,
	onTagClick: PropTypes.func,
}

Tag.defaultProps = {
	tag: {},
	onTagClick: () => { },
}
export default Tag;