import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { Images } from '../config/index';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Image from 'react-bootstrap/Image';
import InputGroup from 'react-bootstrap/InputGroup';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import NavLink from './NavLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons';

import { removeUser } from '../AppRedux/Actions/Auth';
import { setSearch } from '../AppRedux/Actions/Search';

import Helmet from 'react-helmet';
import { helmetJsonLdProp } from "react-schemaorg";
import { auth as fireAuth } from "./RChat/firebase_config";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Header(props) {
    const dispatch = useDispatch();
    const { auth } = useSelector(state => state);
    var queryParams = useQuery();
    let searchText = queryParams.get('q');
    let type = queryParams.get('type');
    let time = queryParams.get('time');
    let user = queryParams.get('user');
    let myposts = queryParams.get('myposts');
    const [searchLocal, setSearchLocal] = useState(searchText || null);
    const searchRef = useRef(null)
    useEffect(() => {
        if (!searchText) {
            setSearchLocal("");
        } else {
            setSearchLocal(searchText);
        }
    }, [searchText])


    const setVisibility = (e) => {
        myposts = myposts ? false : true
        searchDetail();
    }

    const submitSearch = (e) => {
        e.preventDefault();
        searchDetail();
    }
    const searchDetail = () => {
        let { location } = props;
        // dispatch(setSearch(searchLocal))
        // props.history.push('/questionlist?q='+searchLocal);
        let searchParams = {q: searchLocal}
        if(type){
            searchParams.type = type
        }
        if(time){
            searchParams.time = time
        }
        if(user){
            searchParams.user = user
        }
        if(myposts){
            searchParams.myposts = myposts;
        }
        props.history.push({
            pathname: '/questionlist',
            search: "?" + new URLSearchParams(searchParams).toString()
        })
        window.location.reload();
        // if (location && location.pathname != '/questionlist') {
        //     props.history.push('/questionlist');
        // }
    }
    const [show, setShow] = useState(false);
    const showDropdown = (e)=>{
        setShow(!show);
    }
    const hideDropdown = e => {
        setShow(false);
    }

    const signOut = () => {
        dispatch(removeUser())
        fireAuth.signOut()
        props.history.push('/');
    }
    return (
        <div className="header-div">
            <Helmet 
                link = {[{"rel" : "canonical", "href" : window.location.href }]}
                script={[
                    helmetJsonLdProp({
                        "@context": "https://schema.org",
                        "@type": "Organization",
                        "url": "https://lt.englishforward.com/",
                        "logo": "https://lt.englishforward.com/logo.png",
                        "description": "This year, the Internet&#39;s largest English language community goes blockchain. Learn and perfect your English... and earn crypto-tokens"
                    }),
                  ]} 
              >
            </Helmet>
            <Navbar expand="md">
                <Navbar.Brand href="/"><Image src={Images.logo} className="logo" /></Navbar.Brand>
                { (auth && auth.user) ? null : (<Navbar.Toggle aria-controls="responsive-navbar-nav" className="d-none d-sm-none d-md-none"  />) }
                <Navbar.Collapse id="responsive-navbar-nav" className="search-bar" >
                    <Nav className="mr-5 d-none d-sm-block d-md-none">
                        <Form onSubmit={(e) => { submitSearch(e) }}>
                            <InputGroup className="" style={{ height: 40 }}>
                                <FormControl className="search-input" value={searchLocal} onChange={(e) => { setSearchLocal(e && e.target && e.target.value) }} type="text" placeholder="Find an answer..." style={{ height: 40}} />
                                <InputGroup.Append onClick={() => { searchDetail() }} className="search-input-group" >
                                    <Image className={'form-icon'} src={Images.search} />
                                </InputGroup.Append>
                            </InputGroup>
                        </Form>
                    </Nav>
                    {/*<Nav className="mr-5 mt-2 d-none d-sm-block d-md-none">
                        Are you a teacher?
                    </Nav>*/}
                    <Nav className="mr-5 mt-2 d-none d-sm-block d-md-none" onClick={() => props.history.push('/signin')} style={{cursor: 'pointer'}} >
                        <Link to={'/signin'} >Login</Link>
                    </Nav>
                    <Nav className="d-none d-sm-block d-md-none">
                         <Button variant="outline-primary" onClick={() => props.history.push('/signup')} >Sign Up</Button>
                    </Nav>
                </Navbar.Collapse>
                <Nav className="mr-3" className="nav-dropdown-right">
                <Nav className="mr-5 d-none d-sm-none d-md-none d-lg-block" >
                    <Form onSubmit={(e) => { submitSearch(e) }}>
                        <InputGroup className="" style={{ height: 45 }}>
                            <FormControl className="search-input" value={searchLocal} onChange={(e) => { setSearchLocal(e && e.target && e.target.value) }} type="text" placeholder="Find an answer..." style={{ height: 45}} />
                            <InputGroup.Append onClick={() => { searchDetail() }} className="search-input-group" >
                                <Image className={'form-icon'} src={Images.search} />
                            </InputGroup.Append>
                        </InputGroup>
                        {/* (auth && auth.user && type !== "users") ? (<div className="mt-1 mb-3" >
                            <input type="checkbox" checked={myposts} onChange={setVisibility} /> Only my posts
                        </div>) : null */}
                    </Form>
                </Nav>
                <Nav className="mr-5 mt-2 d-none d-sm-none d-md-block" style={{cursor: 'pointer'}} >
                    <Link to={'/discuss-english'} >Discuss English</Link>
                </Nav>
                <NavDropdown title={<Nav className="mr-5 d-none d-sm-none d-md-block mt-1"  style={{cursor: 'pointer'}}  >
                    Blog
                </Nav>} show={show} 
                onMouseEnter={showDropdown}  
                onMouseLeave={hideDropdown} >
                    <>
                        <NavDropdown.Item href="/blog/skills/" onClick={() => window.location.href = '/blog/skills/' }>Skills</NavDropdown.Item>
                        <NavDropdown.Item href="/blog/exams/" onClick={() => window.location.href = '/blog/exams/' }>Exams</NavDropdown.Item>
                        <NavDropdown.Item href="/blog/grammar/" onClick={() => window.location.href = '/blog/grammar/' }>Grammar</NavDropdown.Item>
                        <NavDropdown.Item href="/blog/vocabulary/" onClick={() => window.location.href = '/blog/vocabulary/' }>Vocabulary</NavDropdown.Item>
                        <NavDropdown.Item href="/blog/pronunciation/" onClick={() => window.location.href = '/blog/pronunciation/' }>Pronunciation</NavDropdown.Item>
                        {/*<NavDropdown.Item onClick={() => props.history.push('/')}>Career</NavDropdown.Item>*/}
                    </>
                </NavDropdown>
                    { (auth && auth.user) ?
                        (
                        <>
                            {auth.user.newIncomingChat === true ? (<Nav className="mr-5 d-none d-sm-none d-md-block" onClick={() => props.history.push('/notifications')} style={{cursor: 'pointer', alignItems: 'center'}} >
                                <img className="logo-notification" src={Images.new_notification} />
                            </Nav>) : (<Nav className="mr-5 d-none d-sm-none d-md-block" onClick={() => props.history.push('/notifications')} style={{cursor: 'pointer', alignItems: 'center'}} >
                                <img className="logo-notification" src={Images.notification} />
                            </Nav>) }
                            <Nav className="mr-5"  onClick={() => props.history.push('/profile')} style={{cursor: 'pointer', alignItems: 'center'}} >
                                <Link to={'/profile'} > Hi {auth.user.FirstName}</Link>
                            </Nav>
                            <NavDropdown title={<img className="logo-more" src={Images.more} onClick={() => {
                                //dispatch(removeUser());
                            }} />} >
                                <>
                                    <NavDropdown.Item href={'/feed'} >Feed</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    {auth.user.IsExpert === 1 || auth.user.IsModerator === 1 || auth.user.IsAdmin === 1 ? (
                                        <NavDropdown.Item href={'/private-area'} >Restricted Area</NavDropdown.Item>
                                    ) : null }
                                    <NavDropdown.Item href={'/profile'} >Profile</NavDropdown.Item>
                                    <NavDropdown.Item href={'/accountsetting'} >Account Settings</NavDropdown.Item>
                                    <NavDropdown.Item href={'/bookmarks'} >Bookmarks</NavDropdown.Item>
                                    <NavDropdown.Item href={'/followers'} >Followers</NavDropdown.Item>
                                    <NavDropdown.Item href={'/followlist'} >Follow List</NavDropdown.Item>
                                    <NavDropdown.Item href={'/blocklist'} >Block List</NavDropdown.Item>
                                    <NavDropdown.Item href={'/drafts'}  >Drafts</NavDropdown.Item>
                                    <NavDropdown.Item href={'/leaderboard'}  >Leaderboard</NavDropdown.Item>
                                    <NavDropdown.Item href={'/chat'} refresh="true" >Chat</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item onClick={() => { signOut(); }}>Logout</NavDropdown.Item>
                                </>
                            </NavDropdown>
                        </>
                        ) : (
                        <>
                            {/*<Nav className="mr-5 mt-2 d-sm-none d-md-block">
                                Are you a teacher?
                            </Nav>*/}
                            <Nav className="mr-5 mt-2 d-sm-none d-md-block login-nav" onClick={() => props.history.push('/signin')} style={{cursor: 'pointer'}} >
                                <Link to={'/signin'} >Login</Link>
                            </Nav>
                            <Nav className="mt-0 d-sm-none d-md-block">
                                 <Button variant="outline-primary" className="signup-btn" onClick={() => props.history.push('/signup')} >Sign Up</Button>
                            </Nav>
                            {/*<Nav className="mr-5">
                                <Button className="ask-question-nav" onClick={() => {
                                    if (auth && auth.user) {
                                        props.history.push('/questionCreate');
                                    } else {
                                        props.history.push('/signin');
                                    }
                                }} >ask question</Button>
                            </Nav>*/}
                        </>
                        )
                    }
                    </Nav>
            </Navbar>
        </div >
    )
}
export default Header;