import { Row, Col, Image, Button, ListGroup } from 'react-bootstrap';
import Card from 'react-bootstrap/Card'
import { Images } from '../config';
import NotificationCard from '../Component/NotificationCard';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { httpPost } from '../Services/HttpServices';
import moment from 'moment';
import 'moment-timezone';


function NotificationList() {
    const timeZone  = Intl.DateTimeFormat().resolvedOptions().timeZone;
    var auth = useSelector(({ auth }) => auth);
    const [notificationlist, setNotificationlist] = useState([])
    useEffect(() => {
        // console.log("26th Mar 2021 03:45PM", moment("26th Mar 2021 03:45PM","Do MMM YYYY h:mma").format("LLLL"));
        // return;
        getNotificationList()
    }, [])

    /*function getDuration(date) {
        var end = moment(new Date())
        var duration = moment.duration(end.diff(moment(date, "Do MMM YYYY h:mma")));
        var years = parseInt(duration.asYears());
        var months = parseInt(duration.asMonths());
        var weeks = parseInt(duration.asWeeks());
        var days = parseInt(duration.asDays());
        var hours = parseInt(duration.asHours());
        var minutes = parseInt(duration.asMinutes());
        var seconds = parseInt(duration.asSeconds());
        if (years > 0) {
            if (years == 1) {
                return years + " year";
            } else {
                return years + " years";
            }
        }
        if (months > 0) {
            if (months == 1) {
                return months + " month";
            } else {
                return months + " months";
            }
        }
        if (weeks > 0) {
            if (weeks == 1) {
                return weeks + " week";
            } else {
                return weeks + " weeks";
            }
        }
        if (days > 0) {
            if (days == 1) {
                return days + " day";
            } else {
                return days + " days";
            }
        }
        if (hours > 0) {
            if (hours == 1) {
                return hours + " hour";
            } else {
                return hours + " hours";
            }
        }
        if (minutes > 0) {
            return minutes + "min ago";
        }
        if (seconds > 0) {
            return "just now";
        }
    }*/

    function getNotificationList() {
        httpPost("notification_controller/list")
            .then((res) => {
                if (res.status == 1) {
                    let notArr = res.data;
                    notArr.forEach(element => {
                        // console.log(element,"NotificationList");
                        element.id= element._id;
                        element.image= (element.OtherUser.ProfilePicture == "" || !element.OtherUser.ProfilePicture) ? Images.defaultProfile : element.OtherUser.ProfilePicture;
                        element.username= element.OtherUser.FirstName + ' ' + element.OtherUser.LastName;
                        element.detail1= element.Message;
                        element.detail2= 'Detail2';
                        element.follow_detail= "Follow";
                        element.duration= moment.tz(element.CreatedDate, timeZone).fromNow();
                        element.color= element.ReadStatus == 1 ? "" : "#fdffcf";
                    });
                    setNotificationlist(notArr)
                } else {

                }
            })
    }
    return (
        <Row className="no-gutters">
            {auth.user === null ? (<Redirect to="/" />): null }
            <Col md="3"></Col>
            <Col md="6" className="my-3">
                <NotificationCard notificationlist={notificationlist} notificationArr={() => { getNotificationList() }} />
            </Col>
            <Col md="3"></Col>
        </Row>
    )
}
export default NotificationList;