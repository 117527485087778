import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';

import { BaseColor, Images } from "../config";
import { Link } from "react-router-dom";

function Footer(props) {
    return (
    	<footer className="footer" >
        <div className="container" style={{ padding: 10,borderTop: "2px solid" , borderColor: BaseColor.grayColor }}>
        <Row>
            <Col md="3" className="p-4">
                <h6>About Us</h6>
                <ul>
                    <li><Link to="/help" >Help</Link></li>
                    <li><Link to="/who-we-are" >Who we are</Link></li>
                    <li><Link to="/careers" >Careers</Link></li>
                    <li><Link to="/partners" >Partners</Link></li>
                    <li><Link to="/media-kit" >Media kit</Link></li>
                    <li><Link to="/terms" >Terms of Service</Link></li>
                    <li><Link to="/privacy" >Privacy Policy</Link></li>
                </ul>
            </Col>
            <Col md="3" className="p-4">
                <h6>Popular topics</h6>
                <ul>
                    <li><a href="/topics/grammar" >Grammar</a></li>
                    <li><a href="/topics/parts-of-speech" >Parts of Speech</a></li>
                    <li><a href="/topics/vocabulary" >Vocabulary</a></li>
                    <li><a href="/topics/idioms" >Idioms</a></li>
                    <li><a href="/topics/dictionary" >Dictionary/Usage</a></li>
                    <li><a href="/topics/software-reviews" >Software & Reviews</a></li>
                    <li><a href="/topics/teaching" >Teaching</a></li>
                </ul>
            </Col>
            <Col md="3" className="p-4">
                <h6>Blog</h6>
                <ul>
                    <li><Link to="/blog/skills/" onClick={() => window.location.href = '/blog/skills/' } >Skills</Link></li>
                    <li><Link to="/blog/exams/" onClick={() => window.location.href = '/blog/exams/' } >Exams</Link></li>
                    <li><Link to="/blog/grammar/" onClick={() => window.location.href = '/blog/grammar/' } >Grammar</Link></li>
                    <li><Link to="/blog/vocabulary/" onClick={() => window.location.href = '/blog/vocabulary/' } >Vocabulary</Link></li>
                    <li><Link to="/blog/pronunciation/" onClick={() => window.location.href = '/blog/pronunciation/' } >Pronunciation</Link></li>
                    {/*<li><Link to="/" >Career</Link></li>*/}
                </ul>
            </Col>
            <Col md="3" className="p-4">
                <h6>Popular language tests</h6>
                <ul>
                    <li><Link to="/topics/toefl" >TOEFL</Link></li>
                    <li><Link to="/topics/ielts" >IELTS</Link></li>
                    <li><Link to="/topics/toeic" >TOEIC</Link></li>
                </ul>
            </Col>
            <Col md="6" className="">
            	<label style={{fontSize:'12px'}}>© Copyright 2021 English Forward, All Rights Reserved</label>
            </Col>
            <Col md="6" className=" social">
                <ul className="float-right" > 
                    <li> 
                        <a href="https://www.facebook.com/EnglishForwardBlockchain/" target="_blank" >
                            <Image src={Images.facebook} />
                        </a>
                        <a href="https://twitter.com/englishforwardb?lang=en" target="_blank" >
                            <Image src={Images.twitter} />
                        </a>
                        <a href="https://www.youtube.com/channel/UC1iARXOZqu45-mTGF3r8R8w" target="_blank" >
                            <Image src={Images.youtube} />
                        </a>
                        <a href="https://englishforward.medium.com/" target="_blank" >
                         <Image className="mediumIcon" src={Images.medium} />
                        </a>
                        {/*<a href="https://telegramchannels.me/groups/englishforwardcommunity" target="_blank" >
                         <Image src={Images.paperPlane} />
                        </a>*/}
                        <a href="https://telegramchannels.me/channels/englishforwardblockchain" target="_blank" >
                         <Image src={Images.paperPlane} />
                        </a>
                    </li>
                </ul>
            </Col>
        </Row>
        </div>
    	</footer>

    )
}
export default Footer;