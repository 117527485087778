import { Link } from "react-router-dom";
import { BaseColor, Images } from "../config";

import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';

import PropTypes from "prop-types";
import moment from 'moment';
import 'moment-timezone';
function AnswerTabList(props) {
    let { answer } = props;
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return (
        <>
            <Row>
                <Col className="" md="12" >
                    <div className="row mt-2 p-2 p-md-3 p-lg-3 question-list-tab" style={{ backgroundColor: BaseColor.whiteColor, borderWidth: 'thin', borderRadius: 5, border: "0.1px solid", borderColor: BaseColor.grayColor, boxShadow: "0px 34px 39px -42px rgba(0, 0, 0, 0.2);" }}>
                        <Col md="12" xs="12" className="mb-0">
                            {answer.QuestionID && (
                                <Link to={"/question/" + answer.QuestionID.QuestionUrl} style={{ textDecoration: "none" }}>
                                    {/* target="_blank" */}
                                    {/* <h2 className="answer ">{answer.QuestionID && answer.QuestionID.Subject}</h2> */}
                                    <h2 className="question" dangerouslySetInnerHTML= {{ __html: answer.QuestionID && answer.QuestionID.Subject}} ></h2>
                                </Link>
                            )}
                        </Col>
                        <Col md="1" xs="2" className="pl-0 pl-md-2 pl-lg-2">
                            <Image className="profile-pic" src={answer.UserID && answer.UserID.ProfilePicture ? answer.UserID.ProfilePicture : Images.defaultProfile} width={40} height={40} roundedCircle />
                            <div style={{ flex: '1', alignSelf: 'center' }}>
                                <div className="mt-2" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    {answer.IsVoted && answer.UpVote ? (<Image src={Images.upActive} height={15} width={25} />) : (<Image src={Images.up} height={15} width={25} />)}
                                    <span style={{ fontSize: 12 }}>{answer.percent}</span>
                                    {answer.IsVoted && answer.DownVote ? (<Image src={Images.downActive} height={15} width={25} />) : (<Image src={Images.down} height={15} width={25} />)}
                                </div>
                            </div>
                        </Col>
                        <Col md="11" xs="8" className="p-0" >
                            <div>
                                <span className="user-name">
                                    <Link to={"/profile/" + answer.UserID.UserName} >
                                        {answer.UserID && answer.UserID.FirstName + " " + answer.UserID.LastName}
                                    </Link>
                                </span>
                                <small className="post-date float-right">{"Posted " + moment.tz(answer.CreatedDate, timeZone).fromNow()}</small>
                            </div>
                            <p className="answer-detail mt-2 mb-3" dangerouslySetInnerHTML= {{ __html: answer.Answer }} ></p>
                            {/* <div className="sub-links" style={{ display: 'flex', flexWrap: "wrap", flexDirection: 'row' }}>
                                <label style={{ marginRight: 15 }}>Answer</label>
                                <label style={{ marginRight: 15 }}>Follow</label>
                                <label style={{ marginRight: 15 }}>Request</label>
                                <label style={{ marginRight: 15 }} className="for-dropdown">
                                    <DropdownButton className="menu-three-dots-button" id="dropdown-item-button" title={<Image src={Images.dotIcon} />}>
                                        <Dropdown.Item as="button">All answers</Dropdown.Item>
                                        <Dropdown.Divider />
                                        <Dropdown.Item as="button">Manage</Dropdown.Item>
                                        <Dropdown.Divider />
                                        <Dropdown.Item as="button">Logs</Dropdown.Item>
                                    </DropdownButton>
                                </label>
                            </div> */}
                            <div className="mt-2" style={{ flex: '9', display: 'flex', flexDirection: 'column' }}>
                                <div className="action" >
                                    {/* marginRight: 50, 
                                    <label style={{ marginLeft: 20,marginBottom: 0 }}><Image src={Images.up} />&nbsp;<span>{answer.UpvoteCount}</span></label>*/}
                                    <label style={{  marginBottom: 0 }}><Image src={Images.comment} width={15} height={15} />&nbsp;<span>{answer.ReportCount}</span></label>
                                    {/* <label style={{ marginLeft: 20, marginBottom: 0 }}><Image src={Images.forward} />&nbsp;<span>{answer.ShareCount}</span></label> */}
                                </div>
                            </div>
                        </Col>
                    </div>
                </Col>
            </Row>
        </>
    )
}

AnswerTabList.prototype = {
    answer: PropTypes.object
}

AnswerTabList.defaultProps = {
    answer: {}
}
export default AnswerTabList;

// margin-left: 27px;margin-top: -14px;