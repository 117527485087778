import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, Col, Container, FormControl, Image, InputGroup, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import Helmet from 'react-helmet';
import { Images } from '../config';
import { httpPost } from '../Services/HttpServices';

class Partners extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="partners" >
                <Helmet>
                    <title>{"English Forward | Partners"}</title>
                </Helmet>
                <Container>
                    <Row>
                        <Col md="12" className="p-3 text-center" >
                            <h1>Partners</h1>
                        </Col>
                        <Col md="12" className="p-2 text-center" >
                            <p>
                                Leading Educational institutions and portals trust the EnglishForward.com platform to deliver high-quality English language Q&A and grammar resources for their users.
                            </p>
                            <p>   
                                Together with our partners, we are transforming the future of connecting ESL learners to the biggest learn English community on the internet.
                            </p>
                            <h3>Be Part of the Revolution! </h3>
                            <Link to="/signup" >Join Us</Link>
                        </Col>
                    </Row>
                    <Row className="mt-4 text-center" >
                        <Col xs={0} md={1} >
                        </Col>
                        <Col xs={6} md={2} >
                          <Image src="/partners/1.MasterClass.png" thumbnail />
                        </Col>
                        <Col xs={6} md={2}>
                          <Image src="/partners/2.KDM.png" thumbnail />
                        </Col>
                        <Col xs={6} md={2}>
                          <Image src="/partners/3.MailButler.png" thumbnail />
                        </Col>
                        <Col xs={6} md={2}>
                          <Image src="/partners/4.GoogleNews.png" thumbnail />
                        </Col>
                        <Col xs={6} md={2}>
                          <Image src="/partners/5.Proofed.png" thumbnail />
                        </Col>
                        <Col xs={0} md={1} >
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default Partners;