import { Link } from "react-router-dom";
import { BaseColor, Images } from "../config";

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';

import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { httpPost } from '../Services/HttpServices';
import { toast } from 'react-toastify';
import SweetAlert from 'react-bootstrap-sweetalert';
// import Editor from '../Component/RichTextEditor';
import Editor from '../Component/QuillEditor';

function DraftQuestion(props) {
    let { question, updateList } = props;
    const [del, setDel] = useState(false);
    const [edit, setEdit] = useState(false);
    const [answer, setAnswer] = useState("");//EditorState.createEmpty()
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        console.log('question', question);
        if (question) {
            //setAnswer(question.Answer);
            // setAnswer(EditorState.createWithContent(ContentState.createFromText(question.Answer)));
        }
    }, [question])

    const submitAnswer = () => {
        setLoading(true);
        httpPost('answer_controller/create', {
            Answer: answer,//&& convertToRaw(answer.getCurrentContent()).blocks,
            QuestionID: question && question.QuestionID && question.QuestionID._id || null,
        }).then(res => {
            setLoading(false);
            if (res && res.status) {
                updateList();
                setEdit(false);
                toast.success(res.message)
            } else {
                toast.error(res && res.message || "Network Error");
            }

        }).catch(err => {
            setLoading(false);
        })
    }

    const declineAnswer = () => {
        setLoading(true);
        setDel(false);
        httpPost('answer_request_controller/decline', {
            AnswerRequestID: question._id
        }).then(res => {
            setLoading(false);
            if (res && res.status) {
                updateList();
                toast.success(res.message);
            } else {
                toast.error(res && res.message || "Network Error");
            }

        }).catch(err => {
            setLoading(false);
        })
    }
    return (
        <Col md="12" className="d-detail mb-3">
            <p className="draft-head">Answer - Requested</p>
            <Link to={'questiondetail&id=' + question && question.QuestionID && question.QuestionID._id}>
                <h3 className="mb-3">{question && question.QuestionID && question.QuestionID.Subject || ""}</h3>
            </Link>
            <Row className="mb-3">
                <Col md="3" xs="6">
                    <h5 onClick={() => { setEdit(!edit) }}><Image src={Images.edit} width="25" /> &nbsp;Give Answer</h5>
                </Col >
                <Col md="4" xs="6">
                    <h5 onClick={() => setDel(true)}><Image src={Images.delete} width="25" /> &nbsp;Decline Answer</h5>
                </Col>
            </Row >
            {edit ? (
                <Row>
                    <Col md="12" className="mb-3">
                        <Editor
                            // ref={editor}
                            editorState={question && question.Answer || ""}
                            onUpdate={editorState => setAnswer(editorState)}
                        />
                        {/* <Form.Control value={answer} onChange={(e) => { setAnswer(e && e.target && e.target.value) }} type="text" placeholder="What is your answer? write here..." /> */}
                    </Col>
                    <Col md="2" xs="3">
                        <Button disabled={loading} onClick={() => submitAnswer()} type="primary">Post</Button>
                    </Col >
                    <Col md="3" xs="3">
                        <Button disabled={loading} onClick={() => setEdit(!edit)}>Cancel</Button>
                    </Col>

                </Row>
            ) : null}
            <SweetAlert
                show={del}
                warning
                showCancel
                confirmBtnText={"Yes"}
                cancelBtnText={"No"}
                title={<span style={{ fontSize: 25 }} >Do you want to decline this Answer</span>}
                onConfirm={() => { declineAnswer() }}
                onCancel={() => { setDel(false) }}
            />
        </Col >
    )
}
DraftQuestion.prototype = {
    question: PropTypes.object,
    updateList: PropTypes.func,
}

DraftQuestion.defaultProps = {
    question: {},
    updateList: () => { },
}
export default DraftQuestion;