import React, { Component } from 'react';
import { Button, Col, Container, FormControl, Image, InputGroup, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import { Images } from '../config';
import { httpPost } from '../Services/HttpServices';

class Terms extends Component {
    constructor(props) {
        super(props);
    }
  
    render() {
        return (
            <div className="terms" >
                <Container>
                    <Row>
                        <Col md="12" className="p-3 text-center" >
                            <h1>English Forward Terms & Conditions</h1>
                        </Col>
                        <Col md="12" className="p-2" >
                            <p>These General Terms and Conditions apply to our relation with users and others interacting with English Forward. When you use or open English Forward services, you agree to these General Terms and Conditions, which are regularly amended in accordance with the provisions in article 10.</p>
                            <p>
                            1. Privacy<br />
                            Your privacy is paramount. We have drawn up a privacy statement in which we describe how we collect personal data and for which purposes we use it. We recommend that you read this privacy statement carefully and determine whether to engage our services on the basis thereof.
                            </p>
                            <p>
                            2. Content and information<br />
                            You and only you are the owner of the information saved in your account. We and the advertisers only use this information to ensure that the advertisements you see coincide with your interests to the extent possible. Advertisers cannot see your name, email or other data that could lead to the identification of you as an individual.
                            </p>
                            <p>Although we do our utmost to assess all advertisements prior to publication on the basis of our advertising policy, we cannot guarantee that you will not see advertisements that do not comply with our advertising policy or the legislation and regulations as they apply in your country or region. The advertiser is fully responsible for the content of the advertisements. If you see an advertisement that you consider should not have been published, please report this by sending an email to contact@englishforward.com.</p>
                            <p>
                            3. Security<br />
                            We will do our utmost to keep English Forward secure, but we cannot guarantee it. In order to keep English Forward safe, we need your help and that is why you must follow these rules:
                            <ul>
                                <li>You will not collect content or information from users and will not use English Forward in other ways via automated ways (such as harvesting bots, robots, spiders or scrapers) without our prior permission.</li>
                                <li>You will refrain from engaging in illegal marketing on English Forward such as pyramid schemes.</li>
                                <li>You will not upload viruses or other harmful codes.</li>
                                <li>You will not request registration data from people and you will not register into an account of someone else.</li>
                                <li>You will not use English Forward for unlawful, misleading, malicious or discriminatory activities.</li>
                                <li>You don’t do anything to disrupt or overload the correct operation or reflection of English Forward or to make it impossible, such as a Denial of Service-attack or disruption of advertising reflection or other English Forward functionalities.</li>
                                <li>You do not facilitate or stimulate any violation of these General Terms and Conditions.</li>
                            </ul>
                            </p>
                            <p>
                            Your English Forward can contain hyperlinks by which you leave English Forward and direct you to websites of third parties. We have no control over the websites or services of third parties. It is possible that the use of the websites or services of third parties are subject to other terms and conditions. We accept no responsibility or liability for (the operation and/or content of) websites or services of third parties.
                            </p>
                            <p>
                            4. Registration and Account security<br />
                            You and us benefit from the personal data linked to your account being accurate. Please find below some rules you need to comply with regarding the registration and security of your account:
                            <ul>
                                <li>You will not generate more than one personal account.</li>
                                <li>You guarantee that the information you provide upon creating your account is correct, current and accurate.</li>
                                <li>You will never generate an account for someone else.</li>
                                <li>You may not use English Forward if you are younger than 18 years old.</li>
                                <li>You are responsible for keeping your password confidential, you will not grant anyone access to your account and you will not do anything that could harm the security of your account.</li>
                                <li>If you suspect or know that your login details are held by unauthorized persons, you must inform us thereof immediately. You can do this by sending an email to contact@englishforward.com.</li>
                            </ul>
                            </p>
                            <p>
                            5. Termination<br />
                            If you violate the letter or intent of these General Terms and Conditions or create any other risk or legal risk for us, we can terminate your access to English Forward. You can remove your account at any time by sending a request thereto to the following email address: contact@englishforward.com.
                            </p>
                            <p>
                            Upon termination, regardless of the reason, your right to use English Forward is cancelled immediately. We will remove your account immediately upon termination, regardless of the reason.
                            </p>
                            <p>
                            6. Intellectual Property rights<br />
                            The IP-rights with regard to English Forward are held by English Forward B.V. and/or its licensors. Nothing in these General Terms and Conditions is intended to transfer any IP-right to you or to waive any IP-rights.
                            </p>
                            <p>
                            7. Changes<br />
                            We can always change or add to these General Terms and Conditions. The latest version of these General Terms and Conditions are always published on our website or are brought to your attention upon using English Forward. When you continue using English Forward after changes of and/or additions to these General Terms and Conditions, you therefore irrevocably accept the changes and/or additions to the General Terms and Conditions. If you do not agree with the changes and/or additions of the General Terms and Conditions, your only option is to request us to remove your account and no longer use English Forward. You can do this by sending an email to contact@englishforward.com.
                            </p>
                            <p>
                            8. Disputes<br />
                            <ol>
                                <li>These General Terms and Conditions and all the use of English Forward is governed by Malta law.</li>
                                <li>Any disputes arising between you and us will be brought before the competent court in Malta, unless statutory law requires us to bring the case before a different court.</li>
                                <li>We can transfer the rights and obligations resulting from these General Terms and Conditions to third parties and we will inform you of this. If you do not consider this transfer of obligations to a third party acceptable, your only option is seize the use of English Forward and to remove your account.</li>
                                <li>‘English Forward’ or ‘English Forward services’ refers to functions and services we make available, including via (a) our website at englishforward.com as well as on sub-domains and international sites, (b) our application and (c) other media, brands, products, services or software as they exist or will be developed in future.</li>
                                <li>‘Account’ refers to your personal environment within English Forward.</li>
                                <li>‘Advertisers’ refers to companies that publish one or multiple advertisements within the application.</li>
                                <li>‘IP-rights’ refers to all intellectual property rights and related rights, such as copyright, trade mark right, patent right, model right, trade name right, database right and affiliated rights, including rights on knowhow and performances on par with such rights;</li>
                                <li>‘data’, ‘personal data’ or ‘profile’ refers to the data you have entered in your account, including the location (city, province, country), date of birth, gender, relationship status, interests on the basis of which English Forward shows suitable advertising.</li>
                            </ol>
                            </p>
                            <p>
                            8. Other<br />
                            <ol>
                                <li>These General Terms and Conditions form the full agreement concluded by the parties with regard to English Forward and take precedence over any prior agreements.</li>
                                <li>If a part of these General Terms and Conditions does not appear enforceable, the remaining part thereof will continue to be applicable in full and valid.</li>
                                <li>When we do not enforce any part of these General Terms and Conditions, this cannot be regarded as permission.</li>
                                <li>Any change or statement of rejection statement of these General Terms and Conditions must be made in writing and must be signed by us.</li>
                                <li>You do not transfer any rights or obligations you may have under these General Terms and Conditions to anyone else without our permission.</li>
                                <li>All our rights and obligations under these General Terms and Conditions can be freely allocated in the event of a merger, takeover or sales of assets, by law or otherwise.</li>
                                <li>Nothing stated in these General Terms and Conditions will stop us from complying with the law.</li>
                                <li>These General Terms and Conditions do not grant rights of third parties.</li>
                                <li>We retain all rights that have not explicitly been granted to you.</li>
                                <li>Upon using or opening English Forward you will comply with all applicable legislation.</li>
                            </ol>
                            </p>
                            <p>
                            By using or opening English Forward services, you agree that we can use this content and information and collect it in accordance with the privacy statement which can be amended periodically.
                            </p>
                            <p className="copyright" >
                                © Copyright 2020 English Forward.com All Rights Reserved.
                            </p>
                            <p className="copyright" >
                                This “Terms and Conditions” document was last updated on 8th August 2020 and is effective immediately.
                            </p>
                        </Col>
                    </Row>
                </Container>

            </div>
        )
    }
}

export default Terms;