import { Link } from "react-router-dom";
import { BaseColor, Images } from "../config";

import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';

import moment from 'moment';
import 'moment-timezone';
import PropTypes from "prop-types";

function QuestionCard(props) {
	const timeZone  = Intl.DateTimeFormat().resolvedOptions().timeZone;
	return (
		<>
			<Row>
				{props.questions.map(ele => (
					<Col lg="3" md="6" key={ele._id}>
						<div className="home-question-card" >
							<div style={{ display: 'flex' }}>
								<div style={{display: 'flex', flexDirection: 'column', padding: "7px 20px" }} >
									<div className="left-icon" >
										<Image src={ele.UserID && ele.UserID.ProfilePicture ? ele.UserID.ProfilePicture : Images.defaultProfile} width={50} height={50} roundedCircle />
									</div>
									<div className="right-icon" >
										<Image src={Images.follow} />
									</div>
									<div className="user-info" >
										<Link to={'/profile/' + ele.UserID.UserName} >
										<div style={{}}><b>{ele.UserID && ele.UserID.FirstName || ""}</b></div></Link>
										<div className="action" >
											<label><Image src={Images.up} />&nbsp;<span>{ele.UpvoteCount}</span></label>
											<label style={{ marginLeft: 39 }}><Image src={Images.comment} />&nbsp;<span>{ele.ShareCount}</span></label>
										</div>
										{/*<small>{"posted " + moment.tz(ele.CreatedDate, timeZone).fromNow()}</small>*/}
									</div>
									<Link to={'/question/' + ele.QuestionUrl} >
										<h6 className="detail" dangerouslySetInnerHTML={{ __html: ele.Subject && ele.Subject.length > 100 ? ele.Subject.substring(0, 100) : ele.Subject }} ></h6>
									</Link>
									{ele.TopicID && ele.TopicID.length > 0 ? (<div class="topic" >{ele.TopicID.map(t => {
										return (t && t.Title ? ' '+t.Title : null)
									})}</div>) : null}
									{/*<br />
									<div className="action" >
										 marginRight: 50, 
										<label><Image src={Images.up} />&nbsp;<span>{ele.UpvoteCount}</span></label>
										<label style={{ marginLeft: 50 }}><Image src={Images.comment} />&nbsp;<span>{ele.ShareCount}</span></label>
									</div>*/}
								</div>
							</div>
						</div>
					</Col>
				))}
			</Row>
		</>
	)
}

QuestionCard.prototype = {
	questions: PropTypes.array
}

QuestionCard.defaultProps = {
	questions: []
}
export default QuestionCard;