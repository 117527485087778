import querystring from 'query-string';
import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import { LinkedIn } from 'react-linkedin-login-oauth2';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import TwitterLogin from "react-twitter-login";

import { Images } from '../config';
import { httpPost, httpRequest } from '../Services/HttpServices';

// import linkedin from 'react-linkedin-login-oauth2/assets/linkedin.png'

class AccountSetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userID: "",
            AutoShareQA: 0,
            ConnectSocialMedia: 0,
            // DeleteAccount: 0,
            EmailFrequency: 0,
            EmailOnGeneralQA: 0,
            EmailOnMessageCommentMention: 0,
            EmailOnMyActivity: 0,
            EmailOnMyNetwork: 0,

            NotificationFrequency: 0,
            NotificationOnGeneralQA: 0,
            NotificationOnMessageCommentMention: 0,
            NotificationOnMyActivity: 0,
            NotificationOnMyNetwork: 0,

            showAlert: false,
            text: "",
            showAlertTwo: false,

            isGoogleConnected: false,
            isFacebookConnected: false,
            isLinkedinConnected: false,
            isTwitterConnected: false,
            EnableDarkMode: false,
            ShowEmail: false,
            ShowMobile: false
        }
    }
    handleSubmit = (e) => {
        var obj = {
            // DeleteAccount: this.state.DeleteAccount,
            ConnectSocialMedia: this.state.ConnectSocialMedia,
            AutoShareQA: this.state.AutoShareQA,
            EmailOnGeneralQA: this.state.EmailOnGeneralQA,
            EmailOnMessageCommentMention: this.state.EmailOnMessageCommentMention,
            EmailOnMyNetwork: this.state.EmailOnMyNetwork,
            EmailOnMyActivity: this.state.EmailOnMyActivity,
            EmailFrequency: this.state.EmailFrequency,

            NotificationOnGeneralQA: this.state.NotificationOnGeneralQA,
            NotificationOnMessageCommentMention: this.state.NotificationOnMessageCommentMention,
            NotificationOnMyNetwork: this.state.NotificationOnMyNetwork,
            NotificationOnMyActivity: this.state.NotificationOnMyActivity,
            NotificationFrequency: this.state.NotificationFrequency,

            EnableDarkMode: this.state.EnableDarkMode,
            ShowEmail: this.state.ShowEmail,
            ShowMobile: this.state.ShowMobile
        }
        httpPost("user_controller/update_account_setting", obj)
            .then((res) => {
                if (res.status == 1) {
                    toast.success(res.message)
                } else {
                    toast.error(res.message)
                }
            })
    }

    componentDidMount() {
        this.setState({
            userID: this.props.auth.user._id
        })
        httpPost("user_controller/retrive", { UserID: this.props.auth.user._id })
            .then((res) => {
                if (res.data && res.data.AccountSetting) {
                    this.setState({
                        AutoShareQA: res.data.AccountSetting.AutoShareQA,
                        ConnectSocialMedia: res.data.AccountSetting.ConnectSocialMedia,
                        EnableDarkMode: res.data.AccountSetting.EnableDarkMode ? true : false,
                        //DeleteAccount: res.data.AccountSetting.DeleteAccount,
                        EmailFrequency: res.data.AccountSetting.EmailFrequency,
                        EmailOnGeneralQA: res.data.AccountSetting.EmailOnGeneralQA,
                        EmailOnMessageCommentMention: res.data.AccountSetting.EmailOnMessageCommentMention,
                        EmailOnMyActivity: res.data.AccountSetting.EmailOnMyActivity,
                        EmailOnMyNetwork: res.data.AccountSetting.EmailOnMyNetwork,

                        NotificationFrequency: res.data.AccountSetting.NotificationFrequency,
                        NotificationOnGeneralQA: res.data.AccountSetting.NotificationOnGeneralQA,
                        NotificationOnMessageCommentMention: res.data.AccountSetting.NotificationOnMessageCommentMention,
                        NotificationOnMyActivity: res.data.AccountSetting.NotificationOnMyActivity,
                        NotificationOnMyNetwork: res.data.AccountSetting.NotificationOnMyNetwork,

                        isFacebookConnected: res.data.FacebookID ? true : false,
                        isGoogleConnected: res.data.GoogleID ? true : false,
                        ShowEmail: res.data.AccountSetting.ShowEmail,
                        ShowMobile: res.data.AccountSetting.ShowMobile
                    })
                }
            })
    }

    componentWillMount() {
        // if(this.state.userID === ""){
        //     this.props.history.push('/signin');
        // }
    }

    handleDeleteOne = (e) => {
        this.setState({ showAlert: true, text: "Everything will be deleted along with the user data (Not recommended as this will create a lot of issues. Example, An answer that is chosen as verified answer, will disappear)" })
    }
    handleDeleteTwo = (e) => {
        this.setState({ showAlert: true, text: "Keep posts and just remove my details from these posts and display them as Anonymous User" })
    }
    onConfirm = () => {
        this.setState({ showAlert: false })
        this.setState({ showAlertTwo: true })
    }

    handleRadio = (e) => {
        console.log(e.target.value);
        if (e.target.value == "ConnectSocialMediaNo") {
            this.setState({ ConnectSocialMedia: 2 })
        }
        if (e.target.value == "ConnectSocialMediaYes") {
            this.setState({ ConnectSocialMedia: 1 })
        }
        if (e.target.value == "AutoShareQANo") {
            this.setState({ AutoShareQA: 2 })
        }
        if (e.target.value == "AutoShareQAYes") {
            this.setState({ AutoShareQA: 1 })
        }
       
        if (e.target.value == "einstantly") {
            this.setState({ EmailFrequency: 1 })
        }
        if (e.target.value == "edaily") {
            this.setState({ EmailFrequency: 2 })
        }
        if (e.target.value == "eweekly") {
            this.setState({ EmailFrequency: 3 })
        }

        if (e.target.value == "eansques") {
            if (this.state.EmailOnGeneralQA == 1) {
                this.setState({ EmailOnGeneralQA: 2 })
            } else {
                this.setState({ EmailOnGeneralQA: 1 })
            }
        }

        if (e.target.value == "ementions") {
            if (this.state.EmailOnMessageCommentMention == 1) {
                this.setState({ EmailOnMessageCommentMention: 2 })
            } else {
                this.setState({ EmailOnMessageCommentMention: 1 })
            }
        }

        if (e.target.value == "emynetwork") {
            if (this.state.EmailOnMyNetwork == 1) {
                this.setState({ EmailOnMyNetwork: 2 })
            } else {
                this.setState({ EmailOnMyNetwork: 1 })
            }
        }

        if (e.target.value == "eactivity") {
            if (this.state.EmailOnMyActivity == 1) {
                this.setState({ EmailOnMyActivity: 2 })
            } else {
                this.setState({ EmailOnMyActivity: 1 })
            }
        }

        if (e.target.value == "ShowEmail") {
            if (this.state.ShowEmail == 1) {
                this.setState({ ShowEmail: 0 })
            } else {
                this.setState({ ShowEmail: 1 })
            }
        }

        if (e.target.value == "ShowMobile") {
            if (this.state.ShowMobile == 1) {
                this.setState({ ShowMobile: 0 })
            } else {
                this.setState({ ShowMobile: 1 })
            }
        }


        if (e.target.value == "ndaily") {
            this.setState({ NotificationFrequency: 2 })
        }
        if (e.target.value == "ninstantly") {
            this.setState({ NotificationFrequency: 1 })
        }
        if (e.target.value == "nweekly") {
            this.setState({ NotificationFrequency: 3 })
        }
        if (e.target.value == "nansques") {
            if (this.state.NotificationOnGeneralQA == 1) {
                this.setState({ NotificationOnGeneralQA: 2 })
            } else {
                this.setState({ NotificationOnGeneralQA: 1 })
            }
        }
        if (e.target.value == "nmentions") {
            if (this.state.NotificationOnMessageCommentMention == 1) {
                this.setState({ NotificationOnMessageCommentMention: 2 })
            } else {
                this.setState({ NotificationOnMessageCommentMention: 1 })
            }
        }
        if (e.target.value == "nmynetwork") {
            if (this.state.NotificationOnMyNetwork == 1) {
                this.setState({ NotificationOnMyNetwork: 2 })
            } else {
                this.setState({ NotificationOnMyNetwork: 1 })
            }
        }
        if (e.target.value == "nactivity") {
            if (this.state.NotificationOnMyActivity == 1) {
                this.setState({ NotificationOnMyActivity: 2 })
            } else {
                this.setState({ NotificationOnMyActivity: 1 })
            }
        }

        if(e.target.value === "mode"){
            if (this.state.EnableDarkMode === true) {
                this.setState({ EnableDarkMode: false })
            } else {
                this.setState({ EnableDarkMode: true })
            }   
        }
    }

    onCancel = () => {
        this.setState({ showAlert: false })}

    onConfirmTwo = () => {
        this.setState({ showAlertTwo: false })
    }

    connectGoogleAccount = (e) => {
        let googleId = e.profileObj.googleId;
        httpPost("user_controller/update", { GoogleID: googleId })
            .then((res) => {
                if (res.status) {
                    this.setState({
                        isGoogleConnected: true
                    })
                }
            })
    }

    disconnectGoogleAccount = () => {
         httpPost("user_controller/update", { GoogleID: null })
            .then((res) => {
                if (res.status) {
                    this.setState({
                        isGoogleConnected: false
                    })
                }
            })
    }

    connectFacebookAccount = (e) => {
        if(e && e.id){
            httpPost("user_controller/update", { FacebookID: e.id })
                .then((res) => {
                    if (res.status) {
                        this.setState({
                            isFacebookConnected: true
                        })
                    }
                })
        }
    }

    disconnectFacebookAccount = () => {
         httpPost("user_controller/update", { FacebookID: null })
            .then((res) => {
                if (res.status) {
                    this.setState({
                        isFacebookConnected: false
                    })
                }
            })
    }

    onLinkedinSuccess = async (data) => {
        console.log(data, "onLinkedinSuccess");
        let payload = querystring.stringify({ 
                grant_type: 'authorization_code', 
                code: data.code,
                redirect_uri: 'http://localhost:3000/linkedin',
                client_id: '775b9r7a4jzw6c',
                client_secret: 'SQ70a8vZol6OuVpb'
            })
         // console.log(payload, "payload");return;   
        // ACCESS_TOKEN_URL = "https://www.linkedin.com/oauth/v2/accessToken"
        const response = await fetch("https://www.linkedin.com/oauth/v2/accessToken", {
            mode: 'no-cors',
            method: 'POST',
            headers: {
              'Access-Control-Allow-Headers': 'Accept',
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: payload,
          })
        console.log(await response, "Response");
         // httpRequest("https://www.linkedin.com/oauth/v2/accessToken", { 
         //    grant_type: 'authorizarion_code', 
         //    code: data.code,
         //    redirect_uri: 'http://localhost:3000/linkedin',
         //    client_id: '775b9r7a4jzw6c',
         //    client_secret: 'SQ70a8vZol6OuVpb',
         //    },'', true)
         //    .then((res) => {
         //        console.log(res);
         //        if (res.status) {
         //            // this.setState({
         //            //     isFacebookConnected: false
         //            // })
         //        }
         //    })
        // this.setState({
        //   code: data.code,
        //   errorMessage: '',
        // });
    }

    onLinkedinFailure = (error) => {
        console.log(error, "onLinkedinFailure");
        // this.setState({
        //   code: '',
        //   errorMessage: error.errorMessage,
        // });
    }

    onTwitterSuccess = (err, data) => {
        if(data && data.user_id){
            httpPost("user_controller/update", { TwitterID: data.user_id })
                .then((res) => {
                    if (res.status) {
                        this.setState({
                            isTwitterConnected: true
                        })
                    }
                })
        }
    }

    disconnectTwitterAccount = () => {
         httpPost("user_controller/update", { TwitterID: null })
            .then((res) => {
                if (res.status) {
                    this.setState({
                        isTwitterConnected: false
                    })
                }
            })
    }

    
    render() {
        console.log("Checking google social login");
        return (
            <div className="account-setting" >
                {this.props.auth.user === null ? (<Redirect to="/signin" />): null }
                <Breadcrumb>
                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/profile">
                        Profile
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Settings</Breadcrumb.Item>
                </Breadcrumb>
                <Container>
                    <h2>Settings</h2>
                    {/* ********************* */}
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Row>
                            <Col sm={3}>
                                <Nav variant="pills" className="flex-column">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first" defaultActiveKey>Account</Nav.Link>
                                    </Nav.Item>
                                    {/* <Nav.Item>
                                        <Nav.Link eventKey="second">Email</Nav.Link>
                                    </Nav.Item>*/}
                                    <Nav.Item>
                                        <Nav.Link eventKey="third">Notifications</Nav.Link>
                                    </Nav.Item>
                                    {/* <Nav.Item>
                                        <Nav.Link eventKey="third">Languages</Nav.Link>
                                    </Nav.Item> */}
                                </Nav>
                            </Col>
                            <Col sm={9}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <h4>Account Settings</h4>
                                        <hr></hr>
                                        <Row>
                                            <Col sm={6}>Password</Col>
                                            <Col sm={6}><a href="changepassword" className="btn btn-primary btn-rounded" >Change Password</a></Col>
                                        </Row>
                                        <hr></hr>
                                        <Row>
                                            <Col sm={6}>Privacy</Col>
                                            <Col sm={6}>
                                                <label><input checked={this.state.ShowEmail == 1 ? true : false} type="checkbox" name="ShowEmail" value="ShowEmail" onChange={this.handleRadio} /> Show Email</label>
                                                <br></br>
                                                <label><input checked={this.state.ShowMobile == 1 ? true : false} type="checkbox" name="ShowMobile" value="ShowMobile" onChange={this.handleRadio} /> Show Mobile No</label>
                                            </Col>
                                        </Row>
                                        <hr></hr>
                                        {/* <Row>
                                            <Col sm={6}>Delete Account
                                            <br></br>
                                                <small>
                                                    All the user related data will be deleted from our main database with respect to the question, answers, comments, upvote, downvote, quiz created and participated
                                                </small>
                                            </Col>
                                            <Col sm={6}>
                                                <label><input type="radio" onChange={this.handleDeleteOne} name="deleteoptions" value="delete1" /> Everything will be deleted along with the user data (Not recommended as this will create a lot of issues. Example, An answer that is chosen as verified answer, will disappear)</label>
                                                <br></br>
                                                <label><input type="radio" onChange={this.handleDeleteTwo} name="deleteoptions" value="delete2" /> Keep posts and just remove my details from these posts and display them as Anonymous User</label>
                                            </Col>
                                        </Row> */}
                                        <SweetAlert
                                            show={this.state.showAlert ? true : false}
                                            warning
                                            showCancel
                                            confirmBtnText={"Yes"}
                                            cancelBtnText={"No"}
                                            title="Are you sure?"
                                            onConfirm={this.onConfirm}
                                            onCancel={this.onCancel}
                                        >
                                            {this.state.text}
                                        </SweetAlert>
                                        <SweetAlert
                                            show={this.state.showAlertTwo ? true : false}
                                            info
                                            confirmBtnText={"Ok"}
                                            title="This functionality is under process."
                                            onConfirm={this.onConfirmTwo}
                                        />
                                        {/* <hr></hr> */}
                                        {/* <h4 className="mt-4">Connected Accounts</h4> */}
                                        {/* <hr></hr>
                                            <Row>
                                                <Col sm={6}>Auto share Questions and Answers on your Social Media accounts</Col>
                                                <Col sm={6}>
                                                    <label><input type="radio" onChange={this.handleRadio} name="autoshare" value="AutoShareQAYes" checked={this.state.AutoShareQA == 1 ? true : false} /> Yes&nbsp;&nbsp;</label>
                                                    <label><input type="radio" onChange={this.handleRadio} name="autoshare" value="AutoShareQANo" checked={this.state.AutoShareQA == 2 ? true : false} /> No&nbsp;&nbsp;</label>
                                                </Col>
                                            </Row>
                                        
                                        <Row>
                                            <Col sm={6}>Enable Dark mode</Col>
                                            <Col sm={6}>
                                              <Form.Check 
                                                type="switch"
                                                id="custom-switch"
                                                checked= {this.state.EnableDarkMode}
                                                value="mode"
                                                onChange={this.handleRadio}
                                                // label="Check this switch"
                                              />
                                            </Col>
                                        </Row>
                                        <hr></hr>*/}
                                        {/*<h4>Quick Logins</h4>*/}
                                        <Row className="social-setting" >
                                            <Col sm={6}>
                                                {/*Connect to your Facebook Account*/}
                                                <h4>Connect</h4>
                                            </Col>
                                            <Col sm={6}>
                                            { this.state.isFacebookConnected ? (<Link onClick={this.disconnectFacebookAccount} >Disconnect Facebook</Link>) : (
                                                <FacebookLogin
                                                    appId="163597855587563"
                                                    autoLoad={false}
                                                    fields="name,email,picture"
                                                    callback={(res) => { this.connectFacebookAccount(res) }}
                                                    textButton={<div>Login with Facebook</div>}
                                                    cssClass={"btn-sm btn-rounded btn-fb"}
                                                />) }
                                            </Col>
                                        </Row>
                                        <Row className="social-setting" >
                                            <Col sm={6}>{/*Connect to your Google Account*/}</Col>
                                            <Col sm={6}>
                                                { this.state.isGoogleConnected ? (<Link onClick={this.disconnectGoogleAccount} >Disconnect Google</Link>) : (
                                                <GoogleLogin
                                                    clientId="242651927357-fubf2rb1c4cr668mf9dh1dpj5boopibm.apps.googleusercontent.com"
                                                    render={renderProps => (
                                                        <span><Button type="button" className="btn-sm btn-rounded btn-google" variant="" onClick={renderProps.onClick} >Login with Google</Button></span>
                                                    )}
                                                    //buttonText="Connect"
                                                    autoLoad={false}
                                                    onSuccess={this.connectGoogleAccount}
                                                    onFailure={this.onGoogleFail}
                                                    cookiePolicy={'single_host_origin'}
                                                />) }
                                            </Col>
                                        </Row>
                                        <Row className="social-setting" >
                                            <Col sm={6}>{/*Connect to your Linkedin Account*/}</Col>
                                            <Col sm={6}>
                                                { this.state.isLinkedinConnected ? (<Link onClick={this.disconnectGoogleAccount} >Disconnect Linkedin</Link>) : (
                                                <LinkedIn
                                                  clientId="775b9r7a4jzw6c"
                                                  onFailure={this.onLinkedinFailure}
                                                  onSuccess={this.onLinkedinSuccess}
                                                  redirectUri="http://localhost:3000/linkedin"
                                                  className="btn-sm btn-rounded btn-ln"
                                                  style={{}}
                                                >
                                                  Login with Linkedin
                                                </LinkedIn>) }
                                            </Col>
                                        </Row>
                                        <Row className="social-setting" >
                                            <Col sm={6}>{/*Connect to your Twitter Account*/}</Col>
                                            <Col sm={6}>
                                                { this.state.isTwitterConnected ? (<Link onClick={this.disconnectTwitterAccount} >Disconnect Twitter</Link>) : (
                                                <TwitterLogin
                                                  authCallback={this.onTwitterSuccess}
                                                  consumerKey="kb3hxNI1cUzdjaxIQyJoRhG3F"
                                                  consumerSecret="WF9JEDtNCpo1E1yEgbK8WOd4joqBaIHdYEBYhpLqdMbe52xpu0"
                                                />) }
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="3" className="text-center mt-5 mb-5" >
                                            </Col>
                                            <Col md="5" className="text-left mt-5 mb-5" >
                                                <Button className="btn-sm btn-block btn-rounded" onClick={this.handleSubmit}>Save</Button>
                                            </Col>
                                        </Row>
                                    </Tab.Pane>
                                    {/*<Tab.Pane eventKey="second">
                                        <h4>Email Settings</h4>
                                        <hr></hr>
                                        <Row>
                                            <Col sm={6}>Receive emails for</Col>
                                            <Col sm={6}>
                                                <label><input checked={this.state.EmailOnGeneralQA == 1 ? true : false} type="checkbox" name="emails" value="eansques" onChange={this.handleRadio} /> General Questions & Answers</label>
                                                <br></br>
                                                <label><input checked={this.state.EmailOnMessageCommentMention == 1 ? true : false} type="checkbox" name="emails" value="ementions" onChange={this.handleRadio} /> Messages, Comments & Mentions</label>
                                                <br></br>
                                                <label><input checked={this.state.EmailOnMyNetwork == 1 ? true : false} type="checkbox" name="emails" value="emynetwork" onChange={this.handleRadio} /> My Network</label>
                                                <br></br>
                                                <label><input checked={this.state.EmailOnMyActivity == 1 ? true : false} type="checkbox" name="emails" value="eactivity" onChange={this.handleRadio} /> Activity on my Content</label>
                                            </Col>
                                        </Row>
                                        <hr></hr>
                                        <Row>
                                            <Col sm={6}>Frequency</Col>
                                            <Col sm={6}>
                                                <label><input checked={this.state.EmailFrequency == 1 ? true : false} type="radio" onChange={this.handleRadio} name="frequency" value="einstantly" /> Instantly&nbsp;&nbsp;</label>
                                                <label><input checked={this.state.EmailFrequency == 2 ? true : false} type="radio" onChange={this.handleRadio} name="frequency" value="edaily" /> Daily Digest&nbsp;&nbsp;</label>
                                                <label><input checked={this.state.EmailFrequency == 3 ? true : false} type="radio" onChange={this.handleRadio} name="frequency" value="eweekly" /> Weekly Digest&nbsp;&nbsp;</label>
                                            </Col>
                                            <Col md="12" className="text-center mt-5 mb-5" >
                                                <Button className="btn-sm" onClick={this.handleSubmit}>Save</Button>
                                            </Col>
                                        </Row>
                                    </Tab.Pane>*/}
                                    <Tab.Pane eventKey="third">
                                    <h4>Email Settings</h4>
                                        <hr></hr>
                                        <Row>
                                            <Col sm={6}>Receive emails for</Col>
                                            <Col sm={6}>
                                                <label><input checked={this.state.EmailOnGeneralQA == 1 ? true : false} type="checkbox" name="emails" value="eansques" onChange={this.handleRadio} /> General Questions & Answers</label>
                                                <br></br>
                                                <label><input checked={this.state.EmailOnMessageCommentMention == 1 ? true : false} type="checkbox" name="emails" value="ementions" onChange={this.handleRadio} /> Messages, Comments & Mentions</label>
                                                <br></br>
                                                <label><input checked={this.state.EmailOnMyNetwork == 1 ? true : false} type="checkbox" name="emails" value="emynetwork" onChange={this.handleRadio} /> My Network</label>
                                                <br></br>
                                                <label><input checked={this.state.EmailOnMyActivity == 1 ? true : false} type="checkbox" name="emails" value="eactivity" onChange={this.handleRadio} /> Activity on my Content</label>
                                            </Col>
                                        </Row>
                                        <hr></hr>
                                        <Row>
                                            <Col sm={6}>Frequency</Col>
                                            <Col sm={6}>
                                                <label><input checked={this.state.EmailFrequency == 1 ? true : false} type="radio" onChange={this.handleRadio} name="frequency" value="einstantly" /> Instantly&nbsp;&nbsp;</label>
                                                <label><input checked={this.state.EmailFrequency == 2 ? true : false} type="radio" onChange={this.handleRadio} name="frequency" value="edaily" /> Daily Digest&nbsp;&nbsp;</label>
                                                <label><input checked={this.state.EmailFrequency == 3 ? true : false} type="radio" onChange={this.handleRadio} name="frequency" value="eweekly" /> Weekly Digest&nbsp;&nbsp;</label>
                                            </Col>
                                        </Row>
                                        <h4>Notifications Settings</h4>
                                        <hr></hr>
                                        <Row>
                                            <Col sm={6}>Receive notification for</Col>
                                            <Col sm={6}>
                                                <label><input checked={this.state.NotificationOnGeneralQA == 1 ? true : false} type="checkbox" name="nemails" value="nansques" onChange={this.handleRadio} /> General Questions & Answers</label>
                                                <br></br>
                                                <label><input checked={this.state.NotificationOnMessageCommentMention == 1 ? true : false} type="checkbox" name="nemails" value="nmentions" onChange={this.handleRadio} /> Messages, Comments & Mentions</label>
                                                <br></br>
                                                <label><input checked={this.state.NotificationOnMyNetwork == 1 ? true : false} type="checkbox" name="emails" value="nmynetwork" onChange={this.handleRadio} /> My Network</label>
                                                <br></br>
                                                <label><input checked={this.state.NotificationOnMyActivity == 1 ? true : false} type="checkbox" name="emails" value="nactivity" onChange={this.handleRadio} /> Activity on my Content</label>
                                            </Col>
                                        </Row>
                                        <hr></hr>
                                        <Row>
                                            <Col sm={6}>Frequency</Col>
                                            <Col sm={6}>
                                                <label><input checked={this.state.NotificationFrequency == 1 ? true : false} type="radio" onChange={this.handleRadio} name="nfrequency" value="ninstantly" /> Instantly&nbsp;&nbsp;</label>
                                                <label><input checked={this.state.NotificationFrequency == 2 ? true : false} type="radio" onChange={this.handleRadio} name="nfrequency" value="ndaily" /> Daily Digest&nbsp;&nbsp;</label>
                                                <label><input checked={this.state.NotificationFrequency == 3 ? true : false} type="radio" onChange={this.handleRadio} name="nfrequency" value="nweekly" /> Weekly Digest&nbsp;&nbsp;</label>
                                            </Col>
                                            <Col md="6" className="text-center mt-5 mb-5" >
                                            </Col>
                                            <Col md="3" className="text-left mt-5 mb-5" >
                                                <Button className="btn-sm btn-block btn-rounded" onClick={this.handleSubmit}>Save</Button>
                                            </Col>
                                        </Row>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="fourth">
                                        <h3>Language Settings</h3>
                                        <hr></hr>
                                        <Row>
                                            <Col sm={6}>Default</Col>
                                            <Col sm={6}>
                                                <label><input type="checkbox" name="langauge" value="english" /> English</label>
                                            </Col>
                                            <Col md="12" className="text-center mt-5 mb-5" >
                                                <Button className="btn-sm" onClick={this.handleSubmit}>Save</Button>
                                            </Col>
                                        </Row>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                    {/* ********************* */}
                    {/* <Row className="text-center" >
                        <Col md="12" className="text-left mt-3" >
                            <h2>What language(s) do you speak?</h2>
                        </Col>
                        <Col md="12" className="text-left mt-2" >
                            <label><input type="checkbox" value="small" /> English (United Kingdom)&nbsp;&nbsp;&nbsp;</label>
                            <label><input type="checkbox" value="small" /> English (United States)&nbsp;&nbsp;&nbsp;</label>
                            <label><input type="checkbox" value="small" /> Deutsch (Deutschland)&nbsp;&nbsp;&nbsp;</label>
                            <label><input type="checkbox" value="small" /> Polski (Polska)&nbsp;&nbsp;&nbsp;</label>
                            <label><input type="checkbox" value="small" /> Other</label>
                            <Form.Check type="checkbox" label="English (United Kingdom)" />
                            <Form.Check type="checkbox" label="English (United States)" />
                            <Form.Check type="checkbox" label="Deutsch (Deutschland)" />
                            <Form.Check type="checkbox" label="Polski (Polska)" />
                            <Form.Check type="checkbox" label="Other" />
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12" className="text-left mt-3" >
                            <h2>Frequency Settings</h2>
                        </Col>
                        <Col>
                            <label><input type="radio" name="frequency" value="instantly" /> Instantly&nbsp;&nbsp;</label>
                            <label><input type="radio" name="frequency" value="daily" /> Daily Digest&nbsp;&nbsp;</label>
                            <label><input type="radio" name="frequency" value="weekly" /> Weekly Digest</label>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12" className="text-left mt-3" >
                            <h2>Privacy Settings</h2>
                        </Col>
                        <Col md="12" >
                            <Table striped bordered hover>
                                <tr>
                                    <td>
                                        <div>Allow Conversations From</div>
                                        <span>Allow the following to start private conversations with you</span>
                                    </td>
                                    <td>
                                        <label><input type="radio" value="small" /> None&nbsp;&nbsp;</label>
                                        <label><input type="radio" value="small" /> Mutuals Only&nbsp;&nbsp;</label>
                                        <label><input type="radio" value="small" /> All Users</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div>Allow followers</div>
                                        <span>Allow other users to follow you</span>
                                    </td>
                                    <td>
                                        <label><input type="radio" value="small" /> Yes&nbsp;&nbsp;</label>
                                        <label><input type="radio" value="small" /> No </label>
                                    </td>
                                </tr>
                            </Table>
                        </Col>
                        <Col md="12" className="text-right mb-5" >
                            <Button className="btn-sm" >Save</Button>
                        </Col>
                    </Row>
    */}
                </Container>
            </div>
        )
    }
}

// export default AccountSetting;


const mapStateToProps = ({ auth }) => {
    return { auth };
}
export default connect(mapStateToProps, {
})(AccountSetting)