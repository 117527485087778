import App from '../Pages/index';
import { Container, Spinner } from 'react-bootstrap';
import { BaseColor } from '../config/index';
import Header from '../Component/Header';
import Footer from '../Component/Footer';
import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from 'react-spinners/BounceLoader';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideLoader } from '../AppRedux/Actions/Auth';
import { httpPost } from '../Services/HttpServices';

function MainApp(props) {
    let { match, location } = props;
    var auth = useSelector(({ auth }) => auth);
    let dispatch = useDispatch();
    const pathName = location.pathname.slice(0, location.pathname.lastIndexOf('/'));
    const oldPath = location.pathname;
    const wwwUrl = "https://lt.englishforward.com"+oldPath;
    const nonwwwUrl = "https://lt.englishforward.com"+oldPath;
    const wwwUrl1 = "https://uat.englishforums.com"+oldPath;
    const nonwwwUrl1 = "https://uat.englishforums.com"+oldPath;
    //Comment For Load test
    useEffect(() => {
        dispatch(hideLoader());
        httpPost("home_page/check-url", { url: oldPath })
            .then(res => {
                if (res && res.status) {
                    if(res.data.old_url === wwwUrl || res.data.old_url === wwwUrl1 || res.data.old_url === nonwwwUrl || res.data.old_url === nonwwwUrl1){
                        window.location.href = res.data.new_url;
                    }
                }
            })
        if(auth && auth.user){
            httpPost("user_controller/update-stat", { url: oldPath })
        }
    }, [oldPath]);
    return (
        <LoadingOverlay
        // auth && auth.loading || false
            active={auth && auth.loading || false}
            spinner={<Spinner animation="grow" variant="primary" size="lg" />}
            styles={{
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(0, 0, 0, 0.3)'
                })
            }}
        // spinner={<BounceLoader />}
        >
            <div style={{ backgroundColor: BaseColor.lightGrayColor }}>
                <Header {...props} />
                <App match={match} location={props.location} />
                {pathName === "/chat" || location.pathname === "/chat" || location.pathname === "/questionlist" ? null : <Footer />}
            </div>
        </LoadingOverlay>
    )
}
export default MainApp;