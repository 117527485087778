import { useState, useEffect, createRef, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link, Redirect } from "react-router-dom";

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Image from 'react-bootstrap/Image';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import Related from '../Component/Related';
import Tag from '../Component/Tag';
import Follow from '../Component/Follow';
import Photos from '../Component/Photos';
import { Images } from '../config';
import PhotoListCard from '../Component/PhotosListCard';
import QuestionTabList from '../Component/QuestionListTab';
import AnswerTabList from '../Component/AnswerListTab';
import CommentTabList from '../Component/CommentListTab';
import ActivityTabList from '../Component/ActivityListTab';
import Collpase from '../Component/Collpase';
import { httpPost } from '../Services/HttpServices';
import moment from 'moment';
import { toast } from 'react-toastify';
import SweetAlert from 'react-bootstrap-sweetalert';
import ReactTooltip from 'react-tooltip';

import Helmet from 'react-helmet';
import { helmetJsonLdProp } from "react-schemaorg";

const baseUrl = "https://lt.englishforward.com";
function Profile(props) {
    const [userId, setUserId] = useState(null);
    const [userName, setUserName] = useState(null);
    const [isAnonymous, setIsAnonymous] = useState(0);
    
    const [metaData, setMetaData] = useState([]);
    const [questionRef, setQuestionRef] = useState();
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(null);
    const [profilePic, setProfilePic] = useState(null);
    const [isFollow, setIsFollow] = useState(false);
    const [isNotified, setIsNotified] = useState(false);
    const [showEdit, setShowEdit] = useState(false);

    const [mute, setMute] = useState(null);
    const [unMute, setUnMute] = useState(null);
    const [muteUnmute, setMuteUnmute] = useState(false);

    const [block, setBlock] = useState(null);
    const [unBlock, setUnBlock] = useState(null);
    const [blockUnblock, setBlockUnblock] = useState(false);
    
    const [photoList, setPhotos] = useState([]);

    var auth = useSelector(({ auth }) => auth);
    const [showFollowerFollowing, setShowFollowerFollowing] = useState(false);
    const [questions, setQuestions] = useState({ page: 1, totalCount: 0, list: [] });
    const [activities, setActivitis] = useState({ page: 1, totalCount: 0, list: [] });
    const [answers, setAnswers] = useState({ page: 1, totalCount: 0, list: [] });
    const [comments, setComments] = useState({ page: 1, totalCount: 0, list: [] });

    const [activeTab, setActiveTab] = useState('activity');

    const inputFile = useRef(null);
    const imageUpload = useRef();

    useEffect(() => {
        window.addEventListener('scroll', loadMore);
    }, [questionRef])
    useEffect(() => {
        const { id } = props.match.params;
        if (id) {
            setUserName(id)
        } else {
            if(auth && auth.user && auth.user._id){
                setUserId(auth && auth.user && auth.user._id);
                setUserName(auth && auth.user && auth.user.UserName);
            } else {
                props.history.push('/signin');
            }
        }
        window.addEventListener('scroll', loadMore);
        return () => window.removeEventListener('scroll', loadMore);
    }, [props.location])
    useEffect(() => {
        if (userName) {
            getProfileDetail();
            // getQuestionList();
            // getAnswersList();
            // getCommentsList();
        }
    }, [userName])
    useEffect(() => {
        if (userName) {
            setLoading(true);
            getQuestionList();
        }
    }, [questions.page])

    useEffect(() => {
        if (userName) {
            setLoading(true);
            getActivityList();
        }
    }, [activities.page])

    useEffect(() => {
        if (userId) {
            setLoading(true);
            getAnswersList();
        }
    }, [answers.page])

    useEffect(() => {
        if (userName) {
            setLoading(true);
            getCommentsList();
        }
    }, [comments.page])

    useEffect(() => {
        if(userName){
            if(activeTab === "activity") {
                if(activities.list.length === 0) {
                    getActivityList();
                }
            }
            if(activeTab === "questions") {
                if(questions.list.length === 0) {
                    getQuestionList();
                }
            }
            if(activeTab === "answers") {
                if(answers.list.length === 0) {
                    getAnswersList();
                }
            }
            if(activeTab === "comments") {
                if(comments.list.length === 0) {
                    getCommentsList();   
                }
            }
        }
    }, [userName, activeTab])
    
    const getProfileDetail = () => {
        httpPost('user_controller/retrive', { UserName: userName })
            .then(res => {
                if (res && res.data) {
                    if(auth.user && auth.user._id != res.data._id && res.data.AccountSetting.ShowEmail == 0){
                        res.data.ContactInformation.Email = null
                    }
                    if(auth.user && auth.user._id != res.data._id && res.data.AccountSetting.ShowMobile == 0){
                        res.data.ContactInformation.MobileNo = null
                    }
                    setUserId(res.data._id);
                    setUser(res && res.status && res.data || null);
                    setIsAnonymous(res.data.IsAnonymous || 0)
                    setProfilePic(res.data.ProfilePicture || null)
                    setShowFollowerFollowing(res.data.ShowFollowerFollowing);
                    getFollowDetail(res.data._id);
                    setPhotos(res.data.UserImages)
                    checkMute(res.data._id)
                    checkBlock(res.data._id)
                    getNotifyDetail(res.data._id)
                    // let userImages = []
                    // res.data.UserImages.map((img) => {
                    //     userImages.push({"image": img})
                    // })
                    // setPhotos(userImages)
                }else{
                    props.history.push('/signin');
                }
            })
    }

    const getFollowDetail = (userId) => {
        httpPost('follow_user_controller/retrieve', {
            FollowerID: auth && auth.user && auth.user._id,
            UserID: userId,
        })
            .then(res => {
                console.log('follow', res);
                setIsFollow(res && res.data || false);
            })
    }

    const getNotifyDetail = (userId) => {
        if(auth.user && auth.user._id != userId){
            httpPost('notify_user_controller/retrieve', {
                NotifierID: userId,
            })
                .then(res => {
                    setIsNotified(res && res.data || false);
                })
        }
    }

    const getActivityList = () => {
        if(userId){
            httpPost('logs_controller/list', { UserID: userId, page: activities.page })//auth && auth.user && auth.user._id
                .then(res => {
                    setLoading(false);
                    if (res && res.status) {
                        var oldArr = [...activities.list];
                        var newArr = oldArr.concat(res.data);
                        setActivitis({ ...activities, totalCount: res.totalCount, list: newArr });
                    }
                })
        }
    }
    const getQuestionList = () => {
        httpPost('question_controller/list', { UserID: userId, page: questions.page })//auth && auth.user && auth.user._id
            .then(res => {
                setLoading(false);
                if (res && res.status) {
                    let questionList = res.data;
                    questionList.map(ele => {
                        let percentage = ((ele.UpvoteCount / (ele.UpvoteCount + ele.DownvoteCount))*100);
                        percentage = (Math.round(isNaN(percentage) ? 0 : percentage)) + "%";
                        ele.percent = percentage;
                    })
                    var oldArr = [...questions.list];
                    var newArr = oldArr.concat(questionList);
                    setQuestions({ ...questions, totalCount: res.totalCount, list: newArr });
                }
            })
    }
    const getAnswersList = () => {
        httpPost('answer_controller/list', { UserID: userId, page: answers.page })//auth && auth.user && auth.user._id
            .then(res => {
                setLoading(false);
                if (res && res.status) {
                    let answerList = res.data;
                    answerList.map(ele => {
                        let percentage = ((ele.UpvoteCount / (ele.UpvoteCount + ele.DownvoteCount))*100);
                        percentage = (Math.round(isNaN(percentage) ? 0 : percentage)) + "%";
                        ele.percent = percentage;
                    })
                    var oldArr = [...answers.list];
                    var newArr = oldArr.concat(answerList);
                    setAnswers({ ...answers, totalCount: res.totalCount, list: newArr });
                }
            })
    }
    const getCommentsList = () => {
        httpPost('comment_controller/list', { UserID: userId, page: comments.page })//auth && auth.user && auth.user._id
            .then(res => {
                setLoading(false);
                if (res && res.status) {
                    let commentList = res.data;
                    commentList.map(ele => {
                        let percentage = ((ele.UpvoteCount / (ele.UpvoteCount + ele.DownvoteCount))*100);
                        percentage = (Math.round(isNaN(percentage) ? 0 : percentage)) + "%";
                        ele.percent = percentage;
                    })
                    var oldArr = [...comments.list];
                    var newArr = oldArr.concat(commentList);
                    setComments({ ...comments, totalCount: res.totalCount, list: newArr });
                }
            })
    }
    const followUser = () => {
        if(auth && auth.user && auth.user._id){
            if (userId != (auth && auth.user && auth.user._id)) {
                httpPost('follow_user_controller/create', {
                    FollowerID: auth && auth.user && auth.user._id,
                    UserID: userId
                }).then(res => {
                    if (res && res.status) {
                        toast.success(res.message);
                        setIsFollow(true);
                        // getFollowDetail();
                    } else {
                        toast.error(res.message);
                    }
                })
            }
        }else{
            window.location.href = "/signin?ref="+props.location.pathname;
        }
    }
    const unFollowUser = () => {
        if(auth && auth.user && auth.user._id){
            if (userId != (auth && auth.user && auth.user._id)) {
                httpPost('follow_user_controller/delete', {
                    FollowUserId: isFollow && isFollow._id
                }).then(res => {
                    if (res && res.status) {
                        toast.success(res.message);
                        setIsFollow(false);
                    } else {
                        toast.error(res.message);
                    }
                })
            }
        }else{
            window.location.href = "/signin?ref="+props.location.pathname;
        }
    }
   
    const loadMore = () => {
        var position = questionRef?.getBoundingClientRect();
        if (position) {
            if (window.innerHeight + document.documentElement.scrollTop >= position.bottom) {//document.documentElement.offsetHeight
                if (questions.length < 15 && !loading) {
                    // loadQuestion();
                }
            }
        }
    }
    const loadQuestion = () => {
        setLoading(true);
    }

    const profileFollowSetting = () => {
        let flag = showFollowerFollowing ? 0 : 1;
        httpPost('user_controller/update', {
                ShowFollowerFollowing: flag
            }).then(res => {
                if (res && res.status) {
                    setShowFollowerFollowing((flag ? true : false));
                    toast.success(res.message);
                    setIsFollow(false);
                } else {
                    toast.error(res.message);
                }
        })
    }

    const onCancelMute = () => {
        setMute(null);
    }

    const onConfirmMute = () => {
        if(auth && auth.user && auth.user._id){
            httpPost('mute_user_controller/create', {
                MutedUser: userId || null,
                MainUser: auth && auth.user && auth.user._id || null
            }).then(res => {
                if (res) {
                    if (res.status) {
                        toast.success(res.message);
                        setMuteUnmute(true)
                    } else {
                        toast.error(res.message);
                    }
                }
                setMute(null);
            }).catch(err => {
                console.log('err', err);
                setMute(null);
                toast.error('Network Error')
            })
        }else{
            window.location.href = "/signin?ref="+props.location.pathname;
        }
    }

    const onCancelUnMute = () => {
        setUnMute(null);
    }

    const onConfirmUnMute = () => {
        if(auth && auth.user && auth.user._id){
            httpPost('mute_user_controller/delete', {
                MutedUser: userId || null,
                MainUser: auth && auth.user && auth.user._id || null
            }).then(res => {
                if (res) {
                    if (res.status) {
                        toast.success(res.message);
                        setMuteUnmute(false)
                    } else {
                        toast.error(res.message);
                    }
                }
                setUnMute(null);
            }).catch(err => {
                console.log('err', err);
                setUnMute(null);
                toast.error('Network Error')
            })
        }else{
            window.location.href = "/signin?ref="+props.location.pathname;
        }
    }

    const checkMute = (userId) => {
        httpPost('mute_user_controller/retrieve', { MutedUser: userId })
            .then(res => {
                if (res.status === 1) {
                    setMuteUnmute(true)
                }
            })
    }

    const checkBlock = (userId) => {
        httpPost('block_user_controller/retrieve', { BlockUserId: userId })
            .then(res => {
                if (res.status === 1) {
                    setBlockUnblock(true)
                }
            })
    }
    const onCancelUnBlock = () => {
        setUnBlock(null);
    }
    const onCancelBlock = () => {
        setBlock(null);
    }

    const onConfirmBlock = () => {
        if(auth && auth.user && auth.user._id){
            httpPost('block_user_controller/create', {
                BlockedUser: userId || null,
                MainUser: auth && auth.user && auth.user._id || null
            }).then(res => {
                if (res) {
                    if (res.status) {
                        toast.success(res.message);
                        setBlockUnblock(true)
                    } else {
                        toast.error(res.message);
                    }
                }
                setBlock(null);
            }).catch(err => {
                console.log('err', err);
                setBlock(null);
                toast.error('Network Error')
            })
        }else{
            window.location.href = "/signin?ref="+props.location.pathname;
        }
    }

    const onConfirmUnBlock = () => {
        if(auth && auth.user && auth.user._id){
            httpPost('block_user_controller/delete', {
                BlockedUser: userId || null,
                MainUser: auth && auth.user && auth.user._id || null
            }).then(res => {
                if (res) {
                    if (res.status) {
                        toast.success(res.message);
                        setBlockUnblock(false)
                    } else {
                        toast.error(res.message);
                    }
                }
                setUnBlock(null);
            }).catch(err => {
                console.log('err', err);
                setUnBlock(null);
                toast.error('Network Error')
            })
        }else{
            window.location.href = "/signin?ref="+props.location.pathname;
        }
    }

    const getNotified = () => {
        if(auth && auth.user && auth.user._id){

            httpPost('notify_user_controller/create', { UserID: auth && auth.user && auth.user._id || null,NotifierID: userId })
                .then(res => {
                    if (res.status === 1) {
                        if(isNotified === true){
                            setIsNotified(false);
                        }else{
                            setIsNotified(true);
                        }
                        toast.success(res.message);
                    }else{
                        toast.error(res.message);
                    }
                })
        }else{
            window.location.href = "/signin?ref="+props.location.pathname;
        }
    }

    const getRelationshipStatus = (param) => {
        param = param.toString();
        switch(param) {
            case '1':
              return 'In a relationship';
            case '2':
              return 'Single';
            case '3':
              return 'Engaged';
            case '4':
              return 'Married';
            case '5':
              return 'Open relationship';
            case '6':
              return "It's Complicated";
            default:
              return '';
        }
    }

    const uploadPhotos = (e) => {
        var formdata = new FormData();
        if (e && e.target && e.target.files && e.target.files[0]) {
            formdata.append('file', e.target.files[0]);
            httpPost('user_controller/upload_images', formdata)
                .then(res => {
                    if(res.status === 1){
                        toast.success(res.message);
                        setPhotos(res.data.UserImages)
                        imageUpload.current.value = "";
                    }else{
                        toast.error(res.message)
                    }
                }).catch(err => {
                    console.log('err', err);
                    toast.error('Failed to upload file')
                })
        }
    }

    const openImageBrowser = () => {
        inputFile.current.click();
    };

    const uploadProfilePic = (e) => {
        // ProfilePicture
        var formdata = new FormData();
        if (e && e.target && e.target.files && e.target.files[0]) {
            formdata.append('file', e.target.files[0]);
            httpPost('user_controller/upload_profilepic', formdata)
                .then(res => {
                    if(res.status === 1){
                        toast.success(res.message);
                        setProfilePic(res.data.ProfilePicture || null)
                    }else{
                        toast.error(res.message)
                    }
                }).catch(err => {
                    console.log('err', err);
                    toast.error('Failed to upload file')
                })
        }
    }

    const removeProfilePic = (e) => {
        httpPost('user_controller/remove_profilepic')
            .then(res => {
                if(res.status === 1){
                    toast.success(res.message);
                    setProfilePic(res.data.ProfilePicture)
                }
            }).catch(err => {
                console.log('err', err);
            })

    }

    const removePhotos = (e) => {
        setPhotos(e)
    }

    const showOverlay = () => {
        if(showEdit === false)
            setShowEdit(true)
    }

    const hideOverlay = () => {
        if(showEdit === true)
            setShowEdit(false)
    }

    const loadQuestionTab = (e) => {
        console.log("load-Questions")
    }

    // onMouseOver={showOverlay} onMouseOut={hideOverlay}
    return (
        <div className="profile p-2 p-md-4 p-lg-4 p-sm-2 p-xs-2">
            <Helmet 
            script={[
                helmetJsonLdProp({
                    "@context": "https://schema.org",
                    "@type": "Person",
                    "image": profilePic,  
                    "jobTitle": "",
                    "name": user && user.FirstName+' '+user.LastName,
                    "alumniOf": "",
                    "gender": user && user.Gender == "1" ? "male" : "female",
                    // "url": user && user.ContactInformation.Website
                    "url": baseUrl+props.location.pathname+"/"+ (user && user._id)
                }),
              ]}>
                <title>{metaData && metaData.Title ? metaData.Title : "Profile"}</title>
                <meta name="description" content={metaData && metaData.Description ? metaData.Description : ""} />
                <meta name="keyword" content={metaData ? metaData.Keywords : ""} />
            </Helmet>
            {/*auth.user === null ? (<Redirect to="/signin" />): null */}
            <Container>
                <Row>
                    <Col md="8" >
                        <Row className="p-1 p-md-3 p-lg-3 p-sm-2 p-sm-2" >
                            {user ? (
                                <>
                                    <Col md="2" sm="2" >
                                        <div className="mt-2 text-center" >
                                            <Image src={profilePic || Images.defaultProfile} fluid roundedCircle style={{ 'width': '93px', 'height': '93px' }}  />
                                            {(userId === (auth && auth.user && auth.user._id)) ? (<div className="profile-pic-overlay" >
                                                <Link to="#" onClick={() => { openImageBrowser() }} style={{fontSize: 12}} ><Image src={Images.edit} /></Link>
                                                {profilePic && <Link to="#" onClick={() => { removeProfilePic() }} style={{fontSize: 12}} ><Image src={Images.delete} /></Link>}
                                            </div>) : null }
                                        </div>
                                        <input type='file' id='file' ref={inputFile} onChange={(event) => { uploadProfilePic(event) }} style={{display: 'none'}}/>
                                    </Col>
                                    <Col md="10" sm="10" >
                                        <h2 className="title capitalize" >{user.FirstName + " " + user.LastName} 
                                        {user.EmailVerified ? (<Image className="verfied-icon ml-2" src={Images.check} />) : null}
                                        {(userId === (auth && auth.user && auth.user._id)) ? (
                                            <label className="for-dropdown ml-2">
                                                <DropdownButton className="menu-three-dots-button" id="dropdown-item-button" title={<Image className="menu-icon" src={Images.dotIcon} />}>
                                                    <Dropdown.Item as="button" onClick={() => { props.history.push('/edit-profile') }}>Edit Profile</Dropdown.Item>
                                                    <Dropdown.Item as="button" onClick={() => { props.history.push('/select-topic') }}>Edit Topic</Dropdown.Item>
                                                    <Dropdown.Item as="button" onClick={() => { profileFollowSetting() }}>Make Follower/Following List {(showFollowerFollowing ? 'Private' : 'Public')}</Dropdown.Item>
                                                </DropdownButton>
                                            </label>
                                            ) :  null }
                                            {(userId !== (auth && auth.user && auth.user._id)  && isAnonymous === 0) ? (
                                            <label className="for-dropdown ml-2">
                                                <DropdownButton className="menu-three-dots-button" id="dropdown-item-button" title={<Image className="menu-icon" src={Images.dotIcon} />}>
                                                    <Dropdown.Item as="button" onClick={() => { auth && auth.user && auth.user._id ? props.history.push('/chat/'+userId) : props.history.push('/signin?ref=/chat/'+userId) }} ><p>Chat</p></Dropdown.Item>
                                                    <Dropdown.Item as="button"  onClick={() => { (muteUnmute ? setUnMute(true) : setMute(true) ) }}><p data-tip="Mute User" > {(muteUnmute ? "Unmute" : "Mute")} User</p></Dropdown.Item>
                                                    <Dropdown.Item as="button"  onClick={() => { (blockUnblock ? setUnBlock(true) : setBlock(true) ) }}><p data-tip="BlockedUser User" > {(blockUnblock ? "Unblock" : "Block")} User</p></Dropdown.Item>
                                                    <ReactTooltip />
                                                </DropdownButton>
                                            </label>
                                            ) :  null }

                                            {(userId !== (auth && auth.user && auth.user._id)   && isAnonymous === 0) ? <span className="bellIcon float-right" style={{ textAlign: 'center' }} onClick={() => {getNotified();}} >
                                                {isNotified ? <Image src={Images.bell_off} /> : <Image src={Images.bell} />}
                                            </span> : null }
                                            {(userId !== (auth && auth.user && auth.user._id) && isAnonymous === 0) ? (
                                                <Button className="btn-rounded follow-btn float-right" style={{ boxShadow: 'inherit'}} onClick={() => {
                                                    if (isFollow) {
                                                        unFollowUser();
                                                    } else {
                                                        followUser();
                                                    }
                                                }}>{isFollow ? 'Unfollow' : "Follow"}</Button>

                                            ) : null}
                                        </h2>
                                        <Row className="stats" >
                                            <Col className="text-center" >
                                            {(userId !== (auth && auth.user && auth.user._id) && showFollowerFollowing) ? (
                                                <Link to={"followers?id="+userId} className="reset" >
                                                <h3 className="text-center" >{user.FollowersCount}</h3></Link>
                                                ) : (<h3 className="text-center" >{user.FollowersCount}</h3>) }
                                                <p className="numbers" >Followers</p>
                                            </Col>
                                            <Col className="text-center" >
                                            {(userId !== (auth && auth.user && auth.user._id) && showFollowerFollowing) ? (
                                                <Link to={"followlist?id="+userId} className="reset" >
                                                <h3 className="text-center" >{user.FollowingCount}</h3></Link>
                                                ) : (<h3 className="text-center" >{user.FollowingCount}</h3>) }
                                                <p className="numbers" >Following</p>
                                            </Col>
                                            <Col className="text-center" >
                                                <h3 className="text-center" >{user.UpvotePercentage} %</h3>
                                                <p className="numbers" >Upvotes</p>
                                            </Col>
                                            <Col className="text-center" >
                                                <h3 className="text-center" >{user.AnswersCount}</h3>
                                                <p className="numbers" >Answers</p>
                                            </Col>
                                            <Col className="text-center" >
                                                <h3 className="text-center" >{photoList.length}</h3>
                                                <p className="numbers" >Photos</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md="12" className="mt-3 about" >
                                        {user.About ? (
                                            <>
                                                <h3>About Me</h3>
                                                <p className="about-detail">{user.About}</p>
                                            </>
                                        ) : null}
                                    </Col>
                                </>
                            ) : null}
                            <Col md="12" className="custom-tab mt-2">
                                <Tabs defaultActiveKey="questions" activeKey={activeTab} onSelect={(k) => setActiveTab(k)} id="uncontrolled-tab-example" >
                                    <Tab eventKey="activity" title={<span><Image src={activeTab === "activity" ? Images.activityActive : Images.activity} /> <span className="heading-text">Activity</span></span>}>
                                        {/*{activities && activities.list && activities.list.length > 0 ?
                                            (
                                                <p className="tab-content-head"> <Image className="mr-2" src={Images.warning} /> Activity based on user profile and actions.</p>
                                            ) : (
                                                <p className="tab-content-head"> <Image className="mr-2" src={Images.warning} /> No Activity</p>
                                            )}*/}
                                        {/* <p className="tab-content-head"> <Image className="mr-2" src={Images.warning} /> Activity based on user profile and actions.</p> */}
                                        {activities && activities.list && activities.list.map(ele => (
                                            <ActivityTabList key={ele._id} activity={ele} />
                                        ))}
                                        {activities && activities.list && activities.list.length === 0 ? (<p className="text-center mt-5"> <Image className="mr-2" src={Images.warning} /> You have no activities</p>) : null }
                                        {loading ?
                                            <Spinner animation="border" variant="primary" />
                                            : activities.totalCount && activities.totalCount > activities.list.length ? //
                                                <Button size="sm" className="btn-rounded mt-4" style={{padding: '5px 30px'}} onClick={() => { setActivitis({ ...activities, page: activities.page + 1 }) }}>see more</Button>
                                                : null}
                                    </Tab>
                                    <Tab eventKey="questions" title={<span><Image src={activeTab === "questions" ? Images.questionsActive : Images.questions} /><span className="heading-text">Questions</span> </span>}>
                                        <div ref={rr => setQuestionRef(rr)}>
                                            {/*{questions && questions.list && questions.list.length > 0 ? (
                                                <p className="tab-content-head"> <Image className="mr-2" src={Images.warning} /> question asked by {user && (user.FirstName)} on english forward.</p>
                                            ) : (
                                                    <p className="tab-content-head"> <Image className="mr-2" src={Images.warning} /> No Questions</p>
                                                )}*/}
                                            {questions && questions.list && questions.list.map(ele => (
                                                <QuestionTabList key={ele._id} question={ele} history={props.history} />
                                            ))}
                                            {questions && questions.list && questions.list.length === 0 ? (<p className="text-center mt-5"> <Image className="mr-2" src={Images.warning} /> You have no questions</p>) : null }
                                            {loading ?
                                                <Spinner animation="border" variant="primary" />
                                                : questions.totalCount && questions.totalCount > questions.list.length ? //
                                                    <Button size="sm" className="row btn-rounded mt-4" style={{padding: '5px 30px'}} onClick={() => { setQuestions({ ...questions, page: questions.page + 1 }) }}>see more</Button>
                                                    : null}
                                        </div>
                                    </Tab>
                                    <Tab eventKey="answers" title={<span><Image src={activeTab === "answers" ? Images.answersActive : Images.answers} /> <span className="heading-text">Answers</span></span>}>
                                        {/*{answers && answers.list && answers.list.length > 0 ? (
                                            <p className="tab-content-head"> <Image className="mr-2" src={Images.warning} /> answers given by {user && (user.FirstName)} on english forward.</p>
                                        ) : (
                                                <p className="tab-content-head"> <Image className="mr-2" src={Images.warning} /> No Answers</p>
                                            )}*/}
                                        {answers && answers.list && answers.list.map(ele => (
                                            <AnswerTabList key={ele._id} answer={ele} />
                                        ))}
                                        {answers && answers.list && answers.list.length === 0 ? (<p className="text-center mt-5"> <Image className="mr-2" src={Images.warning} /> You have no answers</p>) : null }
                                        {loading ?
                                            <Spinner animation="border" variant="primary" />
                                            : answers.totalCount && answers.totalCount > answers.list.length ?
                                                <Button size="sm" className="row btn-rounded mt-4" style={{padding: '5px 30px'}} onClick={() => { setAnswers({ ...answers, page: answers.page + 1 }) }}>see more</Button>
                                                : null}
                                    </Tab>
                                    <Tab eventKey="comments" title={<span><Image src={activeTab === "comments" ? Images.commentsActive : Images.comments} /> <span className="heading-text">Comments</span></span>}>
                                        {/*{comments && comments.list && comments.list.length > 0 ? (
                                            <p className="tab-content-head"> <Image className="mr-2" src={Images.warning} /> comments given by Rose {user && (user.FirstName)} on english forward.</p>
                                        ) : (
                                                <p className="tab-content-head"> <Image className="mr-2" src={Images.warning} /> No Comments</p>
                                            )}*/}
                                        {comments && comments.list && comments.list.map(ele => (
                                            <CommentTabList key={ele._id} comment={ele} />
                                        ))}
                                        {comments && comments.list && comments.list.length === 0 ? (<p className="text-center mt-5"> <Image className="mr-2" src={Images.warning} /> You have no comments</p>) : null }
                                        {loading ?
                                            <Spinner animation="border" variant="primary" />
                                            : comments.totalCount && comments.totalCount > comments.list.length ?
                                                <Button size="sm" className="row btn-rounded mt-4" style={{padding: '5px 30px'}} onClick={() => { setComments({ ...comments, page: comments.page + 1 }) }}>see more</Button>
                                                : null}
                                    </Tab>
                                    <Tab eventKey="photos" title={<span><Image src={activeTab === "photos" ? Images.commentsActive : Images.comments} /> <span className="heading-text">Photos</span></span>}>
                                        {(auth && auth.user && auth.user._id === userId) ? (<div className="text-center" >
                                            <label className="mt-2" >Upload Photo
                                                <Form.Control className="mt-2 mb-2" type="file" ref={imageUpload} id="photo" onChange={(e) => { uploadPhotos(e); }} />
                                            </label>
                                        </div>) : null }
                                        {photoList ? (
                                            <>
                                            <Photos photos={photoList} removePhoto={removePhotos} />
                                            </>
                                            ) : (<p className="text-center p-4" >No Photos</p>)}
                                    </Tab>
                                </Tabs>
                            </Col>
                        </Row>
                    </Col>
                    <Col md="4" className=" p-4 p-md-4 p-lg-4 p-sm-2 p-sm-2 br-left" >
                        { /*<PhotoListCard photos={photos} /> */}   
                        <p className="info-text mt-2 mb-2">Profile</p>
                        {user ? (
                            <>
                                <Row className="mb-4" >
                                    <Col md="12" >
                                        <h3 className="inline" >{user.Points} <span data-tip="Point" >Points</span></h3>
                                        {user.Rank ? (<>
                                            <h3 className="inline" >{user.Rank.RankName} <span data-tip="Rank" >Rank</span></h3>
                                            <ReactTooltip />
                                            </>
                                        ) : null }
                                    </Col>
                                </Row>
                                <Collpase
                                    heading={<>
                                        <span><Image src={Images.personal_icon} className={"mr-2"} />Personal Information</span>
                                    </>}
                                    defaultOpen={true} >
                                    <>
                                        <Row className="mb-2" >
                                            {user.Gender && (
                                                <Col md="12">
                                                    <p className="info-secondary">Gender</p>
                                                    <p className="info-primary">{user.Gender == "1" ? "Male" : "Female"}</p>
                                                </Col>

                                            )}
                                            {user.DOB && moment(user.DOB).format("Do MMM YYYY") !== "Invalid date" && (
                                                <Col md="12">
                                                    <p className="info-secondary">Birthday</p>
                                                    {/*h:mma*/}
                                                    <p className="info-primary">{moment(user.DOB).format("Do MMM YYYY")}</p>
                                                </Col>
                                            )}
                                            {user.HomeTown && (
                                                <Col md="12">
                                                    <p className="info-secondary">Hometown</p>
                                                    <p className="info-primary">{user.HomeTown}</p>
                                                </Col>
                                            )}
                                            {user.CurrentLocation && (
                                                <Col md="12">
                                                    <p className="info-secondary">Location</p>
                                                    <p className="info-primary">{user.CurrentLocation}</p>
                                                </Col>
                                            )}
                                            {user.RelationshipStatus && (
                                                <Col md="12">
                                                    <p className="info-secondary">Relationship Status</p>
                                                    <p className="info-primary">{getRelationshipStatus(user.RelationshipStatus)}</p>
                                                </Col>

                                            )}
                                            {user.InterestID && user.InterestID.length > 0 && (
                                                <Col md="12">
                                                    <p className="info-secondary">Interested In</p>
                                                    <p className="info-primary">{user.InterestID.map((ele, index) => {
                                                        return (user.InterestID.length - (index + 1)) == 0 ? ele.Title : ele.Title + ','
                                                    })}</p>
                                                </Col>
                                            )}
                                            {user.LookingFor && user.LookingFor.length > 0 && (
                                                <Col md="12">
                                                    <p className="info-secondary">Looking For</p>
                                                    <p className="info-primary">{user.LookingFor.map((ele, index) => {
                                                        return (user.LookingFor.length - (index + 1)) == 0 ? ele.Title : ele.Title + ','
                                                    })}</p>
                                                </Col>
                                            )}

                                        </Row>
                                    </>
                                </Collpase>
                                <Collpase
                                    heading={<>
                                        <span><Image src={Images.contact_icon} className={"mr-2"} />Contact Information</span>
                                    </>}
                                    defaultOpen={true}
                                >
                                    <>
                                        <Row className="mb-2" >
                                            {(auth && auth.user && auth.user._id) && user.ContactInformation.Email && (
                                                <Col md="12">
                                                    <p className="info-secondary">Email</p>
                                                    <p className="info-primary">{user.ContactInformation.Email}</p>
                                                </Col>

                                            )}
                                            {(auth && auth.user && auth.user._id) && user.ContactInformation.MobileNo && (
                                                <Col md="12">
                                                    <p className="info-secondary">Mobile No</p>
                                                    <p className="info-primary">{user.ContactInformation.CountryCode} {user.ContactInformation.MobileNo}</p>
                                                </Col>

                                            )}
                                            {user.ContactInformation.LandlineNumber && (
                                                <Col md="12">
                                                    <p className="info-secondary">Landline Number</p>
                                                    <p className="info-primary">{user.ContactInformation.LandlineNumber}</p>
                                                </Col>

                                            )}
                                            {user.ContactInformation.BusinessEmail && (
                                                <Col md="12">
                                                    <p className="info-secondary">Business Email</p>
                                                    <p className="info-primary">{user.ContactInformation.BusinessEmail}</p>
                                                </Col>

                                            )}
                                            {user.ContactInformation.Website && (
                                                <Col md="12">
                                                    <p className="info-secondary">Website</p>
                                                    <p className="info-primary">{user.ContactInformation.Website}</p>
                                                </Col>

                                            )}
                                            {user.ContactInformation.BlogAddress && (
                                                <Col md="12">
                                                    <p className="info-secondary">Blog</p>
                                                    <p className="info-primary">{user.ContactInformation.BlogAddress}</p>
                                                </Col>

                                            )}
                                        </Row>
                                    </>
                                </Collpase>
                                <Collpase
                                    heading={<>
                                        <span><Image src={Images.language_icon} className={"mr-2"} />Language</span>
                                    </>}
                                    defaultOpen={true}
                                >
                                    <>
                                        <Row className="mb-2" >
                                            {user.LanguageDetails.WantsEnglishChat && (
                                                <Col md="12">
                                                    <p className="info-secondary">Wants English Chat</p>
                                                    <p className="info-primary">{user.LanguageDetails.WantsEnglishChat == '1' ? "Yes" : "No"}</p>
                                                </Col>
                                            )}
                                            {user.LanguageDetails.NativeLanguage && user.LanguageDetails.NativeLanguage.length > 0 && (
                                                <Col md="12">
                                                    <p className="info-secondary">Languages</p>
                                                    <p className="info-primary">{user.LanguageDetails.NativeLanguage.map((ele, index) => {
                                                        return (user.LanguageDetails.NativeLanguage.length - (index + 1)) == 0 ? ele.Name : ele.Name + ','
                                                    })}</p>
                                                </Col>
                                            )}
                                            {user.LanguageDetails.LevelOfEnglish && (
                                                <Col md="12">
                                                    <p className="info-secondary">Level of English</p>
                                                    <p className="info-primary">{ user.LanguageDetails.LevelOfEnglish }</p>
                                                    {/*user.LanguageDetail<p className="info-primary">{
                                                        user.LanguageDetails.LevelOfEnglish == '1' ? 'Elementary'
                                                            : user.LanguageDetails.LevelOfEnglish == '2' ? "Intermediate"
                                                                : user.LanguageDetails.LevelOfEnglish == '3' ? 'Advanced'
                                                                    : ''
                                                    }</p>*/}
                                                </Col>
                                            )}
                                        </Row>
                                    </>
                                </Collpase>
                                {/*API Not Integrated*/}
                                <Collpase
                                    heading={<>
                                        <span><Image src={Images.topic_icon} className={"mr-2"} />Topics of Interest</span>
                                    </>}
                                    defaultOpen={true}
                                >
                                    <>
                                        <Row className="mb-2" >
                                            <ul style={{listStyleType: 'disc'}} >
                                            {user.MyTopic.map((ele, index) => {
                                                return (<li>{ele.Title}</li>)
                                            })}
                                            </ul>
                                        </Row>
                                    </>
                                </Collpase>
                                <Collpase
                                    heading={<>
                                        <span><Image src={Images.education_icon} className={"mr-2"} />Education and Work</span>
                                    </>}
                                    defaultOpen={true}
                                >
                                    <>
                                        <Row className="mb-2" >
                                            {user.EducationDetails && user.EducationDetails.length > 0 && (
                                                <Col md="12"> 
                                                    <p className="info-secondary"><b><u>Education Details</u></b></p>
                                                    {user.EducationDetails.map((edu) => {
                                                        return (<p className="info-primary">
                                                        {edu.Institution}<br />{edu.PrimaryMajor}<br />
                                                        {edu.SecondaryMajor}<br />
                                                        {edu.GraduationYear}
                                                        </p>)
                                                    })}
                                                </Col>
                                            )}
                                            {user.WorkDetails && user.WorkDetails.length > 0 && (
                                                <Col md="12">
                                                    <p className="info-secondary"><b><u>Work Details</u></b></p>
                                                    {user.WorkDetails.map((work) => {
                                                        return (<p className="info-primary">
                                                        {work.Organization}<br />{work.JobTitle}<br />
                                                        {work.Location} <br />
                                                        {moment(work.StartDate).format("MMM YYYY")} {work.StartDate && work.EndDate ? " - " : " - "} {work.EndDate ? moment(work.EndDate).format("MMM YYYY") : "Current" }
                                                        </p>)
                                                    })}
                                                </Col>
                                            )}
                                        </Row>
                                    </>
                                </Collpase>
                                <Collpase
                                    heading={<>
                                        <span><Image src={Images.learners_icon} className={"mr-2"} />Learners</span>
                                    </>}
                                    defaultOpen={false}
                                >
                                    <>
                                        <Row className="mb-2" >
                                            {user.LearnersDetails.SuggestionsForStudents && (
                                                <Col md="12">
                                                    <p className="info-secondary">What suggestions to do you have for other students?</p>
                                                    <p className="info-primary">{user.LearnersDetails.SuggestionsForStudents}</p>
                                                </Col>
                                            )}
                                            {user.LearnersDetails.LearnerCertificates && user.LearnersDetails.LearnerCertificates.length > 0 && (
                                                <Col md="12">
                                                    <p className="info-secondary">Do you hold any of these certificates?</p>
                                                    {user.LearnersDetails.LearnerCertificates.map((ele) => {
                                                        return (<p className="info-primary">{ele.other_name ? ele.other_name : ele.name}</p>)
                                                    })}
                                                </Col>
                                            )}
                                            {user.LearnersDetails.LearningEnglishReason && (
                                                <Col md="12">
                                                    <p className="info-secondary">Why are you learning English?</p>
                                                    <p className="info-primary">{user.LearnersDetails.LearningEnglishReason}</p>
                                                </Col>
                                            )}
                                            {/*<Col md="12">
                                                <p className="info-secondary">Certificates</p>
                                                <p className="info-primary">{user.BusinessEmail}</p>
                                            </Col>*/}
                                        </Row>
                                    </>
                                </Collpase>
                                <Collpase
                                    heading={<>
                                        <span><Image src={Images.teaching_icon} className={"mr-2"} />Teaching English</span>
                                    </>}
                                    defaultOpen={false}
                                >
                                <>
                                    <Row className="mb-2" >
                                        {user.TeachingEnglishDetails.TeachingEnglishExperience && (
                                            <Col md="12">
                                                <p className="info-secondary">How long have you been teaching English?</p>
                                                <p className="info-primary">{user.TeachingEnglishDetails.TeachingEnglishExperience}</p>
                                            </Col>
                                        )}
                                        {user.TeachingEnglishDetails.AdviceForStudents && (
                                            <Col md="12">
                                                <p className="info-secondary">What advice would you give to new students?</p>
                                                <p className="info-primary">{user.TeachingEnglishDetails.AdviceForStudents}</p>
                                            </Col>
                                        )}
                                        {user.TeachingEnglishDetails.TeachingEnglishCertificates && user.TeachingEnglishDetails.TeachingEnglishCertificates.length > 0 && (
                                            <Col md="12">
                                                <p className="info-secondary">Do you hold any of these certificates?</p>
                                                {user.TeachingEnglishDetails.TeachingEnglishCertificates.map((ele) => {
                                                    <p className="info-primary">{ele}</p>
                                                })}
                                            </Col>
                                        )}
                                        {/*<Col md="12">
                                            <p className="info-secondary">Certificates</p>
                                            <p className="info-primary">{user.BusinessEmail}</p>
                                        </Col>}*/}
                                    </Row>
                                </>
                                </Collpase>
                                <Collpase
                                    heading={<>
                                        <span><Image src={Images.interest_icon} className={"mr-2"} />Interests</span>
                                    </>}
                                    defaultOpen={false}
                                >
                                    <>
                                        <Row className="mb-2" >
                                            {user.InterestDetails.InterestedIn && (
                                                <Col md="12">
                                                    <p className="info-secondary">Interests</p>
                                                    <p className="info-primary">{user.InterestDetails.InterestedIn}</p>
                                                </Col>
                                            )}
                                            {user.InterestDetails.FavouriteMusicBands && (
                                                <Col md="12">
                                                    <p className="info-secondary">Favourite music/bands</p>
                                                    <p className="info-primary">{user.InterestDetails.FavouriteMusicBands}</p>
                                                </Col>
                                            )}
                                            {user.InterestDetails.FavouriteFilms && (
                                                <Col md="12">
                                                    <p className="info-secondary">Favourite films</p>
                                                    <p className="info-primary">{user.InterestDetails.FavouriteFilms}</p>
                                                </Col>
                                            )}
                                            {user.InterestDetails.FavouriteTvShows && (
                                                <Col md="12">
                                                    <p className="info-secondary">Favourite tv shows</p>
                                                    <p className="info-primary">{user.InterestDetails.FavouriteTvShows}</p>
                                                </Col>
                                            )}
                                            {user.InterestDetails.FavouriteBooks && (
                                                <Col md="12">
                                                    <p className="info-secondary">Favourite books or magazines</p>
                                                    <p className="info-primary">{user.InterestDetails.FavouriteBooks}</p>
                                                </Col>
                                            )}
                                            {user.InterestDetails.FavouriteActivities && (
                                                <Col md="12">
                                                    <p className="info-secondary">Activities</p>
                                                    <p className="info-primary">{user.InterestDetails.FavouriteActivities}</p>
                                                </Col>
                                            )}
                                            {user.InterestDetails.Ambitions && (
                                                <Col md="12">
                                                    <p className="info-secondary">Your ambitions</p>
                                                    <p className="info-primary">{user.InterestDetails.Ambitions}</p>
                                                </Col>
                                            )}
                                            {user.InterestDetails.WishToVisit && (
                                                <Col md="12">
                                                    <p className="info-secondary">Anywhere you'd like to visit</p>
                                                    <p className="info-primary">{user.InterestDetails.WishToVisit}</p>
                                                </Col>
                                            )}
                                            {user.InterestDetails.PerfectWayToSpendDay && (
                                                <Col md="12">
                                                    <p className="info-secondary">What would be your perfect way to spend a day</p>
                                                    <p className="info-primary">{user.InterestDetails.PerfectWayToSpendDay}</p>
                                                </Col>
                                            )}
                                            {user.InterestDetails.Pets && (
                                                <Col md="12">
                                                    <p className="info-secondary">Any pets</p>
                                                    <p className="info-primary">{user.InterestDetails.Pets}</p>
                                                </Col>
                                            )}
                                            {user.InterestDetails.LookingFor && (
                                                <Col md="12">
                                                    <p className="info-secondary">What you're looking for</p>
                                                    <p className="info-primary">{user.InterestDetails.LookingFor}</p>
                                                </Col>
                                            )}
                                        </Row>
                                    </>
                                </Collpase>
                                <Collpase
                                    heading={<>
                                        <span><Image src={Images.badge_icon} className={"mr-2"} />Badges</span>
                                    </>}
                                    defaultOpen={true}
                                >
                                    <>
                                        <Row className="mb-2" >
                                            { user.BadgeDetails && user.BadgeDetails.map((badge) => {
                                                return (<Col md="4">
                                                    <Image src={badge.BadgeId.BadgeIcon} height={100} width={100} className="rounded-circle" />
                                                </Col>)
                                            }) }
                                        </Row>
                                    </>
                                </Collpase>
                            </>
                        ) : null}
                    </Col>
                </Row>
                <SweetAlert
                    show={mute ? true : false}
                    warning
                    showCancel
                    confirmBtnText={"Yes"}
                    cancelBtnText={"No"}
                    title="Do you want to mute this User"
                    onConfirm={onConfirmMute}
                    onCancel={onCancelMute}
                />
                <SweetAlert
                    show={unMute ? true : false}
                    warning
                    showCancel
                    confirmBtnText={"Yes"}
                    cancelBtnText={"No"}
                    title="Do you want to unmute this User"
                    onConfirm={onConfirmUnMute}
                    onCancel={onCancelUnMute}
                />
                <SweetAlert
                    show={block ? true : false}
                    warning
                    showCancel
                    confirmBtnText={"Yes"}
                    cancelBtnText={"No"}
                    title="Do you want to block this User"
                    onConfirm={onConfirmBlock}
                    onCancel={onCancelBlock}
                />
                <SweetAlert
                    show={unBlock ? true : false}
                    warning
                    showCancel
                    confirmBtnText={"Yes"}
                    cancelBtnText={"No"}
                    title="Do you want to unblock this User"
                    onConfirm={onConfirmUnBlock}
                    onCancel={onCancelUnBlock}
                />
            </Container>
        </div>
    )
}
export default Profile;