import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import Related from '../Component/Related';
import Tag from '../Component/Tag';
import QuestionCard from '../Component/Question';
import { Images } from '../config';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import moment from "moment";
import 'moment-timezone';
import Helmet from 'react-helmet';
import { helmetJsonLdProp } from "react-schemaorg";
import { httpPost } from '../Services/HttpServices';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function QuestionDetail(props) {
    const timeZone  = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const state = useSelector(state => state)
    var auth = useSelector(({ auth }) => auth);
    const [QuestionID, setQuestionID] = useState(null)
    const [QuestionUrl, setQuestionUrl] = useState(null)


    const [suggestedAnswer, setSuggestedAnswer] = useState([]);
    const [acceptedAnswer, setAcceptedAnswer] = useState({});
    // var sAnswer = []
    // sAnswer.push({
    //     "@type": "Answer",
    //     "text": "Refer to British Council English Learning Courses",
    //     "dateCreated": "2020-10-02T21:11Z",
    //     "upvoteCount": 42,
    //     "url": "https://learnenglish.britishcouncil.org/",
    //     "author": {
    //       "@type": "Person",
    //       "name": "Prinsty"
    //     }
    //   })
    // setSuggestedAnswer(sAnswer)

    // setAcceptedAnswer({
    //     "@type": "Answer",
    //     "text": "Refer to Youtube Videos",
    //     "dateCreated": "2020-09-02T21:11Z",
    //     "upvoteCount": 37,
    //     "url": "https://www.youtube.com/watch?v=IhQt_fxGOcw",
    //     "author": {
    //         "@type": "Person",
    //         "name": "Luce"
    //     }
    // })

    const [questionDetails, setQuestionDetails] = useState({});
    const [answers, setAnswers] = useState([])
    const [MyID, setMyID] = useState(auth && auth.user && auth.user._id)
    const [totalCount, setTotalCount] = useState(0)
    var [searchFilter, setSearchFilter] = useState(1);
    const [topics, setTopics] = useState([])
    const { id } = props.match.params;
    var queryParams = useQuery();
    let AnswerID = queryParams.get('a');
    let commentID = queryParams.get('c');

    useEffect(() => {
        if (!id) {
            props.history.push('/questionlist')
        } else {
            setQuestionUrl(id)
        }
    }, [])
    useEffect(() => {
        if (QuestionUrl) {
            // getTopicList();
            getQuestionDetails();
            getRelativeQuestion();
            getAnswerList();
        }
    }, [QuestionID, QuestionUrl]);
    const getQuestionDetails = () => {
        // QuestionID: props.location.questionID._id
        httpPost('question_controller/retrive', { QuestionUrl: QuestionUrl })
            .then(res => {
                if (res && res.status && res.data) {
                    let percentage = ((res.data.UpvoteCount / (res.data.UpvoteCount + res.data.DownvoteCount))*100);
                    percentage = (Math.round(isNaN(percentage) ? 0 : percentage)) + "%";
                    var obj = {
                        id: res.data._id,
                        name: res.data.UserID.FirstName + " " + res.data.UserID.LastName,
                        question: res.data.Subject,
                        metaTitle: res.data.QuestionTitle,
                        QuestionUrl: res.data.QuestionUrl,
                        FullUrl: window.location.origin+"/question/"+res.data.QuestionUrl,
                        PostExcerpt: res.data.PostExcerpt,
                        Description: res.data.Content.replace('[AUDIO file="', '&nbsp;&nbsp;<img src="'+Images.audio+'" onClick=play("').replace('" AUDIO]', '") />'),
                        // Description: res.data.Content.replace('[AUDIO file="', '<play onClick=play(').replace('" AUDIO]', ') >Play</play>')+'<i class="fa fa-audio" ></i>',
                        //Description: res.data.Content.replace('[AUDIO file="', '<audio src="'+url).replace('AUDIO]', ' controls ></audio>'),
                        user_id: res.data.UserID._id, // not you (you follow/not follow this person)
                        UserName: res.data.UserID.UserName,
                        topics: res.data.TopicID,
                        work: (res.data.UserID.work ? res.data.UserID.work : ""),
                        education: (res.data.UserID.education ? res.data.UserID.education : ""),
                        HomeTown: (res.data.UserID.HomeTown ? res.data.UserID.HomeTown : ""),
                        CurrentLocation: (res.data.UserID.CurrentLocation ? res.data.UserID.CurrentLocation : ""),
                        percent: percentage,
                        updatedDate: res.data.UpdatedDate,
                        postedDate: res.data.CreatedDate,
                        post: moment.tz(res.data.CreatedDate, timeZone).fromNow(),
                        image: (res.data.UserID.ProfilePicture == "" || res.data.UserID.ProfilePicture == null) ? Images.defaultProfile : res.data.UserID.ProfilePicture,
                        answers: res.data.AnswerCount,
                        IsVoted: res.data.IsVoted,
                        UpVote: res.data.UpvoteCount,
                        DownVote: res.data.DownVote
                    }
                    setTopics(res.data.TopicID);
                    setQuestionID(obj.id);
                    setQuestionDetails(obj);
                }else{
                    props.history.push('/404')
                }
            })
    }
    var answersArr = []
    const getAnswerList = (sortValue) => {
        if(QuestionID){
            httpPost('answer_controller/list', { page: searchFilter, QuestionID, AnswerID , sortBy: sortValue })
                .then(res => {
                    if (res.status == 1) {
                        if (res.totalCount > 0) {
                            let sAnswer = []
                            res.data.forEach(element => {
                                let percentage = ((element.UpvoteCount / (element.UpvoteCount + element.DownvoteCount))*100);
                                percentage = (Math.round(isNaN(percentage) ? 0 : percentage)) + "%";
                                answersArr.push({
                                    id: element._id,
                                    user_id: element.UserID._id,
                                    UserName: element.UserID.UserName,
                                    image: (!element.UserID.ProfilePicture || element.UserID.ProfilePicture == "") ? Images.defaultProfile : element.UserID.ProfilePicture,
                                    name: element.UserID.FirstName + " " + "",
                                    answered: moment.tz(element.CreatedDate, timeZone).fromNow(),
                                    detail: element.Answer.replace('[AUDIO file="', '&nbsp;&nbsp;<img src="'+Images.audio+'" onClick=play("').replace('" AUDIO]', '") />'),
                                    PostContent: element.Answer.replace('[AUDIO file="', '&nbsp;&nbsp;<img src="'+Images.audio+'" onClick=play("').replace('" AUDIO]', '") />'),
                                    percent: percentage,
                                    commentsCount: element.CommentCount > 1000000 ? Math.round((element.CommentCount / 1000000) * 10) / 10 + "M" : (element.CommentCount > 1000 ? Math.round((element.CommentCount / 1000) * 10) / 10 + "K" : element.CommentCount),
                                    IsVoted: element.IsVoted,
                                    UpVote: element.UpVote,
                                    DownVote: element.DownVote
                                })
                                sAnswer.push({
                                    "@type": "Answer",
                                    "text": element.Answer.replace('[AUDIO file="', '&nbsp;&nbsp;<img src="'+Images.audio+'" onClick=play("').replace('" AUDIO]', '") />'),
                                    "dateCreated": element.CreatedDate,
                                    "upvoteCount": element.UpVote,
                                    "url": window.location.origin+"/question/"+QuestionUrl,
                                    "author": {
                                      "@type": "Person",
                                      "name": element.UserID.FirstName
                                    }
                                 })
                            });
                            if (searchFilter == 1) {
                                var oldArr = [];
                            } else {
                                var oldArr = [...answers];
                            }
                            var newArr = oldArr.concat(answersArr);
                            setAnswers(newArr)
                            setTotalCount(res.data.length)
                            setSuggestedAnswer(sAnswer)
                        } else {
                            setTotalCount(0)
                        }
                    } else {
                        setTotalCount(0)
                    }
                })
        }
    }
    var questionListArr = []
    const getRelativeQuestion = () => {
        if(QuestionID){
            httpPost("question_controller/related", { page: 1, QuestionID:  QuestionID})
                .then(res => {
                    if (res && res.data) {
                        if (res.totalCount > 5) {
                            res.data.slice(0, 5).map((element, i) => {
                                questionListArr.push({
                                    id: element._id,
                                    image: (element.UserID && element.UserID.ProfilePicture ? element.UserID.ProfilePicture : Images.defaultProfile),
                                    url: "/question/" +element.QuestionUrl,
                                    text: element.Subject,
                                    post: moment.tz(element.CreatedDate, timeZone).fromNow(),
                                    by: (element.UserID) ? element.UserID.FirstName + " " + element.UserID.LastName : ""
                                })
                            });
                        } else {
                            res.data.map((element, i) => {
                                questionListArr.push({
                                    id: element._id,
                                    image: (element.UserID && element.UserID.ProfilePicture ? element.UserID.ProfilePicture : Images.defaultProfile),
                                    url: element.QuestionUrl,
                                    text: element.Subject,
                                    post: moment.tz(element.CreatedDate, timeZone).fromNow(),
                                    by: (element.UserID) ? element.UserID.FirstName + " " + element.UserID.LastName : ""
                                })
                            });
                        }
                    }
                    setQuestionList(questionListArr)
                })
        }
    }

    const getTopicList = () => {
        httpPost("user_topic_controller/list")
            .then(res => {
                console.log('topic', res);
                if (res && res.data) {
                    if (res.data.length > 0) {
                        if (res.data[0].TopicIDs && res.data[0].TopicIDs.length > 0)
                            setTopics(res.data[0].TopicIDs || [])
                    }
                }
            })
    }
    const [questions, setQuestionList] = useState(questionListArr);
    return (
        <div className="p-4 p-md-5 p-lg-5 p-sm-4 question-detail">
        {Object.keys(questionDetails).length > 1 ? <Helmet 
            script={[
                helmetJsonLdProp({
                    "@context": "https://schema.org",
                    "@type": "QAPage",
                        "mainEntity": {
                            "@type": "Question",
                            "name": questionDetails.question,
                            // "text": questionDetails.PostExcerpt,
                            "text": questionDetails.Description.replace(/<[^>]+>/g, ''),
                            "answerCount": questionDetails.answers,
                            "upvoteCount": questionDetails.UpVote ? questionDetails.UpVote : 0,
                            "dateCreated": questionDetails.postedDate,
                            "author": {
                              "@type": "Person",
                              "name": questionDetails.name
                            },
                            "acceptedAnswer": acceptedAnswer,
                            "suggestedAnswer": suggestedAnswer
                        }
                    }),
                helmetJsonLdProp({
                    "@context": "https://schema.org",
                    "@type": "Article",
                    "author": questionDetails.name,
                    "headline": questionDetails.question,
                    "description": questionDetails.PostExcerpt,
                    "datePublished": questionDetails.postedDate,
                    "dateModified": questionDetails.updatedDate,
                    "publisher": "",
                    "genre": "",
                    "articleBody": questionDetails.Description,
                    "url": questionDetails.FullUrl,
                    "about": {
                        "@type": "Article",
                        "name": questionDetails.question,
                    }
                })
            ]} 
          >
            <title>{questionDetails && questionDetails.metaTitle ? questionDetails.metaTitle + " | English Forward" : questionDetails.question + " | English Forward"}</title>
            <meta name="description" content={questionDetails && questionDetails.PostExcerpt ? questionDetails.PostExcerpt : ""} />
            <meta property="og:site_name" content={"English Forward"} />
            <meta property="og:image" content={window.location.origin+"/logo.png"} />
            <meta property="og:title" content={questionDetails && questionDetails.metaTitle ? questionDetails.metaTitle+" | English Forward" : "English Forward"} />
            <meta property="og:url" content={questionDetails && questionDetails.FullUrl} />
            <meta property="og:description" content={questionDetails && questionDetails.PostExcerpt ? questionDetails.PostExcerpt : ""} />
            <meta property="og:type" content="website" />
            <meta name="twitter:title" content={questionDetails && questionDetails.metaTitle ? questionDetails.metaTitle+" | English Forward" : "English Forward"} />
            <meta name="twitter:url" content={questionDetails && questionDetails.FullUrl} />
            <meta name="twitter:image" content={window.location.origin+"/logo.png"} />
            <meta name="twitter:card" content={"summary"} />
            <meta name="twitter:description" content={questionDetails && questionDetails.PostExcerpt ? questionDetails.PostExcerpt : ""} />
        </Helmet> : null}
        <Container fluid>
            <Row>
                <Col md="9">
                    <Row>
                        <QuestionCard
                            {...props}
                            showFullText={(id) => {
                                if (answers && answers.length > 0) {
                                    let arr = [...answers];
                                    arr.map(ele => {
                                        if (ele.id === id) {
                                            ele.showFull = 1
                                        }
                                    })
                                    setAnswers(arr);
                                }
                            }}
                            question={questionDetails}
                            commentID={commentID}
                            answers={answers}
                            questionID={QuestionID}
                            MyID={MyID}
                            currentPage={searchFilter}
                            displaySeeMore={totalCount < 10 ? "none" : "block"}
                            questionArr={(id) => {
                                setSearchFilter(1)
                                getAnswerList()
                                getQuestionDetails()
                            }}
                            getPaginatedAnswerList={(page) => {
                                setSearchFilter(searchFilter++)
                                getAnswerList()
                            }}
                            applySort={(sortValue) => {
                                setSearchFilter(1)
                                getAnswerList(sortValue)
                            }}
                        />
                    </Row>
                </Col>
                <Col md="3">
                    {/*style={{ width: '18rem', marginTop: '14px' }}*/}
                    <Card>
                        <Card.Body>
                            <Related
                                heading="Related Questions"
                                list={questions}
                                link="/questionlist"
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>

        </div>
    )
}
export default QuestionDetail;