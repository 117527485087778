import React, { Component, useEffect, useState } from 'react';
import {Link} from 'react-router-dom';

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';

import { toast } from 'react-toastify';
import { Images } from '../config';
import { httpPost } from '../Services/HttpServices';
import Helmet from 'react-helmet';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";

function DiscussEnglish(props) {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    useEffect(() => {
        setLoading(true)
        httpPost("home_page/discuss-english")
            .then(res => {
                // setLoading(true)
                if (res && res.status) {
                    setLoading(false);
                    setLoading(false);
                    if(res.data.length > 0){
                    	setData(res.data[0].data);
                    }
                }
            })
        }, [])
    return (
        <div className="discuss-english" >
            <Helmet>
                <title>{"Discuss English"}</title>
                <meta name="description" content={"English Forward"} />
                <meta name="keyword" content={""} />
            </Helmet>
            <Container>
                <Row>
                    <Col md="12" className="p-3 mt-4 text-center" >
                        <h1>Discuss English</h1>
                        <p>Not sure where to start? Here is what other members of the English Forward community have deemed most worthy of their attention. Have a look at what's trending across the board!</p>
                    </Col>
                    <Col md="12" >
                        {loading ? <Spinner animation="border" variant="primary" /> : null}
                    </Col>
                </Row>
	            <ResponsiveMasonry
	                columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}
	            >
	                <Masonry columnsCount={3} >
	                    {data.map(question => (
		                        <div className="p-3 discuss-card mb-3 m-2" >
		                            <Link to={"/topics/"+question.Slug}  ><h5>{question.Title}</h5></Link>
		                            {question.questions.map((ele, index) => (
		                                <div className={index == (question.questions.length - 1) ? "" : "border-bottom"} ><Link to={"/question/"+ele.QuestionUrl} ><div dangerouslySetInnerHTML={{ __html: ele.Subject }} ></div></Link></div>
		                            ))}
		                            <div className="mb-3" >
		                            	<Link className="float-right sub-links" to={"/topics/"+question.Slug}  >View more</Link>
		                            </div>
		                        </div>
	                    ))}
	                </Masonry>
	            </ResponsiveMasonry>
            </Container>

        </div>
    )
}

export default DiscussEnglish;