import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { BaseColor, Images } from "../config";
import { Button, Card, Col, Dropdown, DropdownButton, Image, ListGroup, Row } from 'react-bootstrap';
import PropTypes from "prop-types";
import moment from 'moment';
import 'moment-timezone';
import { httpPost } from '../Services/HttpServices';
import ExpertList from '../Component/Experts';
function RequestAnswer(props) {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let { question } = props;
    var auth = useSelector(({ auth }) => auth);
    let profilePicture = auth.ProfilePicture ? auth.ProfilePicture : Images.defaultProfile;

    const [topics, setTopics] = useState([]);
    const [experts, setExperts] = useState([]);
    const [requestList, setRequestList] = useState(null);

    useEffect(() => {
        if (question) {
            setTopics(question.topics || []);
            // getTopics();
            getRequestList();
            getExperts();
        }
    }, [props.question])

    const getTopics = () => {
        httpPost("topic_controller/list")
            .then(res => {
                if (res && res.data) {
                    if (res.data.length > 0) {
                        if (res.data && res.data.length > 0) {
                            setTopics(res.data);
                        }
                    }
                }
            })
    }

    const getExperts = () => {
        if(requestList != null){
            httpPost("user_controller/experts")
                .then(res => {
                    if (res && res.data) {
                        if (res.data.length > 0) {
                            if (res.data && res.data.length > 0) {
                                let expertData = res.data;
                                expertData.map(ex => {
                                    ex.isRequested = requestList.filter(req => ex._id === req.ToUserID )
                                })
                                setExperts(expertData);
                            }
                        }
                    }
                })
        }
    }
        
    const findExpert = (data) => {
        httpPost("user_controller/experts",{TopicID: data._id})
            .then(res => {
                if (res && res.data) {
                    if (res.data.length > 0) {
                        if (res.data && res.data.length > 0) {
                            setExperts(res.data);
                        }
                    }
                }
            })
        }

    const getRequestList = () => {
        httpPost("answer_request_controller/answer_requested_list",{ QuestionID: question._id })
            .then(res => {
                if (res && res.data) {
                    setRequestList(res.data);
                }
            })
    }
            
    return (
        <>
            <Row>
                <Col md="4" style={{borderRight: '1px solid'}} >
                    <Card >
                        <Card.Header>Topic</Card.Header>
                        <ListGroup variant="flush" style={{maxHeight: 300, overflowY: 'auto'}} >
                            {topics.map(t => {
                                return <ListGroup.Item onClick={() => {findExpert(t)}} >{t.Title}</ListGroup.Item>
                            })}
                        </ListGroup>
                    </Card>
                </Col>
                <Col md="8" >
                    <div style={{maxHeight: 300, overflowY: 'auto'}} >
                        {experts.length > 0 ? experts.map(expert => {
                            return <ExpertList expert={expert} question={question} />
                        }) : (<div className="text-center"  >There is no experts in this topic.</div>)}
                    </div>
                </Col>
            </Row>
        </>
    )
}

RequestAnswer.prototype = {
    question: PropTypes.object
}

RequestAnswer.defaultProps = {
    question: {}
}
export default RequestAnswer;