import React, { useEffect } from 'react';
import { BaseColor, Images } from "../config";

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';

import PropTypes from "prop-types";
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import Comments from '../Component/Comments';
import { httpPost } from '../Services/HttpServices';
import { toast } from 'react-toastify';
import SweetAlert from 'react-bootstrap-sweetalert';
import moment from 'moment';
import 'moment-timezone';
import { useSelector } from 'react-redux';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Link, useHistory } from "react-router-dom";
// import Editor from '../Component/RichTextEditor';
import Editor from '../Component/QuillEditor';
import { bounceIn, fadeInUp, fadeInDown, zoomIn } from 'react-animations';
import Radium, {StyleRoot} from 'radium';
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
    WhatsappIcon
  } from "react-share";
import  RequestAnswer  from '../Component/RequestAnswer';

function Feed(props) {
    let dispatch = useDispatch();
    const timeZone  = Intl.DateTimeFormat().resolvedOptions().timeZone;
    var show = props.displaySeeMore ? "none" : "block"
    var auth = useSelector(({ auth }) => auth);
    let profilePicture = auth.ProfilePicture ? auth.ProfilePicture : Images.defaultProfile;
    const [updating, setUpdating] = useState(null);
    const [answerpostdisplay, setAnswerPostDisplay] = useState(false)
    const [answer, setAnswer] = useState("")
    const [followdetail, setFollowDetail] = useState("Follow")
    const [seeMoreCount, setSeeMoreCount] = useState(0)
    const [displaySeeMore, setDisplaySeeMore] = useState(show)
    const [isFollow, setIsFollow] = useState(null);
    const [deleteQn, setDeleteQn] = useState(null);

    const [reqAns, setReqAns] = useState(false);
    const [searchUser, setSearchUser] = useState([]);
    const [requestQnId, setRequestQnId] = useState(null);

    const [reportDisplay, setReportDisplay] = useState(false);
    const [report, setReport] = useState({
        type: null,
        text: ""
    });

    const [mergeQn, setMergeQn] = useState(false);
    const [searchQn, setSearchQn] = useState([]);
    const [mergeQnId, setMergeQnId] = useState(null);
    const [deleteQnId, setDeleteQnId] = useState(null);
    const [loading, setLoading] = useState(false);

    const styles = {
      bounceIn: {
        animation: 'x 1s',
        animationName: Radium.keyframes(bounceIn, 'bounceIn')
      },
      fadeInDown: {
        animation: 'x 1s',
        animationName: Radium.keyframes(fadeInDown, 'fadeInDown')
      },
      fadeInUp: {
        animation: 'x 1s',
        animationName: Radium.keyframes(fadeInUp, 'fadeInUp')
      },
      zoomIn: {
        animation: 'x 1s',
        animationName: Radium.keyframes(zoomIn, 'zoomIn')
      }
    }

    var answersArr = []
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
            href=""
            ref={ref}
            onClick={e => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {/* Render custom icon here */}
          &#x25bc;
            {children}
        </a>
    ));
    let { question, answers } = props;
    useEffect(() => {
        getFollowDetail();
    }, [])

    function handleUpvote(e) {
        if (auth && auth.user && auth.user._id) {
            var Url = "";
            var upVoteObj = {
                upVote: 1
            }
            if(question.Type === "QUESTION"){
                Url = "question_votes_controller/create";
                upVoteObj.QuestionID = question._id;
            }else{
                Url = "answer_votes_controller/create";
                upVoteObj.QuestionID = question.QuestionID._id
                upVoteObj.AnswerID = question._id
            }
            setUpdating(e);
            httpPost(Url, upVoteObj)
                .then(res => {
                    setUpdating(false);
                    if (res.status) {
                        props.updateQn(question._id);
                        toast.success(res.message);
                    } else {
                        toast.error(res.message)
                    }
                })
                .catch(err => {
                    setUpdating(false);
                    console.log('errr', err)
                })
        }else{
            window.location.href = "/signin?ref="+props.location.pathname;
        }
    }

    function handleDownvote(e) {
        if (auth && auth.user && auth.user._id) {
            var Url = "";
            var downVoteObj = {
                downVote: 1
            }
            if(question.Type === "QUESTION"){
                Url = "question_votes_controller/create";
                downVoteObj.QuestionID = question._id;
            }else{
                Url = "answer_votes_controller/create";
                downVoteObj.QuestionID = question.QuestionID._id
                downVoteObj.AnswerID = question._id
            }
            setUpdating(e);
            httpPost(Url, downVoteObj)
                .then(res => {
                    setUpdating(false);
                    if (res.status) {
                        props.updateQn(question._id);
                        toast.success(res.message);
                    } else {
                        toast.error(res.message)
                    }
                })
                .catch(err => {
                    setUpdating(false);
                    console.log('errr', err)
                })
        }else{
            window.location.href = "/signin?ref="+props.location.pathname;
        }
    }
    function handleAnswer() {
        if (auth && auth.user && auth.user._id) {
            setAnswerPostDisplay(answerpostdisplay ? false : true)
        }else{
            window.location.href = "/signin?ref="+props.location.pathname;
        }
    }

    function handleChangeInCreateAnswer(e) {
        setAnswer(e.target.value)
    }

    function handleCreateAnswer() {
        var answerObj = {
            Answer: answer,
            QuestionID: question._id
        }
        httpPost("answer_controller/create", answerObj)
            .then(res => {
                if (res.status == 1) {
                    //Call fetch api.
                    props.updateQn(question._id);
                    toast.success(res.message);
                    props.history.push('/question/'+question.QuestionUrl);
                }
            })
        setAnswerPostDisplay(false)
    }

    const onConfirmDelete = () => {
        if(auth && auth.user && auth.user._id){
            setDeleteQn(null);
            httpPost('question_controller/delete', {
                QuestionID: deleteQnId
            }).then(res => {
                if (res) {
                    if (res.status) {
                        toast.success(res.message);
                        props.history.push('/feed');
                    } else {
                        toast.error(res.message);
                    }
                }
            })
        }else{
            window.location.href = "/signin?ref="+props.location.pathname;
        }
    }
    function handleFollow() {
        if (auth && auth.user && auth.user._id) {
            var followObj = {
                UserID: auth && auth.user && auth.user._id || null,
                QuestionID: question._id || null
            }
            if (followdetail == 'Follow') {
                httpPost("follow_question_controller/create", followObj)
                    .then(res => {
                        if (res.status == 1) {
                        }
                        getFollowDetail();
                    })
            } else {
                httpPost("follow_question_controller/delete", {
                    FollowQuestionId: isFollow && isFollow._id
                })
                    .then(res => {
                        // console.log("follow result ======> ", res);
                        if (res.status == 1) {
                        }
                        getFollowDetail();
                    })
            }
        }else{
            window.location.href = "/signin?ref="+props.location.pathname;
        }
    }
    const getFollowDetail = () => {
        httpPost('follow_question_controller/retrieve', {
            QuestionID: question._id,
            UserID: auth && auth.user && auth.user._id || null,
        })
            .then(res => {
                setIsFollow(res && res.data || null);
                setFollowDetail(res && res.status && "Following" || "Follow");
            })
    }
    function handleSeeMore() {
        setSeeMoreCount(seeMoreCount + 10)
        if (seeMoreCount > props.answertotalCount) {
            setDisplaySeeMore("none")
        } else {
            setDisplaySeeMore("block")
        } 
    }
    const unFollowQuestion = () => {
        if (question.UserID._id != (auth && auth.user && auth.user._id)) {
            console.log("isFollow && isFollow._id", isFollow && isFollow._id);
            httpPost('follow_question_controller/delete', {
                FollowQuestionId: isFollow && isFollow._id
            }).then(res => {
                console.log("follow_question_controller/delete => res => ", res);
                if (res && res.status) {
                    toast.success(res.message);
                    setIsFollow(null);
                } else {
                    toast.error(res.message);
                }
            })
        }
    }
    const followQuestion = () => {
        if (auth && auth.user && auth.user._id) {
            var obj = {
                UserID: auth && auth.user && auth.user._id,
                QuestionID: question._id,
            }
            httpPost('follow_question_controller/create', obj).then(res => {
                if (res && res.status) {
                    toast.success(res.message);
                    getFollowDetails();
                } else {
                    toast.error(res.message);
                }
            })
        }else{
            window.location.href = "/signin?ref="+props.location.pathname;
        }
    }

    const getFollowDetails = () => {
        httpPost('follow_question_controller/retrieve', {
            QuestionID: question.id,
            UserID: auth && auth.user && auth.user._id || null,
        })
            .then(res => {
                setIsFollow(res && res.data || null);
                setFollowDetail(res && res.status && "Following" || "Follow");
            })
    }

    const answerLater = (qData) => {
        if (auth && auth.user && auth.user._id) {
            let postData = {}
            postData.QuestionID = qData._id
            // if(auth && auth.user._id === qData.UserID._id){
            //     postData.QuestionID = qData._id
            // }else{
            //     postData.AnswerRequestID = auth.user._id;
            // }
            httpPost('answer_request_controller/answer_later', postData).then(res => {
                if (res) {
                    if (res.status) {
                        toast.success(res.message);
                    } else {
                        toast.error(res.message);
                    }
                }
            }).catch(err => {
                console.log('err', err);
            })
        }else{
            window.location.href = "/signin?ref="+props.location.pathname;
        }
    }

    const searchQuestion = (e) => {
        httpPost('question_controller/list', {
            // UserID: auth && auth.user && auth.user._id,
            filter: {
                "Subject": e && e || ""
            },
        }).then(res => {
            const sQData = res.data || []
            const searchData = [];
            sQData.map(q => {
                if(question._id !== q._id){
                    q.Subject = q.Subject+' https://lt.englishforward.com/question/'+q.QuestionUrl;
                    searchData.push(q);
                }
            })
            setSearchQn(searchData || []);
        })
    }

    const mergeQuestion = (e) => {
        if(auth && auth.user && auth.user._id){
            if (mergeQnId && mergeQnId.length > 0) {
                setLoading(true)
                httpPost('merge_question_controller/create', {
                    QuestionFromID: question._id,
                    QuestionToID: mergeQnId && mergeQnId.length > 0 && mergeQnId[0]._id
                }).then(res => {
                    setLoading(false);
                    if (res && res.status) {
                        toast.success(res.message);
                        setMergeQn(false);
                        setMergeQnId(null);
                        setSearchQn([]);
                    }
                    if (res && !res.status) {
                        toast.error(res.message);
                    }
                }).catch(err => {
                    setLoading(false)
                })
            } else {
                toast.error('Please select question');
            }
        }else{
            window.location.href = "/signin?ref="+props.location.pathname;
        }
    }

    const searchuser = (e) => {
        httpPost('follow_user_controller/list', {
            FollowerID: auth && auth.user && auth.user._id,
            page: 1,
            filter: {
                'FirstName': e && e || ""
            }
        })
            .then(res => {
                // console.log('search user', res);
                var arr = [];
                if (res && res.data) {
                    res.data.map(ele => {
                        arr.push(ele.UserID || []);
                    })
                    setSearchUser(arr);
                }
            })
    }

    const requestAnswer = (e) => {
        if (requestQnId && requestQnId.length > 0) {
            setLoading(true)
            httpPost('answer_request_controller/request', {
                QuestionID: question.id,
                ToUserID: requestQnId && requestQnId.length > 0 && requestQnId[0]._id
            }).then(res => {
                setLoading(false);
                if (res && res.status) {
                    toast.success(res.message);
                    setReqAns(false);
                    setSearchUser([]);
                    setRequestQnId(null);
                }
                if (res && !res.status) {
                    toast.error(res.message);
                }
            }).catch(err => {
                setLoading(false)
            })
        } else {
            toast.error('Please select user');
        }
    }

    const draftAnswer = () => {
        httpPost('draft_answer_controller/create', {
            Answer: answer || "",
            QuestionID: question._id,
        }).then(res => {
            if (res) {
                if (res.status) {
                    setAnswerPostDisplay(false)
                    toast.success(res.message);
                } else {
                    toast.error(res.message);
                }
            }
        }).catch(err => {
            console.log('err', err);
        })
    }

    const handleReport = () => {
        setLoading(true);
        httpPost('question_report_controlller/create', {
            QuestionID: question._id,
            ReportType: report.type,
            ReportText: report.text
        }).then(res => {
            setLoading(false);
            if (res) {
                if (res.status) {
                    setReport({
                        type: null,
                        text: ""
                    });
                    setReportDisplay(false);
                    toast.success(res.message)
                } else {
                    toast.error(res.message);
                }
            } else {
                toast.error('Network Error')
            }
        }).catch(err => {
            setLoading(false);
            toast.error('Network Error')
        })
    }

    const notifyEdits = () => {
        if(auth && auth.user && auth.user._id){
            httpPost('question_controller/notify', { QuestionID: question._id })
                .then(res => {
                    if (res && res.status === 1) {
                        toast.success(res.message);
                    }
                })  
        }else{
            window.location.href = "/signin?ref="+props.location.pathname;
        }
    }


    const ProfileCard = (
        <Popover className="hoverCard" >
            <Popover.Content>
                <Link to={"/profile/" + (question.UserID && question.UserID.UserName)}>
                    <Image src={question.UserID && question.UserID.ProfilePicture ? question.UserID.ProfilePicture : Images.defaultProfile} width={40} height={40} roundedCircle />
                </Link>
                {question.UserID ? (question.UserID.FirstName + ' ' + question.UserID.LastName) : null}
                <div>
                    <div>
                        Company Name
                    </div>
                    <div>
                        Education:
                    </div>
                    <div>
                        Country:
                    </div>
                    <div>
                        Number of Views:
                    </div>
                    <div>
                        Points:
                    </div>
                </div>
            </Popover.Content>
        </Popover>
    );
    if(question.Type === "QUESTION"){
        return (
        <StyleRoot>
        <div style={styles.zoomIn} >
            <div className="mb-3 p-2 pm-4 p-lg-3" style={{ width: "100%", backgroundColor: BaseColor.whiteColor, borderWidth: 'thin', borderRadius: 5, borderRadius: 5, boxShadow: "0px 15px 15px rgba(0, 0, 0, 0.15)" }}>
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: '1 1 0%' }} className="pr-2 pr-md-0 pr-lg-0 pr-sm-2">
                        <OverlayTrigger trigger={["hover", "hover"]} placement="bottom" overlay={ProfileCard}>
                            <Link to={"/profile/" + (question.UserID && question.UserID.UserName)}>
                                <Image src={question.UserID && question.UserID.ProfilePicture ? question.UserID.ProfilePicture : Images.defaultProfile} width={40} height={40} roundedCircle />
                            </Link>
                        </OverlayTrigger>
                        <div style={{ flex: '1' }} className="pr-4 mt-3">
                            <div style={{ display: 'inline-block', flexDirection: 'column', textAlign: 'center', marginLeft: '5px' }}>
                                <StyleRoot>
                                <div className="upvote" style={styles.fadeInUp} >
                                    <a onClick={e => handleUpvote(question._id)} >{question.IsVoted && question.UpVote ? (<Image src={Images.upActive} height={15} width={30} />) : (<Image src={Images.up} height={15} width={30} />)}</a>
                                </div>
                                </StyleRoot>
                                <a style={{ fontSize: 12 }}>{updating == question._id ? <Spinner animation="grow" variant="primary" size="sm" /> : (question.percent)}</a>
                                <StyleRoot>
                                <div className="downvote" style={styles.fadeInDown} >
                                    <a onClick={e => handleDownvote(question._id)} >{question.IsVoted && question.DownVote ? (<Image src={Images.downActive} height={15} width={30} />) : (<Image src={Images.down} height={15} width={30} />)}</a>
                                </div>
                                </StyleRoot>
                            </div>
                        </div>
                    </div>
                    <div style={{ flex: '9', display: 'flex', flexDirection: 'column' }}>
                        <Link className="reset mt-2" to={"/profile/" + (question.UserID && question.UserID.UserName)}>
                            <span className="capitalize" style={{}}>{question.UserID ? (question.UserID.FirstName + ' ' + question.UserID.LastName) : null}</span>
                            <small className="float-right" >{"posted " + question.CreatedDate ? moment.tz(question.CreatedDate, timeZone).fromNow() : null}</small>
                        </Link>
                        <div>
                            {/*{ question.topics && question.topics.map((top) => {
                                return <Badge variant="secondary">{top.Title}</Badge>
                            }) } */}
                        </div>
                        <br />
                        <Link className="reset question-link" to={"/question/" + (question.QuestionUrl)}>
                            <h5 style={{minHeight: '10px', marginTop: '-2px'}} dangerouslySetInnerHTML={{ __html: question.Subject && question.Subject.length > 140 ? question.Subject.substring(0, 140) + '...' : question.Subject }} ></h5>
                        </Link>
                        {question.Content && <div className="qna-html-content" style={{minHeight: '30px'}} dangerouslySetInnerHTML={{ __html: (question.Content && question.Content.length > 160 ? question.Content.substring(0, 160) + '...' : question.Content).replace('[AUDIO file="', '&nbsp;&nbsp;<img src="'+Images.audio+'" onClick=play("').replace('" AUDIO]', '") />') }} ></div>}
                        {/*<p style={{minHeight: '10px'}}  >
                            {question.AnswerCount === 0 ? "No answers posted yet. Why not post one?" : question.AnswerCount+" answers." }
                            {question.AnswerCount > 0 ? (<span style={{fontSize: '13px'}} > Last answered on  { moment.tz(question.UpdatedDate, timeZone).fromNow() }</span>) : null }
                        </p>*/}
                        <div className="sub-links text-left" >
                            <span>
                                {question.AnswerCount === 0 ? "No answers posted yet. Why not post one?" : question.AnswerCount+" answers." }
                                {question.AnswerCount > 0 ? (<span style={{fontSize: '13px'}} > Last answered on  { moment.tz(question.UpdatedDate, timeZone).fromNow() }</span>) : null }
                            </span>
                            <span className="float-right" >                                    
                                <label className="mb-0" style={{ marginRight: 15 }} onClick={handleAnswer}>Answer</label>
                                <label className="mb-0" style={{ marginRight: 15 }} onClick={() => {
                                    if (isFollow) {
                                        unFollowQuestion();
                                    } else {
                                        followQuestion();
                                    }
                                }}>{isFollow ? 'Unfollow' : "Follow"} {question.FollowCount}</label>
                                <label style={{ marginRight: 15 }} onClick={() => { }} className="for-dropdown mb-0">
                                <DropdownButton className="menu-three-dots-button" id="dropdown-item-share" title={"Share"}>
                                    <Dropdown.Item as="button" ><FacebookShareButton url={window.location.origin+"/question/" + (question.QuestionUrl)} quote={question.Subject} ><FacebookIcon size={"2rem"} round/>&nbsp;Facebook</FacebookShareButton></Dropdown.Item>
                                    <Dropdown.Item as="button" ><TwitterShareButton url={window.location.origin+"/question/" + (question.QuestionUrl)} title={question.Subject} ><TwitterIcon size={"2rem"} round/>&nbsp;Twitter</TwitterShareButton></Dropdown.Item>
                                    <Dropdown.Item as="button" ><LinkedinShareButton url={window.location.origin+"/question/" + (question.QuestionUrl)} title={question.Subject} summary={question.Content} ><LinkedinIcon size={"2rem"} round/>&nbsp;LinkedIn</LinkedinShareButton></Dropdown.Item>
                                    <Dropdown.Item as="button" ><WhatsappShareButton url={window.location.origin+"/question/" + (question.QuestionUrl)} title={question.Subject} ><WhatsappIcon size={"2rem"} round/>&nbsp;Whatsapp</WhatsappShareButton></Dropdown.Item>
                                </DropdownButton>
                                </label>
                                <label className="for-dropdown mb-0">
                                    <DropdownButton className="menu-three-dots-button" id="dropdown-item-button" title={<Image src={Images.dotIcon} />}>
                                        {auth && auth.user && auth.user._id && auth.user._id == question.user_id ? (
                                            <>
                                                <Dropdown.Item onClick={() => { props.history.push('/questionCreate?id=' + question._id) }} as="button">Edit</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { setDeleteQn(!deleteQn); setDeleteQnId(question._id); }} as="button">Delete</Dropdown.Item>
                                                <Dropdown.Divider />
                                            </>
                                        ) : (
                                            <>
                                                <Dropdown.Item as="button" onClick={() => { answerLater(question) }}>Answer Later</Dropdown.Item>
                                            </>
                                            )}
                                        <Dropdown.Item as="button" onClick={() => { setMergeQn(true); setMergeQnId(question.id); }}>Merge Questions</Dropdown.Item>
                                        <Dropdown.Item as="button" onClick={() => { notifyEdits() }}>Notify about Edits</Dropdown.Item>
                                        <Dropdown.Item as="button" onClick={() => { auth && auth.user && auth.user._id ? setReqAns(true) : window.location.href = "/signin?ref="+props.location.pathname; }}>Request Experts to Answer</Dropdown.Item>
                                        <Dropdown.Item as="button" onClick={() => { auth && auth.user && auth.user._id ? setReportDisplay(!reportDisplay) : window.location.href = "/signin?ref="+props.location.pathname; }}>Report</Dropdown.Item>
                                    </DropdownButton>
                                </label>
                            </span>
                        </div>
                    </div>
                </div>
                 {answerpostdisplay ?
                        <Row className="row" style={{ paddingTop: 10, alignItems: 'center' }}>
                            <Col md="12" sm="12" xs="12" >
                                <div style={{ alignItems: 'center' }}>
                                    {/* <Image style={{ marginLeft: 5 }} src={Images.activity} height={35} width={35} roundedCircle /> */}
                                    {/* <InputGroup className="">
                                        <FormControl style={{ border: 0, boxShadow: 'none', marginRight: 5, backgroundColor: 'inherit' }} type="text" placeholder="start typing your answer..." size="lg" onChange={handleChangeInCreateAnswer} />
                                        <InputGroup.Append>
                                        </InputGroup.Append>
                                    </InputGroup> */}
                                    <Editor
                                        editorState={null}
                                        onUpdate={editorState => setAnswer(editorState)}
                                    />
                                </div>
                            </Col>
                            <Col md="12" sm="12" xs="12">
                                <Button className="mt-1 btn-sm" style={{ backgroundColor: BaseColor.primaryColor, color: BaseColor.whiteColor, borderColor: BaseColor.darkPrimaryColor, height: "100%" }} onClick={handleCreateAnswer}>Post</Button>
                                &nbsp;&nbsp;<Button className="mt-1 btn-sm" style={{ backgroundColor: BaseColor.primaryColor, color: BaseColor.whiteColor, borderColor: BaseColor.darkPrimaryColor, height: "100%" }} onClick={draftAnswer}>Save as Draft</Button>
                            </Col>
                        </Row>
                        : null}
                <SweetAlert
                    show={deleteQn ? true : false}
                    warning
                    showCancel
                    confirmBtnText={"Yes"}
                    cancelBtnText={"No"}
                    title={<span style={{ fontSize: 25 }} >Do you want to delete this Question</span>}
                    onConfirm={onConfirmDelete}
                    onCancel={() => { setDeleteQn(null) }}
                />
                {/* Report Question  */}
                <Modal show={reportDisplay} onHide={() => { setReport({ text: '', type: null }); setReportDisplay(false); }}>
                    <Modal.Header closeButton>
                        <Modal.Title as="h4" >Report Question</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="row" style={{ paddingTop: 10, alignItems: 'center' }}>
                            <Col md="12" sm="12" xs="12" >
                                <Form.Check onClick={() => setReport({ ...report, type: '1' })} checked={report.type == '1' ? true : false} type={'radio'} value={"1"} label="Harassment" />
                                <Form.Check onClick={() => setReport({ ...report, type: '2' })} checked={report.type == '2' ? true : false} type={'radio'} value={"2"} label="Spam" />
                                <Form.Check onClick={() => setReport({ ...report, type: '3' })} checked={report.type == '3' ? true : false} type={'radio'} value={"3"} label="Insincere" />
                                <Form.Check onClick={() => setReport({ ...report, type: '4' })} checked={report.type == '4' ? true : false} type={'radio'} value={"4"} label="Poorly Written" />
                                <Form.Check onClick={() => setReport({ ...report, type: '5' })} checked={report.type == '5' ? true : false} type={'radio'} value={"5"} label="Incorrect Topic" />
                                <Form.Check onClick={() => setReport({ ...report, type: '6' })} checked={report.type == '6' ? true : false} type={'radio'} value={"6"} label="Offensive" />
                            </Col>
                            <Col md="12" sm="12" xs="12" className='mt-3'>
                                <div className="input-border" style={{ display: 'flex', alignItems: 'center' }}>
                                    <Image style={{ marginLeft: 5 }} src={profilePicture} height={35} width={35} roundedCircle />
                                    <InputGroup className="">
                                        <FormControl value={report.text} style={{ border: 0, boxShadow: 'none', marginRight: 5, backgroundColor: 'inherit' }} type="text" placeholder="Explain the report" size="lg" onChange={(e) => {
                                            setReport({ ...report, text: e && e.target && e.target.value || null });
                                        }} />
                                        <InputGroup.Append>
                                            {/* <FontAwesomeIcon icon={"search"} /> */}
                                        </InputGroup.Append>
                                    </InputGroup>
                                </div>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" className="btn-sm" onClick={() => { setReport({ text: '', type: null }); setReportDisplay(false); }}>
                            Cancel
                        </Button>
                        <Button disabled={loading} variant="primary" className="btn-sm" onClick={() => { handleReport() }}>
                            Submit
                        </Button>
                    </Modal.Footer>
                </Modal>
                {/* Question Merge  */}
                <Modal size="lg" show={mergeQn} onHide={() => { setMergeQn(false); setMergeQnId(null); }}>
                    <Modal.Header closeButton>
                        <Modal.Title as="h4" >Merge Question</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <label>Merge To</label>
                        <Typeahead
                            id="user-example"
                            onInputChange={(e) => { searchQuestion(e) }}
                            onChange={(e) => { setMergeQnId(e) }}
                            options={searchQn && searchQn || []}
                            placeholder="Choose a question..."
                            labelKey={"Subject"}
                            selected={mergeQnId && mergeQnId || []}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" className="btn-sm" onClick={() => { setMergeQn(false); setMergeQnId(null); }}>
                            Cancel
                        </Button>
                        <Button disabled={loading} variant="primary" className="btn-sm" onClick={() => { mergeQuestion(false) }}>
                            Submit
                        </Button>
                    </Modal.Footer>
                </Modal>
                {/* Request Answer */}
                <Modal show={reqAns} onHide={() => { setReqAns(false); setRequestQnId(null); }} size="lg" >
                    <Modal.Header closeButton>
                        <Modal.Title as="h4" >Request Answer</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <label>Request To</label>
                        <RequestAnswer question={question} />
                    </Modal.Body>
                </Modal>
            </div >
        </div>
        </StyleRoot>
        )

    }else{
        return (
        <StyleRoot>
            <div style={styles.bounceIn} >
            <div className="mb-3 p-2 pm-4 p-lg-3" style={{ width: "100%", backgroundColor: BaseColor.whiteColor, borderWidth: 'thin', borderRadius: 5, borderRadius: 5, boxShadow: "0px 15px 15px rgba(0, 0, 0, 0.15)" }}>
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: '1 1 0%' }} className="pr-2 pr-md-0 pr-lg-0 pr-sm-2">
                        <OverlayTrigger trigger={["hover", "hover"]} placement="bottom" overlay={ProfileCard}>
                            <Link to={"/profile/" + (question.UserID && question.UserID.UserName)}>
                                <Image src={question.UserID && question.UserID.ProfilePicture ? question.UserID.ProfilePicture : Images.defaultProfile} width={40} height={40} roundedCircle />
                            </Link>
                        </OverlayTrigger>
                        <div style={{ flex: '1' }} className="pr-1 mt-3">
                            <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                                <div className="upvote">
                                    <a onClick={e => handleUpvote(question._id)} >{question.IsVoted && question.UpVote ? (<Image src={Images.upActive} height={15} width={30} />) : (<Image src={Images.up} height={15} width={30} />)}</a>
                                </div>
                                <span className="mb-1 mt-1" style={{ fontSize: 12 }}>{updating == question._id ? <Spinner animation="grow" variant="primary" size="sm" /> : (question.percent)}</span>
                                <div className="downvote">
                                    <a onClick={e => handleDownvote(question._id)} >{question.IsVoted && question.DownVote ? (<Image src={Images.downActive} height={15} width={30} />) : (<Image src={Images.down} height={15} width={30} />)}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ flex: '9', display: 'flex', flexDirection: 'column' }}>
                        <Link className="reset mt-2" to={"/profile/" + (question.UserID && question.UserID.UserName)}>
                            <span className="capitalize" style={{}}>{question.UserID ? (question.UserID.FirstName + ' ' + question.UserID.LastName) : null}</span>
                            <small className="float-right" >{"posted " + question.CreatedDate ? moment.tz(question.CreatedDate, timeZone).fromNow() : null}</small>
                        </Link>
                        <br />
                        <Link className="reset question-link" to={"/question/" + (question.QuestionID.QuestionUrl)}><h5 dangerouslySetInnerHTML={{ __html: question.QuestionID.Subject && question.QuestionID.Subject.length > 140 ? question.QuestionID.Subject.substring(0, 140) + '...' : question.QuestionID.Subject }} ></h5></Link>
                        {question.Answer && <Link className="reset" to={"/question/" + (question.QuestionID.QuestionUrl)+'?a='+question._id} ><div className="qna-html-content" dangerouslySetInnerHTML={{ __html: (question.Answer && question.Answer.length > 350 ? question.Answer.substring(0, 350) + '...' : question.Answer).replace('[AUDIO file="', '&nbsp;&nbsp;<img src="'+Images.audio+'" onClick=play("').replace('" AUDIO]', '") />') }} ></div></Link>}
                    </div>
                </div>
                <Comments answerID={question._id} answer={question} questionID={question.QuestionID._id} image={profilePicture}  commentsCount={question.CommentCount} history={props.history} />
            </div >
            </div>
        </StyleRoot>
        )
    }
}

Feed.prototype = {
    question: PropTypes.object,
    answers: PropTypes.array,
    showFullText: PropTypes.func,
    link: PropTypes.string,
    updateQn: PropTypes.func
}

Feed.defaultProps = {
    question: {},
    answers: [],
    showFullText: () => { },
    link: null,
    updateQn: () => { }
}
export default Feed;