import { useEffect, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';

import Related from '../Component/Related';
import Tag from '../Component/Tag';
import QuestionList from '../Component/QuestionList';
import SearchFilter from '../Component/SearchFilter';
import { Images, BaseColor } from '../config';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList, faTh } from '@fortawesome/free-solid-svg-icons';
import Pagination from "react-js-pagination";
import { httpPost } from '../Services/HttpServices';
import { useSelector, useDispatch } from 'react-redux';
import { resetSearch } from '../AppRedux/Actions/Search';
import InfiniteScroll from 'react-infinite-scroll-component';
 
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Questions(props) {
    const dispatch = useDispatch();
    const { auth } = useSelector(state => state);
    const [width, setWidth] = useState(window.innerWidth);
    const [questionRef, setQuestionRef] = useState();
    const [questions, setQuestions] = useState([]);
    const [viewType, setViewType] = useState(false);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [author, setAuthor] = useState("");

    const [y, setY] = useState(0);

    var queryParams = useQuery();
    let search = queryParams.get('q');
    let type = queryParams.get('type');
    let time = queryParams.get('time');
    let user = queryParams.get('user');
    let myposts = queryParams.get('myposts') ? queryParams.get('myposts') : false;
    
    const path = window.location.pathname;

    const [searchFilter, setSearchFilter] = useState({
        search: {
        },
        filter: {
            CreatedDate: null
        },
        pagination: {
            page: 1,
            totalCount: 0,
            end: false,
        }
    })
  
    useEffect(() => {
        resetSearchData();
        //getList();
    }, [search]);

    useEffect(() => {
        if(!searchFilter.pagination.end){
            setSearchFilter({
            ...searchFilter,
            pagination: {
                ...searchFilter.pagination,
                end: true
            }
            });
            getList();
        }
    }, [search, type, searchFilter.filter.CreatedDate])
    useEffect(() => {
        if (searchFilter.pagination.end) {
            setLoading(false);
        }
    }, [searchFilter])

    const resetSearchData = (val) => {
        setQuestions([]);
        setSearchFilter({
            ...searchFilter,
            filter: {
                CreatedDate: val || null,
            },
            pagination: {
                page: 1,
                totalCount: 0,
                end: false,
            }
        })
    }
    const getList = () => {
        let resultType = "questions"
        if (type){
            resultType = type;
        }
        setLoading(true)
        httpPost('home_page/search_questions', { SearchText: search, user: user, time: time, myposts: myposts, page: searchFilter.pagination.page, sort: {CreatedDate: searchFilter.filter.CreatedDate}, type: resultType })
            .then(res => {
                if (res && res.data) {
                    let newData = res.data;
                    newData.map(ele => {
                        if(resultType === "comments"){
                            ele.Type = "COMMENT"
                        }
                        if(resultType === "questions" || resultType === "answers" || resultType === "comments"){
                            let percentage = ((ele.UpvoteCount / (ele.UpvoteCount + ele.DownvoteCount))*100);
                            percentage = (Math.round(isNaN(percentage) ? 0 : percentage)) + "%";
                            ele.percent = percentage
                        }
                    })
                    var oldArr = [...questions];
                    var newArr = oldArr.concat(newData);
                    setLoading(false);
                    setSearchFilter({
                        ...searchFilter,
                        pagination: {
                            ...searchFilter.pagination,
                            page: searchFilter.pagination.page + 1,
                            totalCount: res.totalCount,
                            end: parseInt(res.totalCount) > 0 ? false : true
                        }
                    });
                    setQuestions(newArr);
                }else{
                    setHasMore(false);
                }
            }).catch(err => {
                console.log('err', err);
            })
    }

    const handleChange = (e) => {
        setAuthor(e.target.value);
    }

    const setVisibility = (e) => {
        setParams('myposts', myposts ? false : true);
    }

    const handSubmit = (e) => {
        e.preventDefault();
        setParams('user', author)
    }

    const setParams = (key, val) => {
        let searchParams  = {};
        if(search){
            searchParams.q = search
        }
        if(type){
            searchParams.type = type
        }
        if(time){
            searchParams.time = time
        }
        if(user){
            searchParams.user = user
        }
        if(key){
            if(val){
                searchParams[key] = val;
            }else{
                delete searchParams[key]
            }
        }
        props.history.push({
            pathname: '/questionlist',
            search: "?" + new URLSearchParams(searchParams).toString()
        })
        window.location.reload();
    }
    const breakPoint = 768;
    let items = [];
    let active = 1;
    return (
        <div className="p-4 p-md-5 p-lg-5 p-sm-4 question-list-view" >
            {/*<Row style={{ alignItems: 'baseline' }}>
                <Col md="6">
                    <p className="heading-text">Search Questions <span>(1380 results found)</span></p>
                </Col>
                <Col md="3" style={{ display: 'flex' }}>
                    <p className="heading-text">Show Users only</p>
                    <div className="toggle-container ml-3" onClick={() => setViewType(!viewType)}>
                        <div className={`dialog-button ${viewType ? "" : "disabled"}`}>
                        </div>
                    </div>
                </Col>
            </Row>*/}
            <Row>
                <Col md={{span: 2, offset: 2}} >
                    <Nav defaultActiveKey={path} as="ul" className="flex-column" >
                        <Nav.Item as="li" >
                            <strong>By Type</strong>
                        </Nav.Item>
                        <Nav.Item as="li" >
                            <Nav.Link onClick={() => setParams('type', 'questions') } className={type === "questions" ? "active" : ""} >Questions</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" >
                            <Nav.Link onClick={() => setParams('type', 'answers') } className={type === "answers" ? "active" : ""} >Answers</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" >
                            <Nav.Link onClick={() => setParams('type', 'comments') } className={type === "comments" ? "active" : ""} >Comments</Nav.Link>
                        </Nav.Item >
                        <Nav.Item as="li" >
                            <Nav.Link onClick={() => setParams('type', 'users') } className={type === "users" ? "active" : ""} >Users</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" >
                            <strong>By Author</strong>
                        </Nav.Item>
                        <Nav.Item as="li" >
                            <form onSubmit={handSubmit} >
                                <input type="text" name="user" style={{maxWidth: '184px',height: '30px'}} className="form-control" onChange={handleChange} defaultValue={user} />
                            </form>
                        </Nav.Item>
                        <Nav.Item as="li" >
                            <strong>By Time</strong>
                        </Nav.Item>
                        <Nav.Item as="li" >
                            <Nav.Link onClick={() => setParams('time', null) } className={time === null ? "active" : ""} >All Time</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" >
                            <Nav.Link onClick={() => setParams('time', 'hour') } className={time === "hour" ? "active" : ""} >Past hour</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" >
                            <Nav.Link onClick={() => setParams('time', 'day') } className={time === "day" ? "active" : ""} >Past day</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" >
                            <Nav.Link onClick={() => setParams('time', 'week') } className={time === "week" ? "active" : ""} >Past week</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" >
                            <Nav.Link onClick={() => setParams('time', 'month') } className={time === "month" ? "active" : ""} >Past month</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" >
                            <Nav.Link onClick={() => setParams('time', 'year') } className={time === "year" ? "active" : ""} >Past year</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
                <Col md="6" className="pl-md-0 pr-md-0" >
                    { (auth && auth.user && type !== "users") ? (<Col className="mt-1 mb-3" >
                        <input type="checkbox" checked={myposts} onChange={setVisibility} /> Only my posts
                    </Col>) : null }
                    <InfiniteScroll
                      dataLength={questions.length} //This is important field to render the next data
                      next={getList}
                      hasMore={hasMore}
                      loader={<Spinner animation="border" variant="primary" />}
                      endMessage={
                        <p style={{ textAlign: 'center' }}>
                          <b>No result found </b>
                        </p>
                      }
                    >
                    <div ref={rr => setQuestionRef(rr)}>
                        <QuestionList {...props} questions={questions}
                            showFullText={(id) => {
                                if (questions && questions.length > 0) {
                                    let arr = [...questions];
                                    arr.map(ele => {
                                        if (ele._id === id) {
                                            ele.showFull = 1
                                        }
                                    })
                                    setQuestions(arr);
                                }
                            }}
                        />
                    </div>
                    </InfiniteScroll>
                    {/* <Row className="">
                        <Pagination
                            itemClass="page-item"
                            linkClass="page-link"
                            activePage={1}
                            itemsCountPerPage={10}
                            totalItemsCount={400}
                            pageRangeDisplayed={5}
                            onChange={(e) => { console.log('click page', e) }}
                        />
                    </Row> */}
                </Col>
            </Row>
        </div>
    )
}
export default Questions;