import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';

import Related from '../Component/Related';
import Topic from '../Component/Topic';
import Feed from '../Component/Feed';
import { BaseColor, Images } from '../config';
import { useState, useEffect, useRef } from 'react';
import moment from "moment";
import { useSelector } from 'react-redux';
import { httpPost, httpGet } from '../Services/HttpServices';
import QuestionCreate from './CreateQuestion';
import { Link, Redirect } from 'react-router-dom';
import Helmet from 'react-helmet';
//Custom use previous hook
function usePrevious(value) {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef();
    // Store current value in ref
    useEffect(() => {
        ref.current = value;
    }, [value]); // Only re-run if value changes
    // Return previous value (happens before update in useEffect above)
    return ref.current;
}

var blogUrl = "https://lt.englishforward.com/blog/wp-json/wp/v2/";
var newsUrl = "https://lt.englishforward.com/news/wp-json/wp/v2/";

function FeedList(props) {
    const state = useSelector(state => state);
    var auth = useSelector(({ auth }) => auth);
    useEffect(() => {
        getTopicList();
        getUser();
        httpPost("home_page/get_meta_tag",{PageName: "Feed Page"})
            .then(res => {
                if (res && res.status) {
                    setMetaData(res.data);
                }
            })
    }, [])
    let profilePicture = auth.ProfilePicture ? auth.ProfilePicture : Images.defaultProfile;
    const [userDetail, setUserDetail] = useState(null);
    const [profilePercent, setProfilePercent] = useState("");
    const [topics, setTopics] = useState([]);
    const [trendingQuestions, setTrendingQuestions] = useState([]);

    const [topNews, setTopNews] = useState([]);
    const [topBlog, setTopBlog] = useState([]);
    const prevTopics = usePrevious(topics);

    const [showQuestionBox, setShowQuestionBox] = useState(false);
    const [metaData, setMetaData] = useState([]);

    useEffect(() => {
        if (prevTopics && prevTopics.length > 0) {
            setLoading(true);
            if (feeds.page == 1) {
                setFeeds({ page: 1, totalCount: 0, list: [] });
                getList();
            } else {
                setFeeds({ ...feeds, page: 1, list: [] });
            }
        }
        getTrendingQuestions();
        getTopBlog();
        getTopNews();
    }, [topics])

    const getUser = () => {
        httpPost('user_controller/retrive', { UserID: auth && auth.user && auth.user._id || null })
            .then(res => {
                setProfilePercent(res && res.status && res.data.ProfileScore.ProfileScore || 0);
                setUserDetail(res && res.status && res.profile_complete_status || null);
            })
    }

    const getTopicList = () => {
        httpPost("user_topic_controller/list")
            .then(res => {
            if (res && res.data) {
                if (res.data.length > 0) {
                    if (res.data[0].TopicIDs && res.data[0].TopicIDs.length > 0)
                        setTopics(res.data[0].TopicIDs || [])
                }
            }
        })
    }

    const [feeds, setFeeds] = useState({ page: 1, totalCount: 0, list: [] });
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        setLoading(true);
        getList();
    }, [feeds.page])

    const getList = () => {
        var topicArr = [];
        topics.map(ele => {
            if (ele.active == '1')
                topicArr.push(ele._id)
        });
        httpPost('dashboard_controller/feed', {
            page: feeds.page,
            TopicIDs: topicArr && topicArr.length > 0 ? topicArr.toString() : null
        })
            .then(res => {
                setLoading(false);
                if (res && res.status) {
                    let feedData = res.data;
                    feedData.map(ele => {
                        if (ele.Type === "ANSWER") {
                            let CommentCount = ele.CommentCount ? ele.CommentCount : 0;
                            ele.id = ele._id;
                            ele.image = (!ele.UserID.ProfilePicture || ele.UserID.ProfilePicture == "") ? Images.defaultProfile : ele.UserID.ProfilePicture;
                            ele.commentsCount = CommentCount > 1000000 ? Math.round((CommentCount / 1000000) * 10) / 10 + "M" : (CommentCount > 1000 ? Math.round((CommentCount / 1000) * 10) / 10 + "K" : CommentCount)
                        }else if(ele.Type === "QUESTION"){
                            ele.topics = ele.TopicID;
                        }
                        ele.user_id = ele.UserID._id;
                        // ele.percent = isNaN(ele.UpvoteCount - ele.DownvoteCount) ? 0 : ele.UpvoteCount - ele.DownvoteCount;
                        let percentage = ((ele.UpvoteCount / (ele.UpvoteCount + ele.DownvoteCount))*100);
                        percentage = (Math.round(isNaN(percentage) ? 0 : percentage)) + "%";
                        ele.percent = percentage
                    })
                    var oldArr = [...feeds.list];
                    var newArr = oldArr.concat(feedData);
                    setFeeds({ ...feeds, totalCount: res.totalCount, list: newArr });
                }
            })
    }
    const updateFeed = (feedData) => {
        feeds.list.filter(item => item._id !== feedData._id)
        var arr = [...feeds.list]; 
        var ind = arr.findIndex(ele => ele._id == feedData._id);
        if (ind > -1) {
            if(feedData.Type === "QUESTION"){
                httpGet('question_controller/' + arr[ind].QuestionUrl).then(res => {
                    if (res && res.status && res.data) {
                        let percentage = ((res.data.UpvoteCount / (res.data.UpvoteCount + res.data.DownvoteCount))*100);
                        percentage = (Math.round(isNaN(percentage) ? 0 : percentage)) + "%";
                        res.data.Type = "QUESTION";
                        res.data.percent = percentage;
                        arr[ind] = res.data;
                        setFeeds({ ...feeds, list: arr });
                    }
                })
            }else{
                httpPost('answer_controller/retrive',{AnswerID: feedData._id}).then(res => {
                    if (res && res.status && res.data) {
                        let percentage = ((res.data.UpvoteCount / (res.data.UpvoteCount + res.data.DownvoteCount))*100);
                        percentage = (Math.round(isNaN(percentage) ? 0 : percentage)) + "%";
                        res.data.Type = "ANSWER";
                        res.data.percent = percentage;
                        arr[ind] = res.data;
                        setFeeds({ ...feeds, list: arr });
                    }
                })
            }
        }
    }
    var trendingQuestionList = []
    const getTrendingQuestions = () => {
        httpPost("question_controller/trending", { page: 1})
            .then(res => {
                if (res && res.data) {
                    if (res.totalCount > 0) {
                        res.data.slice(0, 5).map((element, i) => {
                            trendingQuestionList.push({
                                id: element._id,
                                image: (element.UserID && element.UserID.ProfilePicture ? element.UserID.ProfilePicture : Images.defaultProfile),
                                url: "/question/" +element.QuestionUrl,
                                text: element.Subject,
                                // post: moment(Date(element.CreatedDate)).fromNow(),
                                // by: (element.UserID) ? element.UserID.FirstName + " " + element.UserID.LastName : ""
                            })
                        });
                    }
                    setTrendingQuestions(trendingQuestionList)
                }
            })
    }

    const getTopBlog = async () => {
        var blog = [];
        httpGet("", { }, blogUrl+"posts")
            .then(res => {
                if (res) {
                    if (res.length > 0) {
                        res.slice(1, 5).map((element, i) => {
                            var media = element.featured_media;
                            var image = Images.defaultProfile;
                            if(media){
                                // let attachement = await httpGet("", { }, newsUrl+"media/"+media);
                                // console.log(attachement, "attachement");
                            }
                            blog.push({
                                id: element.id,
                                image: image,
                                url: element.link,
                                text: element.title.rendered,
                            })
                        });
                    }
                    setTopBlog(blog)
                }
            })
    }

    const getTopNews = async () => {
        var news = [];
        httpGet("", { }, newsUrl+"posts")
            .then(res => {
                if (res) {
                    if (res.length > 0) {
                        res.slice(1, 5).map((element, i) => {
                            var media = element.featured_media;
                            var image = Images.defaultProfile;
                            if(media){
                                // let attachement = await httpGet("", { }, newsUrl+"media/"+media);
                                // console.log(attachement, "attachement");
                            }
                            news.push({
                                id: element.id,
                                image: image,
                                url: element.link,
                                text: element.title.rendered,
                            })
                        });
                    }
                    setTopNews(news)
                }
            })
    }
    
    return (
        <div className="p-2 p-md-3 p-lg-3 p-sm-2 question-feed">
            <Helmet>
                <title>{metaData && metaData.Title ? metaData.Title : "Feed Page"}</title>
                <meta name="description" content={metaData && metaData.Description ? metaData.Description : "English Forward Feed"} />
                <meta name="keyword" content={metaData ? metaData.Keywords : ""} />
            </Helmet>
            {auth.user === null ? (<Redirect to="/signin" />): null }
            <Container fluid>
                <Row>
                    <Col lg="3" md="4" className="p-2 p-md-1 p-lg-3 p-sm-2">
                        <Card className="ml-4 sidebar-card-width" style={{ zIndex: 1 }}>
                            <Card.Body>
                                <h4 className="border-bottom" style={{ paddingBottom: 10 }}>
                                    <Image src={Images.Question} />&nbsp;&nbsp;Topics</h4>
                                    <Form className="ml-2" >
                                        {topics.map(ele => (
                                            <div>
                                                <Form.Check >
                                                    <Form.Check.Label ><Link to={"/topics/"+ele.Slug} >{ele.TopicName}</Link></Form.Check.Label>
                                                </Form.Check>
                                            </div>
                                        ))}
                                    </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md="6" className="p-2 p-md-3 p-lg-3 p-sm-2">
                        {showQuestionBox ? (<QuestionCreate history={props.history} showBox={(isShow) => { setShowQuestionBox(isShow) }} inline={"1"}  />) : (
                            <div className={"leader-board "} >
                                {auth && auth.user ? (
                                        <Row>
                                            <Col md={{ span: 12 }}>
                                                <Col className="question-list p-4 mb-3" md="12" >
                                                    <div className="row">
                                                        <Col md="1" xs="2" className="pl-0 pl-md-2 pl-lg-2">
                                                            <Image className="profile-pic" src={profilePicture} width={40} height={40} roundedCircle />
                                                        </Col>
                                                        <Col md="11" xs="10">
                                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                <span className="user-name capitalize">{auth.user ? (auth.user.FirstName + " " + auth.user.LastName) : null}
                                                                </span>
                                                            </div>
                                                            <div style={{cursor: 'pointer'}} onClick = {() => { setShowQuestionBox(true) }} >
                                                                Ask a question?
                                                            </div>
                                                        </Col>
                                                    </div >
                                                </Col>
                                            </Col>
                                        </Row>
                                ) : null}

                            </div >
                        )}
                        <Row>
                            <Col md="12" >
                                {feeds && feeds.list && feeds.list.length > 0 ? feeds.list.map(ele => (
                                    <Feed
                                        key={ele._id}
                                        showFullText={(questionId, answerId) => {
                                            var arr = [...feeds.list];
                                            var ind = arr.findIndex(ele => ele._id == questionId);
                                            if (ind > -1) {
                                                var ansArr = [...arr[ind].AnswerID];
                                                var ind2 = ansArr.findIndex(ele => ele._id == answerId);
                                                if (ind2 > -1) {
                                                    ansArr[ind2].showFull = 1;
                                                    arr[ind].AnswerID = ansArr;
                                                    setFeeds({ ...feeds, list: arr })
                                                }
                                            }
                                        }}
                                        question={ele}
                                        updateQn={() => {
                                            updateFeed(ele)
                                        }}
                                        history = {props.history}
                                    />
                                )) : null}
                                {loading ?
                                    <Spinner animation="border" variant="primary" />
                                    : feeds.totalCount && feeds.totalCount > feeds.list.length && feeds.list.length === 10 ?
                                        (<div class="text-center" ><Button className="btn-rounded" variant="outline-primary" size="md" onClick={() => { setFeeds({ ...feeds, page: feeds.page + 1 }) }}>See more</Button></div>)
                                        : ""}
                                 {/*<p>No feeds available</p>*/}       
                            </Col>
                        </Row>
                    </Col>
                    <Col md="3" className="p-2 p-md-3 p-lg-3 p-sm-2 d-none d-lg-block d-md-block" >
                        <Row>
                            {
                              profilePercent < 100 ? (<div className="profile-score" >
                                <Card className="sidebar-card-width" >
                                    <Card.Body>
                                        <Card.Title>Profile Completion Score</Card.Title>
                                        <Card.Text className="text-center" >
                                            <strong>{(profilePercent || 0)}%</strong>
                                            <ProgressBar now={profilePercent || 0} />
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>) : null  
                            }
                            { userDetail && (userDetail.isAnswerQuestion === 0 || userDetail.isAskQuestion === 0 || userDetail.isAskQuestion === 0 || userDetail.isFollowTopic === 0 || userDetail.isProfileComplete === 0 || userDetail.isShareQuestion === 0 || userDetail.isUpvoteAnswers === 0) ? (
                                <div className="check-list" >
                                    <Card className="sidebar-card-width" style={{ marginTop: '14px' }}>
                                        <Card.Body>
                                            <Card.Title as="h4" >Task List</Card.Title>
                                            <Card.Text >
                                                <Form>
                                                    <div>
                                                        <Form.Check type={`checkbox`} id={`check-api`}>
                                                            <Form.Check.Input type={`checkbox`} checked={userDetail && userDetail.isAskQuestion ? true : false} />
                                                            <Form.Check.Label style={{textDecoration:userDetail && userDetail.isAskQuestion ? 'line-through':'none'}}>{`Ask a question`}</Form.Check.Label>
                                                        </Form.Check>
                                                    </div>
                                                    <div>
                                                        <Form.Check type={`checkbox`} id={`check-api`}>
                                                            <Form.Check.Input type={`checkbox`} checked={userDetail && userDetail.isAnswerQuestion ? true : false} />
                                                            <Form.Check.Label style={{textDecoration:userDetail && userDetail.isAnswerQuestion ? 'line-through':'none'}}>{`Answer a question`}</Form.Check.Label>
                                                        </Form.Check>
                                                    </div>
                                                    <div>
                                                        <Form.Check type={`checkbox`} id={`check-api`}>
                                                            <Form.Check.Input type={`checkbox`} checked={userDetail && userDetail.isUpvoteAnswers ? true : false} />
                                                            <Form.Check.Label style={{textDecoration:userDetail && userDetail.isUpvoteAnswers ? 'line-through':'none'}}>{`Upvote 5 good answers`}</Form.Check.Label>
                                                        </Form.Check>
                                                    </div>
                                                    <div>
                                                        <Form.Check type={`checkbox`} id={`check-api`}>
                                                            <Form.Check.Input type={`checkbox`} checked={userDetail && userDetail.isComment ? true : false} />
                                                            <Form.Check.Label style={{textDecoration:userDetail && userDetail.isComment ? 'line-through':'none'}}>{`Comment on 5 answers`}</Form.Check.Label>
                                                        </Form.Check>
                                                    </div>
                                                    <div>
                                                        <Form.Check type={`checkbox`} id={`check-api`}>
                                                            <Form.Check.Input type={`checkbox`} checked={userDetail && userDetail.isShareQuestion ? true : false} />
                                                            <Form.Check.Label style={{textDecoration:userDetail && userDetail.isShareQuestion ? 'line-through':'none'}}>{`Share 5 questions`}</Form.Check.Label>
                                                        </Form.Check>
                                                    </div>
                                                    <div>
                                                        <Form.Check type={`checkbox`} id={`check-api`}>
                                                            <Form.Check.Input type={`checkbox`} checked={userDetail && userDetail.isFollowTopic ? true : false} />
                                                            <Form.Check.Label style={{textDecoration:userDetail && userDetail.isFollowTopic ? 'line-through':'none'}}>{`Follow a topic`}</Form.Check.Label>
                                                        </Form.Check>
                                                    </div>

                                                </Form>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </div>
                                ) : null }
                        </Row>
                        {trendingQuestions ? (
                        <Row >
                            <div className="card-right-wrapper" >
                                <Card className="sidebar-card-width" style={{ marginTop: '14px' }}>
                                    <Card.Body>
                                        <Related
                                            heading="Trending Questions"
                                            list={trendingQuestions}
                                            link="/questionlist"
                                        />
                                    </Card.Body>
                                </Card>
                            </div>
                        </Row>) : null}
                        <Row >
                            <div className="card-right-wrapper" >
                                <Card className="sidebar-card-width" style={{ marginTop: '14px' }}>
                                    <Card.Body>
                                        <Related
                                            heading="Top 5 Blogs"
                                            imageType="2"
                                            list={topBlog}
                                            link="https://lt.englishforward.com/blog/"
                                        />
                                    </Card.Body>
                                </Card>
                            </div>
                        </Row>
                        <Row>
                            <div className="card-right-wrapper" >
                                <Card className="sidebar-card-width" style={{ marginTop: '20px' }}>
                                    <Card.Body>
                                        <Related
                                            heading="Top 5 News"
                                            imageType="2"
                                            list={topNews}
                                            link="https://lt.englishforward.com/news/"
                                        />
                                    </Card.Body>
                                </Card>
                            </div>
                        </Row>
                        {/* <Row>
                        <Card className="sidebar-card-width" style={{ marginTop: '20px' }}>
                            <Card.Body>
                                <Related
                                    heading="Related News"
                                    imageType="2"
                                    list={[{
                                        id: '1',
                                        image: Images.blog1,
                                        text: "Did the design really changes the product? and how?",
                                        post: "July 07, 2020",
                                        by: "John D. Paul"
                                    }, {
                                        id: '2',
                                        image: Images.blog2,
                                        text: "Did the design really changes the product? and how?",
                                        post: "July 07, 2020",
                                        by: "John D. Paul"
                                    }]}
                                    link=""
                                />
                            </Card.Body>
                        </Card>
                    </Row> */}
                    </Col>
                </Row>
            </Container>

        </div>
    )
}
export default FeedList;