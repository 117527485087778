import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';

import Card from 'react-bootstrap/Card'
import { Images } from '../config';
import PropTypes from "prop-types";
import { BaseColor } from '../config/index';
import { useState } from 'react';
import moment from 'moment';
import SweetAlert from 'react-bootstrap-sweetalert';
import { httpPost } from '../Services/HttpServices';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

function BlockCard(props) {
    var auth = useSelector(({ auth }) => auth);

    const [unblock, setUnblock] = useState(null);

    const onConfirm = () => {
        httpPost('block_user_controller/delete', {
            BlockedUser: unblock.BlockedUser && unblock.BlockedUser._id || null,
            MainUser: auth && auth.user && auth.user._id || null,
        }).then(res => {
            if (res) {
                if (res.status) {
                    toast.success(res.message);
                    props.unblockUser(unblock._id);
                } else {
                    toast.error(res.message);
                }
            }
            setUnblock(null);
        }).catch(err => {
            console.log('err', err);
            setUnblock(null);
            toast.error('Network Error')
        })
    }
    //console.log('props',props);
    const onCancel = () => {
        setUnblock(null);
    }

    return (
        <>
            <Card style={{ width: '100%' }} className="mx-3 my-3 px-3 ">
                <Card.Title className="ml-4 mt-3">People blocked by you</Card.Title>
                <Card.Subtitle className="ml-4 text-muted">{props.totalBlocked} blocked</Card.Subtitle>
                {props.blocklist.map((ele, index) => (
                    <Row className="cardStyleListingRow" style={{ borderBottom: "0.5px solid #DEE2E6" }}>
                        <Col md="1" xs="1" sm="1" className="cardStyleListingCol">
                            <Image src={ele.BlockedUser && ele.BlockedUser.ProfilePicture || Images.defaultProfile} className="image-follow-card" />
                        </Col>
                        <Col md="6" xs="9" sm="6" className="cardUserNameCol">
                            <p className="cardUserName">{ele.BlockedUser ? ele.BlockedUser.FirstName + ' ' + ele.BlockedUser.LastName : null}</p>
                            {/* <br></br> */}
                            <p className="cardUserDetails">
                                {moment(ele.CreatedDate, "Do MMM YYYY h:mma").format("Do MMM YYYY")}
                            </p>
                        </Col>
                        <Col md="1"></Col>
                        <Col md="3" xs="12" sm="4">
                            <div className="text-white cardButtonsList" >
                                <Button onClick={() => setUnblock(ele)} className="btn-sm mr-1 px-1" style={{ padding: 0, margin: 0, fontSize: 14, color: BaseColor.whiteColor }} >Unblock</Button>
                            </div>
                        </Col>
                    </Row>
                ))}
                <SweetAlert
                    show={unblock ? true : false}
                    warning
                    showCancel
                    confirmBtnText={"Yes"}
                    cancelBtnText={"No"}
                    title={<span style={{ fontSize: 25 }} >Do you want to unblock this user?</span>}
                    onConfirm={onConfirm}
                    onCancel={onCancel}
                />
            </Card>
        </>
    )
}

BlockCard.prototype = {
    blocklist: PropTypes.array,
    unblockUser: PropTypes.func,
}

BlockCard.defaultProps = {
    blocklist: [],

}
export default BlockCard;

// margin-left: 27px;margin-top: -14px;