import { Link } from "react-router-dom";
import { BaseColor, Images } from "../config";
import { Button, Col, Image, Row, Dropdown, Accordion, Form } from 'react-bootstrap';
import PropTypes from "prop-types";

function SearchFilter(props) {
    return (
        <div className="pt-1 p-2 question-filter">
            <Row className={""} style={{ padding: 20, alignItems: "center" }}>
                <Col md="12" className="heading-div" >
                    <div className="heading">
                        <Image src={Images.filter} className="mr-3" />
                        <h5 class>Filters</h5>
                    </div>
                </Col>
                <Col className="mb-4" md="8" sm="8" xs="8">
                    <label className="filter-head">View List / Grid</label>
                </Col>
                <Col className="mb-4" md="4" sm="4" xs="4">
                    <div className={"toggle-container " + (props.viewType ? "active" : "")} onClick={() => props.changeView(!props.viewType)}>
                        <div className={`dialog-button ${props.viewType ? "" : "disabled"}`}>
                        </div>
                    </div>
                </Col>
                <Col className="mb-4" md="5" sm="4" xs="4">
                    <label className="filter-head">Sort By : </label>
                </Col>
                <Col className="mb-4" md="7" sm="8" xs="8" style={{ marginLeft: '-10px' }}>
                    <Form.Group controlId="exampleForm.SelectCustom">
                        <Form.Control style={{ backgroundColor: BaseColor.whiteColor, border: 0 }} onChange={(e) => { props.changeShort(e.target.value) }} value={null} placeholder="Recomanded" as="select">
                            <option value={null}>Recommended</option>
                            <option value="ascend">Date - ASC</option>
                            <option value="descend">Date - DESC</option>

                        </Form.Control>
                    </Form.Group>
                    {/* <Dropdown className="bg-transparent" >
                        <Dropdown.Toggle value={"1"} className="filter-head" style={{ backgroundColor: BaseColor.whiteColor, border: 0 }} id="dropdown-basic"
                        onSelect={(e)=>{console.log(e)}}
                        >
                            re
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item eventKey="1">Action</Dropdown.Item>
                            <Dropdown.Item eventKey="2">Another action</Dropdown.Item>
                            <Dropdown.Item eventKey="3">Something else</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown> */}
                </Col>

                {/* <Col md="12" className="mt-2">
                    <label className="filter-head">Date :</label>
                </Col>
                <Col md="12" className="ml-3">
                    <Form.Check
                        type={"checkbox"}
                        id={`date`}
                        label={`2 Days`}
                    />

                    <Form.Check
                        //disabled
                        type={"checkbox"}
                        id={`date`}
                        label={`7 Days`}
                    />
                    <Form.Check
                        //disabled
                        type={"checkbox"}
                        id={`answers`}
                        label={`30 Days`}
                    />
                </Col>
                <Col md="12" className="mt-2">
                    <label className="filter-head">Answers :</label>
                </Col>
                <Col md="12" className="ml-3">
                    <Form.Check
                        type={"checkbox"}
                        id={`answers`}
                        label={` > 10`}
                    />

                    <Form.Check
                        //disabled
                        type={"checkbox"}
                        id={`answers`}
                        label={` > 50`}
                    />
                    <Form.Check
                        //disabled
                        type={"checkbox"}
                        id={`answers`}
                        label={` > 100`}
                    />
                </Col> */}

                {/* 
                    <Accordion >

                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                            Date
                        </Accordion.Toggle>

                        <Accordion.Collapse eventKey="0">
                            <Card.Body>Body content for panel 1</Card.Body>
                        </Accordion.Collapse>
                        <Col md="12">
                        </Col>
                        <Accordion.Toggle as={Button} variant="link" eventKey="1">
                            Answers
                        </Accordion.Toggle>

                        <Accordion.Collapse eventKey="1">
                            <Card.Body>Body content for panel 2</Card.Body>
                        </Accordion.Collapse>
                        <Accordion.Toggle as={Button} variant="link" eventKey="2">
                            Answers
                        </Accordion.Toggle>

                        <Accordion.Collapse eventKey="1">
                            <Card.Body>Body content for panel 2</Card.Body>
                        </Accordion.Collapse> 
                    </Col>
                </Accordion>*/}

            </Row>
        </div >
    )
}

SearchFilter.prototype = {
    filter: PropTypes.object,
    viewType: PropTypes.bool,
    changeView: PropTypes.func,
    changeShort:PropTypes.func,
}

SearchFilter.defaultProps = {
    filter: {},
    viewType: false,
    changeView: () => { },
    changeShort: () => { },
}
export default SearchFilter;

// margin-left: 27px;margin-top: -14px;