import { Link } from "react-router-dom";
import { BaseColor, Images } from "../config";

import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';

import PropTypes from "prop-types";
import moment from 'moment';
import 'moment-timezone';
function CommentListTab(props) {
    let { comment } = props;
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return (
        <>
            <Row>
                <Col className="" md="12" >
                    <div className="row mt-2 p-2 p-md-3 p-lg-3 question-list-tab" style={{ backgroundColor: BaseColor.whiteColor, borderWidth: 'thin', borderRadius: 5, border: "0.1px solid", borderColor: BaseColor.grayColor, boxShadow: "0px 34px 39px -42px rgba(0, 0, 0, 0.2);" }}>
                        <Col md="12" xs="12" className="mb-2">
                            {comment.QuestionID && (
                                <Link to={"/question/" + comment.QuestionID.QuestionUrl} style={{ textDecoration: "none" }}>
                                    {/* target="_blank" */}
                                    {/* <h2 className="comment mt-2">{comment.QuestionID && comment.QuestionID.Subject}</h2> */}
                                    <h2 className="question mt-2" dangerouslySetInnerHTML={{ __html: comment.QuestionID && comment.QuestionID.Subject }}></h2>
                                    {comment.AnswerID && comment.AnswerID.Answer && <div className="answer-detail"  dangerouslySetInnerHTML={{ __html: (comment.AnswerID.Answer)}} ></div>}
                                </Link>
                            )}
                        </Col>
                        <Col md="1" xs="2" className="pl-0 pl-md-2 pl-lg-2">
                            <Image className="profile-pic" src={comment.UserID && comment.UserID.ProfilePicture ? comment.UserID.ProfilePicture : Images.defaultProfile} width={40} height={40} roundedCircle />
                            <div className="mt-2" style={{ flex: '1', alignSelf: 'center' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    {comment.IsVoted && comment.UpVote ? (<Image src={Images.upActive} height={15} width={25} />) : (<Image src={Images.up} height={15} width={25} />)}
                                    <span style={{ fontSize: 12 }}>{comment.percent}</span>
                                    {comment.IsVoted && comment.DownVote ? (<Image src={Images.downActive} height={15} width={25} />) : (<Image src={Images.down} height={15} width={25} />)}
                                </div>
                            </div>
                        </Col>
                        <Col md="11" xs="10" className="p-0" >
                            <div>
                                <span className="user-name">
                                    <Link to={"/profile/" + comment.UserID.UserName} >
                                        {comment.UserID && comment.UserID.FirstName + " " + comment.UserID.LastName}
                                    </Link>
                                </span>
                                <small className="post-date float-right">{"Posted " + moment.tz(comment.CreatedDate, timeZone).fromNow()}</small>
                            </div>
                            <p className="detail mt-2 mb-3" dangerouslySetInnerHTML={{ __html: comment.CommentText}} ></p>
                            {/*<div className="mt-2" style={{ flex: '9', display: 'flex', flexDirection: 'column' }}>
                                <div className="action" >
                                     marginRight: 50, 
                                    <label style={{ marginLeft: 20,marginBottom: 0 }}><Image src={Images.up} />&nbsp;<span>{comment.UpvoteCount}</span></label>
                                    <label style={{ marginBottom: 0 }}><Image src={Images.comment} width={15} height={15} />&nbsp;<span>{comment.ReportCount}</span></label>
                                    <label style={{ marginLeft: 20, marginBottom: 0 }}><Image src={Images.forward} />&nbsp;<span>{comment.ShareCount}</span></label>
                                </div>
                            </div>*/}
                        </Col>

                    </div>
                </Col>
            </Row>
        </>
    )
}

CommentListTab.prototype = {
    comment: PropTypes.object
}

CommentListTab.defaultProps = {
    comment: {}
}
export default CommentListTab;