import firebase from "firebase";
import "firebase/auth";

/* Put here your own firebase config */
const app = firebase.initializeApp({
    // apiKey: "AIzaSyCI5MlqDYhTs-q0cK8EOQpPG0_1-ommQuk",
    // authDomain: "master-chat-demo.firebaseapp.com",
    // projectId: "master-chat-demo",
    // storageBucket: "master-chat-demo.appspot.com",
    // messagingSenderId: "1021029417429",
    // appId: "1:1021029417429:web:fe60327a5edd442176fd5c"

    apiKey: "AIzaSyCejReQns1An2GSd0AkoswyEg28cvjZr8k",
    authDomain: "english-forward.firebaseapp.com",
    // databaseURL: "https://english-forward.firebaseio.com",
    projectId: "english-forward",
    storageBucket: "english-forward.appspot.com",
    messagingSenderId: "479309430443",
    appId: "1:479309430443:web:baea0a95ccfdeaaf9141ee"
});

const db = firebase.firestore();
const rdb = firebase.database();
const sdb = firebase.storage();
const auth = app.auth()

export {db, auth, rdb, sdb}
export default app