import React, { useState, useEffect } from 'react';
import { Col, Row, Nav, Tab } from 'react-bootstrap';
import { httpPost } from '../Services/HttpServices';
import { useSelector } from 'react-redux';
import DraftQuestion from '../Component/DraftQuestion';
import AnswerRequested from '../Component/AnswerRequested';
const UserDraft = () => {
    var auth = useSelector(({ auth }) => auth);
    const [drafts, setDrafts] = useState([]);
    const [answerReq, setAnswerReq] = useState([]);
    useEffect(() => {
        getDrafts();
        getAnswerReq();
    }, [])

    const getDrafts = () => {
        httpPost('draft_answer_controller/list', {})
            .then(res => {
                setDrafts(res && res.data || []);
            })
    }
    const getAnswerReq = () => {
        httpPost('answer_request_controller/pending_answer_request_list', {})
            .then(res => {
                console.log('answ req', res);
                setAnswerReq(res && res.data || []);
            })
    }
    return (
        <div className="p-4 p-md-5 p-lg-5 p-sm-4 draft" >
            <Row>
                <Tab.Container id="left-tabs-example" defaultActiveKey="draft">
                    <Col md={{ offset: '1', span: '3' }} >
                        <Col className="d-list" md="12" >
                            <h3 className='d-head'>Questions</h3>
                            <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                    <Nav.Link eventKey="draft" defaultActiveKey>Drafts</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="ans-req">Answer Requested</Nav.Link>
                                </Nav.Item>
                            </Nav>

                        </Col>
                    </Col>
                    <Col md="7" className=''>
                        <Tab.Content>
                            <Tab.Pane eventKey="draft" >
                                <h3 className='d-head'>Drafts {drafts && drafts.length || ""}</h3>
                                {drafts.length > 0 ? (drafts && drafts.map(ele => (
                                    <DraftQuestion
                                        key={ele._id}
                                        question={ele}
                                        updateList={() => {
                                            getDrafts();
                                        }}
                                    />
                                ))) : (<div class="text-center mt-2" >No data found</div>)}
                            </Tab.Pane>
                            <Tab.Pane eventKey="ans-req">
                                <h3 className='d-head'>Answers {answerReq && answerReq.length || ""}</h3>
                                {answerReq.length > 0 ? (answerReq && answerReq.map(ele => (
                                    <AnswerRequested
                                        key={ele._id}
                                        question={ele}
                                        updateList={() => {
                                            getAnswerReq();
                                        }}
                                    />
                                ))) : (<div class="text-center mt-2" >No data found</div>)}
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Tab.Container>
            </Row>
        </div>
    )
}

export default UserDraft;