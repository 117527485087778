import { Row, Col, Image, Button, Spinner } from 'react-bootstrap';
import Card from 'react-bootstrap/Card'
import { Images } from '../config';
import BlockCard from '../Component/BlockCard';
import { httpPost } from '../Services/HttpServices';
import { useSelector } from 'react-redux';
import { useState, useEffect, useRef } from 'react';

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    }, [value]); // Only re-run if value changes
    return ref.current;
}

function BlockList() {
    var auth = useSelector(({ auth }) => auth);
    const [loading, setLoading] = useState(false);
    const [blocklist, setBlockList] = useState({
        page: 1,
        totalCount: 0,
        list: []
    })
    const prevList = usePrevious(blocklist);
    useEffect(() => {
        //console.log('blocklist.page',blocklist.page);
        setLoading(true);
        getList();
    }, [blocklist.page])

    useEffect(() => {
        if (prevList && (prevList.totalCount > blocklist.totalCount)) {
            //Get List if deleted
            getList();
        }
    }, [blocklist.totalCount])
    useEffect(() => {
        getList()
    }, [])
    const getList = () => {
        //601e656b458d741a53d7c5df
        //auth && auth.user && auth.user._id, page: blocklist.page
        httpPost('block_user_controller/list', { MainUser: auth && auth.user && auth.user._id, page: blocklist.page })
            .then(res => {
                setLoading(false);
                console.log(res);
                if (res && res.status) {
                    var listAppend = [];
                    var oldArr = [...blocklist.list];
                    var blockData = res.data;
                    blockData.map(ele => {
                        var ind = oldArr.findIndex(oldEle => oldEle._id == ele._id);
                        if (ind == -1) {
                            listAppend.push(ele);
                        }
                    })
                    var newArr = oldArr.concat(listAppend);
                    setBlockList({ ...blocklist, totalCount: res.totalCount, list: newArr })
                }
            })
    }
    return (
        <Row className="no-gutters">
            <Col md="3"></Col>
            <Col md="6">
                <BlockCard blocklist={blocklist.list} totalBlocked={blocklist.totalCount}
                    unblockUser={(id) => {
                        console.log('id', id);
                        var arr = [...blocklist.list];
                        var newArr = arr.filter(ele => ele._id != id);
                        //ele.BlockedUser && ele.BlockedUser._id
                        setBlockList({ ...blocklist, totalCount: blocklist.totalCount - 1, list: newArr });
                    }} />
                <div className="mx-3 my-3">
                    {loading ?
                        <Spinner animation="border" variant="primary" />
                        : blocklist.totalCount && blocklist.totalCount > blocklist.list.length ? //
                            <Button size="sm" onClick={() => { setBlockList({ ...blocklist, page: blocklist.page + 1 }) }}>see more</Button>
                            : null}
                </div>
            </Col>
            <Col md="3"></Col>
        </Row>
    )
}
export default BlockList;